import { useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import Router from "./routing/router";
import { Provider } from "react-redux";
import { store } from "./store";
import setupInterceptors from "./apiService/setupInterceptors";
// import { useBuildVersion } from "./hooks/useBuildVersion";
import AlertDialog from "./components/funnel-form/alert-dialog";
import "./App.css";
import "./components/variables.css";
import "react-toastify/dist/ReactToastify.css";
import { DialogProvider } from "./context/dialog-context";

setupInterceptors(store);

const App = () => {
  const counterRef = useRef();
  const [showAlert, setShowAlert] = useState(false);
  // const { isNewVersionAvailable } = useBuildVersion();

  // useEffect(() => {
  //   if (isNewVersionAvailable) {
  //     setShowAlert(true);
  //     let timeLeft = 30;
  //     const countdown = () => {
  //       if (timeLeft === -1) {
  //         window.location.reload();
  //       } else {
  //         counterRef.current.innerHTML = timeLeft;
  //         timeLeft--;
  //       }
  //     };
  //     setInterval(countdown, 1000);
  //   }
  // }, [isNewVersionAvailable]);
  return (
    <BrowserRouter>
      <Provider store={store}>
        <DialogProvider>
          <Router />
          <ToastContainer
            theme="dark"
            autoClose={2000}
            hideProgressBar="true"
            position="bottom-right"
          />
          <AlertDialog
            description={
              <p>
                There's a new version of the Application. App is going to reload
                in <span ref={counterRef}> 30</span> seconds
              </p>
            }
            show={showAlert}
            setShow={setShowAlert}
            dialogType="warning"
            title="Warning"
            showFooter={false}
          />
        </DialogProvider>
      </Provider>
    </BrowserRouter>
  );
};
export default App;
