import { createSlice } from "@reduxjs/toolkit";
import authConfig from "../../config/auth";

const initialState = {
  user: null,
  loading: true,
  redirect: null
};
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initApp(state, action) {
      state.loading = false;
      if (
        !action.payload?.value?.includes("login") &&
        action.payload?.type === "redirect"
      ) {
        state.redirect = action.payload.value;
      }
    },
    logout(state, action) {
      if (
        !action.payload?.value?.includes("login") &&
        action.payload?.type === "redirect"
      ) {
        state.redirect = action.payload.value;
      }
      window.localStorage.removeItem(authConfig.userData);
      window.localStorage.removeItem(authConfig.storageTokenKeyName);
      state.user = null;
      state.loading = false;
    },
    login(state, action) {
      state.redirect = null;
      const { userData, token } = action.payload.data;
      localStorage.setItem(authConfig.userData, JSON.stringify(userData));
      localStorage.setItem(authConfig.storageTokenKeyName, token);
      state.user = userData;
    },
    setMe(state, action) {
      localStorage.setItem(
        authConfig.userData,
        JSON.stringify(action.payload.userData)
      );
      state.user = action.payload.userData;
      state.loading = false;
    },
    setPrimaryFbToken(state, action) {
      const { userId } = action.payload;
      const workspace = state.user.workspace;
      const { fbTokens } = workspace;

      state.user.workspace = {
        ...workspace,
        noValidPrimaryTokenExists: false,
        fbTokens: fbTokens.map((fbToken) => ({
          ...fbToken,
          isPrimary: false,
          ...(userId === fbToken.userId._id
            ? { isPrimary: true, isTokenInvalid: false }
            : undefined)
        }))
      };

      localStorage.setItem(authConfig.userData, JSON.stringify(state.user));
    },
    connectToFacebook(state, action) {
      const { userId } = action.payload;
      const workspace = state.user.workspace;
      const { fbTokens } = workspace;

      state.user = {
        ...state.user,
        isConnectedToFb: true,
        workspace: {
          ...workspace,
          noValidPrimaryTokenExists: false,
          fbTokens: fbTokens.map((fbToken) => ({
            ...fbToken,
            isTokenInvalid:
              userId === fbToken.userId._id || fbToken.isTokenInvalid
          }))
        }
      };

      localStorage.setItem(authConfig.userData, JSON.stringify(state.user));
    },
    setLeadServiceScript(state, action) {
      state.user.workspace.leadServiceScript = action.payload;
    },
    setLeadServicePlusScript(state, action) {
      state.user.workspace.leadServicePlusScript = action.payload;
    }
  }
});

export const {
  initApp,
  logout,
  login,
  setMe,
  setPrimaryFbToken,
  connectToFacebook,
  setLeadServiceScript,
  setLeadServicePlusScript
} = authSlice.actions;
export default authSlice.reducer;
