import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NESTED_SIDENAV, SETTINGS } from "../strings";
import NestedTabsComponent from "../nested-tabs-component";

const UserLayout = () => {
  const { t } = useTranslation();

  return (
    <div className="d-flex h-100">
      <div className="sub-sidebar">
        <div className="inner-sub-sidebar">
          <div className="tab-heading ps-3 mt-4">{t("sidebar.settings")}</div>
          <NestedTabsComponent
            nestedNavType={NESTED_SIDENAV.SETTING}
            tabs={[
              { evKey: SETTINGS.PROFILE, title: t("sidebar.profile") },
              {
                evKey: SETTINGS.SECURITY,
                title: t("common.security")
              },
              {
                evKey: SETTINGS.CHANGE_PASSWORD,
                title: t("common.changePassword")
              }
            ]}
          />
        </div>
      </div>
      <div className="outlet-wrapper">
        <Outlet />
      </div>
    </div>
  );
};
export default UserLayout;
