import styled from "styled-components";

const Message = styled.div`
  height: ${({ height }) => height};
`;
const ValidationMessage = ({ error, height = "auto" }) => {
  return (
    <Message height={height} className="text-danger pt-1">
      {error}
    </Message>
  );
};
export default ValidationMessage;
