import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categoryCount: 0,
  customerCount: 0,
  hubspotCount: 0,
  leadCount: 0,
  leadUTMCount: 0,
  adAccountCount: 0
};
export const missingInfoSlice = createSlice({
  name: "missingInfoCount",
  initialState,
  reducers: {
    setMissingInfoCount(state, action) {
      state.leadCount = action.payload.leadCount || 0;
      state.customerCount = action.payload.customerCount || 0;
      state.categoryCount = action.payload.categoryCount || 0;
      state.hubspotCount = action.payload.hubspotCount || 0;
      state.leadUTMCount = action.payload.leadUTMCount || 0;
      state.adAccountCount = action.payload.adAccountCount || 0;
    }
  }
});

export const { setMissingInfoCount } = missingInfoSlice.actions;
export default missingInfoSlice.reducer;
