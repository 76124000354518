import { Controller } from "react-hook-form";
import Select, { components } from "react-select";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";

const SelectField = ({
  control,
  options = [],
  name,
  onSelect,
  optionLabel,
  optionValue,
  placeholder,
  isMulti = false,
  isCapitalize = true,
  isDisabled = false,
  forAdAccount = false,
  showIdWithName = false
}) => {
  const { t } = useTranslation();
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <Spinner animation="border" size="sm" className="me-2" />
      </components.DropdownIndicator>
    );
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <>
          <Select
            isDisabled={isDisabled}
            placeholder={placeholder || t("common.select")}
            {...field}
            onChange={(option, action) => {
              if (option.id && forAdAccount) {
                option._id = option.id;
                delete option.id;
              }
              field.onChange(option);
              if (onSelect) onSelect(option, action);
            }}
            components={isDisabled && { DropdownIndicator }}
            className={`w-100 ${isCapitalize && "text-capitalize"}`}
            getOptionLabel={(option) =>
              typeof option.name === "string"
                ? showIdWithName
                  ? `${t(option.name)} (${option.id})`
                  : t(option.name)
                : option.name || option[optionLabel]
            }
            getOptionValue={(option) =>
              option._id || option.id || option[optionValue]
            }
            options={options}
            isMulti={isMulti}
          />
        </>
      )}
    />
  );
};

export default SelectField;
