import {
  Badge,
  Tooltip,
  Accordion,
  ListGroup,
  OverlayTrigger
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CopyToClipboardButton from "../copy-to-clipboard-button";
import { getEuroCurrencyData } from "../../services/common-service";

const CreativeFeedSpecAccordionItem = ({ evKey, feedSpec, headerText }) => {
  const { t } = useTranslation();
  const generateTextInsight = (text) =>
    feedSpec.insights.find((insight) => insight.text === text);

  return (
    <Accordion.Item eventKey={evKey}>
      <Accordion.Header>{headerText}</Accordion.Header>
      <Accordion.Body>
        {feedSpec.collection.length ? (
          <ListGroup>
            {feedSpec.collection.map((item, idx) => (
              <ListGroup.Item key={idx} className="d-flex mb-1 rounded">
                <div className="d-flex flex-fill align-items-center">
                  {item.text}
                </div>
                <div className="d-flex align-items-baseline ms-2">
                  <CopyToClipboardButton
                    copyWhat="Text"
                    linkToCopy={item.text}
                    customClasses="rounded-circle me-2"
                  />
                  <OverlayTrigger
                    overlay={
                      <Tooltip>{t("advancedFilter.fbSort.leadCount")}</Tooltip>
                    }
                  >
                    <Badge className="me-2" pill>
                      {generateTextInsight(item.text)?.leads || 0}
                    </Badge>
                  </OverlayTrigger>
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        {t("advancedFilter.fbSort.costPerLead")}
                      </Tooltip>
                    }
                  >
                    <Badge pill>
                      {getEuroCurrencyData(
                        generateTextInsight(item.text)?.costPerLead || 0
                      )}
                    </Badge>
                  </OverlayTrigger>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : (
          <span className="ms-2 fw-bold">{t("noData")}</span>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default CreativeFeedSpecAccordionItem;
