import React from "react";
import { Col, Form } from "react-bootstrap";
import ClearFilterButton from "./clear-filter-button";

const SelectStructure = ({
  label,
  active,
  activeKey = label,
  clearFilter,
  children
}) => {
  return (
    <Col sm={12} md={5}>
      <Form.Group controlId={label} className="d-flex align-items-center mb-2">
        <Col sm={4} md={2} className="me-2 me-lg-0">
          <Form.Label className="w-100 mb-0 text-capitalize">
            {label}:
          </Form.Label>
        </Col>
        <Col sm={8} md={3} className="flex-fill">
          <div className="d-flex align-items-center">
            {children}
            {active && (
              <ClearFilterButton param={activeKey} clearFn={clearFilter} />
            )}
          </div>
        </Col>
      </Form.Group>
    </Col>
  );
};

export default SelectStructure;
