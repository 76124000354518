import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const ClearFilterButton = ({
  param,
  clearFn,
  paramRef,
  clearState = () => {}
}) => {
  const { t } = useTranslation();
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip>{t("delete")}</Tooltip>}
    >
      <Button
        className="ms-2"
        variant="outline-danger"
        onClick={() => {
          clearFn(param);
          clearState("");
          if (paramRef) {
            paramRef.current.initialize = true;
          }
        }}
      >
        <FontAwesomeIcon icon={faFilterCircleXmark} />
      </Button>
    </OverlayTrigger>
  );
};
export default ClearFilterButton;
