import styled from "styled-components";
import { Spinner } from "react-bootstrap";

const AbsoluteOverlay = styled.div`
  z-index: 1;
  background: rgb(255 255 255 / 75%);
`;
const AbsoluteLoaderWithOverlay = () => (
  <AbsoluteOverlay className="w-100 h-100 position-absolute d-flex align-items-center justify-content-center">
    <Spinner animation="border" />
  </AbsoluteOverlay>
);
export default AbsoluteLoaderWithOverlay;
