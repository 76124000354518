import { Routes, Route, Outlet } from "react-router-dom";
import SiteSettings from "../../components/site-settings/site-settings";
import TokenSettingsTable from "../../components/site-settings/token-settings-table";

const SiteSettingsPage = () => {
  return (
    <>
      <Routes>
        <Route element={<SiteSettings />}>
          <Route index element={<TokenSettingsTable />} />
        </Route>
      </Routes>
      <Outlet />
    </>
  );
};
export default SiteSettingsPage;
