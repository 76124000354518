import React, { useRef, useEffect, useCallback } from "react";
import { Chart } from "chart.js";
import { Card } from "react-bootstrap";
import "./kpi-tile.css";

const KPITile = ({ data, title, delta, config, bulletColor, bulletColors }) => {
  const svgRef = useRef();
  const canvasRef = useRef();

  const createGraph = () => {
    const ctx = svgRef.current;
    if (canvasRef.current) {
      canvasRef.current.destroy();
    }
    canvasRef.current = new Chart(ctx, config);
  };

  const createGraphCB = useCallback(createGraph, [config]);

  useEffect(() => {
    return () => {
      if (canvasRef.current) {
        canvasRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (!config || !svgRef.current) return;

    createGraphCB();
  }, [config, createGraphCB]);

  return (
    <Card className={`kpi-tile`}>
      <div className="tile-wrapper">
        <div className="tile-header">
          <div className="bullet-wrapper">
            {bulletColor && (
              <div
                className="bullet"
                style={{ backgroundColor: bulletColor }}
              />
            )}
            {bulletColors && (
              <div className="rect-bullet-wrapper">
                {bulletColors.map((bulletColor, idx) => (
                  <div
                    key={idx}
                    className="rect-bullet"
                    style={{ backgroundColor: bulletColor }}
                  />
                ))}
              </div>
            )}
            <h1 className="heading">{data}</h1>
          </div>
          <p className="text-secondary sub-heading">{title}</p>
          {delta !== undefined ? (
            delta > 0 ? (
              <div>
                <span
                  className="sub-heading"
                  style={{
                    color: "#20c997"
                  }}
                >
                  <svg
                    style={{ marginRight: "10px", width: "15px" }}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <g>
                      <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                      <polyline points="17 6 23 6 23 12"></polyline>
                    </g>
                  </svg>
                  {delta}%
                </span>
              </div>
            ) : delta < 0 ? (
              <div>
                <span
                  className="sub-heading"
                  style={{
                    color: "rgb(255, 77, 79)"
                  }}
                >
                  <svg
                    style={{ marginRight: "10px", width: "15px" }}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <g>
                      <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                      <polyline points="17 18 23 18 23 12"></polyline>
                    </g>
                  </svg>
                  {Math.abs(delta)}%
                </span>
              </div>
            ) : (
              <div>
                <span className="text-secondary sub-heading">0%</span>
              </div>
            )
          ) : (
            <></>
          )}
        </div>
        <div>
          {config && <canvas style={{ marginLeft: -5 }} ref={svgRef}></canvas>}
        </div>
      </div>
    </Card>
  );
};

export default KPITile;
