import { useState } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import TextField from "../reusable-form-fields/text-field";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { login } from "../../store/slices/auth";
import { showError, showSuccess } from "../../services/toast-service";
import { loginUser } from "../../services/api-declaration";
import { LoginFormSchema } from "../../services/validation-schema";
import AuthTemplate from "./auth-template";
import SubmitButton from "./submit-button";
import PasswordToggle from "../change-password/field-with-toggle";
import OTPLogin from "./otp-login";
import { MULTI_FACTOR_TYPES } from "../strings";
import { useTranslation } from "react-i18next";

const LoginPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fieldType, setFieldType] = useState("password");
  const [otpLoginData, setOTPLoginData] = useState({
    show: false,
    authenticators: []
  });
  const handleLogin = async (formValues) => {
    try {
      setIsSubmitting(true);
      const { data } = await loginUser(formValues);
      const userData = data?.data?.userData;
      if (
        userData.multiFactorEnabled.email &&
        userData.multiFactorEnabled.authenticator
      ) {
        setOTPLoginData({
          show: true,
          authenticators: []
        });
        return;
      } else if (userData.multiFactorEnabled.email) {
        setOTPLoginData({
          show: true,
          authenticators: [{ text: "Email", type: MULTI_FACTOR_TYPES.EMAIL }]
        });
        return;
      }
      dispatch(login(data));
      showSuccess(t("loginSuccessFull"));
    } catch (error) {
      console.log(error);
      showError(error.response?.data?.message || t("toastMessage.wentWrong"));
    } finally {
      setIsSubmitting(false);
    }
  };

  const togglePasswordField = () =>
    setFieldType(fieldType === "password" ? "text" : "password");

  const methods = useForm({
    defaultValues: { email: "", password: "" },
    resolver: yupResolver(LoginFormSchema)
  });
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = methods;
  return (
    <FormProvider {...methods}>
      {otpLoginData.show ? (
        <OTPLogin
          email={getValues("email")}
          password={getValues("password")}
          authenticators={otpLoginData.authenticators}
        />
      ) : (
        <AuthTemplate title={t("common.login")}>
          <Form onSubmit={handleSubmit(handleLogin)}>
            <TextField
              type="email"
              name="email"
              register={register}
              label={t("common.email")}
              placeholder={t("common.enterEmail")}
              errors={errors?.email}
            />
            <PasswordToggle
              label={t("password")}
              type={fieldType}
              name="password"
              error={errors?.password}
              toggleHandler={togglePasswordField}
              thinLabel
            />
            <hr />
            <div className="d-flex justify-content-between align-items-center">
              <SubmitButton
                btnText={t("common.login")}
                icon={faRightToBracket}
                isSubmitting={isSubmitting}
                spinnerBtnText={`${t("common.login")}...`}
              />
              <Link to="/forgot">
                <p className="m-0">{t("common.forgotPassword")}</p>
              </Link>
            </div>
          </Form>
        </AuthTemplate>
      )}
    </FormProvider>
  );
};
export default LoginPage;
