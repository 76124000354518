import Form from "react-bootstrap/Form";
import ValidationMessage from "../validation-message/validation-message";

const TextArea = ({
  label,
  name,
  placeholder,
  register,
  rows = "4",
  errors = null,
  noSpacing = false
}) => {
  return (
    <Form.Group controlId={label} className={!noSpacing ? "mb-3" : ""}>
      {label ? <Form.Label>{label}</Form.Label> : ""}
      <Form.Control
        rows={rows}
        as="textarea"
        {...register(name)}
        placeholder={placeholder}
        className={errors ? "border-danger" : ""}
      />
      {errors && <ValidationMessage error={errors?.message} />}
    </Form.Group>
  );
};
export default TextArea;
