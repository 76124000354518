import { Button } from "react-bootstrap";
import { forwardRef, useImperativeHandle } from "react";
import { useOutletContext, useParams } from "react-router";
import {
  showError,
  showSuccess,
  showWarning
} from "../../services/toast-service";
import { FieldSet } from "../common-styling";
import { useDialog } from "../../hooks/useDialog";
import { useNotification } from "../../hooks/useNotification";
import { integrateFunnel } from "../../services/api-declaration";
import { FUNNEL_TYPE_FILTER, INTEGRATION_TYPE, SEND_DATA } from "../strings";
import IntegrationsTableView from "./integration-view/integrations-table-view";
import { useTranslation } from "react-i18next";

/* eslint-disable react/display-name --- for forwardRef --- */
const ContactCloud = forwardRef(
  ({ contactCloudIntegrations = [], setIntegrations }, ref) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { funnel } = useOutletContext();
    const { showToastWithLink } = useNotification();
    const { showContactCloudDialogBox } = useDialog();

    useImperativeHandle(ref, () => ({
      handleContactCloudOnDisableLeadService() {
        handleUpdateIntegration({
          _sendData: SEND_DATA.IMMEDIATELY,
          _active: contactCloudIntegrations[0].active,
          forStatus: false
        });
      }
    }));

    const toggleContactCloudDialog = (formData) =>
      showContactCloudDialogBox({
        funnel,
        funnelId: id,
        setIntegrations,
        formData
      });
    const handleUpdateIntegration = async ({
      _sendData,
      _active,
      forStatus
    }) => {
      try {
        const { tenantId, userId, productId, jobId } =
          contactCloudIntegrations[0].sourceData.meta;
        const body = {
          funnelType: funnel.general.funnelType,
          integrationType: INTEGRATION_TYPE.CONTACT_CLOUD,
          tenantId,
          userId,
          productId,
          jobId,
          sendData: _sendData,
          active: _active
        };
        await integrateFunnel(id, body);
        setIntegrations((data) => {
          const clone = [...data.contactCloud];
          if (forStatus) {
            clone[0].active = _active;
          } else {
            clone[0].sourceData.meta.sendData = _sendData;
          }
          data.contactCloud = clone;
          return { ...data };
        });
        showSuccess(t("funnelIntegration.updateToastMsg"));
      } catch (error) {
        console.log(error);
        showError(
          error.response.data.message ||
            t("funnelIntegration.updateIntegration")
        );
      }
    };

    return (
      <div className="px-3">
        <FieldSet>
          {contactCloudIntegrations.length ? (
            <>
              <IntegrationsTableView
                headers={[
                  { name: "Status", colWidth: 1, style: "" },
                  { name: "Tenant ID", colWidth: null, style: "pb-1" },
                  {
                    name: `${t("common.user")} ID`,
                    colWidth: null,
                    style: "pb-1"
                  },
                  {
                    name:
                      funnel.general.funnelType === FUNNEL_TYPE_FILTER.EMPLOYEE
                        ? `${t("common.job")} ID`
                        : `${t("common.product")} ID`,
                    colWidth: null,
                    style: "pb-1"
                  },
                  {
                    name: t("funnelIntegration.sendDataHeading"),
                    colWidth: null,
                    style: "pb-1"
                  },
                  {
                    name: "Action",
                    colWidth: 2,
                    style: "pb-1 text-end"
                  }
                ]}
                integrations={contactCloudIntegrations}
                setIntegrations={setIntegrations}
                propertiesToRender={[
                  "sourceData.meta.tenantId",
                  "sourceData.meta.userId",
                  funnel.general.funnelType === FUNNEL_TYPE_FILTER.EMPLOYEE
                    ? "sourceData.meta.jobId"
                    : "sourceData.meta.productId",
                  "sourceData.meta.sendData"
                ]}
                onEditClick={toggleContactCloudDialog}
              />
            </>
          ) : (
            <Button
              className="w-100"
              variant="outline-primary"
              onClick={() => {
                funnel?.customer?.contactCloudId
                  ? toggleContactCloudDialog(null)
                  : showToastWithLink({
                      url: `/dashboard/customers/${funnel?.customer?._id}/edit`,
                      msg: t("toastMessage.noTenantIdWarning"),
                      showToast: showWarning
                    });
              }}
            >
              {t("funnelIntegration.contactCloud.contactCloudSetup")}
            </Button>
          )}
        </FieldSet>
      </div>
    );
  }
);

export default ContactCloud;
