import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  faPhone,
  faBuilding,
  faEnvelope,
  faAddressCard,
  faEnvelopeOpenText
} from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Row } from "react-bootstrap";
import { useLocation, useParams } from "react-router";
import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getAllLeads,
  getAllFunnels,
  getCustomerById
} from "../../services/api-declaration";
import { CustomCard } from "../common-styling";
import { showError } from "../../services/toast-service";
import { useTranslation } from "react-i18next";

const FAIcon = styled(FontAwesomeIcon)`
  color: #686868;
`;
const StatsItem = styled.div`
  width: 125px !important;
  border-style: dashed !important;
  text-align: center;
`;
const StatsHeadings = styled.div`
  color: var(--text-gray-muted);
`;

const CustomerOverview = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { state } = useLocation();
  const [customer, setCustomer] = useState(state?.customer);
  const [totalFunnelsDocs, setTotalFunnelsDocs] = useState(0);
  const [totalLeadsDocs, setTotalLeadsDocs] = useState(0);

  const customerFunnels = async () => {
    try {
      if (customer) {
        const customerFunnels = await getAllFunnels({
          customer: customer._id
        });
        setTotalFunnelsDocs(customerFunnels.data?.data.totalDocs);
      }
    } catch (error) {
      console.log(error);
      showError(
        error.response.data.message ||
          t("toastMessage.errorInGetting", { what: "funnels" })
      );
    }
  };
  const customerLeads = async () => {
    try {
      if (customer) {
        const customerLeads = await getAllLeads({
          customer: customer._id
        });
        setTotalLeadsDocs(customerLeads.data?.data.totalDocs);
      }
    } catch (error) {
      console.log(error);
      showError(
        error.response.data.message ||
          t("toastMessage.errorInGetting", { what: "leads" })
      );
    }
  };

  const customerFunnelsCb = useCallback(
    customerFunnels,
    // eslint-disable-next-line
    [customer]
  );
  const customerLeadsCb = useCallback(
    customerLeads,
    // eslint-disable-next-line
    [customer]
  );

  useEffect(() => {
    customerFunnelsCb();
  }, [customerFunnelsCb]);
  useEffect(() => {
    customerLeadsCb();
  }, [customerLeadsCb]);
  useEffect(() => {
    if (!customer && params.id) {
      const getCustomer = async () => {
        try {
          const response = await getCustomerById(params.id);
          setCustomer(response.data?.data);
        } catch (err) {
          console.log(err);
          showError(
            err.response.data.message ||
              t("toastMessage.errorInGetting", {
                what: t("common.customer")
              })
          );
        }
      };
      getCustomer();
    }
    // eslint-disable-next-line
  }, [customer, params.id]);

  return (
    <div className="my-4">
      <div>
        <Row>
          <Col>
            <CustomCard>
              <Card.Body className="d-flex flex-center flex-column">
                <div>
                  <h6>{t("customerInfo")}</h6>
                  <div className="ms-2 mt-3">
                    <div className="p-2 bg-light mb-2">
                      <FAIcon icon={faBuilding} className="me-3" />
                      {customer?.companyName || "N/A"}
                    </div>
                    <div className="p-2 bg-light mb-2">
                      <FAIcon icon={faEnvelope} className="me-3" />
                      {customer?.email || "N/A"}
                    </div>
                    <div className="p-2 bg-light mb-2">
                      <FAIcon icon={faPhone} className="me-3" />
                      {customer?.phone || "N/A"}
                    </div>
                    <div className="p-2 bg-light mb-2">
                      <FAIcon icon={faAddressCard} className="me-3" />
                      {customer?.address || "N/A"}
                    </div>
                    <div className="p-2 bg-light">
                      <FAIcon icon={faEnvelopeOpenText} className="me-3" />
                      {customer?.zipCode || "N/A"}
                    </div>
                  </div>
                </div>
              </Card.Body>
            </CustomCard>
          </Col>
          <Col>
            <CustomCard>
              <Card.Body className="d-flex flex-center flex-column">
                <div>
                  <h6>{t("summary")}</h6>
                  <div className="d-flex justify-content-center my-2">
                    <div>
                      <StatsItem className="border rounded py-2 px-3 me-3">
                        <div className="fs-5 fw-bold">{totalFunnelsDocs}</div>
                        <StatsHeadings className="fw-bold mt-1">
                          {t("common.funnel", { count: 2 })}
                        </StatsHeadings>
                      </StatsItem>
                      <div className="text-center mt-2">
                        <Link
                          to="../funnels"
                          state={{ customer: state?.customer }}
                        >
                          {t("view")}
                        </Link>
                      </div>
                    </div>
                    <div>
                      <StatsItem className="border rounded py-2 px-3 me-3">
                        <div className="fs-5 fw-bold">{totalLeadsDocs}</div>
                        <StatsHeadings className="fw-bold mt-1">
                          {t("common.lead", { count: 2 })}
                        </StatsHeadings>
                      </StatsItem>
                      <div className="text-center mt-2">
                        <Link to="../leads">{t("view")}</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </CustomCard>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default CustomerOverview;
