import styled from "styled-components";
import { useSelector } from "react-redux";
import Sidebar from "../sidebar";
import Header from "../header/Header";
import { USER_ROLES } from "../strings";

const TokenError = styled.div`
  background: red;
  padding: 2px;
  padding-left: 20px;
  color: white;
  font-size: 12px;
  font-weight: bold;
`;

const DashboardLayout = ({ children }) => {
  const auth = useSelector((state) => state.auth);
  return (
    <>
      {auth.user.role === USER_ROLES.ADMIN &&
        auth.user.workspace.noValidPrimaryTokenExists && (
          <TokenError>
            Primary Facebook Access Token is not valid anymore need action!
          </TokenError>
        )}
      <Header />
      <div className="d-flex bg-white">
        {auth.user.role === USER_ROLES.ADMIN && <Sidebar />}
        <div
          className={`main-content ${
            auth.user.role === USER_ROLES.AGENT &&
            "rounded-0 w-100 mw-100 h-100"
          }`}
        >
          <div className="container-fluid h-100">
            <div className="row h-100">
              <div className="col-sm-12 px-3">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
