import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";

const LeadServiceActionButton = ({
  faIcon,
  tooltipMsg,
  btnVariant,
  btnStyles = "",
  isLoading = false,
  isDisabled = false,
  clickHandler = () => {}
}) => {
  return (
    <OverlayTrigger overlay={<Tooltip>{tooltipMsg}</Tooltip>}>
      <Button
        size="sm"
        variant={btnVariant}
        disabled={isDisabled}
        onClick={clickHandler}
        className={btnStyles || ""}
      >
        {isLoading ? (
          <Spinner size="sm" animation="border" />
        ) : (
          <FontAwesomeIcon icon={faIcon} />
        )}
      </Button>
    </OverlayTrigger>
  );
};
export default LeadServiceActionButton;
