import { useState } from "react";
import styled from "styled-components";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { Form, Modal, Button, Spinner } from "react-bootstrap";
import { QUESTION_TYPE } from "../strings";
import TextField from "../reusable-form-fields/text-field";
import SpinnerButton from "../spinner-button/spinner-button";
import { loadClickfunnelHtml } from "../../services/api-declaration";
import { showError, showSuccess } from "../../services/toast-service";
import { ImportFunnelSchema } from "../../services/validation-schema";
import { useTranslation } from "react-i18next";

const LoadingContainer = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 6px;
  position: absolute;
  align-items: center;
  justify-content: center;
  background: rgb(200 200 200 / 85%);
`;
const LoadingMessage = styled.div`
  color: #6d767e;
`;

const ImportFunnelModal = ({ show, onHide, setImportedDataCallback }) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const methods = useForm({
    resolver: yupResolver(ImportFunnelSchema),
    defaultValues: {
      importUrl: ""
    }
  });
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = methods;

  const handleFormSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      const response = await loadClickfunnelHtml(values.importUrl);
      showSuccess("Klick-Funnel erfolgreich abgerufen!");
      const importedHtml = response.data;

      const funnelObj = {
        design: {},
        companyInfo: {},
        questions: []
      };
      const backgroundImage = importedHtml
        .querySelector("html")
        .style.backgroundImage.replace(/^url\(['"](.+)['"]\)/, "$1");
      let compLogo = "";
      const imgWrapper = importedHtml.querySelector("div.elImageWrapper > img");
      const logoDataSrc = imgWrapper.getAttribute("data-src");
      if (logoDataSrc) {
        compLogo = logoDataSrc;
      } else {
        compLogo = imgWrapper.getAttribute("src");
      }
      const footer = importedHtml.querySelector('*[data-title="3 column row"]');
      const compName = footer.querySelector("div.elHeadline").innerText;
      let partnerLogo = "";
      const footerImg = footer.querySelector("div.elImageWrapper > img");
      const partnerDataSrc = footerImg.getAttribute("data-src");
      if (partnerDataSrc) {
        partnerLogo = partnerDataSrc;
      } else {
        partnerLogo = footerImg.getAttribute("src");
      }
      const datenschutzUrl = footer
        .querySelector("nav.nodoNav > ul > li.nav1 > a")
        .getAttribute("href");
      const impressumUrl = footer
        .querySelector("nav.nodoNav > ul > li.nav3 > a")
        .getAttribute("href");

      funnelObj.design = {
        backgroundImage: {
          importUrl: `https:${backgroundImage}`
        },
        companyLogo: {
          importUrl: `https:${compLogo}`
        },
        partnerLogo: {
          importUrl: `https:${partnerLogo}`
        }
      };
      funnelObj.companyInfo = {
        companyName: compName.includes("©")
          ? compName.replace("©", "").replace(/\s/, "") // removing copyright symbol from text and removing empty space from start of the string.
          : compName,
        dataProtectionUrl: datenschutzUrl,
        imprintUrl: impressumUrl
      };
      const isLeadFunnel =
        importedHtml
          .querySelector("div.elSurvey")
          .querySelector('*[data-radio-type="none"]')?.childNodes.length > 0
          ? true
          : false;
      const questions = importedHtml.querySelectorAll(
        "div.elSurvey > div.surveyStep"
      );

      for (const [questionIdx, question] of questions.entries()) {
        const questionText = question.querySelector(
          ".surveyStepHeadline"
        ).innerText;
        const answers = question.querySelectorAll("div.surveyRadioOption");
        const allAnswers = [];
        for (const [ansIdx, answer] of answers.entries()) {
          const ansObj = {};
          if (isLeadFunnel) {
            const answerSrc = answer.querySelector("img").getAttribute("src");
            ansObj.asset = { importUrl: `https:${answerSrc}` };
          }
          const answerText = answer.querySelector(
            "span.surveyRadioOptionText"
          ).innerText;
          ansObj.text = answerText;
          ansObj.position = ansIdx;
          allAnswers.push(ansObj);
        }
        funnelObj.questions.push({
          text: questionText,
          position: questionIdx,
          answers: allAnswers,
          type: isLeadFunnel ? QUESTION_TYPE.ICONS : QUESTION_TYPE.TEXTONLY
        });
      }
      setImportedDataCallback(funnelObj);
    } catch (error) {
      console.log("Error ", error);
      showError(
        error.response?.data?.message || t("toastMessage.importFunnel")
      );
    } finally {
      setIsSubmitting(false);
      onHide();
      reset();
    }
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static" centered>
      {isSubmitting && (
        <LoadingContainer>
          <Spinner animation="border" role="status" variant="secondary" />
          <LoadingMessage className="ms-3 fw-bold">
            <div>Daten werden importiert...</div>
          </LoadingMessage>
        </LoadingContainer>
      )}
      <Modal.Header closeButton>
        <Modal.Title className="h5">Funnel {t("common.import")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-2 pt-4">
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(handleFormSubmit)}>
            <TextField
              label={`${t("common.import")} URL`}
              placeholder={`${t("common.enter")} ${t("common.import")} URL-${t(
                "common.address"
              )}`}
              type="text"
              name="importUrl"
              errors={errors?.importUrl}
              register={register}
            />
            <div className="text-end mb-2">
              {isSubmitting ? (
                <SpinnerButton btnText="Importing..." btnStyle="btn-success" />
              ) : (
                <Button type="submit" variant="success">
                  {t("common.import")}
                </Button>
              )}
            </div>
          </Form>
        </FormProvider>
      </Modal.Body>
    </Modal>
  );
};
export default ImportFunnelModal;
