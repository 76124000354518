import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";
import { Button, Spinner, Tooltip, OverlayTrigger } from "react-bootstrap";

const FormActionButtons = ({ isSubmitting, resetFields }) => {
  const { t } = useTranslation();
  return (
    <>
      <OverlayTrigger overlay={<Tooltip>{t("link")}</Tooltip>}>
        <Button
          type="submit"
          className="rounded-circle"
          variant="outline-success"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <Spinner size="sm" variant="success" animation="border" />
          ) : (
            <FontAwesomeIcon icon={faCheck} />
          )}
        </Button>
      </OverlayTrigger>
      <OverlayTrigger overlay={<Tooltip>{t("common.cancel")}</Tooltip>}>
        <Button
          className="ms-2 rounded-circle"
          variant="outline-danger"
          onClick={() => resetFields()}
          disabled={isSubmitting}
        >
          <FontAwesomeIcon icon={faClose} />
        </Button>
      </OverlayTrigger>
    </>
  );
};
export default FormActionButtons;
