import { useFormContext } from "react-hook-form";
import { Row, Col, Form, Collapse } from "react-bootstrap";
import { FieldSet, Legend } from "../common-styling";
import TextArea from "../reusable-form-fields/text-area";
import TextField from "../reusable-form-fields/text-field";
import RadioInput from "../reusable-form-fields/input-radio";
import { useTranslation } from "react-i18next";

const ThankYouPageSection = () => {
  const { t } = useTranslation();
  const {
    watch,
    register,
    formState: { errors }
  } = useFormContext();
  return (
    <div className="py-3 px-0">
      <FieldSet>
        <Legend>
          <h6 className="mb-0 fw-bold">{t("thankyouPage")}</h6>
        </Legend>
        <div className="px-0">
          <Row className="mb-2">
            <Col xs={12} md={6}>
              <Form.Check
                role="button"
                type="switch"
                className="fw-bold"
                label={t("redirect.switchLabel")}
                {...register("thankyouPage.redirectLead")}
              />
            </Col>
            <Collapse in={watch("thankyouPage.redirectLead")}>
              <Row>
                <Col xs={12} md={6}>
                  <TextField
                    type="text"
                    register={register}
                    label={t("redirect.fieldLabel")}
                    name="thankyouPage.redirectUrl"
                    placeholder={t("redirect.fieldPlaceholder")}
                    errors={errors?.thankyouPage?.redirectUrl}
                  />
                </Col>
              </Row>
            </Collapse>
          </Row>
        </div>
        <Collapse in={!watch("thankyouPage.redirectLead")}>
          <div className="px-0">
            <Row>
              <Col xs={12} md={6}>
                <RadioInput
                  label={t("websiteBtnForThankyouPage")}
                  firstOptLabel={t("common.yes")}
                  secondOptLabel={t("common.no")}
                  name="thankyouPage.websiteButton"
                  firstValue="Yes"
                  firstValueId="Yes"
                  secondValue="No"
                  secondValueId="No"
                  register={register}
                />
              </Col>
              <Col xs={12} md={6}>
                <TextField
                  type="text"
                  register={register}
                  label={t("common.websiteBtnTxt")}
                  name="thankyouPage.websiteButtonText"
                  placeholder={`${t("common.enter")} ${t(
                    "common.websiteBtnTxt"
                  )}`}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <TextArea
                  label={`${t("thankyouPage")} ${t("common.headline")}`}
                  name="thankyouPage.thankyouPageHeadline"
                  register={register}
                />
              </Col>
              <Col xs={12} md={6}>
                <TextArea
                  label={`${t("textOnThankyouPage")} ${t("thankyouPage")}`}
                  name="thankyouPage.thankyouPageText"
                  register={register}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <TextArea
                  label={t("greetingPhrase")}
                  name="thankyouPage.greetingPhrase"
                  register={register}
                />
              </Col>
            </Row>
          </div>
        </Collapse>
      </FieldSet>
    </div>
  );
};
export default ThankYouPageSection;
