import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AvailabilityBadgeContent = ({
  icon,
  generator,
  dateValue,
  tooltipText
}) => {
  return (
    <OverlayTrigger overlay={<Tooltip>{tooltipText}</Tooltip>}>
      <div>
        <FontAwesomeIcon icon={icon} className="me-2 text-secondary" />
        {generator(dateValue)}
      </div>
    </OverlayTrigger>
  );
};
export default AvailabilityBadgeContent;
