import { Link } from "react-router-dom";
import { Modal, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const FunnelLinksDialog = (props) => {
  const { t } = useTranslation();
  const { collection } = props;
  return (
    <Modal {...props} size="lg" centered>
      <Modal.Header>
        <h5 className="mb-0">{t("campaign")} Funnels</h5>
      </Modal.Header>
      <Modal.Body>
        <ListGroup className="ms-2" numbered>
          {collection.map((item, idx) => (
            <ListGroup.Item key={idx} className="p-0 pb-2 border-0">
              <Link
                target="_blank"
                className="text-decoration-none"
                to={`/dashboard/funnels/${item._id}/form?funnelType=${item.funnelType}`}
              >
                {item.funnelName}
              </Link>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};

export default FunnelLinksDialog;
