import React, { useState, useEffect, useRef } from "react";

const webWorkerScript = `
  self.addEventListener('message', event => {
    const url = event.data;
    fetch(url, {
        method: 'GET',
        mode: 'no-cors',
        cache: 'default'
    }).then(response => {
        return response.blob();
    }).then(_ => postMessage(url)).catch(console.error);
  })
`;

const ImageWorker = ({ src, placeholder, style, imageClass }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [imgSrc, setImgSrc] = useState("");
  const imageRef = useRef(null);
  const workerRef = useRef(null);

  useEffect(() => {
    workerRef.current = new Worker(
      URL.createObjectURL(
        new Blob([webWorkerScript], { type: "application/javascript" })
      )
    );

    const worker = workerRef.current;
    const loadImage = (url) => {
      const image = new Image();
      imageRef.current = image;

      image.src = url;
      if (image.decode !== undefined) {
        image.decode().then(onLoad).catch(onLoad);
      } else {
        image.onload = onLoad;
      }
    };

    const onLoad = () => {
      setImgSrc(imageRef.current.src);
      setIsLoading(false);
    };

    worker.onmessage = (event) => {
      loadImage(event.data);
    };

    worker.postMessage(src);

    return () => {
      if (imageRef.current) {
        imageRef.current.onload = null;
        imageRef.current.onerror = null;
      }
      if (workerRef.current) {
        workerRef.current.terminate();
      }
    };
  }, [src]);

  const renderPlaceholder = () => {
    if (placeholder) {
      return <img src={placeholder} style={{ ...style }} alt="placeholder" />;
    } else {
      return null;
    }
  };

  return (
    <>
      {isLoading ? (
        renderPlaceholder()
      ) : (
        <img
          src={imgSrc}
          style={{ ...style }}
          className={imageClass}
          alt="worker"
        />
      )}
    </>
  );
};

export default ImageWorker;
