import { FixedContainer, ValiditySpinner } from "../common-styling";

const FixedLoader = ({ loaderMessage }) => {
  return (
    <FixedContainer>
      <ValiditySpinner
        animation="border"
        variant="secondary"
        className="ms-2 rounded-circle"
      />
      <small className="ms-2">{loaderMessage}</small>
    </FixedContainer>
  );
};
export default FixedLoader;
