import {
  Row,
  Col,
  Form,
  Button,
  Tooltip,
  InputGroup,
  OverlayTrigger
} from "react-bootstrap";
import Select from "react-select";
import styled from "styled-components";
import _debounce from "lodash/debounce";
import { useParams } from "react-router-dom";
import React, { useState, useMemo } from "react";
import { useFormContext, Controller, useWatch } from "react-hook-form";
import { useDialog } from "../../hooks/useDialog";
import LinkPreviewComponent from "../link-preview";
import { domainList } from "../common-confgs/common-vars";
import { generateSiteUrl } from "../../services/common-service";
import { getFunnelSiteInfo } from "../../services/api-declaration";
import { FieldSet, Legend, ValiditySpinner } from "../common-styling";
import { showError, showWarning } from "../../services/toast-service";
import ValidationMessage from "../validation-message/validation-message";
import { useTranslation } from "react-i18next";

const AutoCompSelect = styled(Select)`
  width: 200px;
`;
const DomainSection = ({
  setIsDomainValid,
  isDomainValid,
  btnRef,
  setShouldReleaseArchived,
  inDialog = false
}) => {
  const { t } = useTranslation();
  const {
    getValues,
    formState: { errors },
    control,
    register
  } = useFormContext();
  const params = useParams();
  const subDomain = useWatch({ name: "subDomain" });
  const { showConfirmationDialogBox } = useDialog();
  const [isDomainChanged, setIsDomainChanged] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [archivedFunnel, setArchivedFunnel] = useState({});

  const transformSelectValue = (fieldValue) => {
    return fieldValue
      ? {
          label: fieldValue,
          value: fieldValue
        }
      : null;
  };
  const toggleConfirmation = () =>
    showConfirmationDialogBox({
      title: "Warning!",
      dialogType: "warning",
      responseAction: dialogResponseAction,
      dialogMessage: dialogMessage()
    });

  const handleDomainChange = (fieldVal) => {
    if (fieldVal) {
      btnRef.current.disabled = true;
      setIsDomainChanged(true);
    }
  };
  const dialogResponseAction = (action) => {
    if (action && archivedFunnel?.funnelId) {
      setIsDomainValid(true);
      setIsArchived(false);
      setShouldReleaseArchived({ ...archivedFunnel });
    }
  };
  const dialogMessage = () => (
    <div>
      Do you want to use this domain and subdomain anyway?
      <div>
        Because it will remove domain and subdomain from Funnel{" "}
        <OverlayTrigger overlay={<Tooltip>{t("common.archive")}</Tooltip>}>
          <b>{archivedFunnel?.funnelName}</b>
        </OverlayTrigger>
      </div>
    </div>
  );
  const handleDebounceCb = useMemo(
    () =>
      _debounce(async () => {
        const domainValue = getValues("domain");
        const subdomainValue = getValues("subDomain");
        try {
          if (domainValue && subdomainValue) {
            const response = await getFunnelSiteInfo({
              domain: domainValue,
              subDomain: subdomainValue
            });
            if (response.data.data && response.data.data._id !== params.id) {
              let toastMsg = "";
              if (response.data.data.archived) {
                toastMsg = t("domainArchivedMsg1");
                setArchivedFunnel({
                  funnelId: response.data.data._id,
                  funnelName: response.data.data.general.funnelName
                });
              } else {
                toastMsg = t("domainArchivedMsg2");
              }
              setIsArchived(response.data.data.archived);
              setIsDomainValid(false);
              showWarning(toastMsg);
            } else {
              setIsDomainValid(true);
            }
          }
        } catch (err) {
          console.log("ERR ", err);
          showError(
            err.response.data.message || t("toastMessage.funnelByDomain")
          );
        } finally {
          btnRef.current.disabled = false;
          setIsDomainChanged(false);
        }
      }, 2000),
    // eslint-disable-next-line
    [setIsDomainValid, params.id, btnRef, getValues]
  );
  return (
    <div className="py-3 px-0">
      <FieldSet>
        <Legend>
          <h6 className="mb-0 fw-bold">Domain</h6>
        </Legend>
        <div className="px-0">
          <Row>
            <Col xs={12} md={inDialog ? 12 : 6}>
              <div className="my-3">
                <InputGroup className="d-flex align-items-center">
                  <Controller
                    control={control}
                    name="subDomain"
                    render={({ field }) => (
                      <Form.Control
                        type="text"
                        {...field}
                        placeholder="Subdomain"
                        onChange={(e) => {
                          field.onChange(e);
                          setIsDomainValid(true);
                          handleDomainChange(e.target.value);
                          handleDebounceCb();
                        }}
                        className={errors.subDomain && "border-danger"}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="domain"
                    render={({ field: { onChange, ref } }) => (
                      <AutoCompSelect
                        placeholder="Domain"
                        {...register("domain")}
                        options={domainList.map((opt) => ({
                          label: opt,
                          value: opt
                        }))}
                        onChange={(event) => {
                          onChange(event.value);
                          handleDomainChange(event.value);
                          handleDebounceCb();
                        }}
                        value={transformSelectValue(getValues("domain"))}
                        inputRef={ref}
                      />
                    )}
                  />
                  {isDomainChanged && (
                    <ValiditySpinner
                      animation="border"
                      variant="secondary"
                      className="ms-2 rounded-circle"
                    />
                  )}
                </InputGroup>

                {errors.subDomain && (
                  <ValidationMessage error={errors.subDomain.message} />
                )}

                {!isDomainValid && (
                  <div className="mt-2 small text-danger fw-bold">
                    {isArchived && !inDialog ? (
                      <div className="d-flex justify-content-between">
                        {t("domainArchivedMsg1")}
                        <Button onClick={toggleConfirmation}>
                          {t("useAnyway")}
                        </Button>
                      </div>
                    ) : (
                      t("notAllowedDomainMsg")
                    )}
                  </div>
                )}
                <LinkPreviewComponent
                  link={generateSiteUrl(getValues("domain"), subDomain)}
                  linkText={`https://${subDomain}.${getValues("domain")}`}
                  show={subDomain?.length && getValues("domain")}
                  showCopyButton={true}
                />
              </div>
            </Col>
          </Row>
        </div>
      </FieldSet>
    </div>
  );
};

export default DomainSection;
