import Picker from "@emoji-mart/react";
import { faFaceSmile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useRef, useState } from "react";
import { Button, Form, OverlayTrigger, Popover } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
const EmojiTextarea = ({
  label,
  placeholder,
  errors,
  name,
  showCopyButton = false,
  rows = "10"
}) => {
  const [emojiCursorPosition, setEmojiCursorPosition] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const timerRef = useRef();
  const { setValue, register, getValues } = useFormContext();
  const handleTextareaChange = (e) => {
    setValue(name, e.target.value);
    setEmojiCursorPosition(e.target.selectionStart);
  };

  const handleEmojiSelect = (emoji) => {
    if (emojiCursorPosition !== null) {
      const newText =
        getValues(name).slice(0, emojiCursorPosition) +
        emoji.native +
        getValues(name).slice(emojiCursorPosition);
      setValue(name, newText);
      setEmojiCursorPosition(null);
    } else {
      setValue(name, getValues(name) + emoji.native);
    }
  };

  const handleTextareaClick = (e) => {
    const cursorPosition = e.target.selectionStart;
    setEmojiCursorPosition(cursorPosition);
  };

  return (
    <div>
      <Form.Group className="mb-3" controlId={label}>
        <div className="mb-2 d-flex align-items-center justify-content-between">
          <Form.Label>{label}</Form.Label>
          <div className="d-flex align-items-center">
            {showCopyButton && (
              <CopyToClipboard text={getValues(name)}>
                <Button
                  onClick={() => {
                    setIsCopied(true);
                    if (timerRef.current) {
                      clearTimeout(timerRef.current);
                      timerRef.current = null;
                    }
                    timerRef.current = setTimeout(() => {
                      setIsCopied(false);
                    }, 2000);
                  }}
                  style={{ paddingBottom: 2, paddingTop: 0 }}
                  variant="outline-secondary me-2"
                >
                  {isCopied ? "Copied" : "Copy"}
                </Button>
              </CopyToClipboard>
            )}
            <OverlayTrigger
              trigger="click"
              placement="left-start"
              rootClose
              overlay={
                <Popover className="border-0">
                  <Picker
                    previewPosition="none"
                    onEmojiSelect={handleEmojiSelect}
                  />
                </Popover>
              }
            >
              <Button size="sm" variant="outline-secondary rounded-circle">
                <FontAwesomeIcon icon={faFaceSmile} />
              </Button>
            </OverlayTrigger>
          </div>
        </div>
        <div className="d-flex">
          <Form.Control
            {...register(name)}
            rows={rows}
            as="textarea"
            onChange={handleTextareaChange}
            onClick={handleTextareaClick}
            placeholder={placeholder}
            isInvalid={errors?.message}
          />
        </div>
        <Form.Control.Feedback className="d-block" type="invalid">
          {errors?.message}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
};
export default EmojiTextarea;
