import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownToggle } from "../common-styling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { axiosDownload } from "../../services/axios-rest-methods";
import { showError } from "../../services/toast-service";
import { useTranslation } from "react-i18next";

const CustomDropdown = ({ item }) => {
  const { t } = useTranslation();
  const [dropdownVisibilty, setDropdownVisibilty] = useState(false);
  const downloadHandler = async (event, { imgUrl, key }) => {
    event.stopPropagation();
    try {
      const response = await axiosDownload({
        url: `${imgUrl}/${key}`,
        hasAbsoluteUrl: true
      });
      const blob = response.data;
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute("download", "");
      a.click();
      setDropdownVisibilty(false);
    } catch (error) {
      showError(t("toastMessage.downloadError"));
    }
  };
  return (
    <Dropdown
      onToggle={() => setDropdownVisibilty(!dropdownVisibilty)}
      show={dropdownVisibilty}
    >
      <DropdownToggle variant="outlined">
        <FontAwesomeIcon icon={faEllipsis} />
      </DropdownToggle>

      <Dropdown.Menu>
        <Dropdown.Item
          onClick={(event) =>
            downloadHandler(event, {
              key: item.key,
              imgUrl: item.url
            })
          }
          as="button"
        >
          {t("download")}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomDropdown;
