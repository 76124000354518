import React from "react";
import { useTranslation } from "react-i18next";
// import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import NestedTabsComponent from "../nested-tabs-component";
import { SITE_SETTINGS_TYPE, NESTED_SIDENAV } from "../strings";

const SiteSettings = () => {
  const { t } = useTranslation();

  return (
    <div className="d-flex h-100">
      <div className="sub-sidebar">
        <div className="inner-sub-sidebar">
          <div className="tab-heading ps-3 mt-4">
            {t("sidebar.siteSettings")}
          </div>
          <NestedTabsComponent
            nestedNavType={NESTED_SIDENAV.MISSING_INFO}
            clearParams
            tabs={[
              {
                evKey: SITE_SETTINGS_TYPE.FACEBOOK_ACCOUNTS,
                title: "Facebook Accounts"
              }
            ]}
          />
        </div>
      </div>
      <div className="outlet-wrapper">
        <Outlet />
      </div>
    </div>
  );
};
export default SiteSettings;
