import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import {
  TableRow,
  NameLink,
  TableDetailCell,
  TableDetailNameCell
} from "../common-styling";
import NoResultsRow from "../no-results-table-row";

const TagsTableRow = ({ tags }) => {
  const navigate = useNavigate();
  const generateNavigateLink = (tagID) => `${tagID}/edit`;
  const navigateToTag = (tagID) => navigate(generateNavigateLink(tagID));

  return tags.length ? (
    tags.map((tag, index) => (
      <React.Fragment key={index}>
        <TableRow>
          <TableDetailNameCell role="button" className="align-middle py-3">
            <NameLink className="d-block" to={generateNavigateLink(tag._id)}>
              {tag.name}
            </NameLink>
          </TableDetailNameCell>
          <TableDetailCell className="text-end align-middle py-3">
            <div className="d-flex justify-content-end">
              <Button
                size="sm"
                className="me-2 rounded-circle"
                variant="outline-primary"
                onClick={() => navigateToTag(tag._id)}
              >
                <FontAwesomeIcon size="sm" icon={faPenToSquare} />
              </Button>
            </div>
          </TableDetailCell>
        </TableRow>
      </React.Fragment>
    ))
  ) : (
    <NoResultsRow lengthOfColumns="2" />
  );
};
export default TagsTableRow;
