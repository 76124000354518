import { createContext, useRef, useState } from "react";
import CreateNewModal from "../components/create-new-modal/create-new-modal";
import AlertDialog from "../components/funnel-form/alert-dialog";
import ConfirmationDialog from "../components/funnel-form/funnel-custom-accordion/confirmation-dialog";
import SaveAsTemplateDialog from "../components/funnel-form/save-as-template-dialog";
import VersionDialog from "../components/funnel-form/version-dialog";
import AddDomainModal from "../components/funnels-table/add-domain-modal";
import SyncLeadDialog from "../components/leads/sync-lead-dialog";
import ContactCloudSetupDialog from "../components/funnel-integration/contact-cloud-setup-dialog";
import ImageDetailsDialog from "../components/ads-evaluation/image-details-dialog";

export const DialogContext = createContext({});

const initialState = {
  type: "",
  title: "",
  message: "",
  dialogType: "",
  modaltype: ""
};

export function DialogProvider({ children }) {
  const [dialogData, setDialogData] = useState(initialState);
  const [showModal, setShowModal] = useState(false);
  const dependentStatesRef = useRef({});

  const setDependentStates = (value) => {
    dependentStatesRef.current = value;
  };

  const toggleModal = (value) => {
    if (value || !showModal) {
      setShowModal(true);
    } else {
      setShowModal(false);
      setDialogData(initialState);
    }
  };

  const showAlertDialogBox = ({
    message,
    dialogType,
    responseAction,
    title,
    acceptLabel,
    showFooter
  }) => {
    setDialogData({
      title,
      message,
      type: "ALERT",
      dialogType,
      responseAction,
      acceptLabel,
      showFooter
    });
    toggleModal();
  };

  const showConfirmationDialogBox = ({
    dialogMessage,
    dialogType,
    responseAction,
    title,
    acceptLabel,
    declineLabel,
    disableAcceptBtn,
    isSync
  }) => {
    setDialogData({
      title,
      type: "CONFIRMATION",
      dialogType,
      responseAction,
      acceptLabel,
      declineLabel,
      disableAcceptBtn,
      dialogMessage
    });
    toggleModal(isSync);
  };

  const showVersionDialogBox = ({
    responseAction,
    versionTitleRef,
    resourceId,
    register
  }) => {
    setDialogData({
      resourceId,
      versionTitleRef,
      type: "VERSION",
      responseAction,
      register
    });
    toggleModal();
  };

  const showSaveAsTemplateDialogBox = ({ formValues, resourceId }) => {
    setDialogData({
      resourceId,
      formValues,
      type: "SAVE_AS_TEMPLATE"
    });
    toggleModal();
  };

  const showSyncLeadDialogBox = ({ leadInfo, fetchLeads }) => {
    setDialogData({
      leadInfo,
      fetchLeads,
      type: "SYNC_LEAD"
    });
    toggleModal();
  };

  const showAddDomainDialogBox = ({ getFunnels, resourceId }) => {
    setDialogData({
      getFunnels,
      resourceId,
      type: "ADD_DOMAIN"
    });
    toggleModal();
  };

  const showCreateNewDialogBox = ({
    queryparams,
    url,
    customer,
    modaltype
  }) => {
    setDialogData({
      queryparams,
      url,
      customer,
      type: "CREATE_NEW",
      modaltype
    });
    toggleModal();
  };

  const showContactCloudDialogBox = ({
    funnel,
    funnelId,
    setIntegrations,
    formData
  }) => {
    setDialogData({
      type: "CONTACT_CLOUD_SETUP",
      funnel,
      funnelId,
      setIntegrations,
      formData
    });
    toggleModal();
  };

  const showImageDetailsDialogBox = ({ data }) => {
    setDialogData({
      type: "IMAGE_DETAILS",
      data
    });
    toggleModal();
  };

  const dialogTypes = {
    ALERT: () => (
      <AlertDialog
        description={dialogData.message}
        show={showModal}
        setShow={toggleModal}
        dialogType={dialogData.dialogType}
        title={dialogData.title}
        showFooter={dialogData.showFooter}
        acceptLabel={dialogData.acceptLabel}
      />
    ),
    CONFIRMATION: () => (
      <ConfirmationDialog
        title={dialogData.title}
        dialogType={dialogData.dialogType}
        show={showModal}
        toggleDialog={toggleModal}
        dependentStates={dependentStatesRef.current}
        dialogResponseAction={dialogData.responseAction}
        renderDialogMessage={dialogData.dialogMessage}
        acceptLabel={dialogData.acceptLabel}
        declineLabel={dialogData.declineLabel}
        disableAcceptBtn={dialogData.disableAcceptBtn}
      />
    ),
    VERSION: () => (
      <VersionDialog
        resourceId={dialogData.resourceId}
        register={dialogData.register}
        versionTitleRef={dialogData.versionTitleRef}
        show={showModal}
        toggleDialog={toggleModal}
        dialogResponseAction={dialogData.responseAction}
      />
    ),
    SAVE_AS_TEMPLATE: () => (
      <SaveAsTemplateDialog
        show={showModal}
        formValues={dialogData.formValues}
        toggleDialog={toggleModal}
        resourceId={dialogData.resourceId}
      />
    ),
    SYNC_LEAD: () => (
      <SyncLeadDialog
        leadInfo={dialogData.leadInfo}
        show={showModal}
        fetchLeads={dialogData.fetchLeads}
        toggleDialog={toggleModal}
      />
    ),
    ADD_DOMAIN: () => (
      <AddDomainModal
        show={showModal}
        getFunnels={dialogData.getFunnels}
        resourceId={dialogData.resourceId}
        toggleDialog={toggleModal}
      />
    ),
    CREATE_NEW: () => (
      <CreateNewModal
        show={showModal}
        queryparams={dialogData.queryparams}
        url={dialogData.url}
        customer={dialogData.customer}
        modaltype={dialogData.modaltype}
        onHide={() => toggleModal(false)}
      />
    ),
    CONTACT_CLOUD_SETUP: () => (
      <ContactCloudSetupDialog
        show={showModal}
        toggleDialog={toggleModal}
        funnel={dialogData.funnel}
        funnelId={dialogData.funnelId}
        setIntegrations={dialogData.setIntegrations}
        formData={dialogData.formData}
      />
    ),
    IMAGE_DETAILS: () => (
      <ImageDetailsDialog
        show={showModal}
        toggleModal={toggleModal}
        data={dialogData.data}
      />
    )
  };

  return (
    <DialogContext.Provider
      value={{
        showAlertDialogBox,
        showConfirmationDialogBox,
        showVersionDialogBox,
        showSaveAsTemplateDialogBox,
        showSyncLeadDialogBox,
        showAddDomainDialogBox,
        showCreateNewDialogBox,
        showContactCloudDialogBox,
        showImageDetailsDialogBox,
        setDependentStates,
        dependentStates: dependentStatesRef.current
      }}
    >
      {children}
      {dialogTypes[dialogData.type]?.()}
    </DialogContext.Provider>
  );
}
