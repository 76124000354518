import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Badge, Button } from "react-bootstrap";
import { faTrash, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  TableRow,
  NameLink,
  TableDetailCell,
  TableDetailNameCell
} from "../common-styling";
import { useDialog } from "../../hooks/useDialog";
import NoResultsRow from "../no-results-table-row";
import { deleteTemplate } from "../../services/api-declaration";
import { generateDateTime } from "../../services/common-service";
import { showError, showSuccess } from "../../services/toast-service";
import { useTranslation } from "react-i18next";

const FunnelTypeBadge = styled(Badge)`
  padding: 8px;
  min-width: 40%;
  text-transform: capitalize;
  color: ${({ btncolor }) => `var(${btncolor})`};
  background-color: ${({ btnbg }) => `var(${btnbg})`} !important;
`;

const TemplateTableRow = ({ templates, getTemplates }) => {
  const { t } = useTranslation();
  const { showConfirmationDialogBox, setDependentStates } = useDialog();
  const toggleDialog = () =>
    showConfirmationDialogBox({
      title: t("common.confirmDeletion"),
      dialogMessage: renderDialogMessage(),
      dialogType: "danger",
      responseAction: dialogResponseAction
    });

  const renderDialogMessage = () => (
    <div dangerouslySetInnerHTML={{ __html: t("deleteTemplateMessage") }}></div>
  );
  const handleDeleteIconClick = (temp) => {
    setDependentStates({ selectedTemp: temp });
    toggleDialog();
  };
  const handleDelete = async (temp) => {
    try {
      const response = await deleteTemplate(temp.id);
      showSuccess(response.data.message);
      await getTemplates();
    } catch (err) {
      console.log(err);
      showError(err.response.data.message || t("toastMessage.deleteTemplate"));
    }
  };
  const dialogResponseAction = async (action, values) => {
    if (action && Object.keys(values.selectedTemp).length) {
      await handleDelete(values.selectedTemp);
    }
  };

  return templates.length ? (
    templates.map((template, index) => (
      <React.Fragment key={index}>
        <TableRow>
          <TableDetailNameCell className="align-middle py-3">
            <NameLink
              className="d-block"
              to={`${template.id}?templateType=${template.templateType}`}
            >
              {template.name}
            </NameLink>
          </TableDetailNameCell>
          <TableDetailCell className="align-middle py-3">
            <FunnelTypeBadge
              btnbg={`--${template.templateType.toLowerCase()}-badge-bg`}
              btncolor={`--${template.templateType.toLowerCase()}-color`}
            >
              {template.templateType.toLowerCase()}
            </FunnelTypeBadge>
          </TableDetailCell>
          <TableDetailCell className="align-middle py-3">
            {template.updatedAt
              ? generateDateTime(template.updatedAt)
              : generateDateTime(template.createdAt)}
          </TableDetailCell>
          <TableDetailCell className="text-end align-middle py-3  ">
            <div className="d-flex justify-content-end">
              <Button
                size="sm"
                as={Link}
                variant="outline-primary"
                className="me-2 rounded-circle"
                to={`${template.id}?templateType=${template.templateType}`}
              >
                <FontAwesomeIcon size="sm" icon={faPenToSquare} />
              </Button>
              <Button
                size="sm"
                className="rounded-circle"
                variant="outline-danger"
                onClick={() => handleDeleteIconClick(template)}
              >
                <FontAwesomeIcon size="sm" icon={faTrash} />
              </Button>
            </div>
          </TableDetailCell>
        </TableRow>
      </React.Fragment>
    ))
  ) : (
    <NoResultsRow lengthOfColumns="4" />
  );
};
export default TemplateTableRow;
