import styled from "styled-components";
import {
  faGlobe,
  faSearch,
  faUserCircle,
  faChevronDown,
  faRightFromBracket
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navbar, Dropdown, InputGroup, NavbarBrand } from "react-bootstrap";
import { USER_ROLES } from "../strings";
import metadata from "../../metadata.json";
import { logout } from "../../store/slices/auth";
import { useOptionsFetch } from "../../hooks/useOptionsFetch";
import AsyncSelectField from "../reusable-form-fields/async-select";
import { useTranslation } from "react-i18next";

const NavHeader = styled(Navbar)`
  height: 65px;
  background-color: #fff;
`;
const UserIcon = styled(FontAwesomeIcon)`
  font-size: 36px;
`;
const AppLogo = styled.div`
  cursor: pointer;
  width: 60px;
  height: 57px;
  display: flex;
  justify-content: center;
`;
const AppHeading = styled(NavbarBrand)`
  font-size: 18px;
`;
const ProfileName = styled.div`
  font-size: 16px;
`;
const AppVersionContainer = styled.div`
  font-size: 10px;
`;
const DropdownToggler = styled(Dropdown.Toggle)`
  font-size: 14px;
`;
const SearchForm = styled.form`
  min-width: 250px;
  max-width: 250px;
`;
const InputGroupTxt = styled(InputGroup.Text)`
  border-color: #cccccc !important;
  border-right: none !important;
  border-radius: 4px 0 0 4px;
`;
const DropdownMenu = styled(Dropdown.Menu)`
  z-index: 1021; // 1020 is sticky's default z-index
`;
const languageObj = {
  en: "English",
  de: "Deutsch"
};

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const AMLogoPath = `${process.env.PUBLIC_URL}/app-logo.png`;
  const { t, i18n } = useTranslation();
  const { handleFunnelOptions } = useOptionsFetch();
  const methods = useForm({
    defaultValues: {
      funnelName: ""
    }
  });
  const { control, reset } = methods;

  const langaugeToggler = (lang) => {
    i18n.changeLanguage(lang);
  };
  const handleLogout = () => {
    dispatch(logout());
  };
  const handleFunnelSelect = (opt) => {
    navigate(
      `/dashboard/funnels/${opt._id}/form?funnelType=${opt.general.funnelType}`
    );
    reset();
  };

  return (
    <NavHeader expand="lg">
      <div className="d-flex align-items-center justify-content-between w-100 pe-3">
        <div className="d-flex align-items-center">
          <AppLogo>
            <img alt="logo" src={AMLogoPath} />
          </AppLogo>
          <div className="ms-1">
            <AppHeading
              as={Link}
              to="/"
              className="fw-bold text-decoration-none text-dark"
            >
              A&M Cockpit
              <AppVersionContainer className="d-flex align-items-center text-secondary fw-light">
                {`Version ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}
              </AppVersionContainer>
            </AppHeading>
          </div>
        </div>
        <div className="d-flex flex-fill align-items-center justify-content-end">
          {/* Search funnel select */}
          <div
            className={`me-4 ${
              auth.user.role === USER_ROLES.AGENT ? "d-none" : "d-inline-block"
            }`}
          >
            <InputGroup>
              <div className="d-flex align-items-center">
                <InputGroupTxt className="bg-white text-secondary border align-self-stretch">
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroupTxt>
                <FormProvider {...methods}>
                  <SearchForm
                    onSubmit={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <AsyncSelectField
                      control={control}
                      name="funnelName"
                      paramName="funnelName"
                      placeholder={`${t("common.search")} funnel`}
                      fetchData={handleFunnelOptions}
                      getOptionLabel={(option) =>
                        `${option.general?.funnelName} (${option.general?.funnelType})`
                      }
                      onSelect={(option) => handleFunnelSelect(option)}
                      forHeaderOnly
                      capitalized={false}
                      showDefaultOpts={false}
                      grouped
                    />
                  </SearchForm>
                </FormProvider>
              </div>
            </InputGroup>
          </div>
          {/* Language selector */}
          <div className="d-flex align-items-center me-4">
            <FontAwesomeIcon size="lg" className="me-1" icon={faGlobe} />
            <Dropdown>
              <DropdownToggler
                variant="none"
                className="d-flex align-items-center custom p-0 px-1"
              >
                <div className="me-2">
                  {languageObj[localStorage.getItem("i18nextLng")] ||
                    languageObj.en}
                </div>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="text-secondary"
                />
              </DropdownToggler>
              <DropdownMenu>
                <Dropdown.Item onClick={() => langaugeToggler("en")}>
                  <div>{languageObj.en}</div>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => langaugeToggler("de")}>
                  <div>{languageObj.de}</div>
                </Dropdown.Item>
              </DropdownMenu>
            </Dropdown>
          </div>
          {/* user dropdown */}
          <div className="d-flex align-items-center">
            <UserIcon icon={faUserCircle} />
            <Dropdown>
              <Dropdown.Toggle
                className="d-flex align-items-center custom"
                variant="none"
              >
                <ProfileName className="fw-bold me-2">{`${auth.user.firstName} ${auth.user.lastName}`}</ProfileName>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="text-secondary"
                />
              </Dropdown.Toggle>
              <DropdownMenu>
                <Dropdown.Item onClick={handleLogout}>
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faRightFromBracket} />
                    <div className="ms-2">Log out</div>
                  </div>
                </Dropdown.Item>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
    </NavHeader>
  );
};

export default Header;
