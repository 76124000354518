import {
  Row,
  Col,
  Modal,
  Button,
  Tooltip,
  Spinner,
  OverlayTrigger
} from "react-bootstrap";
import moment from "moment";
import { useState } from "react";
import styled from "styled-components";
import {
  faCheck,
  faClose,
  faArrowUpRightFromSquare
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloseIconContainer } from "../../common-styling";
import { updateSuggestion } from "../../../services/api-declaration";
import { DATE_REVERSE_FORMAT, TESTED_LINK_STATUS } from "../../strings";
import { showError, showSuccess } from "../../../services/toast-service";
import AbsoluteButtonAnchor from "../../funnel-form/absolute-button-anchor";
import { useTranslation } from "react-i18next";

const StyledRow = styled(Row)`
  &:hover {
    background: #f9f9f9;
  }
`;
const StyledSmButton = styled(Button)`
  padding-left: 10px;
  padding-right: 10px;
`;
const LoaderOverlay = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  border-radius: 6px;
  align-items: center;
  background: #c7c7c7bf;
  justify-content: center;
`;

const ListDialog = (props) => {
  const { t } = useTranslation();
  const { POSITIVE, NEGATIVE } = TESTED_LINK_STATUS;
  const [isUpdating, setIsUpdating] = useState(false);
  const suggestion = props.suggestion;

  const handleUpdateTestedLinkStatus = async ({ index, status }) => {
    try {
      setIsUpdating(true);
      let links = suggestion.testedLinks;
      links[index].status = status;
      const response = await updateSuggestion(suggestion.id, {
        testedLinks: links
      });
      showSuccess(response.data.message || "Updated Successfully!");
    } catch (error) {
      console.log("Error in updating tested link: ", error);
      showError(error.response?.data?.message || "ERROR!");
    } finally {
      setIsUpdating(false);
    }
  };
  const handleStatusIconClick = (index, status) => {
    handleUpdateTestedLinkStatus({ index, status });
  };

  return (
    <Modal
      {...props}
      size="lg"
      centered
      backdrop={isUpdating ? "static" : "normal"}
    >
      <div className="position-relative p-3">
        <h5 className="mb-2">{t("suggestionGallery.testedLinks")}</h5>
        {suggestion.testedLinks.map((listItem, idx) => (
          <StyledRow key={idx} className="m-1 py-2 align-items-center">
            <OverlayTrigger overlay={<Tooltip>{t("uploadedOn")}</Tooltip>}>
              <Col xs={2}>
                {moment(listItem.uploadedOn).format(DATE_REVERSE_FORMAT)}
              </Col>
            </OverlayTrigger>
            <Col xs={6} className="border-dark border-start">
              <div className="text-center">
                <AbsoluteButtonAnchor
                  classes="me-2 btn-sm"
                  link={listItem.linkToCampaign}
                  icon={faArrowUpRightFromSquare}
                  isDisabled={!listItem.linkToCampaign}
                  buttonText={t("suggestionGallery.linkToCampaignLabel")}
                />
                <AbsoluteButtonAnchor
                  classes="btn-sm"
                  link={listItem.linkToFunnel}
                  icon={faArrowUpRightFromSquare}
                  isDisabled={!listItem.linkToFunnel}
                  buttonText={t("suggestionGallery.linkToFunnelLabel")}
                />
              </div>
            </Col>
            <OverlayTrigger overlay={<Tooltip>{t("common.category")}</Tooltip>}>
              <Col
                xs={2}
                className="text-break fw-bold border-dark border-start"
              >
                {listItem.category?.name || "---"}
              </Col>
            </OverlayTrigger>
            <Col xs={2} className="border-dark border-start text-end">
              <Button
                size="sm"
                className="me-2 rounded-circle"
                onClick={() => handleStatusIconClick(idx, POSITIVE)}
                variant={
                  listItem.status === POSITIVE ? "success" : "outline-success"
                }
                disabled={suggestion.archive || suggestion.isWinningAd}
              >
                <FontAwesomeIcon icon={faCheck} />
              </Button>
              <StyledSmButton
                size="sm"
                className="rounded-circle"
                onClick={() => handleStatusIconClick(idx, NEGATIVE)}
                variant={
                  listItem.status === NEGATIVE ? "danger" : "outline-danger"
                }
                disabled={suggestion.archive || suggestion.isWinningAd}
              >
                <FontAwesomeIcon icon={faClose} />
              </StyledSmButton>
            </Col>
          </StyledRow>
        ))}
        {isUpdating ? (
          <LoaderOverlay>
            <Spinner animation="border" />
          </LoaderOverlay>
        ) : (
          <CloseIconContainer onClick={props.onHide}>
            <FontAwesomeIcon icon={faClose} className="text-danger" />
          </CloseIconContainer>
        )}
      </div>
    </Modal>
  );
};
export default ListDialog;
