import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import {
  TableRow,
  NameLink,
  TableDetailCell,
  TableDetailNameCell
} from "../common-styling";
import NoResultsRow from "../no-results-table-row";
import DeleteButton from "../delete-button";
import { deleteCategory } from "../../services/api-declaration";

const CategoriesTableRow = ({ categories, fetchCategory }) => {
  const navigate = useNavigate();
  const generateNavigateLink = (_categoryId) => `${_categoryId}/edit`;
  const navigateToCategory = (catId) => navigate(generateNavigateLink(catId));

  return categories.length ? (
    categories.map((category, index) => (
      <React.Fragment key={index}>
        <TableRow>
          <TableDetailNameCell
            role="button"
            className="align-middle py-3"
            onClick={() => navigateToCategory(category.id)}
          >
            <NameLink
              className="d-block"
              to={generateNavigateLink(category.id)}
            >
              {category.name}
            </NameLink>
          </TableDetailNameCell>
          <TableDetailCell className="text-end align-middle  py-3">
            <div className="d-flex justify-content-end">
              <Button
                size="sm"
                className="me-2 rounded-circle"
                variant="outline-primary"
                onClick={() => navigateToCategory(category.id)}
              >
                <FontAwesomeIcon size="sm" icon={faPenToSquare} />
              </Button>
              <DeleteButton
                variant={"rounded"}
                deleteId={category.id}
                onDelete={deleteCategory}
                onDeleteSuccess={fetchCategory}
                message={{
                  success: "deleteCategorySuccess",
                  confirmation: "deleteCategoryMessage"
                }}
              />
            </div>
          </TableDetailCell>
        </TableRow>
      </React.Fragment>
    ))
  ) : (
    <NoResultsRow lengthOfColumns="2" />
  );
};
export default CategoriesTableRow;
