import { useCallback, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
// import Filter from "../table-filter/filter";
import {
  CustomCard,
  PageHeading,
  HeaderColumn,
  BootstrapTable,
  LastHeaderColumn,
  FirstHeaderColumn
} from "../common-styling";
import TableLoader from "../loader/table-loader";
import { useDialog } from "../../hooks/useDialog";
import TemplateTableRow from "./template-table-row";
import { showError } from "../../services/toast-service";
import AdvancedFilters from "../table-filter/advanced-filter";
import CustomPagination from "../pagination/custom-pagination";
import { getAllTemplates } from "../../services/api-declaration";
import { funnelFilterConfig } from "../funnels-table/filter-config";
import { useTranslation } from "react-i18next";

const TemplatesTable = ({ asSubComponent = false }) => {
  const { t } = useTranslation();
  const [templates, setTemplates] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [paginateOpts, setPaginateOpt] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { showCreateNewDialogBox } = useDialog();

  const getTemplates = async () => {
    try {
      setIsLoading(true);
      const params = {};
      for (const [key, value] of searchParams.entries()) {
        params[key] = value;
      }
      const response = await getAllTemplates(params);
      const data = response.data.data;
      setTemplates(data.rows);
      setPaginateOpt({
        totalPages: data.totalPages,
        hasPrevPage: data.hasPrevPage,
        hasNextPage: data.hasNextPage,
        totalDocs: data.totalDocs,
        rowsOnPage: data.rows.length
      });
    } catch (error) {
      console.log("ERROR: ", error);
      showError(
        error.response.data.message ||
          t("toastMessage.errorInGetting", {
            what: t("common.template", { count: 2 })
          })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleQueryParams = (property, value) => {
    searchParams.set(property, value);
    setSearchParams(searchParams);
  };
  const params = useParams();
  const getFunnelsCb = useCallback(
    getTemplates,
    // eslint-disable-next-line
    [searchParams]
  );

  useEffect(() => {
    getFunnelsCb();
  }, [getFunnelsCb]);
  const generateNaviagteUrl = () => "/dashboard/templates/new";
  const generateQueryParams = () =>
    params.id ? ["templateType", "customer"] : ["templateType"];
  const handleModal = () =>
    showCreateNewDialogBox({
      queryparams: generateQueryParams(),
      url: generateNaviagteUrl(),
      customer: params.id,
      modaltype: "common.template"
    });
  return (
    <div>
      {/* <Filter
            pageType="templateType"
            heading={t("header.templates")}
            pageName="name"
            btnText={t("common.createNew.title", {
              name: t("common.template"),
            })}
            handleModal={handleModal}
          /> */}
      <PageHeading className="">{t("sidebar.templates")}</PageHeading>
      <div className="mt-3">
        {!asSubComponent && (
          <AdvancedFilters
            queryName="category"
            components={funnelFilterConfig}
            pageType="templateType"
            heading={t("sidebar.templates")}
            pageName="name"
            btnText={t("common.createNew.title", {
              name: t("common.template").toLowerCase()
            })}
            handleModal={handleModal}
          />
        )}
      </div>
      <CustomCard className="mt-4 mb-3 p-3 rounded-0">
        <BootstrapTable responsive>
          <thead>
            <tr>
              <FirstHeaderColumn>{t("common.templateName")}</FirstHeaderColumn>
              <HeaderColumn>{t("common.type")}</HeaderColumn>
              <HeaderColumn>{t("templateTable.lastModified")}</HeaderColumn>
              <LastHeaderColumn></LastHeaderColumn>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <TableLoader colSpan={4} />
            ) : (
              <TemplateTableRow
                templates={templates}
                getTemplates={getTemplates}
              />
            )}
          </tbody>
        </BootstrapTable>
      </CustomCard>
      <CustomPagination
        paginateOpts={paginateOpts}
        activeTab={Number(searchParams.get("pageIndex")) || 1}
        select={(val) => handleQueryParams("pageIndex", val)}
      />
    </div>
  );
};
export default TemplatesTable;
