import { Nav } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { fetchMissingInfoCount } from "../../services/api-declaration";
import { useDispatch, useSelector } from "react-redux";
import { setMissingInfoCount } from "../../store/slices/missingInfo";

const IconWrapper = styled.div`
  position: relative;
  width: 50px;
`;
const SidebarIcon = styled.img`
  width: 24px;
  height: 24px;
`;
const ItemText = styled.div`
  font-size: 18px;
`;
const BubbleDiv = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  border: 2px solid white;
  border-radius: 10px;
  right: 4px;
  top: -7px;
  background: #fd7e14;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

const Sidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const leadInfoCount = useSelector((state) => state.missingInfo.leadCount);
  const categoryInfoCount = useSelector(
    (state) => state.missingInfo.categoryCount
  );
  const customerInfoCount = useSelector(
    (state) => state.missingInfo.customerCount
  );
  const hubspotInfoCount = useSelector(
    (state) => state.missingInfo.hubspotCount
  );

  useEffect(() => {
    (async function () {
      try {
        const resp = await fetchMissingInfoCount();
        const countData = resp.data.data;
        dispatch(setMissingInfoCount(countData));
      } catch (error) {
        console.log("err", error);
      }
    })();
    // eslint-disable-next-line
  }, []);

  const icons = [
    { name: t("sidebar.dashboard"), icon: "/icons/home.svg", link: "" },
    {
      name: "Funnels",
      icon: "/icons/feedback.svg",
      link: "funnels"
    },
    {
      name: t("sidebar.templates"),
      icon: "/icons/folder.svg",
      link: "templates "
    },
    {
      name: t("sidebar.customers"),
      icon: "/icons/folder-share.svg",
      link: "customers"
    },
    {
      name: "Leads",
      icon: "/icons/inbox.svg",
      link: "leads"
    },
    {
      name: t("sidebar.categories"),
      icon: "/icons/diary.svg",
      link: "categories"
    },
    { name: t("sidebar.gallery"), icon: "/icons/image.svg", link: "gallery" },
    { name: t("sidebar.tags"), icon: "/icons/encyclopedia.svg", link: "tags" },
    { name: t("sidebar.users"), icon: "/icons/user.svg", link: "user" },
    {
      name: t("sidebar.settings"),
      icon: "/icons/settings.svg",
      link: `settings/profile`
    },
    {
      name: t("sidebar.siteSettings"),
      icon: "/icons/site-settings.svg",
      link: `site-settings`
    },
    {
      name: t("sidebar.leadAgent"),
      icon: "/icons/agent.svg",
      link: `agent`
    },
    {
      name: "Leadservice Panel",
      icon: "/icons/leadservice-panel.svg",
      link: `leadservice-panel`
    },
    {
      name: t("funnelsEvaluation"),
      icon: "/icons/evaluation.svg",
      link: `evaluation/overview?dateRange=L14D`
    },
    {
      name: "Information Required",
      icon: "/icons/missing.svg",
      link: `missing-info/lead`,
      showPopup:
        Boolean(categoryInfoCount) ||
        Boolean(leadInfoCount) ||
        Boolean(customerInfoCount) ||
        Boolean(hubspotInfoCount)
    },
    {
      name: t("creativeDashboard"),
      icon: "/icons/evaluation.svg",
      link: `ads/evaluation/overview`
    },
    {
      name: t("tools"),
      icon: "/icons/tools.svg",
      link: `tools/fb-duplicator`
    }
    // { name: t("sidebar.advertisements"), icon: "/icons/target.svg", link: "" },
  ];
  return (
    <>
      <div className="sidebar">
        {icons.map((item, index) => (
          <Nav.Link
            key={index}
            className="item-wrapper"
            as={Link}
            to={item.link}
          >
            <IconWrapper className="d-flex align-items-center justify-content-center">
              {item.showPopup && <BubbleDiv />}
              <SidebarIcon alt={`item-${index}`} src={item.icon} />
            </IconWrapper>
            <ItemText>{item.name}</ItemText>
          </Nav.Link>
        ))}
      </div>
    </>
  );
};
export default Sidebar;
