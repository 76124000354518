import { InputGroup } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import TextField from "./text-field";
import {
  openColorPicker,
  handleColorChange
} from "../../services/common-service";
import { FCInputColor, FCInputColorButton } from "../common-styling";
import { useTranslation } from "react-i18next";

const ColorPickerWithTextField = ({
  label,
  fieldRef,
  fieldName,
  fieldError,
  placeholder = "",
  noSpacing = false,
  isInGroup = false,
  setDisplay = false,
  hideErrMsg = false,
  isDisabled = false,
  fieldWatcher = false,
  defaultColorValue = ""
}) => {
  const { t } = useTranslation();
  const { setValue, register } = useFormContext();
  return (
    <InputGroup className="align-items-center">
      <TextField
        type="text"
        messageHeight="30px"
        label={label}
        placeholder={placeholder}
        name={fieldName}
        errors={fieldError}
        register={register}
        fieldWatcher={fieldWatcher}
        isInGroup={isInGroup}
        setDisplay={setDisplay}
        noSpacing={noSpacing}
        hideErrMsg={hideErrMsg}
      />
      <FCInputColor
        type="color"
        name={fieldName}
        ref={fieldRef}
        disabled={isDisabled}
        defaultValue={defaultColorValue}
        onChange={(ev) => handleColorChange(ev, setValue)}
      />
      <FCInputColorButton
        variant="outline-secondary"
        disabled={isDisabled}
        onClick={() => openColorPicker(fieldRef)}
      >
        {t("common.selectColor")}
      </FCInputColorButton>
    </InputGroup>
  );
};
export default ColorPickerWithTextField;
