import Chart from "react-apexcharts";

const BarChart = ({
  categories = [],
  series,
  distributed = false,
  isLegendVisible = false,
  isStrokeVisible = false,
  columnWidth = "50%",
  height = "100%",
  colors
}) => {
  const config = {
    options: {
      chart: {
        id: "bar-chart"
      },
      dataLabels: {
        style: {
          colors: ["#f2f2f2"]
        }
      },
      xaxis: {
        categories
      },
      plotOptions: {
        bar: {
          distributed,
          dataLabels: {
            enabled: false
          },
          columnWidth: columnWidth
        }
      },

      legend: {
        show: isLegendVisible
      },
      stroke: {
        show: isStrokeVisible,
        width: 1,
        colors: ["#fff"]
      }

      // colors: [
      //   "#d9b1ad",
      //   "#d9c1ad",
      //   "#93c47d",
      //   "#c5ac5e",
      //   "#e69138",
      //   "#e06666",
      //   "#15535c",
      //   "#4544cd",
      //   "#819296",
      //   "#605383",
      //   "#82536a"
      // ]
    },
    series: [...series]
  };

  if (colors?.length) {
    config.options.colors = colors;
  }

  return (
    <Chart
      options={config.options}
      series={config.series}
      type="bar"
      height={height}
      width={"100%"}
    />
  );
};

export default BarChart;
