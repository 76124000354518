import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const CustomPagination = ({
  paginateOpts: { totalPages, hasPrevPage, hasNextPage, totalDocs, rowsOnPage },
  activeTab,
  select
}) => {
  const { t } = useTranslation();
  const onSelect = (tabIndex) => {
    if (activeTab !== tabIndex) {
      select(tabIndex);
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-between mb-2">
      <ListGroup className="fw-bold border" horizontal>
        <ListGroup.Item
          role="button"
          className={`border-0 border-end ${hasPrevPage && "text-primary"}`}
          onClick={() => onSelect(activeTab - 1)}
          disabled={!hasPrevPage}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </ListGroup.Item>
        <ListGroup.Item className="border-0">
          {activeTab} <span className="mx-2 fw-normal">{t("common.of")}</span>
          {totalPages}
        </ListGroup.Item>
        <ListGroup.Item
          role="button"
          className={`border-0 border-start ${hasNextPage && "text-primary"}`}
          onClick={() => onSelect(activeTab + 1)}
          disabled={!hasNextPage}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </ListGroup.Item>
      </ListGroup>
      <div>
        {rowsOnPage} {t("common.of")} {totalDocs} {t("common.results")}
      </div>
    </div>
  );
};
export default CustomPagination;
