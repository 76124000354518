import {
  Col,
  Row,
  Form,
  Badge,
  Button,
  Collapse,
  Container
} from "react-bootstrap";
import { useState } from "react";
import { useOutletContext } from "react-router";
import { useTranslation, Trans } from "react-i18next";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  updateIntegration,
  removeIntegration
} from "../../../services/api-declaration";
import { INTEGRATION_TYPE } from "../../strings";
import RowActionButtons from "./row-action-buttons";
import { useDialog } from "../../../hooks/useDialog";
import { showSuccess, showError } from "../../../services/toast-service";

const IntegrationsTableView = ({
  headers,
  integrations = [],
  propertiesToRender = [],
  isAllowedAddNew = false,
  onEditClick,
  setIntegrations = () => {},
  forAdvertisingAccountLink = false,
  forCustomerAppointment = false,
  setIsAllowedAddNew = () => {},
  handleStatesOnStatusChange = () => {},
  handleCustomerAppointment = () => {},
  handleAdvertisingAccount = () => {},
  resetComponentState = () => {}
}) => {
  const { t } = useTranslation();
  const { funnel } = useOutletContext();
  const { showConfirmationDialogBox } = useDialog();
  const [isDeleting, setIsDeleting] = useState({ id: false });
  const allowedMultiple =
    integrations[0]?.type !== INTEGRATION_TYPE.CONTACT_CLOUD &&
    !forCustomerAppointment &&
    !forAdvertisingAccountLink;

  const getIntegrationType = (type) => {
    let integrationType = "";
    switch (type) {
      case INTEGRATION_TYPE.ZAPIER:
        integrationType = "zapier";
        break;
      case INTEGRATION_TYPE.CONTACT_CLOUD:
        integrationType = "contactCloud";
        break;
      case INTEGRATION_TYPE.EMAIL:
        integrationType = "email";
        break;
      default:
        break;
    }
    return integrationType;
  };
  const accessNestedObjValue = (obj, path) =>
    path
      .replace(/\[|\]\.?/g, ".")
      .split(".")
      .filter((s) => s)
      .reduce((acc, val) => acc && acc[val], obj);

  const handleOnCheck = (item, itemIdx) => {
    handleStatesOnStatusChange(item, itemIdx);
    toggleStatusConfirmationDialog(item, itemIdx);
  };
  const toggleStatusConfirmationDialog = (item, itemIdx) =>
    showConfirmationDialogBox({
      title: "Warning",
      dialogType: "warning",
      dialogMessage: renderStatusDialogMessage(!item.active),
      responseAction: (action) => statusDialogResponse(action, item, itemIdx)
    });
  const onDeleteClick = () => {
    resetComponentState();
    setIsAllowedAddNew(false);
    showConfirmationDialogBox({
      title: `${t("delete")} ${
        forCustomerAppointment
          ? t("funnelIntegration.appointment.label")
          : forAdvertisingAccountLink
            ? t("funnelIntegration.advertisingAccount.label")
            : "Integration"
      }`,
      dialogType: "danger",
      dialogMessage: renderDeleteDialogMessage(),
      responseAction: deleteDialogResponse
    });
  };
  const handleUpdateStatus = async (integration, intgIdx) => {
    try {
      const response = await updateIntegration(integration._id, {
        active: !integration.active
      });
      const integrationType = getIntegrationType(integration.type);
      setIntegrations((data) => {
        const clone = [...data[integrationType]];
        clone[intgIdx].active = !integration.active;
        data[integrationType][intgIdx] = clone[intgIdx];
        return { ...data };
      });
      showSuccess(response.data.message);
    } catch (err) {
      showError(
        err.response?.data?.message || t("funnelIntegration.updateIntegration")
      );
    }
  };
  const handleDeleteIntegration = async (integration) => {
    setIsDeleting({ [integration._id]: true });
    try {
      const response = await removeIntegration(integration._id);
      const filteredData = integrations.filter(
        (data) => data._id !== integration._id
      );
      const integrationType = getIntegrationType(integration.type);
      setIntegrations((data) => ({
        ...data,
        [integrationType]: filteredData
      }));
      showSuccess(response.data.message);
    } catch (err) {
      console.log(err);
      showError(
        err.response?.data?.message || t("funnelIntegration.removeIntegration")
      );
    } finally {
      setIsDeleting({ [integration]: false });
    }
  };
  const renderStatusDialogMessage = (_checked) => (
    <Trans
      i18nKey={
        _checked
          ? t("funnelIntegration.enableDialogMsg")
          : t("funnelIntegration.disableDialogMsg")
      }
    />
  );
  const renderDeleteDialogMessage = () =>
    forCustomerAppointment ? (
      <Trans i18nKey={t("funnelIntegration.deleteAppointmentLinkWarning")} />
    ) : forAdvertisingAccountLink ? (
      <Trans i18nKey={t("funnelIntegration.deleteAccountLinkWarning")} />
    ) : (
      <Trans i18nKey="funnelIntegration.deleteIntegrationWarning" />
    );
  const statusDialogResponse = (action, item, idx) => {
    if (action) {
      handleUpdateStatus(item, idx);
    }
  };
  const deleteDialogResponse = async (action, values) => {
    if (action) {
      if (values.selectedIntegration?._id) {
        return await handleDeleteIntegration(values.selectedIntegration);
      }
      if (forCustomerAppointment) {
        return handleCustomerAppointment({ appointmentLink: null });
      }
      if (forAdvertisingAccountLink) {
        return handleAdvertisingAccount({ accountLink: null });
      }
    }
  };
  const defaultEmailCondition = (item, itemIdx) =>
    itemIdx === 0 &&
    item.type === INTEGRATION_TYPE.EMAIL &&
    funnel?.customer?.email === item.sourceData?.id;

  return (
    <>
      <Container
        className={`${isAllowedAddNew ? "pe-none text-muted bg-light" : ""}`}
      >
        {/* Header */}
        {integrations.length ? (
          <Row className="py-2 mb-3 fw-bold border-bottom align-self-center">
            {headers.map((header, headerIndex) => (
              <Col
                key={headerIndex}
                xs={header.colWidth}
                className={header.style}
              >
                {header.name}
              </Col>
            ))}
          </Row>
        ) : (
          <></>
        )}

        {/* Content */}
        {integrations.map((item, itemIndex) => (
          <Row
            key={itemIndex}
            className={`py-1 ${allowedMultiple ? "mb-3" : "mb-0"}`}
          >
            {item.type && item.type !== INTEGRATION_TYPE.EMAIL && (
              <Col xs={1}>
                <Form.Check
                  role="button"
                  checked={item.active}
                  onChange={() => handleOnCheck(item, itemIndex)}
                />
              </Col>
            )}
            {propertiesToRender.map((property, propIdx) => (
              <Col key={propIdx} className="text-break">
                {accessNestedObjValue(item, property) || "---"}
                {defaultEmailCondition(item, itemIndex) && (
                  <Badge pill bg="secondary" className="ms-2 px-3 py-1">
                    Default
                  </Badge>
                )}
              </Col>
            ))}
            <Col xs={2} className="text-end">
              <RowActionButtons
                item={item}
                idx={itemIndex}
                isDeleting={isDeleting}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
                isDisabled={
                  integrations.length === 1 &&
                  defaultEmailCondition(item, itemIndex)
                }
              />
            </Col>
          </Row>
        ))}
      </Container>

      <Collapse in={!isAllowedAddNew && allowedMultiple}>
        <div>
          <Button
            className="w-100"
            variant="outline-primary"
            onClick={() => setIsAllowedAddNew(true)}
          >
            <FontAwesomeIcon icon={faAdd} className="me-2" />
            {t("common.addNew")}
          </Button>
        </div>
      </Collapse>
    </>
  );
};
export default IntegrationsTableView;
