import { useNavigate } from "react-router";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Col, Form, Row } from "react-bootstrap";
import Loader from "../loader/loader";
import {
  CreateUserSchema,
  UserCommonSchema
} from "../../services/validation-schema";
import { CustomCard } from "../common-styling";
import NavigateBackButton from "../back-button";
import { USER_ROLES, USER_STATUS } from "../strings";
import { showError } from "../../services/toast-service";
import PreventReload from "../funnel-form/prevent-reload";
import TextField from "../reusable-form-fields/text-field";
import RadioInput from "../reusable-form-fields/input-radio";
import { fetchSingleUser } from "../../services/api-declaration";
import PasswordToggle from "../change-password/field-with-toggle";
import ValidationMessage from "../validation-message/validation-message";
import { useTranslation } from "react-i18next";

const UserForm = ({ userId, handleFormSubmit, showProfileHeading = false }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [type, setType] = useState("password");

  const methods = useForm({
    resolver: yupResolver(userId ? UserCommonSchema : CreateUserSchema),
    defaultValues: {
      role: USER_ROLES.ADMIN,
      status: USER_STATUS.ACTIVE,
      firstName: "",
      lastName: ""
    }
  });

  const {
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = methods;

  const generatePassword = () => {
    const length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    setValue("password", retVal);
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data } = await fetchSingleUser(userId);
        const fetchedData = { ...data.data };
        reset(fetchedData);
        setIsLoading(false);
      } catch (error) {
        showError(
          error?.response?.data?.message ||
            t("toastMessage.errorInGetting", {
              what: t("common.user")
            })
        );
        console.log(error);
      }
    };
    if (userId) {
      fetchUser();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [reset, userId, navigate]);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <FormProvider {...methods}>
              <PreventReload />
              {userId && !showProfileHeading && (
                <div className={`my-4 d-flex align-items-center`}>
                  <div className="me-3">
                    <NavigateBackButton />
                  </div>
                  <h5 className="mb-0 me-4">
                    {t("common.edit")} {t("userDetails")}
                  </h5>
                </div>
              )}
              <CustomCard className="py-4 px-3">
                <div>
                  <Row>
                    <Col>
                      {!userId ? (
                        <TextField
                          label={<strong>{t("common.email")}:</strong>}
                          placeholder={t("common.email")}
                          type="email"
                          name="email"
                          errors={errors?.email}
                          register={register}
                        />
                      ) : (
                        <p className="m-0">
                          <strong>{t("common.email")}:</strong>{" "}
                          {getValues("email")}
                        </p>
                      )}
                    </Col>
                    {!userId && (
                      <Col>
                        <PasswordToggle
                          toggleHandler={() =>
                            setType(type === "password" ? "text" : "password")
                          }
                          error={errors?.password}
                          name="password"
                          type={type}
                          label="Password:"
                        />
                        <Button onClick={generatePassword}>
                          {t("generatePassword")}
                        </Button>
                      </Col>
                    )}
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <TextField
                        label={<strong>{t("common.firstName")}:</strong>}
                        placeholder={t("common.firstName")}
                        type="text"
                        name="firstName"
                        errors={errors?.firstName}
                        register={register}
                      />
                    </Col>
                    <Col>
                      <TextField
                        label={<strong>{t("common.lastName")}:</strong>}
                        placeholder={t("common.lastName")}
                        type="text"
                        name="lastName"
                        errors={errors?.lastName}
                        register={register}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3 align-items-center">
                    <Col>
                      <Form.Label
                        className={`fw-bold ${errors?.role && "text-danger"}`}
                      >
                        {t("common.role")}:{errors?.role && "*"}
                      </Form.Label>
                      <Form.Select {...register("role")}>
                        <option value={USER_ROLES.ADMIN}>Admin</option>
                        <option value={USER_ROLES.AGENT}>Agent</option>
                      </Form.Select>
                      <ValidationMessage error={errors?.role?.message} />
                    </Col>
                    <Col>
                      <Form.Label
                        className={`fw-bold ${errors?.status && "text-danger"}`}
                      >
                        Status:{errors?.status && "*"}
                      </Form.Label>
                      <div className="mt-2">
                        <RadioInput
                          firstOptLabel="Active"
                          secondOptLabel="In-active"
                          name="status"
                          firstValue={USER_STATUS.ACTIVE}
                          firstValueId={USER_STATUS.ACTIVE}
                          secondValue={USER_STATUS.IN_ACTIVE}
                          secondValueId={USER_STATUS.IN_ACTIVE}
                          register={register}
                          noSpacing
                        />
                      </div>
                      <ValidationMessage error={errors?.status?.message} />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <Button type="submit">
                        {userId ? t("common.update") : t("common.create")}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </CustomCard>
            </FormProvider>
          </form>
        </>
      )}
    </div>
  );
};

export default UserForm;
