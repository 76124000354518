import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const NavigatedRouteMessage = () => {
  const { t } = useTranslation();
  const { state } = useLocation(); // navigatedTemplate or navigatedCustomer, if create funnel open/navigated from a template or customer

  return state?.navigatedTemplate || state?.navigatedCustomer ? (
    <div className="text-muted">
      Diese Seite wurde aufgerufen von:
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip>
            {state.navigatedTemplate
              ? t("common.template")
              : t("common.customer")}
          </Tooltip>
        }
      >
        <Link
          target="_blank"
          className="ms-1"
          to={
            state.navigatedTemplate
              ? `/dashboard/templates/${state.navigatedTemplate?._id}?templateType=${state.navigatedTemplate.templateType}`
              : `/dashboard/customers/${state.navigatedCustomer?._id}/overview`
          }
        >
          {state.navigatedTemplate
            ? state.navigatedTemplate.name
            : state.navigatedCustomer
              ? `${state.navigatedCustomer.firstName} ${state.navigatedCustomer.lastName}`
              : ""}
        </Link>
      </OverlayTrigger>
    </div>
  ) : (
    <></>
  );
};
export default NavigatedRouteMessage;
