import { NOT_SUITABLE_STAGE } from "../strings";

const black = { color: "#ffffff", background: "#000000" };
const darkGray = { color: "#ffffff", background: "#808080" };
const red = { color: "#f5222d", background: "#fff1f0", border: "#ffa39e" };
const blue = { color: "#1890ff", background: "#e6f7ff", border: "#91d5ff" };
const green = { color: "#52c41a", background: "#f6ffed", border: "#b7eb8f" };
const orange = { color: "#fa8c16", background: "#fff7e6", border: "#ffd591" };

// lead & applicant stages
export const stagesHash = [
  { title: "Noch nicht kontaktiert", value: "new", ...darkGray },
  { title: "Kontaktversuch", value: "contact_attempt", ...orange },
  { title: "Warte auf Kundeninformationen", value: "appointment_failed" },
  { title: "Termin vereinbart", value: "appointment_agreed", ...blue },
  { title: "Termin erfolgt", value: "appointment_is_done" },
  { title: "Angebot versendet", value: "offer_sent" },
  { title: "Auftrag", value: "assignment", ...green },
  { title: "Nicht geeignet", value: NOT_SUITABLE_STAGE, ...red },
  { title: "Archiviert", value: "archived", ...black },
  { title: "Alle", value: "all" },
  { title: "Gelöscht", value: "deleted" },
  {
    title: "Bewerbung eingegangen",
    value: "application_received",
    ...darkGray
  },
  { title: "Telefongespräch", value: "phone_interview", ...blue },
  { title: "Bewerbungsgespräch", value: "interview" },
  { title: "Bewertung", value: "screening" },
  { title: "Angebot", value: "offer" },
  { title: "Eingestellt", value: "hired", ...green }
];

export const notSuitableReasons = {
  too_little_budget: "Zu wenig Budget",
  no_longer_available: "Nicht mehr erreichbar",
  bought_somewhere_else: "Hat woanders gekauft",
  has_changed_his_mind: "Hat sich umentschieden",
  another_product: "Möchte ein anderes Produkt",
  wrong_telephone: "Falsche Telefonnummer",
  request_too_far_away: "Anfrage zu weit entfernt",
  disagree_on_the_conditions: "Nicht einig bei den Konditionen",
  no_want_to_change: "Möchte nicht wechseln",
  no_fit_humanly: "Passt menschlich nicht",
  no_fit_technically: "Passt fachlich nicht",
  too_far_away: "Zu weit entfernt"
};
