import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListGroup, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Drawer from "../drawer";
import { HorizontalDivider } from "../common-styling";
import SpinnerButton from "../spinner-button/spinner-button";
import { generateDateTime } from "../../services/common-service";
import { useTranslation } from "react-i18next";

const VersionDrawer = ({
  versions,
  showDrawer,
  setShowDrawer,
  handleVersionChange,
  versionLoadingIndex
}) => {
  const { t } = useTranslation();
  const currentVersion = versions.currentVersion;
  const versionChangeHandler = (version, index) => {
    handleVersionChange(version, index);
  };
  const toggleDrawer =
    versionLoadingIndex === -1
      ? () => setShowDrawer(!showDrawer)
      : () => setShowDrawer(true);
  return (
    <Drawer
      toggleDrawer={toggleDrawer}
      drawerTitle="Versions"
      showDrawer={showDrawer}
      width="20"
    >
      <HorizontalDivider />
      <ListGroup as="ul">
        {versions.allVersions.map((version, index) => {
          const isCurrentVersion = currentVersion?._id === version._id;
          return (
            <ListGroup.Item
              key={index}
              as="li"
              className={`mb-2 ${
                isCurrentVersion
                  ? "border-top border-4 border-success"
                  : "rounded"
              }`}
            >
              <div>
                <div>
                  <div className="mb-1">
                    <h6 className="fw-bold mb-0">
                      {version.funnelVersion.title}
                    </h6>
                    {isCurrentVersion && (
                      <div>
                        <span className="text-success fw-normal small">
                          {t("currentVersion")}
                        </span>
                      </div>
                    )}
                    <div>
                      By{" "}
                      <span className="fw-bold">
                        {version.funnelVersion.createdBy.firstName}{" "}
                        {version.funnelVersion.createdBy.lastName}{" "}
                      </span>
                      <span className="text-muted">
                        {generateDateTime(version.createdAt)}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    {versionLoadingIndex === index ? (
                      <SpinnerButton btnStyle="btn-warning rounded-circle btn-sm" />
                    ) : (
                      <OverlayTrigger
                        placement="left"
                        overlay={<Tooltip>{t("common.select")}</Tooltip>}
                      >
                        <Button
                          onClick={() => versionChangeHandler(version, index)}
                          size="sm"
                          disabled={versionLoadingIndex !== -1}
                          variant={
                            isCurrentVersion ? "success" : "outline-success"
                          }
                          className={`rounded-circle ${
                            isCurrentVersion ? "pe-none" : ""
                          }`}
                        >
                          <FontAwesomeIcon icon={faCheck} />
                        </Button>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </Drawer>
  );
};

export default VersionDrawer;
