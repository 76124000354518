import { useState } from "react";
import styled from "styled-components";
import { useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Col, Form, Modal, ProgressBar, Row } from "react-bootstrap";
import AddNewImage from "./add-new-image";
import { ModalHead } from "../common-styling";
import { showError } from "../../services/toast-service";
import PreventReload from "../funnel-form/prevent-reload";
import { updateAssetEntry } from "../../services/common-service";
import { createAssetEntry } from "../../services/api-declaration";
import { axioxFileUpload } from "../../services/axios-rest-methods";
import { GalleryFormSchema } from "../../services/validation-schema";
import { useTranslation } from "react-i18next";

const DropArea = styled.div`
  border: 1px dashed #80808099;
  border-color: ${({ fielerror }) => `${fielerror ? "red" : "#80808099"}`};
  cursor: pointer;
  padding: 10px;
  border-radius: 6px;
  color: #808080bd;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  width: 100%;
  background: ${({ drag }) => `${drag && "#8080804f"}`};
  & img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;
const CreateGalleryModal = ({ onClose, onFinish }) => {
  const { t } = useTranslation();
  const storedFile = useRef();
  const progressRef = useRef();

  const [displayImage, setDisplayImage] = useState();
  const [fileError, setFileError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const handleUploadFile = async (formValues) => {
    if (isUploading) return;
    const file = storedFile.current;
    if (!file) {
      setFileError(true);
      return;
    }
    try {
      setIsUploading(true);
      const draftAsset = await createAssetEntry({
        contentType: file.type,
        fileSize: file.size,
        fileName: file.name,
        ...formValues
      });
      const bodyData = {
        "Content-Type": draftAsset.data.fields["Content-Type"],
        key: draftAsset.data.fields["key"],
        bucket: draftAsset.data.fields["bucket"],
        "X-Amz-Algorithm": draftAsset.data.fields["X-Amz-Algorithm"],
        "X-Amz-Credential": draftAsset.data.fields["X-Amz-Credential"],
        "X-Amz-Date": draftAsset.data.fields["X-Amz-Date"],
        Policy: draftAsset.data.fields["Policy"],
        "X-Amz-Signature": draftAsset.data.fields["X-Amz-Signature"],
        file: file
      };
      const upload = await axioxFileUpload(
        draftAsset.data.url,
        bodyData,
        function (progress) {
          if (progressRef.current) {
            progressRef.current.children[0].style.width = `${progress}%`;
          }
        }
      );
      console.log(upload);
      const assetData = {
        _id: draftAsset.data._id,
        ...draftAsset.data,
        ETag: upload.headers.etag,
        draft: false
      };
      await updateAssetEntry(assetData);
      const category = formValues.category
        ? { name: formValues.category.name, _id: formValues.category._id }
        : null;
      await onFinish({
        ...assetData,
        category
      });
    } catch (err) {
      console.log(err);
      showError(
        err?.response?.data?.message || t("toastMessage.uploadItemError")
      );
    } finally {
      setIsUploading(false);
    }
  };
  const onDrop = useCallback((files) => {
    if (files.length) {
      storedFile.current = files[0];
      setFileError(false);
      setDisplayImage(URL.createObjectURL(files[0]));
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".gif", ".jpeg", ".jpg"]
    },
    multiple: false
  });

  const methods = useForm({
    resolver: yupResolver(GalleryFormSchema),
    defaultValues: {
      category: "",
      title: "",
      description: "",
      tags: []
    }
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = methods;

  return (
    <>
      <Modal centered size="lg" show={true}>
        <ModalHead className="fw-bold text-light" bg="primary">
          <Modal.Title>{t("uploadImage")}</Modal.Title>
        </ModalHead>
        <Modal.Body>
          <FormProvider {...methods}>
            <PreventReload />
            <Form>
              <Row className="gy-3 align-items-center">
                <AddNewImage
                  errors={errors}
                  control={control}
                  register={register}
                />
                <Col md={12}>
                  <DropArea
                    fielerror={fileError}
                    drag={isDragActive}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    {displayImage ? (
                      <img src={displayImage} alt="" />
                    ) : isDragActive ? (
                      <h5>HIER ABLEGEN!</h5>
                    ) : (
                      <h5>{t("dropImageMsg")}</h5>
                    )}
                  </DropArea>
                </Col>
              </Row>
            </Form>
          </FormProvider>
          <ProgressBar
            className={`mt-2 ${!isUploading ? "d-none" : "d-flex"}`}
            ref={progressRef}
            striped
            animated
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={isUploading}
            onClick={() => {
              onClose();
              setDisplayImage();
            }}
          >
            {t("common.close")}
          </Button>
          <Button
            disabled={isUploading}
            variant="primary"
            onClick={handleSubmit(handleUploadFile)}
          >
            {isUploading ? `${t("uploadImage")}...` : t("uploadImage")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateGalleryModal;
