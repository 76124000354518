import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useOutletContext, useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Form, Spinner, Collapse, Container, Row, Col } from "react-bootstrap";
import {
  FieldSet,
  BorderlessSelect,
  FadedBackgroundDiv
} from "../common-styling";
import {
  integrateFunnel,
  updateIntegration,
  updateIntegrations
} from "../../services/api-declaration";
import { useDialog } from "../../hooks/useDialog";
import { INTEGRATION_TYPE, SEND_DATA } from "../strings";
import PreventReload from "../funnel-form/prevent-reload";
import TextField from "../reusable-form-fields/text-field";
import { showError, showSuccess } from "../../services/toast-service";
import ActionButtonsColumn from "./integration-view/action-buttons-column";
import { EmailIntegrationFormSchema } from "../../services/validation-schema";
import IntegrationsTableView from "./integration-view/integrations-table-view";
import { useTranslation } from "react-i18next";

/* eslint-disable react/display-name --- for forwardRef --- */
const EmailToCustomer = forwardRef(
  ({ setActivePanel, emailIntegrations, setIntegrations }, ref) => {
    const { t } = useTranslation();
    const paramsId = useParams().id;
    const { funnel } = useOutletContext();
    const { showAlertDialogBox, setDependentStates } = useDialog();
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [selectedIntegration, setSelectedIntegration] = useState(null);
    const [isEditing, setIsEditing] = useState({ id: false });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submittingAddNew, isSubmittingAddNew] = useState(false);
    const [isAllowedAddNew, setIsAllowedAddNew] = useState(false);
    const [restoredValue, setRestoredValue] = useState("");
    const methods = useForm({
      resolver: yupResolver(EmailIntegrationFormSchema),
      defaultValues: {
        emailValue:
          emailIntegrations[0]?.sourceData?.meta?.sendData || SEND_DATA.NEVER,
        email: ""
      }
    });
    const {
      register,
      setValue,
      clearErrors,
      handleSubmit,
      reset,
      formState: { errors }
    } = methods;

    // Added useImperativeHandle to call this function in it's parent component
    useImperativeHandle(ref, () => ({
      handleEmailOnDisableLeadService() {
        handleEmailConfiguration(SEND_DATA.NEVER);
        setValue("emailValue", SEND_DATA.NEVER);
      }
    }));

    const handleValueChange = (_value) => {
      if (_value === SEND_DATA.AFTER_CONFIRMATION && !funnel.leadService) {
        toggleAlertDialog();
        // open accordion for enable lead service 2.0
        setActivePanel("2");
        // set previous email config value
        setValue("emailValue", restoredValue || SEND_DATA.NEVER);
      } else {
        console.log("_value ", _value);
        handleEmailConfiguration(_value);
      }
    };
    const _integrateFunnel = async ({ sendDataVal, emailAddress }) => {
      const body = {
        funnelType: funnel.general.funnelType,
        integrationType: INTEGRATION_TYPE.EMAIL,
        sendData: sendDataVal,
        email: emailAddress,
        active: true
      };
      const response = await integrateFunnel(paramsId, body);
      const integrationData = {
        funnelId: paramsId,
        type: INTEGRATION_TYPE.EMAIL,
        sourceData: {
          id: body.email,
          meta: { sendData: body.sendData }
        },
        description: "",
        active: true
      };
      setIntegrations((data) => {
        let clone;
        if (!data.email?.length) {
          clone = [];
        } else {
          clone = [...data.email];
        }
        clone.push({
          ...integrationData,
          _id: response.data.data.upsertedId
        });
        data.email = clone;
        return { ...data };
      });
      showSuccess(response.data.message);
    };
    const handleEmailConfiguration = async (value) => {
      try {
        setIsSubmitting(true);
        const response = await updateIntegrations(
          {
            funnelId: paramsId,
            integrationType: INTEGRATION_TYPE.EMAIL
          },
          { "sourceData.meta.sendData": value }
        );
        setIntegrations((data) => {
          const clone = [...data.email];
          for (const email of clone) {
            email.sourceData.meta.sendData = value;
          }
          return { ...data, email: clone };
        });
        setRestoredValue(value);
        showSuccess(response.data?.message);
        reset({ emailValue: value }); // for reset isDirty value
      } catch (error) {
        console.log("Error: ", error);
        showError(t("funnelIntegration.email.toastError"));
      } finally {
        setIsSubmitting(false);
      }
    };
    const toggleAlertDialog = () => {
      showAlertDialogBox({
        message: (
          <div
            dangerouslySetInnerHTML={{
              __html: t("funnelIntegration.email.alertDialogMessage")
            }}
          ></div>
        ),
        dialogType: "danger",
        title: "Warning"
      });
    };
    const handleUpdateIntegration = async (
      integration,
      payload,
      currentIdx
    ) => {
      try {
        const response = await updateIntegration(integration._id, payload);

        setIntegrations((data) => {
          const clone = [...data.email];
          clone[currentIdx].sourceData.id = payload.sourceData.id;
          data.email = clone;
          return { ...data };
        });
        showSuccess(response.data.message);
      } catch (err) {
        console.log(err);
        showError(
          err.response?.data?.message ||
            t("funnelIntegration.updateIntegration")
        );
      }
    };
    const onSubmit = async (formVals) => {
      isSubmittingAddNew(true);
      try {
        if (selectedIntegration && isEditing[selectedIntegration?._id]) {
          await handleUpdateIntegration(
            selectedIntegration,
            {
              sourceData: {
                id: formVals.email,
                meta: { sendData: formVals.emailValue }
              }
            },
            currentIndex
          );
        } else {
          await _integrateFunnel({
            sendDataVal: formVals.emailValue,
            emailAddress: formVals.email
          });
        }
        resetComponentState();
      } catch (err) {
        console.log("Error: ", err);
      } finally {
        isSubmittingAddNew(false);
      }
    };
    const handleStatesOnStatusChange = (data, idx) => {
      setCurrentIndex(idx);
      setSelectedIntegration(data);
      setDependentStates({ selectedIntegration: data, currentIndex: idx });
    };
    const handleEditClick = (data, emailIndex) => {
      setCurrentIndex(emailIndex);
      setSelectedIntegration(data);
      setIsEditing({ [data._id]: true });
      setIsAllowedAddNew(true);
      setValue("email", data.sourceData.id);
      clearErrors();
    };
    const resetComponentState = () => {
      setValue("email", "");
      clearErrors();
      setCurrentIndex(-1);
      setSelectedIntegration(null);
      setIsEditing({ id: false });
      setIsAllowedAddNew(false);
      reset(); // for reset isDirty
    };

    useEffect(() => {
      if (!emailIntegrations.length) {
        setValue("emailValue", SEND_DATA.NEVER);
      }
    }, [emailIntegrations, setValue]);

    return (
      <div className="px-3">
        <FieldSet>
          <Container className="px-0">
            <FormProvider {...methods}>
              <PreventReload />
              <Form
                className="p-0"
                onSubmit={(event) => {
                  handleSubmit(onSubmit)(event);
                }}
              >
                <div className="pb-2 d-flex align-items-center border-bottom">
                  <div className="flex-fill">
                    <div className="fw-bold">
                      {t("funnelIntegration.email.label")}
                    </div>
                  </div>
                  <Collapse in={!isAllowedAddNew}>
                    <div>
                      <div className="d-flex align-items-center">
                        <BorderlessSelect
                          fs="12"
                          height="30"
                          width="200"
                          align="right"
                          {...register("emailValue", {
                            onChange: ({ target: { value } }) => {
                              handleValueChange(value);
                            }
                          })}
                        >
                          <option value={SEND_DATA.NEVER}>
                            {t("funnelIntegration.selectOptions.never")}
                          </option>
                          <option value={SEND_DATA.IMMEDIATELY}>
                            {t("funnelIntegration.selectOptions.immediately")}
                          </option>
                          <option value={SEND_DATA.AFTER_CONFIRMATION}>
                            {t(
                              "funnelIntegration.selectOptions.afterConfirmation"
                            )}
                          </option>
                        </BorderlessSelect>
                        {isSubmitting ? (
                          <Spinner
                            size="sm"
                            animation="border"
                            className="text-secondary"
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="text-success"
                            icon={faCheckCircle}
                          />
                        )}
                      </div>
                    </div>
                  </Collapse>
                </div>
                <Collapse in={isAllowedAddNew}>
                  <div>
                    <FadedBackgroundDiv className="px-2 py-3 border-bottom">
                      <Row>
                        <Col lg={10} md={8} sm={12}>
                          <TextField
                            type="email"
                            name="email"
                            register={register}
                            errors={errors?.email}
                            label={t("common.enterEmail")}
                            placeholder={`${t("common.email")} ${t(
                              "common.address"
                            )}`}
                            noSpacing
                          />
                        </Col>
                        <ActionButtonsColumn
                          mdColWidth={4}
                          fieldError={errors?.email}
                          actions={{
                            isSubmitting: submittingAddNew,
                            resetComponentState
                          }}
                        />
                      </Row>
                    </FadedBackgroundDiv>
                  </div>
                </Collapse>
                <IntegrationsTableView
                  headers={[
                    {
                      name: t("common.email"),
                      colWidth: null,
                      style: "pb-1"
                    },
                    {
                      name: "Action",
                      colWidth: 2,
                      style: "pb-1 text-end"
                    }
                  ]}
                  integrations={emailIntegrations}
                  setIntegrations={setIntegrations}
                  propertiesToRender={["sourceData.id"]}
                  handleStatesOnStatusChange={handleStatesOnStatusChange}
                  onEditClick={handleEditClick}
                  isAllowedAddNew={isAllowedAddNew}
                  setIsAllowedAddNew={setIsAllowedAddNew}
                  resetComponentState={resetComponentState}
                />
              </Form>
            </FormProvider>
          </Container>
        </FieldSet>
      </div>
    );
  }
);

export default EmailToCustomer;
