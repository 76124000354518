import { List } from "react-virtualized";
const MenuList = (props) => {
  const rows = props.children;
  return (
    <List
      className="w-100"
      width={props.container.current.offsetWidth}
      height={rows.length > 10 ? 300 : rows.length ? rows.length * 40 : 40}
      rowHeight={40}
      rowCount={rows.length || 1}
      rowRenderer={({ key, index, style }) => (
        <div key={key} style={style}>
          {rows[index] ? rows[index] : rows}
        </div>
      )}
    />
  );
};
export default MenuList;
