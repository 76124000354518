import Select from "react-select";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const SelectUser = ({ isLoadingPages }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const user = useSelector((state) => state.auth.user);
  const availableUsers = user.workspace.fbTokens;

  return (
    <Form.Group className="mb-4 flex-fill me-2" controlId="user">
      <Form.Label className="fw-bold">{t("selectUserAccount")}:</Form.Label>
      <Controller
        control={control}
        name="facebookForm.user"
        render={({ field }) => (
          <Select
            {...field}
            isDisabled={isLoadingPages}
            getOptionLabel={(opt) => opt.email}
            getOptionValue={(opt) => opt._id}
            options={availableUsers.map((user) => user.userId)}
          />
        )}
      />
    </Form.Group>
  );
};
export default SelectUser;
