import { useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Button, Modal } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import {
  showError,
  showSuccess,
  showWarning
} from "../../services/toast-service";
import { unarchiveFunnel } from "../../services/api-declaration";
import DomainSection from "../reusable-form-section/domain-section";
import { DomainDialogSchema } from "../../services/validation-schema";
import { useTranslation } from "react-i18next";
const AddDomainModal = ({ show, toggleDialog, resourceId, getFunnels }) => {
  const { t } = useTranslation();
  const initialValues = { domain: "", subDomain: "" };
  const [isDomainValid, setIsDomainValid] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const btnRef = useRef();

  const handleSave = async (formValues) => {
    if (!isDomainValid) {
      showWarning(
        "Please change domain or sub-domain. This is already used in another funnel."
      );
      return;
    }
    try {
      setIsSubmitting(true);
      const response = await unarchiveFunnel(resourceId, formValues);
      showSuccess(response.data.message);
      toggleDialog();
      getFunnels();
    } catch (error) {
      console.log(error);
      showError(error.response.data.message || t("toastMessage.updateFunnels"));
    } finally {
      setIsSubmitting(false);
    }
  };
  const methods = useForm({
    resolver: yupResolver(DomainDialogSchema),
    defaultValues: initialValues
  });
  const { handleSubmit } = methods;

  return (
    <Modal show={show} onHide={toggleDialog} centered>
      <Modal.Header className="bg-primary text-white">
        <h5 className="m-0">{t("addDomainModal.title")}</h5>
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...methods} id="domain-form">
          <Form onSubmit={handleSubmit(handleSave)}>
            <DomainSection
              btnRef={btnRef}
              isDomainValid={isDomainValid}
              setIsDomainValid={setIsDomainValid}
              setShouldReleaseDomain={() => {}}
              inDialog
            />
            <hr />
            <div className="d-flex flex-row-reverse">
              <Button
                className="ms-2"
                variant="outline-secondary"
                onClick={() => toggleDialog()}
              >
                {t("common.cancel")}
              </Button>
              <Button
                type="submit"
                ref={btnRef}
                disabled={isSubmitting}
                variant="outline-primary"
              >
                {t("addDomainModal.btnText")}
              </Button>
            </div>
          </Form>
        </FormProvider>
      </Modal.Body>
    </Modal>
  );
};
export default AddDomainModal;
