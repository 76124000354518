import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { showError } from "../../services/toast-service";
import AdvancedFilters from "../table-filter/advanced-filter";
import CustomPagination from "../pagination/custom-pagination";
import { getAllFunnelsEvaluationInsights } from "../../services/api-declaration";
import {
  FEInsightLeadFilterConfig,
  FEInsightConversionFilterConfig
} from "../funnels-table/filter-config";
import { useTranslation } from "react-i18next";
import Loader from "../loader/loader";
import BarGraph from "./bar-graph";
import { CustomCard } from "../common-styling";
import { ButtonGroup, Card, ToggleButton } from "react-bootstrap";
import "./funnel-evaluation.css";

const RADIO_VALUE = {
  LEAD: "lead",
  CONVERSION: "conversion"
};

const RADIOS = [
  { name: "Lead", value: RADIO_VALUE.LEAD },
  { name: "Conversion Rate", value: RADIO_VALUE.CONVERSION }
];

const Analytics = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [radioValue, setRadioValue] = useState(
    searchParams.get("type") ? searchParams.get("type") : RADIO_VALUE.LEAD
  );
  const [paginateOpts, setPaginateOpt] = useState({});
  const [barGaphConfig, setBarGaphConfig] = useState();

  const generateBarGraphConfig = (funnelData) => {
    const labels = funnelData.map((d) => {
      const title = d.funnel.general.funnelName;
      return title.length < 10 ? title : title.slice(0, 9).concat("...");
    });
    const label =
      radioValue === RADIO_VALUE.LEAD ? t("createdLeads") : t("conversionRate");
    const unit = radioValue === RADIO_VALUE.LEAD ? "" : "%";
    const config = {
      type: "bar",
      data: {
        labels,
        datasets: [
          {
            label,
            data: funnelData.map((d) => d.leadCount),
            backgroundColor: "#20c997"
          }
        ]
      },
      options: {
        legend: {
          display: false
        },
        layout: { padding: 20 },
        scales: {
          xAxes: [
            {
              barPercentage: 0.2
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        },
        // plugins: {
        tooltips: {
          callbacks: {
            label: function (context) {
              let value = `${label}: ${context.value}${unit}` || "";
              return value;
            },
            title: function (context) {
              let index = context[0].index || 0;
              return funnelData[index].funnel.general.funnelName;
            }
          }
        }
        // },
      }
    };

    setBarGaphConfig(config);
  };

  const getFunnelsEvaluationInsights = async () => {
    try {
      setIsLoading(true);
      const params = {};
      for (const [key, value] of searchParams.entries()) {
        params[key] = value;
      }
      //   if (params.archived === undefined) {
      //     params.archived = false;
      //   }
      const response = await getAllFunnelsEvaluationInsights(params);
      const respData = response.data.data;
      if (!respData.data.length) {
        setPaginateOpt({});
        return;
      }
      generateBarGraphConfig(respData.data);
      setPaginateOpt({
        totalPages: Math.ceil(
          respData.metadata.total / respData.metadata.limit
        ),
        hasPrevPage: respData.metadata.hasPrevPage,
        hasNextPage: respData.metadata.hasNextPage,
        totalDocs: respData.metadata.total,
        rowsOnPage: respData.metadata.length
      });
    } catch (error) {
      setPaginateOpt({});
      console.log("ERROR: ", error);
      showError(
        error.response?.data.message ||
          t("toastMessage.errorInGetting", { what: "funnels" })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleRadioSelect = async (value) => {
    searchParams.delete("pageIndex");
    setRadioValue(value);
    handleQueryParams("type", value);
    // dateRangeModeRef.current = RADIO_RANGE[value];
    // if (RADIO_RANGE[value] !== DATE_RANGE_MODE.RANGE) {
    //   await fetchFunnelInsightsData({
    //     startDate,
    //     endDate,
    //   });
    // }
  };

  const handleQueryParams = (property, value) => {
    searchParams.set(property, value);
    setSearchParams(searchParams);
  };

  //  ---------- CallBacks

  const getFunnelsCb = useCallback(
    getFunnelsEvaluationInsights,
    // eslint-disable-next-line
    [searchParams]
  );

  //  ---------- UseEffects

  useEffect(() => {
    getFunnelsCb();
  }, [getFunnelsCb]);

  return (
    <>
      <div className="mt-3" />
      {
        <AdvancedFilters
          btnText={t("common.createNew.title", {
            name: "funnel"
          })}
          hideAddNew
          pageType="funnelType"
          heading=""
          pageName="funnelName"
          components={
            radioValue !== RADIO_VALUE.LEAD
              ? FEInsightConversionFilterConfig
              : FEInsightLeadFilterConfig
          }
        />
      }
      <div className="mt-3" />
      <CustomCard className="mb-3 p-3 rounded-0">
        <Card.Header className="bg-white d-flex justify-content-between">
          <h5 className="mb-0 fw-bold leads-header">
            {radioValue === RADIO_VALUE.LEAD
              ? t("createdLeads")
              : t("conversionRate")}
          </h5>
          <div className="evaluation-div">
            <ButtonGroup>
              {RADIOS.map((radio, idx) => (
                <ToggleButton
                  disabled={isLoading}
                  key={idx}
                  id={`radio-${idx}`}
                  type="radio"
                  className={`evaluation-toggle-button`}
                  name="radio"
                  value={radio.value}
                  checked={radioValue === radio.value}
                  onChange={(e) => handleRadioSelect(e.currentTarget.value)}
                >
                  {radio.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </div>
        </Card.Header>
        {isLoading ? (
          <Loader colSpan={8} />
        ) : !paginateOpts.totalPages ? (
          <h6 className="mb-0 fw-bold p-5 align-self-center">
            Not Enough Data to show
          </h6>
        ) : (
          <BarGraph config={barGaphConfig} />
        )}
      </CustomCard>
      {paginateOpts.totalPages && (
        <CustomPagination
          paginateOpts={paginateOpts}
          activeTab={Number(searchParams.get("pageIndex")) || 1}
          select={(val) => handleQueryParams("pageIndex", val)}
        />
      )}
    </>
  );
};
export default Analytics;
