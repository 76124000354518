import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { Col, Collapse, Form, Row } from "react-bootstrap";
import { useOutletContext, useParams } from "react-router";
import {
  FieldSet,
  HorizontalDivider,
  FadedBackgroundDiv
} from "../common-styling";
import TextField from "../reusable-form-fields/text-field";
import { showError, showSuccess } from "../../services/toast-service";
import { AppointmentFormSchema } from "../../services/validation-schema";
import ActionButtonsColumn from "./integration-view/action-buttons-column";
import { handleCustomerAppointmentLink } from "../../services/api-declaration";
import IntegrationsTableView from "./integration-view/integrations-table-view";
import { useTranslation } from "react-i18next";

const CustomerAppointment = () => {
  const { t } = useTranslation();
  const paramsId = useParams().id;
  const { funnel, setFunnel } = useOutletContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAllowedAddNew, setIsAllowedAddNew] = useState(false);
  const dummyIntegration = funnel?.customerAppointmentLink
    ? [{ appointmentLink: funnel.customerAppointmentLink }]
    : [];
  const initialValues = {
    appointmentLink: ""
  };
  const methods = useForm({
    resolver: yupResolver(AppointmentFormSchema),
    defaultValues: initialValues
  });
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = methods;

  const onSubmit = (formValues) => handleCustomerAppointment(formValues);
  const handleCustomerAppointment = async (formVals) => {
    try {
      const response = await handleCustomerAppointmentLink(paramsId, {
        ...formVals
      });
      setFunnel({
        ...funnel,
        customerAppointmentLink: formVals.appointmentLink
      });
      showSuccess(response?.data?.message);
      resetComponentState();
    } catch (err) {
      showError(err.response?.data?.message || t("funnelIntegration.error"));
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleEditClick = () => {
    setIsAllowedAddNew(true);
    reset({ appointmentLink: funnel?.customerAppointmentLink });
  };
  const resetComponentState = () => {
    reset(initialValues);
    setIsAllowedAddNew(false);
  };

  return (
    <FormProvider {...methods}>
      <Form
        className="p-0"
        onSubmit={(event) => {
          handleSubmit(onSubmit)(event);
        }}
      >
        <div className="px-3">
          <FieldSet>
            <Collapse in={isAllowedAddNew}>
              <div>
                <FadedBackgroundDiv className="p-2">
                  <Row className="mb-2">
                    <Col lg={10} md={8} sm={12}>
                      <TextField
                        type="text"
                        label={t("funnelIntegration.appointment.label")}
                        name="appointmentLink"
                        placeholder={t(
                          "funnelIntegration.appointment.placeholder"
                        )}
                        register={register}
                        errors={errors?.appointmentLink}
                        noSpacing
                      />
                    </Col>
                    <ActionButtonsColumn
                      mdColWidth={4}
                      fieldError={errors?.appointmentLink}
                      actions={{ isSubmitting, resetComponentState }}
                    />
                  </Row>
                </FadedBackgroundDiv>
                <HorizontalDivider />
              </div>
            </Collapse>
            <IntegrationsTableView
              headers={[
                {
                  name: t("funnelIntegration.appointment.label"),
                  colWidth: null,
                  style: "pb-1"
                },
                {
                  name: "Action",
                  colWidth: 2,
                  style: "pb-1 text-end"
                }
              ]}
              integrations={dummyIntegration}
              propertiesToRender={["appointmentLink"]}
              onEditClick={handleEditClick}
              isAllowedAddNew={isAllowedAddNew}
              setIsAllowedAddNew={setIsAllowedAddNew}
              handleCustomerAppointment={handleCustomerAppointment}
              forCustomerAppointment={Boolean(dummyIntegration.length)}
              resetComponentState={resetComponentState}
            />
          </FieldSet>
        </div>
      </Form>
    </FormProvider>
  );
};

export default CustomerAppointment;
