import React, { useEffect, useRef } from "react";
import { LeadDetailsChart } from "./lead-details-chart";

const SunburstChart = (props) => {
  const { data } = props;

  const chartRef = useRef();

  useEffect(() => {
    if (!chartRef.current || !data?.children.length) {
      return;
    }
    const chart = new LeadDetailsChart({
      chartRef: chartRef.current,
      data
    });
    chart.draw([
      "rgba(32, 201, 151, 1)",
      "rgba(255, 138, 37, 1)",
      "rgba(24, 120, 243, 1)"
    ]);
    return () => chart.destroy();
  }, [data]);

  return data?.children.length ? (
    <div ref={chartRef} className="lead-details-analytic">
      <div className="data">
        <div className="lead-text-data">
          <div className="source">
            <p className="heading m-0">{""}</p>
            <h4 className="name m-0">{""}</h4>
            <span className="count">{""}</span> <span>leads</span>
          </div>
          <div className="category">
            <p className="heading m-0">{""}</p>
            <h4 className="name m-0">{""}</h4>
            <span className="count">{""}</span> <span>leads</span>
            <p className="id m-0">{""}</p>
          </div>
          <div className="adset1">
            <p className="heading m-0">{""}</p>
            <h4 className="name m-0">{""}</h4>
            <span className="count">{""}</span> <span>leads</span>
            <p className="id m-0">{""}</p>
          </div>
          <div className="ad">
            <p className="heading m-0">{""}</p>
            <h4 className="name m-0">{""}</h4>
            <span className="count">{""}</span> <span>leads</span>
            <p className="id m-0">{""}</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <h6 className="mb-0 fw-bold p-5 align-self-center">
      Not Enough Data to show
    </h6>
  );
};

export default SunburstChart;
