import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

const PriceTypeRadio = ({ value, name, label, isDisabled = false }) => {
  const { register } = useFormContext();
  return (
    <Form.Check
      type="radio"
      label={label}
      value={value}
      className="ms-1"
      id={Math.random()}
      {...register(name)}
      disabled={isDisabled}
    />
  );
};
export default PriceTypeRadio;
