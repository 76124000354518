import { createSlice } from "@reduxjs/toolkit";
import { logout } from "./auth";

const initialState = {
  tags: []
};
export const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    setTags(state, action) {
      state.tags = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(logout, (state) => {
      state.tags = [];
    });
  }
});

export const { setTags } = tagsSlice.actions;
export default tagsSlice.reducer;
