// const reorder = (list, startIndex, endIndex) => {
//   const result = Array.from(list);
//   const [removed] = result.splice(startIndex, 1);
//   result.splice(endIndex, 0, removed);

//   return result;
// };

// export const onDragEnd = (result, list) => {
//   if (!result.destination) {
//     return;
//   }
//   const reorderedItems = reorder(
//     list,
//     result.source.index,
//     result.destination.index
//   );
//   return reorderedItems;
// };

export const getItemStyle = (isDragging, draggableStyle) => ({
  borderRadius: "4px",
  marginBottom: "10px",
  // change background colour if dragging
  // background: isDragging ? "var(--leads-badge-bg)" : "transparent",
  // styles we need to apply on draggables
  ...draggableStyle
});

export const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "var(--leads-badge-bg)" : "transparent"
});
