import React, { useRef, useEffect, useCallback } from "react";
import { Chart } from "chart.js";
import { Card } from "react-bootstrap";
import "./kpi-tile.css";

const FunnelVersionsAnalytics = ({ config }) => {
  const svgRef = useRef();
  const canvasRef = useRef();

  const createGraph = () => {
    const ctx = svgRef.current;
    if (canvasRef.current) {
      canvasRef.current.destroy();
    }
    canvasRef.current = new Chart(ctx, config);
  };

  const createGraphCB = useCallback(createGraph, [config]);

  useEffect(() => {
    return () => {
      if (canvasRef.current) {
        canvasRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (!config || !svgRef.current) return;

    createGraphCB();
  }, [config, createGraphCB]);

  return (
    <Card className={`kpi-tile`}>
      <div className="tile-wrapper">
        <div style={{ height: 440 }}>
          {config && <canvas style={{ marginLeft: -5 }} ref={svgRef}></canvas>}
        </div>
      </div>
    </Card>
  );
};

export default FunnelVersionsAnalytics;
