import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { AnswerRow } from "../../common-styling";

const SliderOptionFields = ({ questionIndex }) => {
  const {
    register,
    formState: { errors }
  } = useFormContext();
  const fieldName = `funnelQA.${[questionIndex]}`;

  return (
    <AnswerRow className="d-flex align-items-center mb-2 p-3">
      <Form.Group controlId="min" className="me-4 d-flex align-items-center">
        <Form.Label
          className={`mb-0 me-2 ${
            errors.funnelQA?.[questionIndex]?.range?.min && "text-danger"
          }`}
        >
          Min:{errors.funnelQA?.[questionIndex]?.range?.min && "*"}
        </Form.Label>
        <Form.Control
          size="sm"
          type="number"
          placeholder="Min Range - 0"
          onWheel={({ target }) => target.blur()}
          {...register(`${fieldName}.range.min`)}
          className={
            errors.funnelQA?.[questionIndex]?.range?.min && "border-danger"
          }
        />
      </Form.Group>
      <Form.Group controlId="max" className="me-4 d-flex align-items-center">
        <Form.Label
          className={`mb-0 me-2 ${
            errors.funnelQA?.[questionIndex]?.range?.max && "text-danger"
          }`}
        >
          Max:{errors.funnelQA?.[questionIndex]?.range?.max && "*"}
        </Form.Label>
        <Form.Control
          size="sm"
          type="number"
          placeholder="Max Range - 100"
          onWheel={({ target }) => target.blur()}
          {...register(`${fieldName}.range.max`)}
          className={
            errors.funnelQA?.[questionIndex]?.range?.max && "border-danger"
          }
        />
      </Form.Group>
      <Form.Group controlId="step" className="d-flex align-items-center">
        <Form.Label className="mb-0 me-2">Step:</Form.Label>
        <Form.Control
          size="sm"
          type="number"
          defaultValue={1}
          placeholder="Step - 1"
          onWheel={({ target }) => target.blur()}
          {...register(`${fieldName}.range.step`)}
        />
      </Form.Group>
    </AnswerRow>
  );
};
export default SliderOptionFields;
