import { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faImage } from "@fortawesome/free-solid-svg-icons";
import ImagePreviewModal from "../funnel-form/preview-image-modal";
import { Form, OverlayTrigger, Tooltip, Image, Button } from "react-bootstrap";
import { MODAL_TYPE } from "../strings";
import { showError } from "../../services/toast-service";
import ViewGalleryModal from "../gallery/view-gallery-modal";
import CreateGalleryModal from "../gallery/create-gallery-modal";
import ValidationMessage from "../validation-message/validation-message";
import { useTranslation } from "react-i18next";

const IconContainer = styled.div`
  flex: 1;
  margin-right: 15px;
  padding-left: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #c0c4c5;
`;
const FAIcon = styled(FontAwesomeIcon)`
  color: #616568;
  font-size: 40px;
`;
const FixedWidthImgDiv = styled.div`
  width: 68px;
  height: 54px;
`;
const Img = styled(Image)`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const FileInput = (props) => {
  const { t } = useTranslation();
  const [showPreview, setShowPreview] = useState(false);
  const tags = useSelector((state) => state.tags);
  const selectedTags = tags.tags.find((tag) => tag.name === props.tagName);
  const [showViewGalleryModal, setShowViewGalleryModal] = useState(false);
  const [showCreateGalleryModal, setShowCreateGalleryModal] = useState(false);
  const { setValue, watch, register } = useFormContext();
  const logoDisplay = watch("design.partnerLogoDisplay");
  const toggleModal = (modal) => {
    if (modal === MODAL_TYPE.VIEW) {
      setShowViewGalleryModal(!showViewGalleryModal);
      setShowCreateGalleryModal(false);
    } else if (modal === MODAL_TYPE.CREATE) {
      setShowCreateGalleryModal(!showCreateGalleryModal);
      setShowViewGalleryModal(false);
    } else {
      setShowViewGalleryModal(false);
      setShowCreateGalleryModal(false);
    }
  };

  const selectImage = ({ url, key, _id }) => {
    try {
      const fieldObj = { key, url, _id, asset: _id };
      setValue(props.name, fieldObj, { shouldDirty: true });
      toggleModal();
    } catch (error) {
      showError(
        error?.response?.data?.message || t("toastMessage.selectAssetError")
      );
    }
  };
  return (
    <div className="mb-4">
      <div className="d-flex">
        <div
          className={`mb-2 ${
            props.error?.message ? "text-danger fw-bold" : ""
          }`}
        >
          {props.label}
        </div>
        {props.setDisplay && (
          <div className="ms-2">
            <OverlayTrigger
              overlay={
                <Tooltip>{logoDisplay ? "Display On" : "Display Off"}</Tooltip>
              }
            >
              <Form.Check
                role="button"
                type="switch"
                {...register("design.partnerLogoDisplay")}
              />
            </OverlayTrigger>
          </div>
        )}
      </div>
      <div className="d-flex align-items-center">
        <IconContainer>
          {props.url ? (
            <FixedWidthImgDiv>
              <Img src={props.url} />
            </FixedWidthImgDiv>
          ) : (
            <FAIcon icon={faImage} />
          )}
        </IconContainer>
        {props?.urlForPreview && (
          <>
            <Button
              variant="outline-secondary me-2"
              onClick={() => setShowPreview(!showPreview)}
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
            {showPreview && (
              <ImagePreviewModal
                source={props.urlForPreview}
                toggleModal={() => setShowPreview(!showPreview)}
              />
            )}
          </>
        )}
        <Button
          onClick={() => toggleModal(MODAL_TYPE.VIEW)}
          variant="outline-secondary"
        >
          {t("searchImage")}
        </Button>

        {showCreateGalleryModal && (
          <CreateGalleryModal
            onFinish={selectImage}
            onClose={() => toggleModal(MODAL_TYPE.CREATE)}
          />
        )}
        {showViewGalleryModal && (
          <ViewGalleryModal
            tagsSelected={selectedTags ? [selectedTags] : []}
            selectedCategory={props.selectedCategory}
            selectImage={selectImage}
            handleClose={() => toggleModal(MODAL_TYPE.VIEW)}
            toggleAddImage={() => toggleModal(MODAL_TYPE.CREATE)}
          />
        )}
      </div>
      {props.error?.message && (
        <ValidationMessage error={props.error.message} />
      )}
    </div>
  );
};
export default FileInput;
