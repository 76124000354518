import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const AreaChart = ({ series = [], height = "280px" }) => {
  const { t } = useTranslation();
  const config = {
    series: [
      {
        name: t("customerStats.leads"),
        data: series
      }
    ],
    options: {
      chart: {
        type: "area",
        height: 350,
        zoom: {
          autoScaleYaxis: true
        }
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0,
        style: "hollow"
      },
      xaxis: {
        type: "datetime",
        showForNullSeries: false,
        axisTicks: {
          show: false
        },
        labels: {
          format: "d. MMM"
        }
      },
      yaxis: {
        type: "numeric",
        showForNullSeries: true,
        tickAmount: 4,
        min: 0,
        labels: {
          formatter: (value) => (value % 1 === 0 ? value : "")
        }
      },
      grid: {
        strokeDashArray: 5,
        padding: {
          left: 30,
          right: 30
        },
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      tooltip: {
        theme: "dark",
        x: {
          show: false
        },
        followCursor: true,
        style: {
          backgroundColor: "#000",
          fontWeight: 900,
          fontSize: 30
        },
        marker: {
          show: false
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100]
        }
      }
    },
    selection: "one_month"
  };

  return (
    <Chart
      options={config.options}
      series={config.series}
      type="area"
      height={height}
      width={"100%"}
    />
  );
};

export default AreaChart;
