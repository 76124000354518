import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { CustomCard } from "../common-styling";
import EmojiTextarea from "../reusable-form-fields/text-area-wtih-emoji";
import { useTranslation } from "react-i18next";

const AdCopyResults = ({ results = [] }) => {
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: {
      result1: "",
      result2: "",
      result3: ""
    }
  });

  const { setValue } = methods;

  useEffect(() => {
    results.forEach((result, index) => {
      setValue(`result${index}`, result);
    });
  }, [results, setValue]);

  return (
    <CustomCard className="my-3">
      <FormProvider {...methods}>
        <Container className="p-4">
          {results.length ? (
            results.map((data, index) => (
              <Row className="mb-4" key={index}>
                <Col xs={12} md={6}>
                  <EmojiTextarea
                    showCopyButton
                    label={`${t("adCopy.title")} ${t("common.result")} ${
                      index + 1
                    }`}
                    name={`result${index}`}
                  />
                </Col>
              </Row>
            ))
          ) : (
            <h2>{t("adCopy.noAdMessage")}</h2>
          )}
        </Container>
      </FormProvider>
    </CustomCard>
  );
};

export default AdCopyResults;
