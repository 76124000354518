import styled from "styled-components";
import Select, { components } from "react-select";
import { useEffect, useRef, useState } from "react";
import {
  generateDateTime,
  handleSelectFieldValue
} from "../../services/common-service";

const VersionDropdownDiv = styled.div`
  margin-left: 4px;
  max-width: 100px;
  min-width: 100px;
`;

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div>{props.data.label}</div>
      <small className={props.isSelected ? "" : "text-muted"}>
        {props.data.subLabel}
      </small>
    </components.Option>
  );
};

const VersionDropdown = ({
  selectedVersion,
  handleVersionChange,
  funnelVersions
}) => {
  const [options, setOptions] = useState([]);
  const selectRef = useRef();
  const focusSelectedOption = () => {
    options.forEach((item) => {
      if (
        selectRef.current &&
        item.value === selectRef.current.state.selectValue[0]?.value
      ) {
        selectRef.current.state.focusedOption = item;
      }
    });
  };
  const selectedV = {
    label: selectedVersion?.funnelVersion.title,
    value: selectedVersion?._id
  };
  useEffect(() => {
    if (funnelVersions?.allVersions.length) {
      const data = funnelVersions.allVersions.map((mp) => ({
        label: mp.funnelVersion.title,
        subLabel: generateDateTime(mp.createdAt),
        value: mp._id
      }));
      setOptions(data);
    }
  }, [funnelVersions]);
  return (
    <VersionDropdownDiv>
      <Select
        className="w-100 text-capitalize"
        ref={selectRef}
        options={options}
        components={{ Option }}
        value={handleSelectFieldValue(selectedV?.value, options)}
        onChange={(option) => {
          const data = funnelVersions.allVersions.find(
            (d) => d._id === option.value
          );
          if (selectedV?.value !== option.value) {
            handleVersionChange(data);
          }
        }}
        onMenuOpen={focusSelectedOption}
      />
    </VersionDropdownDiv>
  );
};

export default VersionDropdown;
