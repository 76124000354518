import { useMemo, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { ModalHead, ValiditySpinner } from "../common-styling";
import ValidationMessage from "../validation-message/validation-message";
import _debounce from "lodash/debounce";
import { showError } from "../../services/toast-service";
import { Modal, Button } from "react-bootstrap";
import { checkVersionAvailableById } from "../../services/api-declaration";
import { useTranslation } from "react-i18next";

const VersionDialog = ({
  resourceId,
  versionTitleRef,
  show,
  toggleDialog,
  dialogResponseAction,
  register
}) => {
  const { t } = useTranslation();
  const versionErrorRef = useRef();
  const [requireVersion, setRequireVersion] = useState(true);
  const [checkingVersion, setCheckingVersion] = useState(false);
  const [versionTitle, setVersionTitle] = useState(versionTitleRef?.current);

  const handleVersionDebounce = useMemo(
    () =>
      _debounce(async (versionTitle) => {
        setRequireVersion(true);
        if (!versionTitle) {
          versionErrorRef.current = "Title is required!";
          setCheckingVersion(false);
          return;
        }
        try {
          const res = await checkVersionAvailableById(resourceId, {
            versionTitle
          });
          if (!res.data.data.available) {
            setRequireVersion(false);
          } else {
            versionErrorRef.current = res.data.data.message;
          }
        } catch (error) {
          showError(
            error.response?.data?.message || t("toastMessage.availableVersion")
          );
        } finally {
          setCheckingVersion(false);
        }
      }, 2000),
    // eslint-disable-next-line
    [resourceId]
  );

  const handleClick = (action) => {
    dialogResponseAction(action);
    toggleDialog();
  };

  return (
    <Modal show={show} onHide={toggleDialog} backdrop="static">
      <ModalHead className={`fw-bold`} bg={"warning"}>
        <Modal.Title>{t("common.update")} Version</Modal.Title>
      </ModalHead>
      <Modal.Body>
        <div>
          <h4>{t("versionDialogHeading")}</h4>
          <Form.Group className="mt-2">
            <Form.Label>Version {t("title")}</Form.Label>
            <div className="d-flex">
              <Form.Control
                className={requireVersion && "border-danger"}
                type="text"
                placeholder={`Version ${t("title")}`}
                value={versionTitle}
                onChange={(e) => {
                  setVersionTitle(e.target.value);
                  versionTitleRef.current = e.target.value;
                  handleVersionDebounce(e.target.value);
                  setCheckingVersion(true);
                }}
              />
              {checkingVersion && (
                <div className="align-self-center">
                  <ValiditySpinner
                    animation="border"
                    variant="secondary"
                    className="ms-2 rounded-circle"
                  />
                </div>
              )}
            </div>
            {requireVersion && (
              <ValidationMessage error={versionErrorRef.current} />
            )}
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label>Version {t("common.description")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={`Version ${t("common.description")}`}
              {...register("versionDescription")}
            />
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer className={`border-warning`}>
        <Button variant="outline-secondary" onClick={() => handleClick(false)}>
          {t("saveWithoutNewVersion")}
        </Button>
        <Button
          variant={"warning"}
          onClick={() => handleClick(true)}
          disabled={requireVersion}
        >
          {t("common.create")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VersionDialog;
