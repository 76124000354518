import { Image, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  faPlus,
  faFile,
  faTrashCan,
  faCameraAlt,
  faArrowsUpDown
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  AnswerRow,
  FixedWidthDiv,
  BorderlessInput
} from "../../common-styling";
import CustomAnswer from "./custom-answer";
import ActionPopover from "./actions-popover";
import SliderOptionFields from "./slider-option-fields";
import AnswerActionsDropdown from "./answer-action-dropdown";
import { showSuccess } from "../../../services/toast-service";
import ViewGalleryModal from "../../gallery/view-gallery-modal";
import { getListStyle, getItemStyle } from "./drag-drop-helper";
import CreateGalleryModal from "../../gallery/create-gallery-modal";
import {
  FUNNEL_QA_ACTIONS,
  MODAL_TYPE,
  QUESTION_TYPE,
  THUMBNAIL_TYPE
} from "../../strings";
import { useTranslation } from "react-i18next";

const IconColumn = styled.div`
  color: var(--secondary-text);
`;
const CameraIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
`;
const CameraButton = styled(Button)`
  color: var(--secondary-text);
`;
const AddNewContainer = styled.div`
  color: var(--icon-gray);
  cursor: pointer;
  font-size: 20px;
  padding: 10px 0;
  background: #fff;
  text-align: center;
  border-radius: 4px;
  border: 1px dashed var(--light-border);
`;
const AnswerIcon = styled.div`
  height: 54px;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: 0.5s all ease;
    &:hover {
      transform: scale(1.6);
    }
  }
`;

const Answer = ({
  item,
  index,
  onAction,
  questionIndex,
  update,
  selectedCategory,
  errors,
  isMulti
}) => {
  const { getValues, register, control } = useFormContext();
  const { t } = useTranslation();
  const tags = useSelector((state) => state.tags.tags);
  const selectedTags = tags.find((tag) => tag.name === "answer");
  const questionType = useWatch({
    name: `funnelQA.${questionIndex}.type`,
    control
  });
  const options = [
    {
      text: `${t("duplicateAnswer")}`,
      textColor: "",
      action: FUNNEL_QA_ACTIONS.DUPLICATE,
      icon: faFile,
      onClick: (action) => {
        onAction(index, action);
      }
    },
    {
      text: `${t("deleteAnswer")}`,
      textColor: "--danger-color",
      action: FUNNEL_QA_ACTIONS.DELETE,
      icon: faTrashCan,
      onClick: (action) => {
        onAction(index, action);
      }
    }
  ];
  const fieldName = `funnelQA[${[questionIndex]}].answers[${index}]`;

  const selectImage = ({ url, key, _id }) => {
    const question = getValues("funnelQA")[questionIndex];
    const answers = question.answers;
    answers[index].asset = {
      url,
      key,
      _id
    };
    update(questionIndex, { ...question, answers });
    showSuccess("Bild erfolgreich angewendet!");
  };
  const [showCreateGalleryModal, setShowCreateGalleryModal] = useState(false);
  const [showViewGalleryModal, setShowViewGalleryModal] = useState(false);
  const toggleModal = (modal) => {
    if (modal === MODAL_TYPE.VIEW) {
      setShowViewGalleryModal(!showViewGalleryModal);
      setShowCreateGalleryModal(false);
    } else {
      setShowCreateGalleryModal(!showCreateGalleryModal);
      setShowViewGalleryModal(false);
    }
  };
  return (
    <>
      <AnswerRow>
        <div className="d-flex align-items-center">
          <FixedWidthDiv className="text-center" width="40">
            <IconColumn>
              <FontAwesomeIcon icon={faArrowsUpDown} />
            </IconColumn>
          </FixedWidthDiv>
          <FixedWidthDiv
            width="40"
            className="text-center d-flex align-self-stretch border-start"
          >
            <div className="flex-fill align-self-center">{`A${index + 1}`}</div>
          </FixedWidthDiv>
          <div className="flex-fill p-0 align-self-stretch">
            <BorderlessInput
              className={`border-start border-end ${
                errors?.length &&
                errors[questionIndex]?.answers?.length &&
                errors[questionIndex]?.answers[index]?.text &&
                "border-danger"
              }`}
              type="text"
              name={`${fieldName}.text`}
              placeholder="Add answer here"
              {...register(`${fieldName}.text`)}
            />
          </div>
          <FixedWidthDiv className="text-center" width="200">
            <AnswerActionsDropdown
              isMulti={isMulti}
              field={fieldName}
              questionIndex={questionIndex}
            />
          </FixedWidthDiv>
          <FixedWidthDiv
            width="70"
            className="text-center d-flex align-self-stretch border-start border-end"
          >
            <div className="flex-fill py-1">
              {(item.asset?.url || item.asset?.importUrl) &&
              questionType !== QUESTION_TYPE.TEXTONLY ? (
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>{t("changeIcon")}</Tooltip>}
                >
                  <AnswerIcon>
                    <Image
                      onClick={() => toggleModal(MODAL_TYPE.VIEW)}
                      src={
                        item.asset
                          ? item.asset.importUrl
                            ? item.asset.importUrl
                            : `${process.env.REACT_APP_ASSET_CDN_URL}/${item.asset.key}/${THUMBNAIL_TYPE.ANSWER_OPTION_ICON}`
                          : ""
                      }
                    />
                  </AnswerIcon>
                </OverlayTrigger>
              ) : (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    questionType !== QUESTION_TYPE.TEXTONLY ? (
                      <Tooltip id="tooltip-bottom">{t("addIcon")}</Tooltip>
                    ) : (
                      <></>
                    )
                  }
                >
                  <div>
                    {questionType === QUESTION_TYPE.TEXTONLY ? (
                      <CameraButton
                        className={
                          errors?.length &&
                          errors[questionIndex]?.answers?.length &&
                          errors[questionIndex]?.answers[index]?.asset
                            ? "border-danger"
                            : "border-0 outline-0"
                        }
                        disabled
                        variant="light"
                      >
                        <CameraIcon
                          className={
                            errors?.length &&
                            errors[questionIndex]?.answers?.length &&
                            errors[questionIndex]?.answers[index]?.asset &&
                            "text-danger"
                          }
                          disabled
                          icon={faCameraAlt}
                        />
                      </CameraButton>
                    ) : (
                      <CameraButton
                        onClick={() => toggleModal(MODAL_TYPE.VIEW)}
                        as="div"
                        className={
                          errors?.length &&
                          errors[questionIndex]?.answers[index]?.asset &&
                          "border-danger"
                        }
                        variant="light"
                      >
                        <CameraIcon
                          className={
                            errors?.length &&
                            errors[questionIndex]?.answers[index]?.asset &&
                            "text-danger"
                          }
                          icon={faCameraAlt}
                        />
                      </CameraButton>
                    )}
                  </div>
                </OverlayTrigger>
              )}
            </div>
          </FixedWidthDiv>
          <FixedWidthDiv className="text-center" width="40">
            <ActionPopover options={options} />
          </FixedWidthDiv>
        </div>
      </AnswerRow>
      {showCreateGalleryModal && (
        <CreateGalleryModal
          onFinish={selectImage}
          onClose={() => toggleModal(MODAL_TYPE.CREATE)}
        />
      )}
      {showViewGalleryModal && (
        <ViewGalleryModal
          tagsSelected={selectedTags ? [selectedTags] : []}
          selectedCategory={selectedCategory}
          selectImage={selectImage}
          handleClose={() => toggleModal(MODAL_TYPE.VIEW)}
          toggleAddImage={() => toggleModal(MODAL_TYPE.CREATE)}
        />
      )}
    </>
  );
};

const AnswerList = ({
  questionIndex,
  update,
  resourceId,
  parentFolderName,
  errors,
  selectedCategory
}) => {
  const { control, getValues, clearErrors } = useFormContext();
  const { fields, append, move, remove } = useFieldArray({
    control,
    name: `funnelQA[${questionIndex}].answers` // unique name for your Field Array
  });
  const isMulti = useWatch({
    name: `funnelQA.${questionIndex}.isMultiSelectable`,
    control
  });
  const typeWatcher = useWatch({
    name: `funnelQA.${questionIndex}.type`,
    control
  });
  const onAction = async (index, action) => {
    if (action === FUNNEL_QA_ACTIONS.DUPLICATE) {
      const answers = getValues("funnelQA")[questionIndex].answers;
      const clone = JSON.parse(JSON.stringify(answers[index]));
      append({
        ...clone,
        id: answers.length + 1,
        position: answers.length
      });
    } else if (action === FUNNEL_QA_ACTIONS.DELETE) {
      remove(index);
    }
  };
  const addNewAnswer = () => {
    append({
      id: `${fields.length + 1}`,
      text: "",
      position: fields.length,
      answerAction: isMulti ? "multi" : "",
      asset: null
    });
    clearErrors("funnelQA");
  };
  const handleOnDragEnd = (result) => {
    if (result.source.index !== result.destination.index) {
      move(result.source.index, result.destination.index);
    }
  };

  return typeWatcher === QUESTION_TYPE.FREE_TEXT_FIELD ? (
    <div className="d-flex align-items-center justify-content-between">
      <CustomAnswer />
      <div>
        <AnswerActionsDropdown
          isMulti={isMulti}
          field={`funnelQA[${[questionIndex]}].answers[0]`} // because it will always have 1 answer
          questionIndex={questionIndex}
        />
      </div>
    </div>
  ) : typeWatcher === QUESTION_TYPE.SLIDER ? (
    <SliderOptionFields questionIndex={questionIndex} />
  ) : (
    // for text-only and icons
    <DragDropContext onDragEnd={(res) => handleOnDragEnd(res)}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {fields.map((item, index) => (
              <Draggable
                key={String(index)}
                draggableId={String(index)}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <Answer
                      item={item}
                      index={index}
                      onAction={onAction}
                      questionIndex={questionIndex}
                      update={update}
                      resourceId={resourceId}
                      parentFolderName={parentFolderName}
                      errors={errors}
                      isMulti={isMulti}
                      selectedCategory={selectedCategory}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            <AddNewContainer onClick={addNewAnswer}>
              <FontAwesomeIcon icon={faPlus} />
            </AddNewContainer>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
export default AnswerList;
