import {
  Row,
  Col,
  Card,
  Button,
  Accordion,
  InputGroup,
  useAccordionButton
} from "react-bootstrap";
import styled from "styled-components";
import {
  faTrashCan,
  faChevronUp,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons";
import React, { memo, useRef, useState } from "react";
import CardHeader from "react-bootstrap/esm/CardHeader";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Legend,
  FieldSet,
  FixedWidthDiv,
  BootstrapCard,
  BorderlessInput
} from "../../common-styling";
import { useDialog } from "../../../hooks/useDialog";
import TextArea from "../../reusable-form-fields/text-area";
import { showSuccess } from "../../../services/toast-service";
import TextField from "../../reusable-form-fields/text-field";
import RadioInput from "../../reusable-form-fields/input-radio";
import { useTranslation } from "react-i18next";

const AccordionItemContainer = styled(AccordionItem)`
  border: none;
  background-color: transparent;
`;
const Header = styled(CardHeader)`
  background-color: #fff;
  border-radius: 0 !important;
`;
const AccordionToggleHandler = styled.div`
  cursor: pointer;
`;
const Icon = styled(FontAwesomeIcon)`
  color: ${({ icon_name }) =>
    icon_name === "delete" ? "var(--danger-color)" : "var(--secondary-text)"};
  cursor: pointer;
`;
const GroupLabel = styled.div`
  background: #f5f8f9;
`;
const OutcomeBody = styled(AccordionBody)`
  background: var(--body-light-bg);
`;

const FunnelOutcomes = () => {
  const { t } = useTranslation();
  const { getValues, control, register, setValue } = useFormContext();
  const { showConfirmationDialogBox } = useDialog();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "outcomes" // unique name for your Field Array
  });
  const outcomeRef = useRef({});
  const [activeKey, setActiveKey] = useState(null);
  const toggleDialog = () =>
    showConfirmationDialogBox({
      title: t("common.confirmDeletion"),
      dialogType: "danger",
      dialogMessage: renderDialogMessage(),
      responseAction: dialogResponseAction
    });
  const updateAnswerAction = () => {
    getValues("funnelQA").forEach((question, questionIdx) => {
      question.answers.forEach((answer, ansIdx) => {
        if (answer.answerAction === outcomeRef.current.id) {
          setValue(
            `funnelQA[${questionIdx}].answers[${ansIdx}].answerAction`,
            ""
          );
        }
      });
    });
  };
  const dialogResponseAction = (action) => {
    if (action) {
      remove(outcomeRef.current.index);
      updateAnswerAction();
      showSuccess(`Outcome is deleted.`);
    }
  };
  const renderDialogMessage = () => (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: t("modalContent.deleteOutcome")
        }}
      ></div>
    </>
  );
  const ToggleAccordion = ({ eventKey, content }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      if (eventKey === activeKey) {
        setActiveKey(-1);
      } else {
        setActiveKey(eventKey);
      }
    });
    return (
      <AccordionToggleHandler onClick={decoratedOnClick}>
        {content}
      </AccordionToggleHandler>
    );
  };
  const addOutcome = () => {
    append({
      id: Math.random().toString(16).slice(2),
      outcomeLogic: "directly",
      outcomeName: "",
      outcomeHeadline: "",
      outcomeText: ""
    });
  };
  const deleteOutcome = (index) => {
    const id = getValues("outcomes")[index].id;
    outcomeRef.current = { index, id };
    let shouldDelete = true;
    getValues("funnelQA").forEach((qa) => {
      const found = qa.answers.find((answer) => answer.answerAction === id);
      if (found) {
        shouldDelete = false;
      }
    });
    if (shouldDelete) {
      remove(index);
    } else {
      toggleDialog();
    }
  };

  return (
    <div className="py-3 px-0">
      <FieldSet>
        <Legend>
          <h6 className="mb-0 fw-bold d-flex align-center">Outcomes</h6>
        </Legend>
        <div className="d-flex justify-content-end">
          <Button variant="primary" onClick={addOutcome}>
            {t("addNewOutcome")}
          </Button>
        </div>
        <Accordion flush>
          {fields.map((outcome, index) => (
            <AccordionItemContainer
              key={outcome.id}
              eventKey={index}
              className="p-0 m-0"
            >
              <BootstrapCard className="mt-3" $showShadow={true}>
                <Header className="p-0">
                  <div className="d-flex align-items-center">
                    <FixedWidthDiv className="text-center" width="40">
                      <ToggleAccordion
                        eventKey={index}
                        content={
                          activeKey === index ? (
                            <Icon icon={faChevronUp} />
                          ) : (
                            <Icon icon={faChevronDown} />
                          )
                        }
                      />
                    </FixedWidthDiv>
                    <div className="flex-fill p-0 border-start align-self-stretch">
                      <InputGroup className="">
                        <GroupLabel className="d-flex align-items-center text-secondary px-2 fw-bold">
                          Outcome {t("common.name")}:
                        </GroupLabel>
                        <BorderlessInput
                          type="text"
                          className="border-start border-end"
                          placeholder={`${t("common.enter")} Outcome-Name`}
                          name={`outcomes.${index}.outcomeName`}
                          {...register(`outcomes.${index}.outcomeName`)}
                        />
                      </InputGroup>
                    </div>
                    <FixedWidthDiv className="text-center" width="40">
                      <Icon
                        onClick={() => deleteOutcome(index)}
                        icon={faTrashCan}
                        icon_name="delete"
                      />
                    </FixedWidthDiv>
                  </div>
                </Header>
                <OutcomeBody>
                  <Card.Body className="px-0">
                    <Row>
                      <Col xs={12} md={6}>
                        <RadioInput
                          isInline={false}
                          label={`Outcome-${t("common.logic")}:`}
                          firstOptLabel={t("outcome.jumpDirect")}
                          secondOptLabel={t("outcome.jumpEnd")}
                          thirdOptLabel={t("outcome.restart")}
                          firstValue="directly"
                          firstValueId={`directly-${index}`}
                          secondValue="end"
                          secondValueId={`end-${index}`}
                          thirdValue="restart"
                          thirdValueId={`restart-${index}`}
                          name={`outcomes[${index}].outcomeLogic`}
                          register={register}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <TextField
                          type="text"
                          placeholder={`Outcome-${t("common.headline")}`}
                          label={`Outcome-${t("common.headline")}:`}
                          name={`outcomes[${index}].outcomeHeadline`}
                          register={register}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <TextArea
                          name={`outcomes[${index}].outcomeText`}
                          label="Outcome Text:"
                          register={register}
                          noSpacing
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </OutcomeBody>
              </BootstrapCard>
            </AccordionItemContainer>
          ))}
        </Accordion>
      </FieldSet>
    </div>
  );
};
export default memo(FunnelOutcomes);
