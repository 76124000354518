import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IconButton = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  place-content: center;
`;
const RoundedIconContainer = ({ icon, borderColor, iconColor }) => (
  <IconButton
    className={`d-flex align-items-center border rounded-circle border-${borderColor} text-${iconColor}`}
  >
    <FontAwesomeIcon icon={icon} />
  </IconButton>
);
export default RoundedIconContainer;
