import { AnswerRow } from "../../common-styling";
import { Trans } from "react-i18next";

const CustomAnswer = () => {
  return (
    <AnswerRow className="p-2 flex-fill">
      <Trans i18nKey="customAnswerMsg" />
    </AnswerRow>
  );
};
export default CustomAnswer;
