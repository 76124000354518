import { createSlice } from "@reduxjs/toolkit";
import { INTEGRATION_NAMES } from "../../components/strings";

const initialState = {
  count: 0,
  activeIntegrations: {
    [INTEGRATION_NAMES.ZAPIER]: false,
    [INTEGRATION_NAMES.SLACK]: false,
    [INTEGRATION_NAMES.LEAD_SERVICE]: false,
    [INTEGRATION_NAMES.EMAIL]: false,
    [INTEGRATION_NAMES.CONTACT_CLOUD]: false,
    [INTEGRATION_NAMES.APPOINTMENT_LINK]: false,
    [INTEGRATION_NAMES.ADVERTISING_ACCOUNT_LINK]: false
  }
};
const integrationsInfoSlice = createSlice({
  name: "count",
  initialState,
  reducers: {
    setActiveIntegrationsCount: (state, action) => {
      state.count = action.payload;
    },
    setActiveIntegration: (state, action) => {
      state.activeIntegrations[action.payload.collectionName] =
        action.payload.isActive;
    }
  }
});
export const { setActiveIntegrationsCount, setActiveIntegration } =
  integrationsInfoSlice.actions;
export default integrationsInfoSlice.reducer;
