import OTPInputs from "./otp-inputs";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { generateOTPViaService } from "../../services/api-declaration";
import { showError, showSuccess } from "../../services/toast-service";
import SpinnerButton from "../spinner-button/spinner-button";
import { MULTI_FACTOR_TYPES } from "../strings";
import { useTranslation } from "react-i18next";

const OTPMethod = ({
  email,
  onSubmit,
  inputsRef,
  loadingData,
  setLoadingData,
  authenticators = [],
  defaultAuthType = MULTI_FACTOR_TYPES.AUTHENTICATOR
}) => {
  const { t } = useTranslation();
  const INITITIAL_TIME = 60;
  const INITITIAL_LOADING_DATA = {
    isLoading: false,
    loadingText: ""
  };
  const [authType, setAuthType] = useState(defaultAuthType);
  const [allowSend, setAllowSend] = useState(
    defaultAuthType === MULTI_FACTOR_TYPES.EMAIL
  );
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const timer = useRef();
  const counterRef = useRef();

  const sendCodeHandler = async () => {
    try {
      setLoadingData({ isLoading: true, loadingText: "" });
      const { data } = await generateOTPViaService({
        email
      });
      setAllowSend(false);
      let timeLeft = INITITIAL_TIME - 1;
      const countdown = () => {
        if (timeLeft === 0) {
          setIsTimerRunning(false);
          setAllowSend(true);
          clearInterval(timer.current);
          if (counterRef.current) {
            counterRef.current.querySelector("strong").innerHTML =
              INITITIAL_TIME;
          }
        } else {
          if (counterRef.current) {
            counterRef.current.querySelector("strong").innerHTML = timeLeft;
          }
          timeLeft--;
        }
      };
      setIsTimerRunning(true);
      timer.current = setInterval(countdown, 1000);
      showSuccess(data.message);
    } catch (error) {
      showError(error.response?.data?.message || "Error in getting code");
      console.log(error);
    } finally {
      setLoadingData(INITITIAL_LOADING_DATA);
    }
  };

  const submitOTP = async (otp) => {
    onSubmit({ otp, type: authType });
  };

  useEffect(() => {
    return () => {
      clearInterval(timer.current);
    };
  }, []);
  return (
    <Form>
      <Form.Group
        className="mb-2 d-flex align-items-center justify-content-between"
        controlId="Authenticator Method"
      >
        <Form.Label className="m-0">{t("multifactor.method")}</Form.Label>
        <Form.Select
          className="w-50"
          onChange={({ target }) => {
            setAuthType(target.value);
            if (!isTimerRunning) {
              setAllowSend(
                target.value === MULTI_FACTOR_TYPES.EMAIL ? true : false
              );
            }
          }}
          value={authType}
        >
          {authenticators.length ? (
            authenticators.map(({ type, text }, index) => (
              <option key={index} value={type}>
                {text}
              </option>
            ))
          ) : (
            <>
              <option value={MULTI_FACTOR_TYPES.AUTHENTICATOR}>
                Authenticator App
              </option>
              <option value={MULTI_FACTOR_TYPES.EMAIL}>Email</option>
            </>
          )}
        </Form.Select>
      </Form.Group>
      {(!allowSend || authType !== MULTI_FACTOR_TYPES.EMAIL) && (
        <OTPInputs inputsRef={inputsRef} submitOTP={submitOTP} />
      )}
      <p
        className={
          isTimerRunning && authType === MULTI_FACTOR_TYPES.EMAIL
            ? "d-block"
            : "d-none"
        }
        ref={counterRef}
        dangerouslySetInnerHTML={{
          __html: t("multifactor.verificationMsg", { timer: INITITIAL_TIME })
        }}
      ></p>
      {allowSend && authType === MULTI_FACTOR_TYPES.EMAIL && (
        <>
          <p>
            {t("multifactor.sendCodeMsg")} <strong> {email} </strong>
          </p>
          {loadingData.isLoading ? (
            <SpinnerButton
              btnText={t("multifactor.sendCode")}
              btnStyle="btn-outline-dark"
            />
          ) : (
            <Button
              disabled={loadingData.isLoading}
              onClick={sendCodeHandler}
              variant="outline-dark"
            >
              {t("multifactor.sendCode")}
              <FontAwesomeIcon icon={faRightToBracket} className="ms-2" />
            </Button>
          )}
        </>
      )}
    </Form>
  );
};
export default OTPMethod;
