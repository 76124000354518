import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { useCallback, useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Form, FormLabel, Modal, ProgressBar } from "react-bootstrap";
import TextArea from "../../reusable-form-fields/text-area";
import TextField from "../../reusable-form-fields/text-field";
import { uploadSuggestion } from "../../../services/api-declaration";
import { axioxFileUpload } from "../../../services/axios-rest-methods";
import { showError, showSuccess } from "../../../services/toast-service";
import { SuggestionGallerySchema } from "../../../services/validation-schema";
import { useTranslation } from "react-i18next";

const DropArea = styled.div`
  border: 1px dashed #80808099;
  border-color: ${({ fielerror }) => `${fielerror ? "red" : "#80808099"}`};
  cursor: pointer;
  padding: 10px;
  border-radius: 6px;
  color: #808080bd;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  width: 100%;
  background: ${({ drag }) => `${drag && "#8080804f"}`};
  & img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

const UploadModal = ({ toggleUpload, fetchSuggestions }) => {
  const { t } = useTranslation();
  const storedFile = useRef();
  const progressRef = useRef();
  const [fileError, setFileError] = useState(false);
  const [displayImage, setDisplayImage] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const initialValues = { link: "", note: "" };
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(SuggestionGallerySchema)
  });
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = methods;

  const handleUploadFile = async (formValues) => {
    if (isUploading) return;
    const file = storedFile.current;
    if (!file) {
      setFileError(true);
      return;
    }
    try {
      setIsUploading(true);
      const uploadResponse = await uploadSuggestion({
        contentType: file.type,
        fileSize: file.size,
        fileName: file.name,
        ...formValues
      });
      const uploadData = uploadResponse.data.data;
      const bodyData = {
        "Content-Type": uploadData.fields["Content-Type"],
        key: uploadData.fields["key"],
        bucket: uploadData.fields["bucket"],
        "X-Amz-Algorithm": uploadData.fields["X-Amz-Algorithm"],
        "X-Amz-Credential": uploadData.fields["X-Amz-Credential"],
        "X-Amz-Date": uploadData.fields["X-Amz-Date"],
        Policy: uploadData.fields["Policy"],
        "X-Amz-Signature": uploadData.fields["X-Amz-Signature"],
        file: file
      };
      await axioxFileUpload(uploadData.url, bodyData, function (progress) {
        if (progressRef.current) {
          progressRef.current.children[0].style.width = `${progress}%`;
        }
      });
      showSuccess(t("suggestionGallery.uploadedSuccessfully"));
      toggleUpload();
      await fetchSuggestions();
    } catch (error) {
      console.log("Error in Uploading: ", error);
      showError(
        error.response?.data?.message || t("toastMessage.uploadItemError")
      );
    } finally {
      setIsUploading(false);
    }
  };
  const onDrop = useCallback((files) => {
    if (files.length) {
      storedFile.current = files[0];
      setFileError(false);
      setDisplayImage(URL.createObjectURL(files[0]));
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".gif", ".jpeg", ".jpg"]
    },
    multiple: false
  });

  return (
    <Modal show={true} centered size="lg">
      <div className="p-3">
        <h5 className="mb-3">{t("suggestionGallery.uploadDialogHeading")}</h5>
        <FormProvider {...methods}>
          <Form>
            <TextField
              type="text"
              name="link"
              register={register}
              errors={errors?.link}
              label={`${t("common.enter")} ${t("link")}:`}
            />
            <TextArea
              rows="3"
              name="note"
              label={`${t("common.enter")} ${t(
                "suggestionGallery.noteLabel"
              )}:`}
              register={register}
            />
            <div>
              <FormLabel>{t("suggestionGallery.uploadImage")}:</FormLabel>
              <DropArea
                fielerror={fileError}
                drag={isDragActive}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {displayImage ? (
                  <img src={displayImage} alt="" />
                ) : isDragActive ? (
                  <h5>{t("suggestionGallery.dropHere")}!</h5>
                ) : (
                  <h5>{t("dropImageMsg")}</h5>
                )}
              </DropArea>
            </div>
            <ProgressBar
              className={`mt-2 ${!isUploading ? "d-none" : "d-flex"}`}
              ref={progressRef}
              striped
              animated
            />
            <div className="text-end mt-3">
              <Button
                className="me-2"
                variant="outline-secondary"
                onClick={toggleUpload}
                disabled={isUploading}
              >
                {t("common.close")}
              </Button>
              <Button
                variant="success"
                disabled={isUploading}
                onClick={handleSubmit(handleUploadFile)}
              >
                {t("common.save")}
              </Button>
            </div>
          </Form>
        </FormProvider>
      </div>
    </Modal>
  );
};
export default UploadModal;
