import _debounce from "lodash/debounce";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Form, Button, Container } from "react-bootstrap";
import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { faEye, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import {
  CustomCard,
  ValiditySpinner,
  HorizontalDivider
} from "../common-styling";
import Loader from "../loader/loader";
import {
  showError,
  showSuccess,
  showWarning
} from "../../services/toast-service";
import {
  updateCategory,
  createCategory,
  deleteCategory,
  getCategoryById,
  getCategoryByName
} from "../../services/api-declaration";
import DeleteButton from "../delete-button";
import NavigateBackButton from "../back-button";
import TextArea from "../reusable-form-fields/text-area";
import PreventReload from "../funnel-form/prevent-reload";
import TextField from "../reusable-form-fields/text-field";
import SpinnerButton from "../spinner-button/spinner-button";
import { CategoryFormSchema } from "../../services/validation-schema";
import ValidationMessage from "../validation-message/validation-message";
import EmojiTextarea from "../reusable-form-fields/text-area-wtih-emoji";
import LeadPriceBenchmark from "../reusable-form-fields/lead-price-benchmark";
import { useTranslation } from "react-i18next";

const CategoryForm = ({
  fromModal = false,
  initialValue = null,
  createCategoryCallback = () => {}
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const saveBtnRef = useRef();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isNameChanged, setIsNameChanged] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialValues = {
    name: initialValue,
    description: "",
    trainingExample1: "",
    trainingExample2: "",
    trainingExample3: "",
    unit: ""
  };
  const methods = useForm({
    resolver: yupResolver(CategoryFormSchema),
    defaultValues: initialValues
  });
  const {
    formState: { errors },
    reset,
    register,
    handleSubmit
  } = methods;

  const handleNameChange = (fieldVal) => {
    if (fieldVal) {
      saveBtnRef.current.disabled = true;
      setIsNameChanged(true);
    }
  };
  const handleDebounceCb = useMemo(
    () =>
      _debounce(async (name) => {
        try {
          if (name) {
            const response = await getCategoryByName({ name });
            if (response.data.data && response.data.data._id !== params.id) {
              setIsNameValid(false);
              saveBtnRef.current.disabled = true;
              showWarning(
                `${t("common.categories")}: ${name} ${t(
                  "common.isAlreadyTaken"
                )}`
              );
            } else {
              saveBtnRef.current.disabled = false;
              setIsNameValid(true);
            }
          }
        } catch (err) {
          console.log(err);
          showError(
            err.response.data.message ||
              t("toastMessage.errorInGetting", {
                what: t("toastMessage.categoryByName")
              })
          );
        } finally {
          setIsNameChanged(false);
        }
      }, 2000),
    // eslint-disable-next-line
    [setIsNameValid, saveBtnRef, params.id]
  );
  const updateInitialValues = async () => {
    try {
      setIsLoading(true);
      const response = await getCategoryById(params.id);
      reset(response.data.data);
    } catch (err) {
      console.log(err);
      showError(
        err.response.data.message ||
          t("toastMessage.errorInGetting", {
            what: t("toastMessage.categoryByID")
          })
      );
    } finally {
      setIsLoading(false);
    }
  };
  const navigateBack = () => navigate(-1);
  const onSubmit = (formValues) => handleSubmitCategory(formValues);
  const handleSubmitCategory = async (formData) => {
    try {
      setIsSubmitting(true);
      if (params.id && !fromModal) {
        const response = await updateCategory(params.id, formData);
        if (response) {
          showSuccess(response.data.message);
        }
      } else {
        const response = await createCategory(formData);
        if (response) {
          showSuccess(response.data.message);
          fromModal ? createCategoryCallback(response.data) : navigateBack();
        }
      }
    } catch (error) {
      console.log(error);
      showError(error.response.data.message || t("toastMessage.saveCategory"));
    } finally {
      setIsSubmitting(false);
    }
  };

  const categoryCb = useCallback(
    updateInitialValues,
    // eslint-disable-next-line
    [params.id, reset]
  );
  useEffect(() => {
    if (params.id) {
      categoryCb();
    }
  }, [categoryCb, params.id]);

  return params.id && isLoading ? (
    <Loader />
  ) : (
    <FormProvider {...methods}>
      <PreventReload />
      <form
        onSubmit={(event) => {
          handleSubmit(onSubmit)(event);
        }}
      >
        {!fromModal && (
          <div
            className={`${
              fromModal ? "py-2" : "py-4"
            } px-0 d-flex align-items-center justify-content-between`}
          >
            <div className="d-flex align-items-center">
              {params.id && (
                <div className="me-3">
                  <NavigateBackButton />
                </div>
              )}
              <h5 className="mb-0 me-4">{t("categoryForm")}</h5>
            </div>
            {params.id && (
              <Button
                as={Link}
                target="_blank"
                to={`../../funnels?category=${params.id}&pageIndex=1`}
              >
                <FontAwesomeIcon icon={faEye} className="me-2" />
                {t("linkedFunnels")}
              </Button>
            )}
          </div>
        )}
        <CustomCard className="mb-4">
          <Container fluid className="p-4">
            <Row className="mb-4">
              <Col xs={12} md={6}>
                <Form.Group controlId={t("common.categories")}>
                  <Form.Label className={errors?.name && "text-danger"}>
                    {t("common.categories")}
                    {errors?.name && "*"}
                  </Form.Label>
                </Form.Group>
                <Row className="align-items-center">
                  <Col xs={isNameChanged ? 11 : 12}>
                    <Form.Control
                      type="text"
                      placeholder={t("common.name")}
                      className={`form-control ${
                        errors?.name && "border-danger"
                      }`}
                      {...(register && {
                        ...register("name", {
                          onChange: ({ target: { value } }) => {
                            handleNameChange(value);
                            handleDebounceCb(value);
                            return value;
                          }
                        })
                      })}
                    />
                  </Col>
                  {isNameChanged && (
                    <Col xs={1}>
                      <ValiditySpinner
                        animation="border"
                        variant="secondary"
                        className="rounded-circle"
                      />
                    </Col>
                  )}
                </Row>
                {!isNameValid && !isNameChanged && (
                  <small className="mt-1 text-danger fw-bold">
                    <FontAwesomeIcon
                      className="me-2"
                      icon={faExclamationCircle}
                    />
                    {t("common.alreadyTaken")}
                  </small>
                )}
                {errors?.name && (
                  <ValidationMessage error={errors?.name.message} />
                )}
              </Col>
              <Col xs={12} md={6}>
                <TextField
                  type="text"
                  name="unit"
                  register={register}
                  label={t("unitLabel")}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xs={12} md={12}>
                <TextArea
                  label={t("categoryDescription")}
                  name="description"
                  placeholder={t("enterCategoryDesc")}
                  register={register}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xs={12}>
                <LeadPriceBenchmark
                  fromModal={fromModal}
                  categoryId={params.id}
                  errors={errors}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xs={12} md={12}>
                <EmojiTextarea
                  label={`${t("adCopy.title")} Training Example 1`}
                  name="trainingExample1"
                  placeholder={`${t("adCopy.title")} Training Example 1`}
                  errors={errors?.trainingExample1}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xs={12} md={12}>
                <EmojiTextarea
                  label={`${t("adCopy.title")} Training Example 2`}
                  name="trainingExample2"
                  placeholder={`${t("adCopy.title")} Training Example 2`}
                  errors={errors?.trainingExample2}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <EmojiTextarea
                  label={`${t("adCopy.title")} Training Example 3`}
                  name="trainingExample3"
                  placeholder={`${t("adCopy.title")} Training Example 3`}
                  errors={errors?.trainingExample3}
                />
              </Col>
            </Row>
            <HorizontalDivider />
            <div className="mt-4 d-flex justify-content-between">
              {params.id && !fromModal && (
                <DeleteButton
                  variant={"box"}
                  deleteId={params.id}
                  onDelete={deleteCategory}
                  onDeleteSuccess={() => navigate("/dashboard/categories")}
                  message={{
                    success: "deleteCategorySuccess",
                    confirmation: "deleteCategoryMessage"
                  }}
                />
              )}

              <div className="d-flex justify-content-end">
                {!fromModal && (
                  <Button
                    className="me-2"
                    onClick={navigateBack}
                    variant="outline-secondary"
                  >
                    {t("common.cancel")}
                  </Button>
                )}
                {isSubmitting ? (
                  <SpinnerButton
                    btnText={`${t("common.save")}...`}
                    btnStyle="btn-success"
                  />
                ) : (
                  <Button
                    type="submit"
                    ref={saveBtnRef}
                    variant="outline-success"
                  >
                    {t("common.save")}
                  </Button>
                )}
              </div>
            </div>
          </Container>
        </CustomCard>
      </form>
    </FormProvider>
  );
};
export default CategoryForm;
