import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { useFormContext } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import SelectField from "../reusable-form-fields/select-field";
import ClearFilterButton from "./clear-filter-button";
import SelectStructure from "./select-structure";
import { dateRangeOptions, fbDateRangeOptions } from "./static-filter-opts";
import { useTranslation } from "react-i18next";

const ReactDP = styled(ReactDatePicker)`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--field-border-color-regular);
`;
const DateRange = ({
  active,
  clearFilter,
  name,
  fromName,
  toName,
  inputFormat,
  outputFormat,
  isCustomDate,
  setIsCustomDate,
  hideCustomDate,
  label,
  pageType
}) => {
  const { t } = useTranslation();
  const { setValue, control, watch, getValues } = useFormContext();
  const [searchParams] = useSearchParams();
  const startDate = watch(fromName);
  const endDate = watch(toName);
  useEffect(() => {
    const from = getValues("from");
    const to = getValues("to");
    if (from || to) {
      setIsCustomDate(true);
    }
  }, [getValues, setIsCustomDate]);
  return (
    <SelectStructure label={label}>
      {!isCustomDate ? (
        <>
          <SelectField
            control={control}
            name={name}
            options={
              pageType === "fbAds" ? fbDateRangeOptions : dateRangeOptions
            }
            onSelect={(value) => {
              if (value._id === "custom") {
                const dateRangeParam = (
                  pageType === "fbAds" ? fbDateRangeOptions : dateRangeOptions
                ).find((el) => el._id === searchParams.get("dateRange"));
                if (dateRangeParam) {
                  setValue(name, dateRangeParam);
                }
                setIsCustomDate(true);
              }
            }}
          />
          {active[name] && (
            <ClearFilterButton param={name} clearFn={clearFilter} />
          )}
        </>
      ) : (
        <>
          <div className="d-flex align-items-center me-3 w-100">
            <ReactDP
              className="w-100"
              placeholderText="From"
              selected={(() => {
                const value = moment(startDate, outputFormat);
                return value.isValid() ? startDate : "";
              })()}
              onChange={(date) => {
                setValue(fromName, date);
              }}
              dateFormat={inputFormat}
              maxDate={moment.now()}
            />
            {active.from && (
              <ClearFilterButton param={fromName} clearFn={clearFilter} />
            )}
          </div>
          <div className="d-flex align-items-center w-100">
            <ReactDP
              className="w-100"
              placeholderText="To"
              selected={(() => {
                const value = moment(endDate, outputFormat);
                return value.isValid() ? endDate : "";
              })()}
              onChange={(date) => {
                setValue(toName, date);
              }}
              dateFormat={inputFormat}
              maxDate={moment.now()}
            />
            {active.to && (
              <ClearFilterButton param={toName} clearFn={clearFilter} />
            )}
          </div>
          <OverlayTrigger
            overlay={
              <Tooltip>
                {t("common.select")} Date {t("common.category")}
              </Tooltip>
            }
          >
            <Button
              className="ms-2"
              variant="outline-secondary"
              onClick={hideCustomDate}
            >
              <FontAwesomeIcon icon={faRotateRight} />
            </Button>
          </OverlayTrigger>
        </>
      )}
    </SelectStructure>
  );
};

export default DateRange;
