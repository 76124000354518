import { memo } from "react";
import styled from "styled-components";
import { FieldSet, Legend } from "../common-styling";
import ValidationMessage from "../validation-message/validation-message";
import FunnelQA from "../funnel-form/funnel-custom-accordion/funnel-question-answer";
import { useTranslation } from "react-i18next";

const Error = styled.small`
  text-decoration: underline;
`;

const FunnelQASection = ({
  errors,
  resourceId,
  parentFolderName,
  selectedCategory
}) => {
  const { t } = useTranslation();
  return (
    <div className="py-3 px-0">
      <FieldSet>
        <Legend>
          <h6
            className={`mb-0 fw-bold d-flex align-center ${
              errors ? "text-danger" : ""
            }`}
          >
            Funnel {t("question", { count: 2 })}
            {errors && (
              <Error className="ms-3">
                <ValidationMessage error={errors.message} />
              </Error>
            )}
          </h6>
        </Legend>
        <FunnelQA
          selectedCategory={selectedCategory}
          parentFolderName={parentFolderName}
          resourceId={resourceId}
          errors={errors?.length && errors}
        />
      </FieldSet>
    </div>
  );
};
export default memo(FunnelQASection);
