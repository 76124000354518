import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useOutletContext, useParams } from "react-router";
import { useState, forwardRef, useImperativeHandle } from "react";
import { Col, Collapse, Form, FormSelect, Row } from "react-bootstrap";
import {
  FieldSet,
  HorizontalDivider,
  FadedBackgroundDiv
} from "../common-styling";
import {
  integrateFunnel,
  updateIntegration
} from "../../services/api-declaration";
import { useDialog } from "../../hooks/useDialog";
import { INTEGRATION_TYPE, SEND_DATA } from "../strings";
import PreventReload from "../funnel-form/prevent-reload";
import TextField from "../reusable-form-fields/text-field";
import { showError, showSuccess } from "../../services/toast-service";
import { IntegrationFormSchema } from "../../services/validation-schema";
import ActionButtonsColumn from "./integration-view/action-buttons-column";
import IntegrationsTableView from "./integration-view/integrations-table-view";
import { useTranslation } from "react-i18next";

/* eslint-disable react/display-name --- for forwardRef --- */
const Zapier = forwardRef(
  ({ zapierIntegrations = [], setIntegrations }, ref) => {
    const { t } = useTranslation();
    const paramsId = useParams().id;
    const { funnel, funnelType } = useOutletContext();
    const { setDependentStates } = useDialog();
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isEditing, setIsEditing] = useState({ id: false });
    const [isAllowedAddNew, setIsAllowedAddNew] = useState(false);
    const [selectedIntegration, setSelectedIntegration] = useState(null);
    const initialValues = {
      type: "",
      webHookFlag: false,
      webHookUrl: "",
      description: "",
      sendData: SEND_DATA.IMMEDIATELY
    };
    const methods = useForm({
      resolver: yupResolver(IntegrationFormSchema),
      defaultValues: initialValues
    });
    const {
      reset,
      register,
      handleSubmit,
      formState: { errors }
    } = methods;

    useImperativeHandle(ref, () => ({
      handleZapierOnDisableLeadService() {
        handleUpdateOnDisableLeadService();
      }
    }));

    const handleUpdateOnDisableLeadService = async () => {
      for (const [index, integration] of zapierIntegrations.entries()) {
        if (
          integration.sourceData.meta.sendData === SEND_DATA.AFTER_CONFIRMATION
        ) {
          try {
            await handleUpdateIntegration(
              integration,
              {
                description: integration.description,
                sourceData: {
                  ...integration.sourceData,
                  meta: { sendData: SEND_DATA.NEVER }
                }
              },
              index
            );
          } catch (err) {
            console.log(err);
            showError();
          }
        }
      }
    };

    const onSubmit = (formValues) => handleIntegrateFunnel(formValues);
    const handleIntegrateFunnel = async (formVals) => {
      setIsSubmitting(true);
      try {
        if (selectedIntegration && isEditing[selectedIntegration._id]) {
          // EDIT
          await handleUpdateIntegration(
            selectedIntegration,
            {
              description: formVals.description,
              sourceData: {
                ...selectedIntegration.sourceData,
                meta: { sendData: formVals.sendData }
              }
            },
            currentIndex
          );
        } else {
          // CREATE
          const body = {
            funnelType,
            integrationType: INTEGRATION_TYPE.ZAPIER,
            webHookUrl: formVals.webHookUrl,
            description: formVals.description,
            sendData: formVals.sendData,
            active: true
          };
          const response = await integrateFunnel(paramsId, body);
          const integrationData = {
            funnelId: paramsId,
            type: INTEGRATION_TYPE.ZAPIER,
            sourceData: {
              id: formVals.webHookUrl,
              meta: { sendData: formVals.sendData }
            },
            description: formVals.description,
            active: true
          };
          setIntegrations((data) => {
            let clone;
            if (!data.zapier?.length) {
              clone = [];
            } else {
              clone = [...data.zapier];
            }
            clone.push({
              ...integrationData,
              _id: response.data.data.upsertedId
            });
            data.zapier = clone;
            return { ...data };
          });
          showSuccess(response.data.message);
        }
        resetComponentState();
      } catch (err) {
        console.log(err);
        showError(err.response?.data?.message || t("funnelIntegration.error"));
      } finally {
        setIsSubmitting(false);
      }
    };
    const handleUpdateIntegration = async (
      integration,
      payload,
      currentIdx
    ) => {
      try {
        const response = await updateIntegration(integration._id, payload);
        setIntegrations((data) => {
          const clone = [...data.zapier];
          clone[currentIdx].description = payload.description;
          clone[currentIdx].sourceData.meta.sendData =
            payload.sourceData.meta.sendData;
          data.zapier[currentIdx] = clone[currentIdx];
          return { ...data };
        });
        showSuccess(response.data.message);
      } catch (err) {
        console.log(err);
        showError(
          err.response?.data?.message ||
            t("funnelIntegration.updateIntegration")
        );
      }
    };
    const handleStatesOnStatusChange = (data, idx) => {
      setCurrentIndex(idx);
      setSelectedIntegration(data);
      setDependentStates({ selectedIntegration: data, currentIndex: idx });
    };
    const handleEditClick = (data, idx) => {
      setCurrentIndex(idx);
      setSelectedIntegration(data);
      setIsEditing({ [data._id]: true });
      setIsAllowedAddNew(true);
      reset({
        webHookUrl: data.sourceData.id,
        description: data.description,
        sendData: data.sourceData.meta?.sendData
      });
    };
    const resetComponentState = () => {
      reset(initialValues);
      setCurrentIndex(-1);
      setSelectedIntegration(null);
      setIsEditing({ id: false });
      setIsAllowedAddNew(false);
    };

    return (
      <FormProvider {...methods}>
        <PreventReload />
        <Form
          className="p-0"
          onSubmit={(event) => {
            handleSubmit(onSubmit)(event);
          }}
        >
          <div className="px-3">
            <FieldSet>
              <Collapse in={isAllowedAddNew}>
                <div>
                  <FadedBackgroundDiv className="p-2">
                    <Row className="mb-3">
                      <Col lg={5} md={6} sm={12}>
                        <TextField
                          type="text"
                          label="Webhook-URL"
                          name="webHookUrl"
                          placeholder={t(
                            "funnelIntegration.webhookPlaceholder"
                          )}
                          register={register}
                          errors={errors?.webHookUrl}
                          disabled={isEditing[selectedIntegration?._id]}
                          noSpacing
                        />
                      </Col>
                      <Col lg={3} md={6} sm={12}>
                        <TextField
                          type="text"
                          label={t("common.description")}
                          name="description"
                          placeholder={t(
                            "funnelIntegration.descriptionPlaceholder"
                          )}
                          register={register}
                          noSpacing
                        />
                      </Col>
                      <Col lg={2} md={6} sm={12}>
                        <Form.Label>
                          {t("funnelIntegration.sendDataHeading")}
                        </Form.Label>
                        <FormSelect {...register("sendData")}>
                          <option value={SEND_DATA.NEVER}>
                            {t("funnelIntegration.selectOptions.never")}
                          </option>
                          <option value={SEND_DATA.IMMEDIATELY}>
                            {t("funnelIntegration.selectOptions.immediately")}
                          </option>
                          <option
                            value={SEND_DATA.AFTER_CONFIRMATION}
                            disabled={!funnel?.leadService}
                          >
                            {t(
                              "funnelIntegration.selectOptions.afterConfirmation"
                            )}
                            {!funnel?.leadService &&
                              `(${t(
                                "funnelIntegration.selectOptions.leadServiceInfo"
                              )})`}
                          </option>
                        </FormSelect>
                      </Col>
                      <ActionButtonsColumn
                        mdColWidth={6}
                        fieldError={errors?.webHookUrl}
                        actions={{ isSubmitting, resetComponentState }}
                      />
                    </Row>
                  </FadedBackgroundDiv>
                  <HorizontalDivider />
                </div>
              </Collapse>
              <IntegrationsTableView
                headers={[
                  {
                    name: "Status",
                    colWidth: 1,
                    style: "pb-1"
                  },
                  {
                    name: "Webhook-URL",
                    colWidth: null,
                    style: "pb-1"
                  },
                  {
                    name: t("common.description"),
                    colWidth: null,
                    style: "pb-1"
                  },
                  {
                    name: t("funnelIntegration.sendDataHeading"),
                    colWidth: null,
                    style: "pb-1"
                  },
                  {
                    name: "Action",
                    colWidth: 2,
                    style: "pb-1 text-end"
                  }
                ]}
                integrations={zapierIntegrations}
                setIntegrations={setIntegrations}
                propertiesToRender={[
                  "sourceData.id",
                  "description",
                  "sourceData.meta.sendData"
                ]}
                handleStatesOnStatusChange={handleStatesOnStatusChange}
                onEditClick={handleEditClick}
                isAllowedAddNew={isAllowedAddNew}
                setIsAllowedAddNew={setIsAllowedAddNew}
                resetComponentState={resetComponentState}
              />
            </FieldSet>
          </div>
        </Form>
      </FormProvider>
    );
  }
);

export default Zapier;
