import * as d3 from "d3";

export class LeadDetailsChart {
  chart = undefined;
  svg = undefined;
  textData = undefined;
  data = undefined;

  constructor({ chartRef, data }) {
    this.chart = d3.select(chartRef);
    this.textData = this.chart.selectAll("div.lead-text-data");
    this.svg = this.chart.append("svg").attr("class", "sunburst");
    this.data = data;
  }
  // Specify the chart’s dimensions.
  draw(colors) {
    const width = 628;
    const height = width;
    const radius = width / 6;

    // Compute the layout.
    const hierarchy = d3
      .hierarchy(this.data)
      .sum((d) => d.value)
      .sort((a, b) => b.value - a.value);
    const root = d3.partition().size([2 * Math.PI, hierarchy.height + 1])(
      hierarchy
    );

    root.each((d, i) => {
      d.colorId = Math.random() * 360;
      d.color = `hsl(${d.colorId},65%,50%)`;
      d.current = d;
    });

    // Create the arc generator.
    const arc = d3
      .arc()
      .startAngle((d) => d.x0)
      .endAngle((d) => d.x1)
      .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
      .padRadius(radius * 1.5)
      .innerRadius((d) => d.y0 * radius)
      .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1));

    // Create the SVG container.
    this.svg
      .attr("viewBox", [-width * 2, -height, width * 3.5, width * 1.8])
      .style("font", "21px sans-serif")
      .style("font-weight", "bold");

    const g = this.svg
      .append("g")
      .attr("transform", `translate(${250},-60)`)
      .on("mouseleave", mouseleave);

    // Append the arcs.
    const path = g
      .append("g")
      .selectAll("path")
      .data(root.descendants().slice(1))
      .join("path")
      .attr("fill", (d) => d.color)
      .attr("stroke-width", "2")
      .attr("stroke", "white")
      .attr("fill-opacity", (d) => (arcVisible(d.current) ? 1 : 0))
      // .attr("pointer-events", (d) => (arcVisible(d.current) ? "auto" : "none"))

      .attr("d", (d) => arc(d.current))
      .on("mouseover", mouseover);

    // Make them clickable if they have children.
    path
      .filter((d) => d.children)
      .style("cursor", "pointer")
      .on("click", clicked);

    const format = d3.format(",d");
    path
      .append("title")
      .text((d) => `${d.data.name}\n\nCount:${format(d.value)}`);

    const label = g
      .append("g")
      .attr("pointer-events", "none")
      .attr("text-anchor", "middle")
      .style("user-select", "none")
      .selectAll("text")
      .data(root.descendants().slice(1))
      .join("text")
      .attr("dy", "0.35em")
      .attr("fill-opacity", (d) => +labelVisible(d.current))
      .attr("transform", (d) => labelTransform(d.current));

    const parent = g
      .append("circle")
      .datum(root)
      .attr("r", radius)
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .on("click", clicked);

    g.append("text")
      .attr("class", "selected-title")
      .attr("dx", -5)
      .attr("dy", 8)
      .text("");

    //============================== Source Detail ============================

    const toggleSourceDetails = (data) => {
      this.textData.selectAll("div.source").style("opacity", "0");
      this.textData.selectAll("div.category").style("opacity", "0");
      this.textData.selectAll("div.adset1").style("opacity", "0");
      this.textData.selectAll("div.ad").style("opacity", "0");

      const createText = (treeData, heading, label) => {
        const textDataD3 = this.textData
          .selectAll(`.${label}`)
          .style("opacity", "1")
          .style("border-color", treeData.color);

        textDataD3.selectAll(".heading").text(`${heading}`);
        textDataD3.selectAll(".count").text(`${treeData.count}`);
        textDataD3.selectAll(".name").text(`${treeData.name}`);
        textDataD3.selectAll(".id").text(`#${treeData.id}`);
      };

      if (data) {
        const tree = data
          .ancestors()
          .map((d) => ({
            id: d.data.id,
            name: d.data.name,
            count: d.value,
            color: d.color
          }))
          .filter((d) => d.name !== "Lead Details")
          .reverse();

        createText(tree[0], "Source", "source");

        if (tree[1]) {
          createText(tree[1], "Campagin", "category");
        }
        if (tree[2]) {
          createText(tree[2], "Adset", "adset1");
        }
        if (tree[3]) {
          createText(tree[3], "Ad", "ad");
        }
      }
    };

    const toggleSelectedHeader = (idx) => {
      const depth = ["Facebook Cockpit", "Campaign", "Adset", "Ad"];
      this.svg.selectAll(".selected-title").text(depth[idx]);
    };
    //=========================================================================
    //============================== On Hover ============================
    //mouse over

    const svgD3 = this.svg;
    function mouseover(e, d) {
      toggleSourceDetails(d);
      let sequenceArray = d.ancestors().reverse();
      sequenceArray.shift(); // remove root node from the array
      // Fade all the segments.
      svgD3.selectAll("path").style("opacity", 0.3);

      // Then highlight only those that are an ancestor of the current segment.
      svgD3
        .selectAll("path")
        .filter(function (node) {
          return sequenceArray.indexOf(node) >= 0;
        })
        .style("opacity", 1);
    }
    //mouse leave
    function mouseleave() {
      svgD3
        .selectAll("path")
        // .transition()
        // .duration(200)
        .style("opacity", 1)
        .on("end", function () {
          d3.select(this).on("mouseover", mouseover);
        });
      toggleSourceDetails();
    }
    //====================================================================

    //============================== Handle zoom on click ============================
    function clicked(event, p) {
      parent.datum(p.parent || root);
      if (p.parent !== null) {
        toggleSelectedHeader(p.depth - 1);
      } else {
        toggleSelectedHeader("");
      }
      root.each(
        (d) =>
          (d.target = {
            x0:
              Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
              2 *
              Math.PI,
            x1:
              Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
              2 *
              Math.PI,
            y0: Math.max(0, d.y0 - p.depth),
            y1: Math.max(0, d.y1 - p.depth)
          })
      );

      const t = d3.transition().duration(750);

      // Transition the data on all arcs, even the ones that aren’t visible,
      // so that if this transition is interrupted, entering arcs will start
      // the next transition from the desired position.
      path
        .transition(t)
        .tween("data", (d) => {
          const i = d3.interpolate(d.current, d.target);
          return (t) => (d.current = i(t));
        })
        .filter(function (d) {
          return +this.getAttribute("fill-opacity") || arcVisible(d.target);
        })
        .attr("fill-opacity", (d) => (arcVisible(d.target) ? 1 : 0))
        .attr("pointer-events", (d) => (arcVisible(d.target) ? "auto" : "none"))

        .attrTween("d", (d) => () => arc(d.current));

      label
        .filter(function (d) {
          return +this.getAttribute("fill-opacity") || labelVisible(d.target);
        })
        .transition(t)
        .attr("fill-opacity", (d) => +labelVisible(d.target))
        .attrTween("transform", (d) => () => labelTransform(d.current));
    }
    //================================================================================

    function arcVisible(d) {
      return d.y1 <= 6 && d.y0 >= 1 && d.x1 > d.x0;
    }

    function labelVisible(d) {
      return d.y1 <= 6 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
    }

    function labelTransform(d) {
      const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
      const y = ((d.y0 + d.y1) / 2) * radius;
      return `rotate(${x - 90}) translate(${y},0) rotate(${
        x < 180 ? -90 : 90
      })`;
    }
  }

  destroy() {
    this.chart.selectChildren("svg").remove();
    this.chart = undefined;
    this.svg = undefined;
  }
}
