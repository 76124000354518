import { useRef } from "react";
import { components } from "react-select";
import { Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { useTranslation } from "react-i18next";
import Option from "./select-custom-option-item";

const AsyncSelectField = ({
  fetchData,
  optionLabel = "name",
  getOptionLabel,
  name,
  control,
  paramName = "name",
  isMulti = false,
  isClearable = false,
  onSelect,
  placeholder,
  isDisabled = false,
  capitalized = true,
  grouped = false,
  forHeaderOnly = false,
  showDefaultOpts = true,
  fieldError = false
}) => {
  const { t } = useTranslation();
  const timer = useRef();
  const isFirstRender = useRef(true);
  const promiseOptions = (inputValue) => {
    if (isDisabled && !inputValue) return;
    const params = { [paramName]: inputValue };
    if (forHeaderOnly) {
      params.forHeaderOnly = true;
    }
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return fetchData(params);
    }
    return new Promise((resolve) => {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        resolve(fetchData(params));
      }, 1000);
    });
  };
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <AsyncSelect
          {...field}
          onChange={(option, action) => {
            field.onChange(option);
            if (onSelect) onSelect(option, action);
          }}
          isClearable={isClearable}
          isMulti={isMulti}
          className={`w-100 ${capitalized && "text-capitalize"}`}
          defaultOptions={showDefaultOpts}
          placeholder={placeholder || t("common.select")}
          cacheOptions
          loadOptions={promiseOptions}
          getOptionLabel={(option) =>
            getOptionLabel ? getOptionLabel(option) : option[optionLabel]
          }
          getOptionValue={(option) => option._id}
          isDisabled={isDisabled}
          isOptionDisabled={(option) => option.disabled}
          components={forHeaderOnly ? { Option } : { ...components.Option }}
          styles={{
            control: (base) => ({
              ...base,
              borderRadius: grouped ? "0 4px 4px 0" : "4px",
              borderColor: fieldError
                ? "var(--danger-color)"
                : "var(--field-border-color-regular)",
              "&:hover": {
                borderColor: fieldError ? "var(--danger-color)" : "#b3b3b3"
              }
            }),
            menuPortal: (base) => ({
              ...base,
              zIndex: 1021
            }),
            menu: (base) => ({
              ...base,
              zIndex: 1021
            })
          }}
        />
      )}
    />
  );
};

export default AsyncSelectField;
