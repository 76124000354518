import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Form, Modal, Button, FormGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TESTED_LINK_STATUS } from "../../strings";
import { CloseIconContainer } from "../../common-styling";
import TextField from "../../reusable-form-fields/text-field";
import { useOptionsFetch } from "../../../hooks/useOptionsFetch";
import { updateSuggestion } from "../../../services/api-declaration";
import { removeWhiteSpaces } from "../../../services/common-service";
import AsyncSelectField from "../../reusable-form-fields/async-select";
import { TestedLinksSchema } from "../../../services/validation-schema";
import { showError, showSuccess } from "../../../services/toast-service";
import { useTranslation } from "react-i18next";

const AddLinkDialog = (props) => {
  const { t } = useTranslation();
  const { handleCategoryOptions } = useOptionsFetch();
  const suggestionData = props.suggestion;
  const initialValues = { linkToCampaign: "", linkToFunnel: "", category: "" };
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(TestedLinksSchema)
  });
  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = methods;

  const onSubmit = async (values) => {
    try {
      let testedLinks = suggestionData.testedLinks;
      testedLinks.push({
        linkToCampaign: removeWhiteSpaces(values.linkToCampaign),
        linkToFunnel: removeWhiteSpaces(values.linkToFunnel),
        category: values.category,
        status: TESTED_LINK_STATUS.NEUTRAL,
        uploadedOn: new Date()
      });
      const response = await updateSuggestion(suggestionData.id, {
        testedLinks
      });
      showSuccess(response.data.message);
      handleClose();
    } catch (error) {
      showError(error.response?.data?.message);
    }
  };
  const handleClose = () => {
    props.onHide();
    reset(initialValues);
  };
  return (
    <Modal {...props} centered backdrop="static">
      <div className="position-relative p-3">
        <h5 className="mb-3">{t("suggestionGallery.addNewTestedLink")}</h5>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              type="text"
              name="linkToCampaign"
              register={register}
              errors={errors?.linkToCampaign}
              placeholder={t("common.enter")}
              label={`${t("suggestionGallery.linkToCampaignLabel")}:`}
            />
            <TextField
              type="text"
              name="linkToFunnel"
              register={register}
              errors={errors?.linkToFunnel}
              placeholder={t("common.enter")}
              label={`${t("suggestionGallery.linkToFunnelLabel")}:`}
            />
            <FormGroup className="mb-3" controlId="category">
              <Form.Label className={errors?.category ? "text-danger" : ""}>
                {`${t("common.category")}:${errors?.category ? "*" : ""}`}
              </Form.Label>
              <AsyncSelectField
                control={control}
                name="category"
                fieldError={errors?.category}
                fetchData={handleCategoryOptions}
              />
            </FormGroup>
            <div className="text-end">
              <Button type="submit" variant="success">
                {t("common.save")}
              </Button>
            </div>
            <CloseIconContainer onClick={handleClose}>
              <FontAwesomeIcon icon={faClose} className="text-danger" />
            </CloseIconContainer>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};
export default AddLinkDialog;
