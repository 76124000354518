import { Controller } from "react-hook-form";
import { Col, Form, FormGroup } from "react-bootstrap";
import { useOptionsFetch } from "../../hooks/useOptionsFetch";
import AsyncSelectField from "../reusable-form-fields/async-select";
import { useTranslation } from "react-i18next";

const AddNewImage = ({ control, register, errors }) => {
  const { t } = useTranslation();
  const { handleTagOptions, handleCategoryOptions } = useOptionsFetch();

  return (
    <>
      <Col md={12}>
        <FormGroup className="mb-3" controlId="title">
          <Form.Label>{t("title")}</Form.Label>
          <Controller
            control={control}
            name="title"
            render={({ field }) => (
              <Form.Control {...field} placeholder="Title" />
            )}
          />
        </FormGroup>
      </Col>
      <Col md={12}>
        <FormGroup className="mb-3" controlId="description">
          <Form.Label>{t("common.description")}</Form.Label>
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <Form.Control
                {...field}
                as="textarea"
                placeholder={t("common.description")}
              />
            )}
          />
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup className="mb-3" controlId="type">
          <Form.Label>{t("common.type")}</Form.Label>
          <Form.Select className="text-lowercase" {...register("type")}>
            <option>ICON</option>
            <option>BACKGROUND</option>
          </Form.Select>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup className="mb-3" controlId="category">
          <Form.Label className={errors?.category ? "text-danger" : ""}>
            {`${t("common.category")}${errors?.category ? "*" : ""}`}
          </Form.Label>
          <AsyncSelectField
            control={control}
            name="category"
            fieldError={errors?.category}
            fetchData={handleCategoryOptions}
          />
        </FormGroup>
      </Col>
      <Col md={12}>
        <FormGroup className="mb-3" controlId="tags">
          <Form.Label>Tags</Form.Label>
          <AsyncSelectField
            isMulti
            isClearable
            fetchData={handleTagOptions}
            placeholder={`${t("common.select")} Tags`}
            name="tags"
            control={control}
          />
        </FormGroup>
      </Col>
    </>
  );
};

export default AddNewImage;
