import React from "react";
import { TableRow } from "../common-styling";
import Loader from "./loader";

const TableLoader = ({ colSpan, calcHeight = 300 }) => {
  return (
    <TableRow>
      <td colSpan={colSpan}>
        <Loader calcHeight={calcHeight} />
      </td>
    </TableRow>
  );
};

export default TableLoader;
