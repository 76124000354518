import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import LeadsTable from "../../components/leads/leads-table";

const LeadPage = () => {
  return (
    <>
      <Routes>
        <Route index element={<LeadsTable />} />
        <Route path="*" element={<Navigate to="/dashboard/leads" />} />
      </Routes>
      <Outlet />
    </>
  );
};
export default LeadPage;
