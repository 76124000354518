import { useRef } from "react";
import { Link } from "react-router-dom";
import { components } from "react-select";
import {
  faUserTag,
  faUserCheck,
  faBellSlash,
  faBoxArchive,
  faPhoneVolume,
  faCalendarDays,
  faExclamationCircle,
  faArrowUpRightFromSquare
} from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_TYPE } from "../strings";
import { generateDate, generateDateTime } from "../../services/common-service";
import { useTranslation } from "react-i18next";

const Option = (props) => {
  const { t } = useTranslation();
  const { data } = props;
  const linkRef = useRef(false);

  const interactionOptions = {
    [ICON_TYPE.ALREADY_INITIATED]: {
      optIcon: faPhoneVolume,
      renderTooltip: (_data) => _data.interactionStatus?.toLowerCase()
    },
    [ICON_TYPE.ALREADY_QUALIFIED]: {
      optIcon: faUserCheck,
      renderTooltip: () => t("leadAgent.qualified")
    },
    [ICON_TYPE.NOT_AVAILABLE_NOW]: {
      optIcon: faBellSlash,
      renderTooltip: (_data) => (
        <>
          <div>{`${t("leadAgent.available")} ${t("leadAgent.after")}`}:</div>
          <div>{generateDateTime(_data.availableAfter)}</div>
        </>
      )
    },
    [ICON_TYPE.ALREADY_ASSIGNED]: {
      optIcon: faUserTag,
      renderTooltip: (_data) =>
        `${t("leadAgent.interactingWithAnotherAgent")} ${
          _data.serviceAgent.firstName
        } ${_data.serviceAgent.lastName} (${_data.serviceAgent.email})!`
    },
    [ICON_TYPE.TARGET_BLANK]: {
      optIcon: faArrowUpRightFromSquare,
      renderTooltip: () => t("common.openInNewTab")
    },
    [ICON_TYPE.ARCHIVED]: {
      optIcon: faBoxArchive,
      renderTooltip: () => t("common.archive")
    },
    [ICON_TYPE.NO_LEADS]: {
      optIcon: faExclamationCircle,
      renderTooltip: () => t("leadAgent.noLeadsYetTooltip")
    },
    [ICON_TYPE.CONTACT_LATER]: {
      optIcon: faCalendarDays,
      renderTooltip: () => (
        <div>
          {t("leadAgent.contactLater")} {t("common.on")}
          <div>{generateDate(data.contactLater)}</div>
        </div>
      )
    }
  };

  const renderOptionIcon = (iconType) => (
    <FontAwesomeIcon icon={interactionOptions[iconType].optIcon} />
  );

  return (
    <components.Option
      {...props}
      innerProps={{
        ...props.innerProps,
        onClick: (e) => {
          if (linkRef?.current === true || data.disabled) return;
          props.innerProps.onClick(e);
        }
      }}
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className="flex-fill">
          {props.children}
          {data.createdAt && <div>{generateDateTime(data.createdAt)}</div>}
        </div>
        {data.iconType && (
          <div className="mx-1">
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip>
                  {interactionOptions[data.iconType].renderTooltip(data)}
                </Tooltip>
              }
            >
              {data.iconType === ICON_TYPE.TARGET_BLANK ? (
                <Link
                  ref={linkRef}
                  target="_blank"
                  to={data.iconLink}
                  onClick={() => (linkRef.current = true)}
                >
                  {renderOptionIcon(data.iconType)}
                </Link>
              ) : (
                renderOptionIcon(data.iconType)
              )}
            </OverlayTrigger>
          </div>
        )}
      </div>
    </components.Option>
  );
};
export default Option;
