import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  TableRow,
  CustomCard,
  FirstHeaderColumn,
  HeaderColumn,
  LastHeaderColumn,
  BootstrapTable,
  TableDetailCell,
  PageHeading,
  NameLink,
  TableWrap
} from "../common-styling";
import TableLoader from "../loader/table-loader";
import NoResultsRow from "../no-results-table-row";
import { showError, showSuccess } from "../../services/toast-service";
import CustomPagination from "../pagination/custom-pagination";
import {
  fetchMissingHubspotInfo,
  fetchMissingInfoCount,
  updateMissingHubspotInfo
} from "../../services/api-declaration";
import { useTranslation } from "react-i18next";
// import AsyncSelectField from "../reusable-form-fields/async-select";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import { setMissingInfoCount } from "../../store/slices/missingInfo";
import { useDispatch } from "react-redux";
import TextField from "../reusable-form-fields/text-field";

const MissingFunnelHubspotInfo = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState({});
  const [funnels, setFunnels] = useState([]);
  const [paginateOpts, setPaginateOpts] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [animateItem, setAnimateItem] = useState("");
  const dispatch = useDispatch();

  const handleQueryParams = (property, value) => {
    searchParams.set(property, value);
    setSearchParams(searchParams);
  };

  const methods = useForm({
    defaultValues: {}
  });
  const { register, watch } = methods;

  const handleSave = async (funnelId, key) => {
    const loadingState = btnLoading;
    loadingState[key] = "load";
    setBtnLoading({ ...loadingState });
    const hubspotProjectId = watch(key);
    try {
      const body = {
        funnelId,
        hubspotProjectId
      };
      const response = await updateMissingHubspotInfo(body);
      if (response?.data) {
        setAnimateItem(funnelId);
      }
      showSuccess(response.data.message);
    } catch (error) {
      showError(
        error?.response?.data?.message ||
          t("toastMessage.errorInGetting", {
            what: t("common.funnel")
          })
      );
    } finally {
      loadingState[key] = "hide";
      setBtnLoading({ ...loadingState });
      try {
        const resp = await fetchMissingInfoCount();
        const countData = resp.data.data;
        dispatch(setMissingInfoCount(countData));
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  useEffect(() => {
    const handleFetchMissingHubspotInfo = async () => {
      try {
        setIsLoading(true);
        const params = {};
        for (const [key, value] of searchParams.entries()) {
          params[key] = value;
        }
        const response = await fetchMissingHubspotInfo(params);
        const respData = response.data.data;
        if (!respData.data.length) {
          return;
        }
        setFunnels(respData.data);
        setPaginateOpts({
          ...respData.metadata,
          totalDocs: respData.metadata.total,
          rowsOnPage: respData.data.length
        });
      } catch (error) {
        showError(
          error?.response?.data?.message ||
            t("toastMessage.errorInGetting", {
              what: t("common.funnel", { count: 2 })
            })
        );
      } finally {
        setIsLoading(false);
      }
    };
    handleFetchMissingHubspotInfo();
    // eslint-disable-next-line
  }, [searchParams]);

  useEffect(() => {
    if (animateItem) {
      setTimeout(() => {
        setPaginateOpts((prevPage) => ({
          ...prevPage,
          totalDocs: prevPage.totalDocs - 1,
          rowsOnPage: prevPage.rowsOnPage - 1
        }));
        setFunnels((prevFunnels) =>
          prevFunnels.filter((funnel) => funnel._id !== animateItem)
        );
      }, 300);
    }
  }, [animateItem]);

  return (
    <>
      <div>
        <PageHeading className="">{t("Missing Funnel Info")}</PageHeading>

        <CustomCard className="mt-4 mb-3 p-3 rounded-0">
          <FormProvider {...methods}>
            <Form>
              <TableWrap>
                <BootstrapTable responsive>
                  <thead>
                    <tr>
                      <FirstHeaderColumn>
                        Funnel {t("common.name")}
                      </FirstHeaderColumn>
                      {/* <HeaderColumn className="text-center align-middle">
                        {t("missingInfo.leadCount")}
                      </HeaderColumn> */}
                      <HeaderColumn>{t("hubspotProjectID")}</HeaderColumn>
                      <LastHeaderColumn />
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <TableLoader colSpan={8} />
                    ) : funnels.length ? (
                      funnels.map((item) => {
                        const key = `hubspotProjectId-${item._id}`;
                        return (
                          <TableRow
                            className={
                              animateItem === item._id ? "slide-out" : ""
                            }
                            key={key}
                          >
                            <TableDetailCell className="py-3">
                              <NameLink
                                className="d-block"
                                to={`/dashboard/funnels/${item._id}/form?funnelType=${item.general.funnelType}`}
                              >
                                {item.general.funnelName}
                              </NameLink>
                            </TableDetailCell>
                            {/* <TableDetailCell className="text-center align-middle py-3">
                              {item.leadCount || 0}
                            </TableDetailCell> */}
                            <TableDetailCell
                              style={{ overflow: "visible" }}
                              className="py-3"
                            >
                              <TextField
                                type="text"
                                name={key}
                                placeholder={`${t("common.enter")} ${t(
                                  "hubspotProjectID"
                                )}`}
                                register={register}
                                //   errors={errors?.hubspotProjectId}
                                noSpacing
                              />
                              {/* <Form.Group>
                              <AsyncSelectField
                                control={control}
                                name={key}
                                paramName="name"
                                showDefaultOpts={false}
                                getOptionLabel={getOptionLabel}
                                fetchData={handleCustomerOptions}
                                isDisabled={btnLoading[key] === "hide"}
                              />
                            </Form.Group> */}
                            </TableDetailCell>
                            <TableDetailCell className="text-center align-middle py-3">
                              {btnLoading[key] === "hide" ? (
                                <></>
                              ) : (
                                <Button
                                  disabled={
                                    !watch(key) || btnLoading[key] === "load"
                                  }
                                  variant="primary"
                                  onClick={() => handleSave(item._id, key)}
                                >
                                  {btnLoading[key] === "load"
                                    ? "Saving..."
                                    : "Save"}
                                </Button>
                              )}
                            </TableDetailCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <NoResultsRow lengthOfColumns="5" />
                    )}
                  </tbody>
                </BootstrapTable>
              </TableWrap>
            </Form>
          </FormProvider>
        </CustomCard>
        {Object.values(paginateOpts).length ? (
          <CustomPagination
            paginateOpts={paginateOpts}
            activeTab={Number(searchParams.get("pageIndex")) || 1}
            select={(val) => handleQueryParams("pageIndex", val)}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default MissingFunnelHubspotInfo;
