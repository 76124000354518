import { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  disableAuthenticatorVerification,
  disableEmailVerification
} from "../../services/api-declaration";
import { showError, showSuccess } from "../../services/toast-service";
import { setMe } from "../../store/slices/auth";
import OTPMethod from "../auth/otp-method";
import { MULTI_FACTOR_TYPES } from "../strings";
import { INITITIAL_LOADING_DATA } from "../common-confgs/common-vars";
import { useTranslation } from "react-i18next";

const Disable2FA = ({ toggleModal, disableMultiFactorType }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const email = user.email;
  const inputsRef = useRef([]);
  const [loadingData, setLoadingData] = useState(INITITIAL_LOADING_DATA);
  const disable2FAHanlder = async (body) => {
    try {
      //   setIsLoading(true);
      let data;
      const multiFactorEnabled = {
        ...user.multiFactorEnabled
      };
      if (disableMultiFactorType === MULTI_FACTOR_TYPES.EMAIL) {
        const response = await disableEmailVerification(body);
        data = response.data;
        multiFactorEnabled.email = false;
      } else {
        const response = await disableAuthenticatorVerification(body);
        data = response.data;
        multiFactorEnabled.authenticator = false;
      }
      dispatch(setMe({ userData: { ...user, multiFactorEnabled } }));
      toggleModal();
      showSuccess(data.message);
    } catch (error) {
      showError(
        error.response?.data?.message || t("toastMessage.disable2FAError")
      );
    } finally {
      inputsRef.current.forEach((input) => {
        input.value = "";
        input.disabled = false;
      });
      //   setIsLoading(false);
    }
  };
  const authenticators = [{ text: "Email", type: MULTI_FACTOR_TYPES.EMAIL }];
  return (
    <Modal
      backdrop="static"
      keyboard={false}
      onHide={() => toggleModal()}
      size="md"
      show={true}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">{t("multifactor.disableHeading")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <OTPMethod
          email={email}
          onSubmit={({ otp, type }) =>
            disable2FAHanlder({ otp, otpType: type })
          }
          inputsRef={inputsRef}
          loadingData={loadingData}
          setLoadingData={setLoadingData}
          defaultAuthType={
            disableMultiFactorType === MULTI_FACTOR_TYPES.EMAIL
              ? MULTI_FACTOR_TYPES.EMAIL
              : MULTI_FACTOR_TYPES.AUTHENTICATOR
          }
          authenticators={
            disableMultiFactorType === MULTI_FACTOR_TYPES.EMAIL
              ? authenticators
              : []
          }
        />
      </Modal.Body>
    </Modal>
  );
};

export default Disable2FA;
