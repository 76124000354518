import {
  Row,
  Col,
  Form,
  Card,
  Button,
  Spinner,
  Container
} from "react-bootstrap";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faPhoneFlip } from "@fortawesome/free-solid-svg-icons";
import PDFViewer from "./pdf-viewer";
import {
  getCallInsights,
  getLeadSyncRate,
  getOnHoldLeadsCount,
  updateLeadBlockTime
} from "../../services/api-declaration";
import BarChart from "../charts/barChart";
import { SCRIPT_ROUTE } from "../strings";
import {
  DATE_FILTERS,
  PICKER_OPT_A,
  PICKER_LAYOUT
} from "../date-range-pickers/picker-helper";
import { setMe } from "../../store/slices/auth";
import StackedBarChart from "../charts/stackedBarChart";
import UploadScriptDialog from "./upload-script-dialog";
import { CustomCard, PageHeading } from "../common-styling";
import SpinnerButton from "../spinner-button/spinner-button";
import { generateScriptUrl } from "../../services/common-service";
import { showError, showSuccess } from "../../services/toast-service";
import DateSelectorToolbar from "../date-range-pickers/date-selector-toolbar";
import { useTranslation } from "react-i18next";

const PlusSpan = styled.span`
  font-size: 20px;
`;
const StyledIconDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  padding: 2px;
`;

const LeadservicePanel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [callInsights, setCallInsights] = useState();
  const [leadInsights, setLeadInsights] = useState();
  const [onHoldLeadsCount, setOnHoldLeadsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingLeadBlock, setLoadingLeadBlock] = useState(false);
  const [callInsightsLoading, setCallInsightsLoading] = useState(false);
  const [availableAfter, setAvailableAfter] = useState();
  const [showScriptDialog, setShowScriptDialog] = useState(false);
  const [scriptTitle, setScriptTitle] = useState("");

  const toggleUploadDialog = () => setShowScriptDialog(!showScriptDialog);

  const onEditClick = (title) => {
    toggleUploadDialog();
    setScriptTitle(title);
  };

  const fetchOnHoldLeadsCount = async () => {
    try {
      const response = await getOnHoldLeadsCount();
      setOnHoldLeadsCount(response.data.data);
    } catch (err) {
      console.log(err);
      showError(err.response?.data?.message);
    }
  };

  const fetchCallInsights = async () => {
    setCallInsightsLoading(true);
    try {
      const resp = await getCallInsights();
      if (resp) {
        const closedLeadsAverage =
          resp.data.data.closedLeadsResult.totalContactAttempts /
          resp.data.data.closedLeadsResult.totalCount;
        setCallInsights({
          openLeads: resp.data.data.contactAttemptsArray,
          closedLeadsAverage: Math.round(closedLeadsAverage * 100) / 100
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCallInsightsLoading(false);
    }
  };

  const fetchAvailableAfter = async () => {
    try {
      const resp = localStorage.getItem("userData");
      if (resp) {
        const data = JSON.parse(resp).workspace.availableAfterDelay;
        setAvailableAfter(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateLeadBlockTimeFunc = async () => {
    try {
      setLoadingLeadBlock(true);
      const resp = await updateLeadBlockTime({ availableAfter });
      if (resp.data.data) {
        dispatch(
          setMe({
            userData: {
              ...user,
              workspace: {
                ...user.workspace,
                availableAfterDelay: availableAfter
              }
            }
          })
        );
        showSuccess(resp.data.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingLeadBlock(false);
    }
  };

  const fetchLeadSyncRate = async (queryParams) => {
    if (loading) return;
    setLoading(true);
    try {
      const resp = await getLeadSyncRate(queryParams);
      if (resp) {
        setLeadInsights(resp.data.data);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const fetchData = ({ startDate, endDate }) =>
    fetchLeadSyncRate({
      startDate,
      endDate
    });

  useEffect(() => {
    fetchAvailableAfter();
    fetchCallInsights();
    fetchOnHoldLeadsCount();
  }, []);

  return (
    <div>
      <PageHeading>Leadservice Panel</PageHeading>

      <Container fluid className="mt-4 px-0">
        <Form.Group className="mb-4" controlId="vorlage">
          <Form.Label>{t("availableAfter")}</Form.Label>
          <div className="d-flex gap-2">
            <Form.Control
              type="number"
              className="w-25"
              placeholder={`Enter ${t("availableAfter")}`}
              // defaultValue={availableAfter}
              value={availableAfter}
              onChange={(e) => setAvailableAfter(e.target.value)}
            />
            {loadingLeadBlock ? (
              <SpinnerButton btnText={t("common.save")} />
            ) : (
              <Button
                disabled={loadingLeadBlock}
                onClick={updateLeadBlockTimeFunc}
              >
                {t("common.save")}
              </Button>
            )}
          </div>
        </Form.Group>

        <Row className="mb-4">
          <Col md={8} sm={12}>
            <CustomCard className="h-100">
              <Card.Header className="mb-3">
                <div className="fw-bold">{t("leadpannel.leadCountTitle")}</div>
              </Card.Header>
              <div className="h-100">
                {callInsightsLoading && (
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <Spinner animation="border" />
                  </div>
                )}

                <StackedBarChart
                  series={[
                    {
                      data: callInsights?.openLeads?.map(
                        (data) =>
                          data.leadCount -
                          data.availableAfterCount -
                          data.contactLaterCount
                      ),
                      name: "offene lead"
                    },
                    {
                      data: callInsights?.openLeads?.map(
                        (data) => data.availableAfterCount
                      ),
                      name: "verfügbar nach",
                      color: "#808080"
                    },
                    {
                      data: callInsights?.openLeads?.map(
                        (data) => data.contactLaterCount
                      ),
                      name: "Offene Anfrage",
                      color: "#808080"
                    }
                  ]}
                  categories={callInsights?.openLeads?.map(
                    (data) => `${data.contactAttempts}.${t("contact")}`
                  )}
                />
              </div>
            </CustomCard>
          </Col>
          <Col md={4} sm={12} className="d-flex flex-column gap-2">
            <div>
              <CustomCard>
                <Card.Header>
                  <div className="fw-bold">{t("averageCalls")}</div>
                </Card.Header>
                {callInsightsLoading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: 70 }}
                  >
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <div className="d-flex justify-content-between align-items-center px-4 py-3">
                    <div>
                      <div className="fw-bolder fs-5">
                        {callInsights?.closedLeadsAverage
                          ? callInsights?.closedLeadsAverage
                          : 0}
                      </div>
                      <div>{t("averageCalls")}</div>
                    </div>
                    <StyledIconDiv className="bg-primary">
                      <FontAwesomeIcon icon={faPhoneFlip} color={"white"} />
                    </StyledIconDiv>
                  </div>
                )}
              </CustomCard>
            </div>
            <div>
              <CustomCard>
                <Card.Header>
                  <div className="fw-bold">
                    {t("leadservicePanel.onHoldHeading")}
                  </div>
                </Card.Header>
                <div className="d-flex justify-content-between align-items-center px-4 py-3">
                  <div>
                    <div className="fw-bolder fs-5">
                      {onHoldLeadsCount || 0}
                    </div>
                    <div>{t("leadservicePanel.onHoldInfoMsg")}</div>
                  </div>
                  <StyledIconDiv className="bg-primary">
                    <FontAwesomeIcon icon={faCalendarDays} color={"white"} />
                  </StyledIconDiv>
                </div>
              </CustomCard>
            </div>
            <div>
              <CustomCard>
                <Card.Header className="mb-2 py-1 d-flex justify-content-between">
                  <div className="fw-bold align-self-center">
                    {t("leadpannel.leadRateTitle")}
                  </div>
                  <div>
                    <DateSelectorToolbar
                      options={PICKER_OPT_A}
                      fetchData={fetchData}
                      layout={PICKER_LAYOUT.DROPDOWN}
                      defaultSelected={DATE_FILTERS.TODAY}
                    />
                  </div>
                </Card.Header>

                {loading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: 200 }}
                  >
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <BarChart
                    columnWidth={"20%"}
                    isStrokeVisible
                    series={[
                      {
                        data: [leadInsights?.totalLeadCount],
                        name: t("leadpannel.totalLeads")
                      },
                      {
                        data: [leadInsights?.totalSyncCount],
                        name: t("leadpannel.syncedLeads")
                      }
                    ]}
                    categories={[
                      `${t("conversionRate")}: ${
                        leadInsights?.rate ? leadInsights?.rate : 0
                      }%`
                    ]}
                  />
                )}
              </CustomCard>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={12} lg={6}>
            <CustomCard>
              <Card.Header className="d-flex align-items-center justify-content-between">
                <div className="fw-bold">Leadservice {t("script")}</div>
                <Button
                  size="sm"
                  variant="success"
                  onClick={() => onEditClick(SCRIPT_ROUTE.LS)}
                >
                  {t("common.change")}
                </Button>
              </Card.Header>
              <div className="p-3">
                <PDFViewer
                  ariaLabel="default"
                  scriptUrl={generateScriptUrl({
                    workspace: user.workspace,
                    scriptProp: "leadServiceScript",
                    localFileName: "default.pdf"
                  })}
                />
              </div>
            </CustomCard>
          </Col>
          <Col md={12} lg={6}>
            <CustomCard>
              <Card.Header className="d-flex align-items-center justify-content-between">
                <div className="fw-bold">
                  Leadservice<PlusSpan>+</PlusSpan> {t("script")}
                </div>
                <Button
                  size="sm"
                  variant="success"
                  onClick={() => onEditClick(SCRIPT_ROUTE.LS_PLUS)}
                >
                  {t("common.change")}
                </Button>
              </Card.Header>
              <div className="p-3">
                <PDFViewer
                  ariaLabel="ls-plus"
                  scriptUrl={generateScriptUrl({
                    workspace: user.workspace,
                    scriptProp: "leadServicePlusScript",
                    localFileName: "leadservice_plus.pdf"
                  })}
                />
              </div>
            </CustomCard>
          </Col>
        </Row>
        <UploadScriptDialog
          title={scriptTitle}
          show={showScriptDialog}
          toggle={toggleUploadDialog}
        />
      </Container>
    </div>
  );
};
export default LeadservicePanel;
