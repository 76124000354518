import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import { Form, Tooltip, FormGroup, OverlayTrigger } from "react-bootstrap";
import ValidationMessage from "../validation-message/validation-message";

const FCFormGroup = styled(FormGroup)`
  ${(props) => {
    return (
      props.isingroup === "true" &&
      `display: flex;
      flex : 1`
    );
  }}
`;
const FCTextField = styled(Form.Control)`
  ${(props) => {
    return (
      props.isingroup === "true" &&
      `border-top-right-radius: 0;
      border-bottom-right-radius: 0;`
    );
  }}
`;

const TextField = (props) => {
  const { register } = useFormContext();

  return (
    <FCFormGroup
      className={!props.noSpacing ? "mb-3" : ""}
      controlId={`${props.label} ${props.name}`}
      // isingroup={props.isInGroup ? "true" : "false"}
    >
      <div className="d-flex">
        {props.label && (
          <Form.Label
            className={`${props.boldLabel ? "fw-bold" : "fw-normal"} ${
              props.errors ? "text-danger" : ""
            }`}
          >
            {props.label}
            {props.errors && "*"}
          </Form.Label>
        )}
        {props.setDisplay && (
          <div className="ms-2">
            <OverlayTrigger
              overlay={
                <Tooltip>
                  {props.fieldWatcher ? "Set Color" : "Transparent"}
                </Tooltip>
              }
            >
              <Form.Check
                id="display-switch"
                role="button"
                type="switch"
                {...register("design.companyLogoBackgroundDisplay")}
              />
            </OverlayTrigger>
          </div>
        )}
      </div>
      <FCTextField
        {...(props.register && { ...props.register(props.name) })}
        isingroup={props.isInGroup ? "true" : "false"}
        type={props.type}
        placeholder={props.placeholder}
        className={`form-control ${props.errors ? "border-danger" : ""}`}
        disabled={
          props.disabled || (props.setDisplay && Boolean(!props.fieldWatcher))
        }
        onWheel={({ target }) => {
          if (props.blurOnScroll) {
            target.blur();
          }
        }}
        size={props.inputSize || "md"}
      />
      {props.errors && !props.hideErrMsg && (
        <ValidationMessage
          error={props.errors?.message}
          height={props.messageHeight}
        />
      )}
    </FCFormGroup>
  );
};

export default TextField;
