import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SpinnerButton from "../spinner-button/spinner-button";

const SubmitButton = ({ isSubmitting, btnText, spinnerBtnText, icon }) => {
  return (
    <>
      {isSubmitting ? (
        <SpinnerButton btnText={spinnerBtnText} btnStyle="btn-outline-dark" />
      ) : (
        <Button type="submit" variant="outline-dark" disabled={isSubmitting}>
          {btnText}
          <FontAwesomeIcon icon={icon} className="ms-2" />
        </Button>
      )}
    </>
  );
};

export default SubmitButton;
