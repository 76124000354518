import {
  faBoxArchive,
  faPlusCircle,
  faTrashCanArrowUp
} from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { Button, Card, Tab, Tabs } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UploadModal from "./upload";
import Loader from "../../loader/loader";
import {
  getSuggestions,
  archiveOrUnarchiveMultipleSuggestions
} from "../../../services/api-declaration";
import { CustomCard } from "../../common-styling";
import { DISPLAY_SUGGESTIONS } from "../../strings";
import { useDialog } from "../../../hooks/useDialog";
import CustomPagination from "../../pagination/custom-pagination";
import SuggestionGalleryContent from "./suggestion-gallery-content";
import { showError, showSuccess } from "../../../services/toast-service";
import { Trans, useTranslation } from "react-i18next";

const SuggestionGallery = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { showConfirmationDialogBox, setDependentStates } = useDialog();
  const { ARCHIVED_ONES, WINNING_ONES, NORMAL_ONES } = DISPLAY_SUGGESTIONS;

  const [isLoading, setIsLoading] = useState(true);
  const [paginateOpts, setPaginateOpt] = useState({});
  const [sugesstions, setSuggestions] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const isArchivedActive = searchParams.get("archived") === "true";
  const isWinningActive = searchParams.get("winning-ads") === "true";

  const toggleUpload = () => setShowUploadModal(!showUploadModal);
  const toggleConfirmationDialog = () =>
    showConfirmationDialogBox({
      title: t("suggestionGallery.archiveModal.multiHeading", {
        what: isArchivedActive ? t("common.unarchive") : t("common.archive")
      }),
      dialogType: isArchivedActive ? "primary" : "danger",
      dialogMessage: renderDialogMessage(),
      responseAction: dialogResponseAction
    });
  const renderDialogMessage = () => (
    <div>
      <Trans
        i18nKey={t("suggestionGallery.archiveModal.multiMsg", {
          what: isArchivedActive ? t("common.unarchive") : t("common.archive")
        })}
      />
    </div>
  );
  const dialogResponseAction = async (action, values) => {
    if (action && values.hasOwnProperty.call(values, "id")) {
      handleUpdateArchiveMulti(values.id);
    }
  };
  const handleUpdateArchiveMulti = async (ids) => {
    try {
      const response = await archiveOrUnarchiveMultipleSuggestions({
        selectedIds: ids,
        shouldArchive: !isArchivedActive
      });
      showSuccess(response.data.message);
      setSelectedIds([]);
      fetchSuggestions();
    } catch (error) {
      console.log("Error: ", error);
      showError(
        error.response?.data?.message || isArchivedActive
          ? t("toastMessage.unarchiveError")
          : t("toastMessage.archiveError")
      );
    }
  };
  const handleMultiDelete = () => {
    setDependentStates({ id: selectedIds });
    toggleConfirmationDialog();
  };
  const handleQueryParams = (property, value) => {
    searchParams.set(property, value);
    setSearchParams(searchParams);
  };
  const clearAllParams = () => {
    searchParams.delete("pageIndex");
    searchParams.delete("archived");
    searchParams.delete("winning-ads");
    setSearchParams(searchParams);
  };
  const fetchSuggestions = async () => {
    try {
      setIsLoading(true);
      const params = {};
      for (const [key, value] of searchParams.entries()) {
        params[key] = value;
      }
      const response = await getSuggestions(params);
      const respData = response.data.data;
      setSuggestions(respData.rows);
      setPaginateOpt({
        totalPages: respData.totalPages,
        hasPrevPage: respData.hasPrevPage,
        hasNextPage: respData.hasNextPage,
        totalDocs: respData.totalDocs,
        rowsOnPage: respData.rows.length
      });
    } catch (error) {
      console.log("ERROR: ", error);
      showError(
        error.response?.data.message ||
          t("toastMessage.errorInGetting", { what: "Suggestions" })
      );
    } finally {
      setIsLoading(false);
    }
  };
  const handleSelectTab = (evKey) => {
    setSelectedIds([]);
    clearAllParams();
    if (evKey === ARCHIVED_ONES) {
      handleQueryParams("archived", true);
    } else if (evKey === WINNING_ONES) {
      handleQueryParams("winning-ads", true);
    }
  };

  const fetchSuggestionsCB = useCallback(
    fetchSuggestions,
    // eslint-disable-next-line
    [searchParams]
  );

  useEffect(() => {
    fetchSuggestionsCB();
  }, [fetchSuggestionsCB]);

  return (
    <div className="mt-3 w-100">
      <div className="mt-3" />
      <CustomCard className="mb-3 p-3 rounded-0">
        <Card.Header className="bg-white mb-3 sticky-top">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <h5>{t("adEvaluation.suggestionGallery")}</h5>
              <Tabs
                className="ms-3 mb-2 border-bottom"
                onSelect={(evKey) => handleSelectTab(evKey)}
                activeKey={
                  isArchivedActive
                    ? ARCHIVED_ONES
                    : isWinningActive
                      ? WINNING_ONES
                      : NORMAL_ONES
                }
              >
                <Tab
                  eventKey={NORMAL_ONES}
                  title={t("suggestionGallery.currentTabLabel")}
                />
                <Tab eventKey={ARCHIVED_ONES} title={t("common.archive")} />
                <Tab eventKey={WINNING_ONES} title={"Winning Ads"} />
              </Tabs>
            </div>
            <div>
              {selectedIds.length ? (
                <Button
                  className="me-2"
                  variant={
                    isArchivedActive ? "outline-primary" : "outline-danger"
                  }
                  onClick={handleMultiDelete}
                >
                  <FontAwesomeIcon
                    icon={isArchivedActive ? faTrashCanArrowUp : faBoxArchive}
                    className="me-2"
                  />
                  {isArchivedActive
                    ? t("common.unarchive")
                    : t("common.archive")}
                </Button>
              ) : null}
              <Button
                variant="success"
                onClick={toggleUpload}
                disabled={isArchivedActive}
              >
                <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
                {t("suggestionGallery.upload")}
              </Button>
            </div>
          </div>
        </Card.Header>
        {isLoading ? (
          <Loader colSpan={8} />
        ) : !sugesstions?.length ? (
          <h6 className="mb-0 fw-bold p-5 align-self-center">{t("noData")}</h6>
        ) : (
          <SuggestionGalleryContent
            selectedIds={selectedIds}
            collection={sugesstions}
            setSelectedIds={setSelectedIds}
            fetchSuggestions={fetchSuggestions}
          />
        )}
      </CustomCard>
      {sugesstions.length ? (
        <CustomPagination
          paginateOpts={paginateOpts}
          activeTab={Number(searchParams.get("pageIndex")) || 1}
          select={(val) => handleQueryParams("pageIndex", val)}
        />
      ) : null}
      {showUploadModal && (
        <UploadModal
          toggleUpload={toggleUpload}
          fetchSuggestions={fetchSuggestions}
        />
      )}
    </div>
  );
};

export default SuggestionGallery;
