import styled from "styled-components";
import { Modal, Button } from "react-bootstrap";
import { createSearchParams, useNavigate } from "react-router-dom";
import { FUNNEL_TYPE_FILTER } from "../strings";
import { useTranslation } from "react-i18next";

const ModalActionButton = styled(Button)`
  width: 200px;
  border: none;
  border-radius: 0;
  background: ${({ btncolor }) => `var(${btncolor})`};
  &:hover {
    background: ${({ btncolor }) => `var(${btncolor})`};
  }
  &:focus {
    background: ${({ btncolor }) => `var(${btncolor})`};
  }
`;

const CreateNewModal = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateToCreateNew = (type) => {
    props.onHide();
    navigate(
      {
        pathname: props.url,
        search: createSearchParams({
          [props.queryparams[0]]: type
        }).toString()
      },
      {
        state: {
          nestedSideNav: true,
          navigatedCustomer: props.customer ? props.customer : null
        }
      }
    );
  };
  return (
    <Modal {...props} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title className="h5" id="contained-modal-title-vcenter">
          {t("createFunnelModal.title", { type: t(props.modaltype) })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center pb-2 pt-4">
        <ModalActionButton
          size="lg"
          className="me-3"
          btncolor={"--lead-color"}
          onClick={() => navigateToCreateNew(FUNNEL_TYPE_FILTER.LEAD)}
        >
          {t("createFunnelModal.actions.lead")}
        </ModalActionButton>
        <ModalActionButton
          size="lg"
          btncolor={"--employee-color"}
          onClick={() => navigateToCreateNew(FUNNEL_TYPE_FILTER.EMPLOYEE)}
        >
          {t("createFunnelModal.actions.employee")}
        </ModalActionButton>
      </Modal.Body>
      <Modal.Footer className="py-4"></Modal.Footer>
    </Modal>
  );
};
export default CreateNewModal;
