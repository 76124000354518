import { useState } from "react";
import styled from "styled-components";
import {
  faEye,
  faPlus,
  faBoxArchive,
  faTrashCanArrowUp
} from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";
import { Form, Button, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  setIsWinningSuggestion,
  archiveOrUnarchiveSuggestion
} from "../../../services/api-declaration";
import AddLinkDialog from "./add-link-dialog";
import ListDialog from "./tested-links-dialog";
import { CustomCard } from "../../common-styling";
import { TESTED_LINK_STATUS } from "../../strings";
import { useDialog } from "../../../hooks/useDialog";
import { showError, showSuccess } from "../../../services/toast-service";
import { Trans, useTranslation } from "react-i18next";

const SuggestionImageContainer = styled.div`
  border: 1px solid #dee2e6;
  height: 237px;
`;
const SuggestionImage = styled.img`
  height: 100%;
  object-fit: contain;
`;
const StyledCard = styled(CustomCard)`
  border-width: ${({ ids, id }) => (ids.includes(id) ? "2px" : "1px")};
  border-color: ${({ ids, id, archived }) =>
    ids.includes(id)
      ? !archived
        ? "var(--danger-color)"
        : archived
          ? "var(--primary-color)"
          : ""
      : ""};
`;
const Checkbox = styled(Form.Check)`
  margin-top: 2px;
`;
const PercentageBadge = styled(Badge)`
  text-transform: capitalize;
  font-size: 10px;
  color: ${({ btncolor }) => `var(${btncolor})`};
  background-color: ${({ btnbg }) => `var(${btnbg})`} !important;
`;

const ImageCard = ({ cardData, methods }) => {
  const { data, index, selectedIds } = cardData;
  const { fetchSuggestions, togglePreviewModal, setSelectedIds } = methods;
  const { NEUTRAL, POSITIVE, NEGATIVE } = TESTED_LINK_STATUS;
  const nonNeutralLinks = data.testedLinks.filter(
    (link) => link.status !== NEUTRAL
  );
  const { t } = useTranslation();
  const { showConfirmationDialogBox, setDependentStates } = useDialog();
  const [showLinksModal, setShowLinksModal] = useState(false);
  const [showAddLinkModal, setShowAddLinkModal] = useState(false);
  const [searchParams] = useSearchParams();
  const isArchivedSelected = searchParams.get("archived");
  const isWinningSelected = searchParams.get("winning-ads");

  const toggleLinksModal = () => setShowLinksModal(!showLinksModal);
  const toggleAddLinkModal = () => setShowAddLinkModal(!showAddLinkModal);
  const toggleConfirmationDialog = ({
    forArchive = false,
    forWinning = false
  }) =>
    showConfirmationDialogBox({
      title: forArchive
        ? t("suggestionGallery.archiveModal.heading", {
            what: data?.archive ? t("common.unarchive") : t("common.archive")
          })
        : forWinning
          ? t("common.confirm")
          : "",
      dialogType: forArchive
        ? data?.archive
          ? "primary"
          : "danger"
        : forWinning
          ? "success"
          : "",
      dialogMessage: renderDialogMessage({ forArchive, forWinning }),
      responseAction: dialogResponseAction
    });
  const renderDialogMessage = ({ forArchive = false, forWinning = false }) => (
    <div>
      {forArchive ? (
        <Trans
          i18nKey={t("suggestionGallery.archiveModal.msg", {
            what: data?.archive ? t("common.unarchive") : t("common.archive")
          })}
        />
      ) : forWinning ? (
        <Trans
          i18nKey={t("suggestionGallery.winningModal.msg", {
            what: data?.isWinningAd
              ? t("suggestionGallery.winningModal.remove")
              : t("suggestionGallery.winningModal.mark")
          })}
        />
      ) : (
        ""
      )}
    </div>
  );
  const dialogResponseAction = async (action, values) => {
    if (action && values.hasOwnProperty.call(values, "id")) {
      if (values.forArchive) {
        handleUpdateArchive(values.id);
      } else if (values.forWinning) {
        handleUpdateIsWinning(values.id);
      }
    }
  };
  const handleUpdateIsWinning = async (id) => {
    try {
      const response = await setIsWinningSuggestion(id, {
        isWinningAd: !data.isWinningAd
      });
      showSuccess(response.data.message);
      fetchSuggestions();
    } catch (error) {
      console.log("Error in setting winning suggestion: ", error);
      showError(error.response?.data?.message);
    }
  };
  const handleUpdateArchive = async (id) => {
    try {
      const response = await archiveOrUnarchiveSuggestion(id, {
        shouldArchive: !data.archive
      });
      showSuccess(response.data.message);
      fetchSuggestions();
    } catch (error) {
      console.log("Error in archiving suggestion: ", error);
      showError(
        error.response?.data?.message || t("toastMessage.archiveError")
      );
    }
  };
  const handleDeleteClick = (imageId) => {
    setDependentStates({ id: imageId, forArchive: true });
    toggleConfirmationDialog({ forArchive: true });
  };
  const handleIsWinning = (imageId) => {
    setDependentStates({ id: imageId, forWinning: true });
    toggleConfirmationDialog({ forWinning: true });
  };
  const handleSelectionChange = (_checked, dataId) => {
    let ids = [...selectedIds];
    if (_checked) {
      ids.push(dataId);
    } else {
      ids = selectedIds.filter((selectedId) => selectedId !== dataId);
    }
    setSelectedIds(ids);
  };
  const calculatePositives = () => {
    const positiveLinks = nonNeutralLinks.filter(
      (link) => link.status === POSITIVE
    );
    return nonNeutralLinks.length && positiveLinks.length
      ? `${Math.round((positiveLinks.length / nonNeutralLinks.length) * 100)}%`
      : null;
  };
  const calculateNegatives = () => {
    const negativeLinks = nonNeutralLinks.filter(
      (link) => link.status === NEGATIVE
    );
    return nonNeutralLinks.length && negativeLinks.length
      ? `${Math.round((negativeLinks.length / nonNeutralLinks.length) * 100)}%`
      : null;
  };

  return (
    <>
      <StyledCard
        ids={selectedIds}
        id={data.id}
        archived={data.archive ? "true" : undefined}
      >
        <div className="p-2">
          <div className="d-flex align-items-center justify-content-between">
            <a href={data.link} target="_blank" rel="noreferrer">
              {t("suggestionGallery.adLink")}
            </a>
            <div className="d-flex align-items-center">
              <Checkbox
                role="button"
                type="checkbox"
                className="me-2"
                id={`select-multi-${data.id}`}
                onChange={({ target: { checked } }) =>
                  handleSelectionChange(checked, data.id)
                }
                disabled={Boolean(isWinningSelected)}
              />
              <Button
                size="sm"
                variant="outline-success"
                className="rounded-circle border-0"
                onClick={() => handleIsWinning(data.id)}
                disabled={
                  selectedIds.includes(data.id) || Boolean(isArchivedSelected)
                }
              >
                <FontAwesomeIcon
                  icon={data.isWinningAd ? faTrashCanArrowUp : faBoxArchive}
                />
              </Button>
              <Button
                size="sm"
                variant={data.archive ? "outline-primary" : "outline-danger"}
                className="rounded-circle border-0"
                onClick={() => handleDeleteClick(data.id)}
                disabled={
                  selectedIds.includes(data.id) || Boolean(isWinningSelected)
                }
              >
                <FontAwesomeIcon
                  icon={data.archive ? faTrashCanArrowUp : faBoxArchive}
                />
              </Button>
              <Button
                size="sm"
                variant="outline-primary"
                className="rounded-circle border-0"
                onClick={() => togglePreviewModal(data)}
              >
                <FontAwesomeIcon icon={faEye} />
              </Button>
            </div>
          </div>
          <SuggestionImageContainer className="my-2">
            <SuggestionImage
              className="w-100"
              src={`${data.url}/${data.key}`}
              alt={`suggestion-asset-${index}`}
            />
          </SuggestionImageContainer>
          <div className="d-flex align-items-center justify-content-between my-3">
            <div>
              <span className="fw-bold me-1">
                {t("suggestionGallery.count")}:
              </span>
              {data.testedLinks?.length}
            </div>
            <div>
              <PercentageBadge
                btnbg={`--lead-success-badge-bg`}
                btncolor={`--lead-success-color`}
              >
                {calculatePositives()}
              </PercentageBadge>
              <PercentageBadge
                className="ms-1"
                btnbg={`--lead-danger-badge-bg`}
                btncolor={`--lead-danger-color`}
              >
                {calculateNegatives()}
              </PercentageBadge>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <Button
              size="sm"
              className="me-2"
              variant="outline-secondary"
              onClick={toggleLinksModal}
              disabled={!data.testedLinks.length}
            >
              {t("suggestionGallery.testedLinksButtonText")}
            </Button>
            <Button
              variant="dark"
              onClick={toggleAddLinkModal}
              className="rounded-circle fs-5"
              disabled={data.archive || data.isWinningAd}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </div>
      </StyledCard>
      <ListDialog
        suggestion={data}
        show={showLinksModal}
        onHide={toggleLinksModal}
      />
      <AddLinkDialog
        suggestion={data}
        show={showAddLinkModal}
        onHide={toggleAddLinkModal}
      />
    </>
  );
};
export default ImageCard;
