import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  TableRow,
  NameLink,
  CustomCard,
  FirstHeaderColumn,
  HeaderColumn,
  LastHeaderColumn,
  BootstrapTable,
  TableDetailCell,
  PageHeading
} from "../common-styling";
import { faLock, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateUser from "../user/create-user";
import TableLoader from "../loader/table-loader";
import NoResultsRow from "../no-results-table-row";
import { showError } from "../../services/toast-service";
import AdvancedFilters from "../table-filter/advanced-filter";
import CustomPagination from "../pagination/custom-pagination";
import { fetchAllUsers } from "../../services/api-declaration";
import { userFilterConfig } from "../funnels-table/filter-config";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const UsersTable = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState(true);
  const [paginateOpts, setPaginateOpts] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [show, setShow] = useState(false);

  const handleQueryParams = (property, value) => {
    searchParams.set(property, value);
    setSearchParams(searchParams);
  };
  const toggleModal = () => setShow(!show);
  const onCreate = (userData) => {
    setUsers((prev) => [userData, ...prev]);
    toggleModal();
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoading(true);
        const params = {};
        for (const [key, value] of searchParams.entries()) {
          params[key] = value;
        }
        const response = await fetchAllUsers(params);
        const data = response.data.data;
        setUsers(data.rows);
        setPaginateOpts({
          totalPages: data.totalPages,
          hasPrevPage: data.hasPrevPage,
          hasNextPage: data.hasNextPage,
          totalDocs: data.totalDocs,
          rowsOnPage: data.rows.length
        });
      } catch (error) {
        showError(
          error?.response?.data?.message ||
            t("toastMessage.errorInGetting", {
              what: t("common.user", { count: 2 })
            })
        );
      } finally {
        setIsLoading(false);
      }
    };
    fetchUsers();
    // eslint-disable-next-line
  }, [searchParams]);

  return (
    <>
      <div>
        {/* <Filter
          pageType=""
          heading="User"
          pageName="name"
          btnText={t("common.createNew.title", {
            name: "User",
          })}
          isResourceTypeAvailable={false}
          placeholder={t("userSearchPlaceholder")}
          handleModal={toggleModal}
        /> */}
        <PageHeading className="">{t("sidebar.users")}</PageHeading>
        <div className="mt-3">
          <AdvancedFilters
            components={userFilterConfig}
            pageType=""
            pageName="name"
            btnText={t("common.createNew.title", {
              name: t("common.user").toLowerCase()
            })}
            isResourceTypeAvailable={false}
            placeholder={t("userSearchPlaceholder")}
            handleModal={toggleModal}
          />
        </div>
        <CustomCard className="mt-4 mb-3 p-3 rounded-0">
          <BootstrapTable responsive>
            <thead>
              <tr>
                <FirstHeaderColumn>{t("common.email")}</FirstHeaderColumn>
                <HeaderColumn>{t("common.firstName")}</HeaderColumn>
                <HeaderColumn>{t("common.lastName")}</HeaderColumn>
                <HeaderColumn>{t("common.role")}</HeaderColumn>
                <HeaderColumn>Status</HeaderColumn>
                <LastHeaderColumn>2FA</LastHeaderColumn>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <TableLoader colSpan={8} />
              ) : users.length ? (
                users.map((item) => (
                  <TableRow key={item._id}>
                    <TableDetailCell className="py-3">
                      <NameLink to={item._id}>{item.email}</NameLink>
                    </TableDetailCell>
                    <TableDetailCell className="py-3">
                      {item.firstName}
                    </TableDetailCell>
                    <TableDetailCell className="py-3">
                      {item.lastName}
                    </TableDetailCell>
                    <TableDetailCell className="text-capitalize py-3">
                      {item.role}
                    </TableDetailCell>
                    <TableDetailCell className="text-capitalize py-3">
                      {item.status?.toLowerCase()}
                    </TableDetailCell>
                    <TableDetailCell className="text-capitalize py-3">
                      {item?.multiFactorEnabled?.email && (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip">
                              {t("multifactor.emailAuth")}
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon className="me-2" icon={faEnvelope} />
                        </OverlayTrigger>
                      )}{" "}
                      {item?.multiFactorEnabled?.authenticator && (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip">
                              {t("multifactor.appAuth")}
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon className="me-2" icon={faLock} />
                        </OverlayTrigger>
                      )}
                    </TableDetailCell>
                  </TableRow>
                ))
              ) : (
                <NoResultsRow lengthOfColumns="5" />
              )}
            </tbody>
          </BootstrapTable>
        </CustomCard>
        <CustomPagination
          paginateOpts={paginateOpts}
          activeTab={Number(searchParams.get("pageIndex")) || 1}
          select={(val) => handleQueryParams("pageIndex", val)}
        />
      </div>
      {show && <CreateUser onCreate={onCreate} onClose={toggleModal} />}
    </>
  );
};

export default UsersTable;
