import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import ImageCard from "./image-card";
import PreviewDialog from "./preview-dialog";

const SuggestionGalleryContent = ({
  collection,
  selectedIds,
  setSelectedIds,
  fetchSuggestions
}) => {
  const [showPreview, setShowPreview] = useState(false);
  const [modalData, setModalData] = useState({ url: null, note: "" });

  const togglePreviewModal = (item) => {
    if (item) {
      setModalData({ url: `${item.url}/${item.key}`, note: item.note });
    } else {
      setModalData({ url: null, note: "" });
    }
    setShowPreview(!showPreview);
  };

  return (
    <>
      <Row className="g-3">
        {collection.map((data, index) => (
          <Col md={2} sm={6} xs={12} key={index}>
            <ImageCard
              cardData={{ data, index, selectedIds }}
              methods={{ togglePreviewModal, fetchSuggestions, setSelectedIds }}
            />
          </Col>
        ))}
      </Row>
      <PreviewDialog
        data={modalData}
        show={showPreview}
        onHide={togglePreviewModal}
      />
    </>
  );
};
export default SuggestionGalleryContent;
