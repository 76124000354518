import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
  Badge,
  Alert
} from "react-bootstrap";
import {
  TableRow,
  HeaderColumn,
  BootstrapTable,
  TableDetailCell,
  LastHeaderColumn,
  FirstHeaderColumn,
  TableDetailNameCell,
  CustomCard,
  TableDetailSwitchCell
} from "../common-styling";
import {
  faCircleCheck,
  faArrowUpFromBracket,
  faCircleExclamation,
  faEye,
  faBan,
  faTrash,
  faUpload,
  faSearch,
  faPaste,
  faRepeat,
  faEraser,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import { useDialog } from "../../hooks/useDialog";
import NoResultsRow from "../no-results-table-row";
import TextField from "../reusable-form-fields/text-field";
import {
  bytesToSize,
  generateUniqueNumber
} from "../../services/common-service";
import ImageWorker from "../web-worker/image-worker";
import ImagePreviewModal from "../funnel-form/preview-image-modal";
import { showError, showSuccess } from "../../services/toast-service";
import {
  isAdValid,
  deleteAdImage,
  upoadImageToFacebook,
  duplicateAd
} from "../../services/api-declaration";
import { AdDuplicatorScehma } from "../../services/validation-schema";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CheckBoxSwitch from "../reusable-form-fields/checkbox-switch";

const DropArea = styled.div`
  border: 1px dashed;
  border-color: ${({ fielerror }) => `${fielerror ? "red" : "#80808099"}`};
  cursor: pointer;
  padding: 10px;
  border-radius: 6px;
  color: #808080bd;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  width: 100%;
  background-color: ${({ disabled }) => `${disabled && "#f1f1f1"}`};
  & img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;
const ImageContainer = styled.div`
  height: 40px;
  width: 40px;
  & img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 5px;
  }
`;

const AdDuplicator = () => {
  const workspace = useSelector((state) => state.auth.user.workspace);
  const { t } = useTranslation();
  const [url, setUrl] = useState(null);
  const [files, setFiles] = useState([]);
  // const [fileError, setFileError] = useState(false);
  const [isLoading, setIsLoading] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const { showConfirmationDialogBox, setDependentStates } = useDialog();
  const [validAd, setValidAd] = useState(undefined);

  const tokenUser = workspace.fbTokens.find(
    ({ isPrimary }) => isPrimary
  )?.userId;

  const adUploadingStatus = {
    waiting: {
      id: "waiting",
      status: t("adDuplicator.adUploadingStatus.waiting.status"),
      variant: "secondary",
      message: t("adDuplicator.adUploadingStatus.waiting.message")
    },
    uploading: {
      id: "uploading",
      status: t("adDuplicator.adUploadingStatus.uploading.status"),
      variant: "primary",
      message: t("adDuplicator.adUploadingStatus.uploading.message")
    },
    success: {
      id: "success",
      status: t("adDuplicator.adUploadingStatus.success.status"),
      variant: "success",
      message: t("adDuplicator.adUploadingStatus.success.message")
    },
    rejected: {
      id: "rejected",
      status: t("adDuplicator.adUploadingStatus.rejected.status"),
      variant: "danger",
      message: ""
    }
  };

  const status = { active: "ACTIVE", pause: "PAUSED" };

  const isDuplicateAdDisabled =
    !validAd ||
    isLoading ||
    files.every(
      ({ adStatus, fileStatus }) =>
        adStatus.id === adUploadingStatus.success.id || fileStatus.loading
    );

  const methods = useForm({
    defaultValues: {
      viewId: ""
    },
    resolver: yupResolver(AdDuplicatorScehma)
  });

  const {
    control,
    register,
    getValues,
    setError,
    handleSubmit,
    setValue,
    formState: { errors }
  } = methods;

  const processFileStatus = (error) => {
    return {
      loading: false,
      status: error ? false : true,
      code: error ? error.message : 200,
      message: error ? error.message : "Image successfully uploded to facebook."
    };
  };

  const processValidFiles = (prevFiles, updatedFile, error) => {
    return prevFiles.map((prevFile) => {
      if (prevFile.id === updatedFile.id) {
        return {
          ...prevFile,
          name: prevFile.name,
          size: prevFile.size,
          imageHash: updatedFile.imageHash,
          fileStatus: processFileStatus(error),
          adId: updatedFile?.adId,
          adStatus: error
            ? { ...adUploadingStatus.rejected, message: error.message }
            : !updatedFile?.adStatus
              ? adUploadingStatus.waiting
              : updatedFile.adStatus
        };
      }
      return prevFile;
    });
  };

  const handleUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("image", file);
      const config = {};
      const { data } = await upoadImageToFacebook(
        validAd.adAccountId,
        formData,
        config
      );
      if (data) {
        setFiles((prevFiles) =>
          processValidFiles(prevFiles, {
            ...file,
            imageHash: data.data.imageHash
          })
        );
      }
    } catch (err) {
      console.log(err);
      setFiles((prevFiles) =>
        processValidFiles(
          prevFiles,
          file,
          err.response?.data || {
            message: "Could not connect to server!"
          }
        )
      );
    }
  };

  const isValidFile = (file) => {
    if (file?.errors) {
      return {
        status: false,
        code: file.errors[0].code,
        message: file.errors[0].message,
        loading: false
      };
    } else {
      return {
        status: true,
        code: "",
        message: "",
        loading: true
      };
    }
  };

  const processFile = (file) => {
    const fileStatus = isValidFile(file);
    let src;
    let tempFile;
    if (file?.errors) {
      src = URL.createObjectURL(file.file);
      tempFile = file.file;
    } else {
      src = URL.createObjectURL(file);
      tempFile = file;
    }
    const id = generateUniqueNumber(6);

    return Object.assign(
      tempFile,
      { src },
      {
        dimension: `${0} x ${0}`,
        id,
        fileStatus,
        adStatus: !fileStatus.status && {
          ...adUploadingStatus.rejected,
          message: fileStatus.message
        },
        status: status.active
      }
    );
  };

  const onDrop = async (acceptedFiles, rejectedFiles) => {
    let allFiles = [...acceptedFiles, ...rejectedFiles];
    const processedFiles = allFiles.map(processFile);
    setFiles((prevFiles) => [...processedFiles, ...prevFiles]);
    for (const file of processedFiles) {
      if (file.fileStatus.status) {
        await handleUpload(file);
      }
    }
    // setFileError(false);
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      onError: (error) => console.log(error),
      disabled: !validAd,
      maxSize: 20000000,
      onDrop,
      accept: {
        "image/*": [".png", ".gif", ".jpeg", ".jpg"]
      },
      multiple: true
    });

  const onClearAllAds = async () => {
    const filesWithHashes = files.filter(
      (file) =>
        file.imageHash &&
        (adUploadingStatus.waiting.id, adUploadingStatus.rejected.id).includes(
          file.adStatus.id
        )
    );
    setFiles([]);
    setValidAd(undefined);

    for (const file of filesWithHashes) {
      handleDelete(file);
    }
  };
  let adCounter = 1;
  const createDuplicateAd = async (file) => {
    const payload = {
      name: `${t("adName")} ${adCounter}`,
      status: file.status,
      adId: validAd.adId,
      adCreativeId: validAd.adCreativeId,
      adAccountId: validAd.adAccountId,
      imageHash: file.imageHash
    };
    setFiles((prevFiles) =>
      processValidFiles(prevFiles, {
        ...file,
        adStatus: adUploadingStatus.uploading
      })
    );
    try {
      adCounter += 1;
      const resp = await duplicateAd(payload);
      if (resp) {
        setFiles((prevFiles) =>
          processValidFiles(prevFiles, {
            ...file,
            adStatus: adUploadingStatus.success,
            adId: resp.data.data
          })
        );
      } else {
        throw Error("connection failed");
      }
    } catch (err) {
      setFiles((prevFiles) =>
        processValidFiles(prevFiles, {
          ...file,
          adStatus: {
            ...adUploadingStatus.rejected,
            message: (err.response.data || err)?.message
          }
        })
      );
    }
  };

  const onReplayDuplicateAd = async (file) => {
    setIsLoading("DUPLICATE");
    await createDuplicateAd(file);

    setIsLoading("");
  };

  const onDuplicateAds = async () => {
    // if (files.length === 0) {
    //   setFileError(true);
    //   console.log(fileError);
    //   return;
    // }
    // if (isLoading) return;

    setIsLoading("DUPLICATE");
    const filesWithHashes = files.filter(
      (file) =>
        file.imageHash && file.adStatus.id === adUploadingStatus.waiting.id
    );
    try {
      await Promise.all(filesWithHashes.map(createDuplicateAd));
    } finally {
      setIsLoading("");
    }
  };

  const handlePreview = (src) => {
    if (src) {
      setUrl(src);
    } else {
      setUrl(null);
    }
    setShowPreview(!showPreview);
  };

  const toggleDialog = () =>
    showConfirmationDialogBox({
      title: t("common.confirmDeletion"),
      dialogMessage: renderDialogMessage(),
      dialogType: "danger",
      responseAction: dialogResponseAction
    });

  const renderDialogMessage = () => (
    <div dangerouslySetInnerHTML={{ __html: t("deleteImageMessage") }}></div>
  );

  const handleDeleteIconClick = (file) => {
    setDependentStates({ selectedFile: file });
    toggleDialog();
  };

  const handleDelete = async (file) => {
    try {
      if (file.imageHash) {
        const resp = await deleteAdImage(validAd.adAccountId, file.imageHash);
        if (!resp?.data?.data.status) {
          throw Error("not connected");
        }
      }
      setFiles((files) => files.filter((item) => item.id !== file.id));
      // showSuccess("Image removed succesfully");
    } catch (err) {
      showError((err.response?.data || err).message);
    }
  };

  const dialogResponseAction = (action, values) => {
    if (action && Object.keys(values.selectedFile).length) {
      handleDelete(values.selectedFile);
    }
  };

  const pasteAndValidateAdId = async () => {
    let cliboardText;
    try {
      cliboardText = await navigator.clipboard.readText();
    } catch (error) {
      console.log(error);
      showError(t("clipBoardNotAllowedMessage"));
      return;
    }
    setValue("viewId", cliboardText);
    handleSubmit(onAdValidityCheck)(getValues());
  };

  const onAdValidityCheck = async ({ viewId: adId }, _, confirmedRemoval) => {
    if (isLoading) return;

    try {
      const hasPendingUpload = files.some(
        ({ adStatus }) => adStatus.id !== adUploadingStatus.success.id
      );
      if (!confirmedRemoval && hasPendingUpload) {
        showConfirmationDialogBox({
          title: t("clearAdDuplicatorFilesTitle"),
          dialogMessage: (
            <div
              dangerouslySetInnerHTML={{ __html: t("clearAdDuplicatorFiles") }}
            ></div>
          ),
          dialogType: "danger",
          responseAction: (shouldProceed) => {
            if (shouldProceed) {
              onAdValidityCheck(getValues(), undefined, true);
            }
          }
        });
        return;
      }
      onClearAllAds();
      setIsLoading("VALIDATE");
      const resp = await isAdValid(adId);
      if (resp?.data.data?.isValid) {
        const { adCreativeId, adAccountId } = resp.data.data;
        setValidAd({
          adId,
          adCreativeId,
          adAccountId
        });
        showSuccess(resp.data.message);
      } else {
        throw Error(resp?.data.message);
      }
    } catch (err) {
      console.log(err);
      setError("viewId", { type: "focus", message: "" }, { shouldFocus: true });
      const errData = err?.response?.data || err;
      showError(errData?.message || "something wrong");
      setValidAd(undefined);
    } finally {
      setIsLoading("");
    }
  };

  const onStatusChange = (value, file) => {
    setFiles((prevFiles) =>
      prevFiles.map((fileItem) =>
        fileItem.id === file.id
          ? { ...fileItem, status: value ? status.active : status.pause }
          : fileItem
      )
    );
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onAdValidityCheck)}>
          <CustomCard className="position-relative mt-4">
            <div className="p-4">
              <Row>
                <Col xs={12} md={6}>
                  <div className="d-flex align-items-center">
                    <div className="flex-fill me-2">
                      <TextField
                        type="string"
                        name="viewId"
                        placeholder="ID"
                        control={control}
                        register={register}
                        errors={errors.viewId}
                        label={t("adDuplicator.viewIdLabel")}
                        hideErrMsg
                      />
                    </div>
                    <OverlayTrigger
                      overlay={<Tooltip>{t("adDuplicator.fetchAd")}</Tooltip>}
                    >
                      <Button
                        type="submit"
                        disabled={isLoading}
                        variant="outline-primary"
                        className="me-2"
                        style={{ marginTop: "12px" }}
                      >
                        {isLoading === "VALIDATE" ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          <FontAwesomeIcon icon={faSearch} />
                        )}
                      </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={
                        <Tooltip>{t("adDuplicator.pasteAndFetchAd")}</Tooltip>
                      }
                    >
                      <Button
                        onClick={pasteAndValidateAdId}
                        disabled={isLoading}
                        variant="outline-success"
                        style={{ marginTop: "12px" }}
                      >
                        <FontAwesomeIcon icon={faPaste} />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </Col>

                <Col xs={12} md={6}>
                  <div className="d-flex align-items-end h-100 pb-3">
                    <Alert
                      key="info"
                      variant="info"
                      className="w-100 mb-0"
                      style={{
                        paddingTop: "8px",
                        paddingBottom: "8px"
                      }}
                    >
                      <FontAwesomeIcon className="me-2" icon={faInfoCircle} />
                      <Trans
                        i18nKey={t("fbTokenInfoMessage", {
                          what: `${tokenUser.firstName} ${tokenUser.lastName}`
                        })}
                      />
                    </Alert>
                  </div>
                </Col>
              </Row>
              <DropArea disabled={!validAd} {...getRootProps()}>
                <div className="d-flex flex-column">
                  <input {...getInputProps()} />
                  <FontAwesomeIcon
                    icon={validAd ? faUpload : faBan}
                    color="#a9a9a9c2"
                    size="4x"
                  />
                  <h5 className="mt-2">
                    {validAd
                      ? t("dropImageMsg")
                      : t("adDuplicator.disabledDropImageMsg")}
                  </h5>
                  <h5>{isDragAccept && "HIER ABLEGEN!"}</h5>
                  {/* verify ad account to upload */}
                  {isDragReject ? showError("Unacceptable file type") : ""}
                </div>
              </DropArea>
              <CustomCard className="mt-4 mb-3 p-3 rounded-0">
                <BootstrapTable responsive>
                  <thead>
                    <tr>
                      <FirstHeaderColumn>Aus/Eir</FirstHeaderColumn>
                      <HeaderColumn>{t("common.name")}</HeaderColumn>
                      <HeaderColumn className="text-center">
                        {t("adDuplicator.imgStatus")}
                      </HeaderColumn>
                      <HeaderColumn className="text-center">Size</HeaderColumn>
                      <HeaderColumn className="text-center">
                        {t("adDuplicator.adStatus")}
                      </HeaderColumn>
                      <LastHeaderColumn empty></LastHeaderColumn>
                    </tr>
                  </thead>
                  <tbody>
                    {files?.length ? (
                      files.map((file, index) => (
                        <TableRow key={file.name}>
                          <TableDetailSwitchCell>
                            <CheckBoxSwitch
                              onClick={(e) =>
                                onStatusChange(e.target.checked, file)
                              }
                              checked={
                                file.status === status.active ? true : false
                              }
                            />
                          </TableDetailSwitchCell>
                          <TableDetailNameCell className="py-3">
                            <div className="d-flex flex-row">
                              <div className="d-flex align-items-center me-2">
                                <ImageContainer className="me-1">
                                  <ImageWorker src={file.src} />
                                </ImageContainer>
                              </div>
                              <div className="d-flex align-items-center">
                                <p className="m-0 fw-bold">{file.name}</p>
                              </div>
                            </div>
                          </TableDetailNameCell>
                          <TableDetailCell className="text-center">
                            {!file.fileStatus.status && (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip">
                                    {file.fileStatus.message}
                                  </Tooltip>
                                }
                              >
                                <FontAwesomeIcon
                                  className="text-danger"
                                  icon={faCircleExclamation}
                                />
                              </OverlayTrigger>
                            )}
                            {file.fileStatus.loading && (
                              <Spinner
                                animation="border"
                                size="sm"
                                className="me-2"
                              />
                            )}
                            {file.fileStatus.code === 200 && (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip">
                                    {file.fileStatus.message}
                                  </Tooltip>
                                }
                              >
                                <FontAwesomeIcon
                                  className="text-success"
                                  icon={faCircleCheck}
                                />
                              </OverlayTrigger>
                            )}
                          </TableDetailCell>
                          <TableDetailCell className="py-3 text-center">
                            {bytesToSize(file.size)}
                          </TableDetailCell>
                          <TableDetailCell className="py-3 text-center">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip">
                                  {file.adStatus?.message}
                                </Tooltip>
                              }
                            >
                              <Badge bg={file.adStatus?.variant}>
                                {file.adStatus?.status}
                              </Badge>
                            </OverlayTrigger>
                          </TableDetailCell>
                          <TableDetailCell className="text-end align-middle py-3  ">
                            <div className="d-flex justify-content-end">
                              <Button
                                disabled={file.fileStatus.loading || isLoading}
                                size="sm"
                                variant="outline-secondary"
                                className="me-2 rounded-circle"
                                onClick={() => handlePreview(file.src)}
                              >
                                <FontAwesomeIcon size="sm" icon={faEye} />
                              </Button>
                              {file.adStatus.id !==
                                adUploadingStatus.success.id && (
                                <Button
                                  disabled={
                                    file.fileStatus.loading || isLoading
                                  }
                                  size="sm"
                                  className="me-2 rounded-circle"
                                  variant="outline-danger"
                                  onClick={() => handleDeleteIconClick(file)}
                                >
                                  <FontAwesomeIcon size="sm" icon={faTrash} />
                                </Button>
                              )}
                              {file.adStatus.id ===
                                adUploadingStatus.rejected.id && (
                                <Button
                                  disabled={
                                    file.fileStatus.loading || isLoading
                                  }
                                  size="sm"
                                  className="rounded-circle"
                                  variant="outline-success"
                                  onClick={() => onReplayDuplicateAd(file)}
                                >
                                  <FontAwesomeIcon size="sm" icon={faRepeat} />
                                </Button>
                              )}
                            </div>
                          </TableDetailCell>
                        </TableRow>
                      ))
                    ) : (
                      <NoResultsRow lengthOfColumns="7" />
                    )}
                  </tbody>
                </BootstrapTable>
              </CustomCard>
              <div className="d-flex justify-content-end mt-3">
                <Button
                  className="me-2"
                  onClick={onClearAllAds}
                  disabled={!validAd || isLoading}
                  variant="outline-danger"
                >
                  <FontAwesomeIcon className="me-2" icon={faEraser} />
                  {t("adDuplicator.clearAllAds")}
                </Button>
                <Button
                  onClick={onDuplicateAds}
                  disabled={isDuplicateAdDisabled}
                >
                  {isLoading === "DUPLICATE" ? (
                    <Spinner animation="border" size="sm" className="me-2" />
                  ) : (
                    <FontAwesomeIcon
                      className="me-2"
                      icon={faArrowUpFromBracket}
                    />
                  )}
                  {t("adDuplicator.uploadAdsToFb")}
                </Button>
              </div>
            </div>
          </CustomCard>
        </form>
      </FormProvider>
      {showPreview && (
        <ImagePreviewModal source={url} toggleModal={handlePreview} />
      )}
    </>
  );
};

export default AdDuplicator;
