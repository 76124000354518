import { TableRow, TableDetailCell } from "../common-styling";
import { useTranslation } from "react-i18next";

const NoResultsRow = ({ lengthOfColumns }) => {
  const { t } = useTranslation();
  return (
    <TableRow>
      <TableDetailCell className="fw-bold" colSpan={lengthOfColumns}>
        <div>{t("noResults")}...</div>
      </TableDetailCell>
    </TableRow>
  );
};
export default NoResultsRow;
