import { useTranslation, Trans } from "react-i18next";

export const useNotification = () => {
  const { t } = useTranslation();
  const showToastWithLink = ({ url, msg, showToast }) => {
    const message = (
      <>
        <Trans i18nKey={msg} />
        <br />
        <a href={url} target="_blank" rel="noreferrer">
          <Trans i18nKey={t("viewHere")} />
        </a>
      </>
    );
    showToast(message, { autoClose: false });
  };

  return { showToastWithLink };
};
