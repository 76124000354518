import styled from "styled-components";
import { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { faEdit, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import Loader from "../loader/loader";
import { showError } from "../../services/toast-service";
import NestedTabsComponent from "../nested-tabs-component";
import { CUSTOMER_ROUTES, NESTED_SIDENAV } from "../strings";
import { getCustomerById } from "../../services/api-declaration";
import { useTranslation } from "react-i18next";

const NameDiv = styled.div`
  font-size: 20px;
`;

const CustomerView = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getCustomer = async () => {
    try {
      const response = await getCustomerById(params.id);
      setCustomer(response.data.data);
    } catch (error) {
      console.log(error);
      showError(
        error.response.data.message ||
          t("toastMessage.errorInGetting", {
            what: t("common.customer")
          })
      );
    } finally {
      setIsLoading(false);
    }
  };
  const customerCb = useCallback(
    getCustomer,
    // eslint-disable-next-line
    [params.id]
  );
  useEffect(() => {
    customerCb();
  }, [customerCb]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div className="d-flex h-100">
        <div className="sub-sidebar">
          <div className="inner-sub-sidebar">
            <div className="tab-heading ps-3 mt-4">
              {t("sidebar.customers")}
            </div>
            <NestedTabsComponent
              nestedNavType={NESTED_SIDENAV.CUSTOMER}
              tabs={[
                { evKey: CUSTOMER_ROUTES.OVERVIEW, title: t("overview") },
                {
                  evKey: CUSTOMER_ROUTES.FUNNELS,
                  title: t("common.funnel", { count: 2 })
                },
                {
                  evKey: CUSTOMER_ROUTES.LEADS,
                  title: t("common.lead", { count: 2 })
                },
                {
                  evKey: CUSTOMER_ROUTES.PERFORMANCE_REPORT,
                  title: t("common.performanceReport", { count: 2 })
                }
              ]}
            />
          </div>
        </div>
        <div className="outlet-wrapper">
          <div className="mt-4">
            <NameDiv className="fw-bold d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faUserCircle} className="me-2" />
                {customer.firstName} {customer.lastName}
              </div>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    {t("common.customer")} {t("common.edit")}
                  </Tooltip>
                }
              >
                <div className="ms-3">
                  <Button
                    variant="outline-dark"
                    onClick={() => navigate(`edit`)}
                  >
                    <FontAwesomeIcon icon={faEdit} className="me-2" />
                    {t("common.edit")}
                  </Button>
                </div>
              </OverlayTrigger>
            </NameDiv>
          </div>
          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
export default CustomerView;
