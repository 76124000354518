import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import TagsForm from "../../components/tags/tags-form";
import TagsTable from "../../components/tags/tags-table";

const TagsPage = () => {
  return (
    <>
      <Routes>
        <Route index element={<TagsTable />} />
        <Route path="new" element={<TagsForm />} />
        <Route path=":id/edit" element={<TagsForm />} />
        <Route path="*" element={<Navigate to="/dashboard/tags" />} />
      </Routes>
      <Outlet />
    </>
  );
};
export default TagsPage;
