import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import MissingInfo from "../../components/missing-info/missing-info";
import MissingLeadInfo from "../../components/missing-info/missing-lead-info";
import MissingUTMParamsInfo from "../../components/missing-info/missing-utm-param-info";
import MissingFunnelHubspotInfo from "../../components/missing-info/missing-hubspot-info";
import MissingFunnelCategoryInfo from "../../components/missing-info/missing-category-info";
import MissingFunnelCustomerInfo from "../../components/missing-info/missing-customer-info";
import MissingFunnelAdAccountInfo from "../../components/missing-info/missing-ad-account-info";

const MissingInfoPage = () => {
  return (
    <>
      <Routes>
        <Route element={<MissingInfo />}>
          <Route index path="lead" element={<MissingLeadInfo />} />
          <Route path="category" element={<MissingFunnelCategoryInfo />} />
          <Route path="customer" element={<MissingFunnelCustomerInfo />} />
          <Route path="hubspot" element={<MissingFunnelHubspotInfo />} />
          <Route path="ad-account" element={<MissingFunnelAdAccountInfo />} />
          <Route path="utm-parameter" element={<MissingUTMParamsInfo />} />
          <Route path="*" element={<Navigate to="lead" />} />
        </Route>
      </Routes>
      <Outlet />
    </>
  );
};
export default MissingInfoPage;
