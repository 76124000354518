import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import TemplateForm from "../../components/template-form/template-form";
import TemplatesTable from "../../components/templates-table/templates-table";

const TemplatePage = () => {
  return (
    <>
      <Routes>
        <Route index element={<TemplatesTable />} />
        <Route path=":id" element={<TemplateForm />} />
        <Route path="new" element={<TemplateForm />} />
        <Route path="*" element={<Navigate to="/dashboard/templates" />} />
      </Routes>
      <Outlet />
    </>
  );
};

export default TemplatePage;
