import SplitButton from "react-bootstrap/SplitButton";
import DropdownItem from "react-bootstrap/DropdownItem";

const SplitFilterButton = ({ title, options, handleChange }) => (
  <SplitButton
    id="search-by-type-dropdown"
    bsPrefix={"text-capitalize btn btn-outline-secondary"}
    title={title}
  >
    {options.map(({ eventKey, funcKey, label }, optIndex) => (
      <DropdownItem
        key={optIndex}
        eventKey={eventKey}
        className="text-capitalize"
        onClick={() => handleChange(funcKey)}
      >
        {label}
      </DropdownItem>
    ))}
  </SplitButton>
);
export default SplitFilterButton;
