import { Col } from "react-bootstrap";
import FormActionButtons from "./form-action-buttons";

const ActionButtonsColumn = ({ mdColWidth = 4, fieldError, actions }) => (
  <Col
    lg={2}
    md={mdColWidth}
    sm={12}
    className={`text-end ${
      fieldError ? "align-self-center mt-1" : "align-self-end mb-1"
    }`}
  >
    <FormActionButtons
      isSubmitting={actions.isSubmitting}
      resetFields={actions.resetComponentState}
    />
  </Col>
);
export default ActionButtonsColumn;
