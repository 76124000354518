import { Routes, Route, Outlet } from "react-router-dom";
import Tools from "../../components/tools/tools";
import AdDuplicator from "../../components/tools/ad-duplicator";

const ToolsPage = () => {
  return (
    <>
      <Routes>
        <Route element={<Tools />}>
          <Route index path="fb-duplicator" element={<AdDuplicator />} />
        </Route>
      </Routes>
      <Outlet />
    </>
  );
};
export default ToolsPage;
