import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  faPen,
  faXmark,
  faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";
import { useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FbForm from "./fb-form";
import FbInfo from "./fb-info";
import FbActionButton from "./fb-action-btn";
import { FB_FORM_MODE } from "../../strings";
import { useTranslation } from "react-i18next";

const FacebookTab = ({
  cancel,
  funnel,
  fbLoader,
  fbOptions,
  fbFormMode,
  getFbPagesCb,
  handleFbFormMode,
  getPageForms,
  fbTabInfo,
  handleFbTabFieldOpts,
  setIsCustomFieldValidated,
  isCustomFieldValidated
}) => {
  const { t } = useTranslation();
  const { getValues, setValue } = useFormContext();
  const user = useSelector((state) => state.auth.user);

  const getFbPages = () => {
    getFbPagesCb();
  };
  const handleEditClick = () => {
    getPageForms(getValues("facebookForm.page.id"));
    handleFbFormMode(FB_FORM_MODE.EDITABLE);
  };
  const handleCancelEdit = () => {
    cancel && cancel();
    setValue("facebookForm", funnel.facebookForm);
    handleFbFormMode(FB_FORM_MODE.READONLY);
  };

  return (
    <div className="p-3">
      <div className="mb-3">
        <div className="d-flex align-items-center justify-content-between">
          <h6 className="mb-0 fw-bold">Facebook</h6>
          {user.isConnectedToFb && fbFormMode !== FB_FORM_MODE.ADD_NEW && (
            <>
              {fbFormMode === FB_FORM_MODE.READONLY ? (
                <FbActionButton
                  icon={faPen}
                  btnText={t("common.edit")}
                  handleClick={handleEditClick}
                  isDisabled={!getValues("facebookForm.user")?._id}
                />
              ) : (
                <FbActionButton
                  icon={faXmark}
                  btnText={t("cancelEditing")}
                  handleClick={handleCancelEdit}
                  isDisabled={false}
                />
              )}
            </>
          )}
        </div>
      </div>

      {!user.workspace.fbTokens?.length ? (
        <div>
          <FontAwesomeIcon
            className="text-info me-2"
            icon={faExclamationCircle}
          />
          You are not connected to <b>facebook</b>!
          <div>
            Go to
            <Link to="/dashboard/settings/security" className="ms-1">
              profile settings.
            </Link>
          </div>
        </div>
      ) : fbFormMode === FB_FORM_MODE.READONLY ? (
        <FbInfo fbTabInfo={fbTabInfo} />
      ) : (
        // for add new and editable
        <FbForm
          fbLoader={fbLoader}
          fbOptions={fbOptions}
          getFbPages={getFbPages}
          getPageForms={getPageForms}
          setFbOptionsCb={handleFbTabFieldOpts}
          setIsCustomFieldValidated={setIsCustomFieldValidated}
          isCustomFieldValidated={isCustomFieldValidated}
        />
      )}
    </div>
  );
};
export default FacebookTab;
