import { useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { createCity } from "../../services/api-declaration";
import { showError, showSuccess } from "../../services/toast-service";
import { useTranslation } from "react-i18next";

const CityForm = ({ createCityText, handleAllowAddNew, setValue }) => {
  const { t } = useTranslation();
  const cityRef = useRef();

  const handleCreateCity = async () => {
    try {
      if (cityRef.current && !cityRef.current.value) {
        return;
      }
      const response = await createCity({ name: cityRef.current.value });
      showSuccess(response.message);
      handleAllowAddNew(false);
      setValue("city", response.data, { shouldDirty: true });
    } catch (err) {
      console.log(err);
      showError(err.response.data.message || t("toastMessage.createCity"));
    }
  };
  return (
    <div>
      <Form.Label>
        {t("common.city")} {t("common.name")}:
      </Form.Label>
      <Form.Control
        type="text"
        ref={cityRef}
        defaultValue={createCityText}
        onChange={({ target: { value } }) => (cityRef.current.value = value)}
      />
      <div className="d-flex justify-content-end mt-3">
        <Button variant="success" onClick={handleCreateCity}>
          {t("common.save")}
        </Button>
      </div>
    </div>
  );
};
export default CityForm;
