import { toast } from "react-toastify";

export const showSuccess = (msg, options = undefined) => {
  toast.success(msg, options);
};
export const showError = (msg, options = undefined) => {
  toast.error(msg, options);
};
export const showInfo = (msg, options = undefined) => {
  toast.info(msg, options);
};
export const showWarning = (msg, options = undefined) => {
  toast.warning(msg, options);
};
