import Form from "react-bootstrap/Form";

const CheckBoxSwitch = (props) => {
  return (
    <Form.Check
      role="button"
      type="switch"
      {...(props.register ? { ...props.register(props.name) } : { ...props })}
    />
  );
};
export default CheckBoxSwitch;
