import { useState } from "react";
import styled from "styled-components";
import Popover from "react-bootstrap/Popover";
import { PopoverBody } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Body = styled(PopoverBody)`
  cursor: pointer;
  color: var(--secondary-text);
`;
const FAIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: var(--secondary-text);
`;
const SpanElement = styled.span`
  margin-left: 6px;
  color: ${({ color }) => `var(${color})`};
`;
const DivContainer = styled.div`
  &:hover {
    text-decoration: underline;
  }
`;

const ActionPopover = (props) => {
  const [hideOverlay, setHideOverlay] = useState(false);
  const toggleOverlay = (condition) => setHideOverlay(condition);
  const popover = (
    <Popover id="popover-basic">
      <Body>
        {props.options.map((opt, index) => (
          <DivContainer
            key={index}
            className="mb-1"
            onClick={() => {
              opt.onClick(opt.action);
              toggleOverlay(false);
            }}
          >
            <FontAwesomeIcon icon={opt.icon} />
            <SpanElement color={opt.textColor}>{opt.text}</SpanElement>
          </DivContainer>
        ))}
      </Body>
    </Popover>
  );

  return (
    <div onClick={() => toggleOverlay(!hideOverlay)}>
      <OverlayTrigger
        trigger="click"
        placement="bottom-start"
        overlay={popover}
        show={hideOverlay}
        rootClose
        onToggle={(bool) => toggleOverlay(bool)}
      >
        <FAIcon icon={faEllipsis} />
      </OverlayTrigger>
    </div>
  );
};
export default ActionPopover;
