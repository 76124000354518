import styled from "styled-components";
import { useCallback, useEffect, useState } from "react";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Modal, Image, Accordion } from "react-bootstrap";
import { CloseIconContainer } from "../common-styling";
import { showError } from "../../services/toast-service";
import { getAdCreativeInsights } from "../../services/api-declaration";
import { generateAssetFeedSpecs } from "../../services/common-service";
import AbsoluteLoaderWithOverlay from "../loader/absolute-loader-with-overlay";
import CreativeFeedSpecAccordionItem from "../creative-feed-spec-accordion-item";
import { useTranslation } from "react-i18next";

const Img = styled(Image)`
  width: 100%;
  height: 100%;
  border-radius: 6px;
`;
const DetailsContainer = styled.div`
  padding: 10px;
  border-top: 1px solid var(--th-border-color);
`;

const VerticallyCenteredModal = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  const [isLoadingInsights, setIsLoadingInsights] = useState(false);
  const [creativeInsights, setCreativeInsights] = useState({
    creativeAssetsInsights: null
  });
  const assetFeedSpecs = generateAssetFeedSpecs({
    t,
    data,
    insights: creativeInsights.creativeAssetsInsights
  });

  const getAdInsights = async (_adId) => {
    setIsLoadingInsights(true);
    try {
      const response = await getAdCreativeInsights(_adId);
      const data = response.data.data;
      setCreativeInsights({
        creativeAssetsInsights: data.creativeAssetsInsights
      });
    } catch (error) {
      showError(
        error.response?.data?.message || "Error in getting ad insights"
      );
    } finally {
      setIsLoadingInsights(false);
    }
  };

  const adInsightsCb = useCallback((_adId) => getAdInsights(_adId), []);
  useEffect(() => {
    if (data.ad_id) {
      adInsightsCb(data.ad_id);
    }
  }, [adInsightsCb, data]);

  return (
    <Modal {...props} size="lg" centered>
      <Img src={data.image || data.videoInfo?.picture || data.thumbnail} />
      <DetailsContainer className="p-3">
        <Row className="align-items-baseline">
          <Col lg={3}>
            <h5>{`${t("common.creative")} ${t("title")}`}:</h5>
          </Col>
          <Col>{data.creativeTitle}</Col>
        </Row>
        <Row className="align-items-baseline">
          <Col lg={3}>
            <h5>
              {t("adEvaluation.adLabel")} {t("common.name")}:
            </h5>
          </Col>
          <Col>{data.ad_name}</Col>
        </Row>
        <Row className="align-items-baseline">
          <Col lg={3}>
            <h5>
              {t("adEvaluation.adsetLabel")} {t("common.name")}:
            </h5>
          </Col>
          <Col>{data.adset_name}</Col>
        </Row>
        <Row className="align-items-baseline">
          <Col lg={3}>
            <h5>{`${t("campaign")} ${t("common.name")}`}:</h5>
          </Col>
          <Col>{data.campaign_name}</Col>
        </Row>
        <Row className="align-items-baseline">
          <Col lg={3}>
            <h5>Funnel:</h5>
          </Col>
          <Col>{data.funnelName}</Col>
        </Row>
        <Row className="align-items-baseline mb-2">
          <Col lg={3}>
            <h5>{t("common.customer")}:</h5>
          </Col>
          <Col>{data.customer}</Col>
        </Row>
        <div className="position-relative">
          {isLoadingInsights && <AbsoluteLoaderWithOverlay />}
          <Accordion className="position-relative" flush>
            {assetFeedSpecs.map((feedSpec, index) => (
              <CreativeFeedSpecAccordionItem
                key={index}
                evKey={index}
                feedSpec={feedSpec}
                headerText={feedSpec.headerText}
              />
            ))}
          </Accordion>
        </div>
      </DetailsContainer>
      <CloseIconContainer onClick={(e) => props.onHide(e.target.value)}>
        <FontAwesomeIcon icon={faClose} className="text-danger" />
      </CloseIconContainer>
    </Modal>
  );
};

const ImageDetailsDialog = ({ data, toggleModal }) => {
  return (
    <VerticallyCenteredModal data={data} show={true} onHide={toggleModal} />
  );
};
export default ImageDetailsDialog;
