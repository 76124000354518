import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { Row, Col, Form, Modal, Button, Container } from "react-bootstrap";
import TextField from "../reusable-form-fields/text-field";
import {
  updateTemplate,
  createDraftTemplate
} from "../../services/api-declaration";
import SpinnerButton from "../spinner-button/spinner-button";
import { showError, showSuccess } from "../../services/toast-service";
import { TemplateModalSchema } from "../../services/validation-schema";
import { useTranslation } from "react-i18next";

const SaveAsTemplateDialog = ({
  show,
  formValues,
  toggleDialog,
  resourceId
}) => {
  const { t } = useTranslation();
  const initialValues = { name: "" };
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSave = async (values) => {
    if (!formValues.funnelQA?.length) return;
    try {
      setIsSubmitting(true);
      const draftResp = await createDraftTemplate();
      const payload = {
        templateDesign: formValues.funnelDesign,
        name: values.name,
        templateType: formValues.general.funnelType,
        funnelQA: formValues.funnelQA,
        outcomes: formValues.outcomes,
        design: formValues.design,
        textElements: formValues.textElements,
        thankyouPage: formValues.thankyouPage,
        draft: false,
        fromFunnel: resourceId
      };
      const response = await updateTemplate(draftResp.data.data._id, payload);
      showSuccess(response.data.message);
      toggleDialog();
    } catch (error) {
      console.log(error);
      showError(
        error.response.data.message || t("toastMessage.createTemplateError")
      );
    } finally {
      setIsSubmitting(false);
    }
  };
  const methods = useForm({
    resolver: yupResolver(TemplateModalSchema),
    defaultValues: initialValues
  });
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = methods;
  return (
    <Modal show={show} onHide={toggleDialog} centered>
      <Modal.Header className="bg-success text-white">
        <h5 className="m-0">
          {t("modalContent.saveAsTemplate", { new: " " })}
        </h5>
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...methods} id="template-form">
          <Form onSubmit={handleSubmit(handleSave)}>
            <Container className="px-0">
              <Row>
                <Col>
                  <TextField
                    label={t("common.templateName")}
                    placeholder={t("common.templateName")}
                    type="text"
                    name="name"
                    errors={errors?.name}
                    register={register}
                    noSpacing
                  />
                </Col>
              </Row>
            </Container>
            {!formValues.funnelQA?.length && (
              <div className="text-danger mt-2">
                This template does not have any Funnel QA, which are
                <span className="fw-bold"> Required</span>
              </div>
            )}
            <hr />
            <div className="d-flex flex-row-reverse">
              <Button
                className="ms-2"
                variant="outline-secondary"
                onClick={() => toggleDialog()}
              >
                {t("common.cancel")}
              </Button>
              {isSubmitting ? (
                <SpinnerButton
                  btnText="Saving..."
                  btnStyle="btn-outline-success"
                />
              ) : (
                <Button type="submit" variant="outline-success">
                  {t("common.save")}
                </Button>
              )}
            </div>
          </Form>
        </FormProvider>
      </Modal.Body>
    </Modal>
  );
};
export default SaveAsTemplateDialog;
