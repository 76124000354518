import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import UserForm from "./profile";
import { SETTINGS } from "../strings";
import { updateUserById } from "../../services/api-declaration";
import { showError, showSuccess } from "../../services/toast-service";
import { useTranslation } from "react-i18next";

const UserFormPage = ({ showProfileHeading = false }) => {
  const { t } = useTranslation();
  const userId = useParams().id;
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const [isSettingsProfileActive, setIsSettingsProfileActive] = useState(false);
  const splittedPath = location.pathname.split("/");
  let tabName = splittedPath[splittedPath.length - 1];

  useEffect(() => {
    if (tabName.toUpperCase() === SETTINGS.PROFILE) {
      setIsSettingsProfileActive(true);
    } else {
      setIsSettingsProfileActive(false);
    }
  }, [tabName]);

  const handleUpdate = async (formValues) => {
    try {
      const data = { ...formValues };
      await updateUserById(data, userId);
      showSuccess(data.message || "User updated successfully!");
    } catch (error) {
      showError(
        error?.response?.data?.message || t("toastMessage.updateUserError")
      );
    }
  };
  return (
    <>
      {showProfileHeading && <h5 className="my-4">Profile</h5>}
      <UserForm
        userId={isSettingsProfileActive ? user._id : userId}
        handleFormSubmit={handleUpdate}
        showProfileHeading={showProfileHeading}
      />
    </>
  );
};

export default UserFormPage;
