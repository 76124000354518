import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

const SpinnerButton = ({ btnText, btnStyle }) => {
  return (
    <>
      <Button className={btnStyle} disabled>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        <span className={btnText ? "ms-2" : ""}>{btnText}</span>
      </Button>
    </>
  );
};
export default SpinnerButton;
