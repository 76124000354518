import { NOT_SUITABLE_STAGE } from "../strings";
import { DealStageBadge } from "../common-styling";
import { notSuitableReasons } from "./deal-stages-hash";
import { generateDealStage } from "../../services/common-service";

const SyncedDealStageBadge = ({ stage, reason = "" }) => {
  return (
    <DealStageBadge stage={generateDealStage(stage)}>
      <span>{generateDealStage(stage)?.title}</span>
      {stage === NOT_SUITABLE_STAGE && reason ? (
        <span className="ms-1">({notSuitableReasons[reason]})</span>
      ) : (
        ""
      )}
    </DealStageBadge>
  );
};
export default SyncedDealStageBadge;
