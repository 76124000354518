import styled from "styled-components";
import { Modal, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Img = styled(Image)`
  width: 100%;
  height: 100%;
  border-radius: 6px;
`;
const NoteContainer = styled.div`
  min-height: 80px;
  max-height: 150px;
  overflow-y: scroll;
`;

const PreviewDialog = (props) => {
  const { t } = useTranslation();
  return (
    <Modal {...props} size="md" centered>
      <Img src={props.data?.url} />
      <NoteContainer className="bg-white mb-2 p-3 border-top rounded-bottom">
        <h6 className="fw-bold">{t("suggestionGallery.noteLabel")}:</h6>
        {props.data?.note || "---"}
      </NoteContainer>
    </Modal>
  );
};

export default PreviewDialog;
