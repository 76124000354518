import Chart from "react-apexcharts";

const StackedBarChart = ({
  categories,
  series,
  isLegendVisible = false,
  isStrokeVisible = false,
  columnWidth = "50%"
}) => {
  //   const color = [
  //     ["#008ffbd9", "#00e396d9", "#feb019d9", "#ff4560d9", "#775dd0d9"],
  //     ["#008ffbd", "#00e396d", "#feb019d", "#ff4560d", "#775dd0d"]
  //   ];

  const config = {
    options: {
      chart: {
        id: "bar-chart",
        stacked: true
      },
      dataLabels: {
        style: {
          colors: ["#f2f2f2"]
        }
      },
      xaxis: {
        categories
      },
      plotOptions: {
        bar: {
          distributed: false,
          dataLabels: {
            enabled: false
          },
          columnWidth: columnWidth
        }
      },

      legend: {
        show: isLegendVisible
      },
      stroke: {
        show: isStrokeVisible,
        width: 1,
        colors: ["#fff"]
      }

      //   colors: [
      //     "#d9b1ad",
      //     "#d9c1ad",
      //     "#93c47d",
      //     "#c5ac5e",
      //     "#e69138",
      //     "#e06666",
      //     "#15535c",
      //     "#4544cd",
      //     "#819296",
      //     "#605383",
      //     "#82536a"
      //   ]
    },
    series: [...series]
  };

  return (
    <Chart
      options={config.options}
      series={config.series}
      type="bar"
      height={"100%"}
      width={"100%"}
    />
  );
};

export default StackedBarChart;
