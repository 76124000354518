import { Form } from "react-bootstrap";
import styled from "styled-components";

const OTPContainer = styled.div`
  gap: 5px;
  & input {
    width: 50px;
  }
`;

const OTPInputs = ({ submitOTP, inputsRef }) => {
  const handleOnPasteOtp = (event) => {
    const data = event.clipboardData.getData("text");
    const value = data.split("");
    if (value.length === inputsRef.current.length) {
      inputsRef.current.forEach((input, index) => {
        input.value = value[index];
        input.disabled = true;
      });
      submit();
    }
  };
  const handleOtp = (event, index) => {
    const input = inputsRef.current[index];
    let value = input.value;
    let isValidInput = value.match(/[0-9a-z]/gi);
    input.value = "";
    input.value = isValidInput ? value[0] : "";

    let fieldIndex = +input.dataset.index;
    if (fieldIndex < inputsRef.current.length - 1 && isValidInput) {
      inputsRef.current[index + 1].focus();
    }

    if (event.key === "Backspace" && fieldIndex > 0) {
      inputsRef.current[index - 1].focus();
    }

    if (fieldIndex === inputsRef.current.length - 1 && isValidInput) {
      submit();
    }
  };
  const submit = () => {
    let otp = "";
    inputsRef.current.forEach((input) => {
      input.disabled = true;
      otp += input.value;
    });
    submitOTP(otp);
  };
  return (
    <OTPContainer className="d-flex mb-3 justify-content-center">
      {["", "", "", "", "", ""].map((item, index) => (
        <Form.Control
          key={index}
          data-index={index}
          ref={(ref) => (inputsRef.current[index] = ref)}
          type="text"
          onKeyUp={(e) => handleOtp(e, index)}
          maxLength={1}
          onPaste={handleOnPasteOtp}
        />
      ))}
    </OTPContainer>
  );
};

export default OTPInputs;
