import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FbActionButton = ({ icon, btnText, handleClick, isDisabled }) => {
  return (
    <Button
      size="sm"
      className="ms-2"
      variant="outline-dark"
      onClick={handleClick}
      disabled={isDisabled}
    >
      <FontAwesomeIcon className="me-2" icon={icon} />
      {btnText}
    </Button>
  );
};
export default FbActionButton;
