import { useNavigate } from "react-router";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const NavigateBackButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Button variant="outline-primary" onClick={() => navigate(-1)}>
      <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
      {t("common.back")}
    </Button>
  );
};
export default NavigateBackButton;
