import { useState } from "react";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { showError, showSuccess } from "../../services/toast-service";
import { resetPasswordToken } from "../../services/api-declaration";
import { ForgotPasswordFormSchema } from "../../services/validation-schema";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import TextField from "../reusable-form-fields/text-field";
import AuthTemplate from "./auth-template";
import SubmitButton from "./submit-button";
import { useTranslation } from "react-i18next";

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleFormSubmit = async (formValues) => {
    try {
      if (isSubmitting) return;
      setIsSubmitting(true);
      const response = await resetPasswordToken(formValues);
      reset({ email: "" });
      showSuccess(response.data.message);
    } catch (error) {
      console.log(error);
      showError(error.response?.data?.message || t("toastMessage.wentWrong"));
    } finally {
      setIsSubmitting(false);
    }
  };
  const methods = useForm({
    defaultValues: { email: "" },
    resolver: yupResolver(ForgotPasswordFormSchema)
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = methods;
  return (
    <AuthTemplate title={t("common.forgotPassword")}>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(handleFormSubmit)}>
          <TextField
            type="email"
            name="email"
            register={register}
            label={t("common.email")}
            placeholder={t("common.enterEmail")}
            errors={errors?.email}
          />
          <hr />
          <div className="d-flex justify-content-between align-items-center">
            <SubmitButton
              isSubmitting={isSubmitting}
              btnText={t("common.send")}
              icon={faPaperPlane}
              spinnerBtnText={`${t("common.send")}...`}
            />
            <Link to="/login">
              <p className="m-0">{t("backToLogin")}</p>
            </Link>
          </div>
        </Form>
      </FormProvider>
    </AuthTemplate>
  );
};
export default ForgotPasswordPage;
