import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import {
  TableRow,
  NameLink,
  TableDetailCell,
  TableDetailNameCell
} from "../common-styling";
import NoResultsRow from "../no-results-table-row";

const CustomersTableRow = ({ customers }) => {
  const navigate = useNavigate();

  return customers.length ? (
    customers.map((customer, index) => (
      <React.Fragment key={index}>
        <TableRow>
          <TableDetailNameCell
            role="button"
            className="align-middle py-3"
            onClick={() =>
              navigate(`${customer.id}/overview`, { state: { customer } })
            }
          >
            <NameLink
              className="d-block"
              to={`${customer.id}/overview`}
              state={{ customer }}
            >
              {customer.firstName} {customer.lastName}
            </NameLink>
          </TableDetailNameCell>
          <TableDetailCell className="align-middle py-3">
            {customer.email}
          </TableDetailCell>
          <TableDetailCell className="align-middle py-3">
            {customer.companyName}
          </TableDetailCell>
          <TableDetailCell className="align-middle py-3">
            {customer.phone}
          </TableDetailCell>
          <TableDetailCell className="text-end align-middle py-3">
            <div className="d-flex justify-content-end">
              <Button
                size="sm"
                className="me-2 rounded-circle"
                variant="outline-primary"
                onClick={() => navigate(`${customer.id}/edit`)}
              >
                <FontAwesomeIcon size="sm" icon={faPenToSquare} />
              </Button>
            </div>
          </TableDetailCell>
        </TableRow>
      </React.Fragment>
    ))
  ) : (
    <NoResultsRow lengthOfColumns="5" />
  );
};
export default CustomersTableRow;
