import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import UserLayout from "../../components/user-layout";
import UserSecurity from "../../components/user/security";
import UserFormPage from "../../components/user-form/user-form-page";
import ChangePassword from "../../components/change-password/change-password";

const UserSettingsPage = () => {
  return (
    <>
      <Routes>
        <Route element={<UserLayout />}>
          <Route
            index
            path="profile"
            element={<UserFormPage showProfileHeading />}
          />
          <Route path="security" element={<UserSecurity />} />
          <Route path="change-password" element={<ChangePassword />} />
        </Route>
        <Route
          path="*"
          element={<Navigate to={`/dashboard/settings/profile`} />}
        />
      </Routes>
      <Outlet />
    </>
  );
};

export default UserSettingsPage;
