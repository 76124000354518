import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import Loader from "../components/loader/loader";
import { THUMBNAIL_TYPE } from "../components/strings";
import { CardContainer } from "../components/common-styling";
import AddNewImage from "../components/gallery/add-new-image";
import { GalleryFormSchema } from "../services/validation-schema";
import { showError, showSuccess } from "../services/toast-service";
import PreventReload from "../components/funnel-form/prevent-reload";
import { getSingleAsset, updateAsset } from "../services/api-declaration";
import ImagePreviewModal from "../components/funnel-form/preview-image-modal";
import { useTranslation } from "react-i18next";

const ImageContainer = styled.div`
  width: 230px;
  cursor: pointer;
  max-height: 200px;
  & img {
    max-height: inherit;
  }
`;
const GalleryForm = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [url, setUrl] = useState();
  const [previewImageUrl, setPreviewImageUrl] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const methods = useForm({
    resolver: yupResolver(GalleryFormSchema),
    defaultValues: {
      category: "",
      title: "",
      description: "",
      tags: [],
      type: ""
    }
  });
  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = methods;
  const updateAssetHandler = async (formValues) => {
    try {
      const data = { ...formValues };
      await updateAsset(data, params.assetId);
      showSuccess(t("assetUpdateMsg"));
    } catch (error) {
      showError(error?.response?.data?.message || "Error in Updating Asset!");
    }
  };
  useEffect(() => {
    const fetchAsset = async () => {
      try {
        const { data } = await getSingleAsset(params.assetId);
        const fetchedData = { ...data.data };
        setUrl(
          `${process.env.REACT_APP_ASSET_CDN_URL}/${fetchedData.key}/${THUMBNAIL_TYPE.GALLERY_FORM_ASSET}`
        );
        setPreviewImageUrl(`${fetchedData.url}/${fetchedData.key}`);
        reset(fetchedData);
        setIsLoading(false);
      } catch (error) {
        showError(
          error?.response?.data?.message ||
            t("toastMessage.errorInGetting", { what: "asset" })
        );
        navigate("/dashboard/gallery");
        console.log(error);
      }
    };
    fetchAsset();
    // eslint-disable-next-line
  }, [reset, params.assetId, navigate]);
  const toggleModal = () => setShowPreview(!showPreview);
  return (
    <>
      {/* Container on above fragment */}
      {isLoading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(updateAssetHandler)}>
          <FormProvider {...methods}>
            <PreventReload />
            <div className={`py-4 px-0 d-flex align-items-center`}>
              <h5 className="mb-0 me-4">Asset-Details {t("common.edit")}</h5>
            </div>
            <CardContainer className="p-4">
              <>
                <Row className="gy-3 align-items-center">
                  <AddNewImage
                    errors={errors}
                    control={control}
                    register={register}
                  />
                </Row>
                <Row className="justify-content-center mt-3">
                  <Col>
                    <div className="d-flex justify-content-center">
                      <ImageContainer
                        onClick={toggleModal}
                        className="text-center"
                      >
                        {url && <img src={url} alt="" className="img-fluid" />}
                      </ImageContainer>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Button type="submit">{t("common.update")}</Button>
                  </Col>
                </Row>
              </>
            </CardContainer>
          </FormProvider>
        </form>
      )}
      {showPreview && (
        <ImagePreviewModal source={previewImageUrl} toggleModal={toggleModal} />
      )}
    </>
  );
};

export default GalleryForm;
