import { useState } from "react";
import styled from "styled-components";
import {
  faLock,
  faCheck,
  faTrash,
  faEnvelope,
  faSquareArrowUpRight
} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import SetupTwoFA from "./setup-2fa";
import Disable2FA from "./disable-2fa";
import { CustomCard } from "../common-styling";
import { MULTI_FACTOR_TYPES } from "../strings";
import { connectToFacebook } from "../../store/slices/auth";
import { loadToken } from "../../services/api-declaration";
import SpinnerButton from "../spinner-button/spinner-button";
import { showError, showSuccess } from "../../services/toast-service";
import { useTranslation } from "react-i18next";
import { useDialog } from "../../hooks/useDialog";

const AuthCard = styled(Card)`
  border-radius: 30px;
`;

const PrimaryTokenBadge = styled(Badge)`
  margin-left: 8px;
  text-transform: capitalize;
  color: var(--primary-color);
  background-color: white !important;
`;

const UserSecurity = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { showConfirmationDialogBox } = useDialog();
  const [modalData, setModalData] = useState({
    disable: {
      authType: "",
      show: false
    },
    setup: {
      authType: "",
      show: false
    }
  });
  const user = useSelector((state) => state.auth.user);
  const [showLoader, setShowLoader] = useState(false);

  const isUserPrimary = Boolean(
    user.workspace.fbTokens.find(({ userId }) => userId._id === user._id)
      ?.isPrimary
  );

  const toggleDialog = (cbFunc) =>
    showConfirmationDialogBox({
      dialogMessage: connectFbDialogMessage(),
      dialogType: "warning",
      responseAction: (action) => {
        if (!action) return;
        return cbFunc();
      },
      title: "Warning"
    });

  const connectFbDialogMessage = () => (
    <div
      dangerouslySetInnerHTML={{
        __html: t("connectFbMessage")
      }}
    ></div>
  );

  const fbLoginCallback = async (loginResponse) => {
    if (loginResponse.accessToken) {
      setShowLoader(true);
      try {
        const response = await loadToken({
          accessToken: loginResponse.accessToken
        });
        dispatch(connectToFacebook({ userId: user._id }));
        showSuccess(response.data.message);
      } catch (error) {
        showError(t("toastMessage.updateFBToken"));
      } finally {
        setShowLoader(false);
      }
    }
  };
  const modalHandler = (modalType, authType) => {
    setModalData((prev) => ({
      ...prev,
      [modalType]: {
        authType,
        show: !modalData[modalType].show
      }
    }));
  };
  return (
    <div>
      <h5 className="my-4">Security</h5>
      <CustomCard className="p-3 mb-3">
        <h6> {t("multifactor.title")} </h6>
        <p>{t("multifactor.subtitle")} </p>
        <div>
          <Row>
            <Col md={6}>
              <AuthCard disabled body>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                    {t("common.email")}
                  </div>
                  {user.multiFactorEnabled.email ? (
                    <div>
                      <Badge className="rounded-circle me-2" bg="success">
                        <FontAwesomeIcon icon={faCheck} />
                      </Badge>
                      <Button
                        onClick={() =>
                          modalHandler("disable", MULTI_FACTOR_TYPES.EMAIL)
                        }
                        variant="outline-dark"
                        disabled={user.multiFactorEnabled.authenticator}
                        className="p-0 px-3"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </div>
                  ) : (
                    <Button
                      variant="outline-dark"
                      className="p-0 px-3"
                      onClick={() =>
                        modalHandler("setup", MULTI_FACTOR_TYPES.EMAIL)
                      }
                    >
                      {t("multifactor.setup")}
                    </Button>
                  )}
                </div>
              </AuthCard>
            </Col>
            <Col md={6}>
              <AuthCard body>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <FontAwesomeIcon icon={faLock} className="me-2" />
                    Authenticator App
                  </div>
                  {user.multiFactorEnabled.authenticator ? (
                    <div>
                      <Badge className="rounded-circle me-2" bg="success">
                        <FontAwesomeIcon icon={faCheck} />
                      </Badge>
                      <Button
                        onClick={() =>
                          modalHandler(
                            "disable",
                            MULTI_FACTOR_TYPES.AUTHENTICATOR
                          )
                        }
                        variant="outline-dark"
                        className="p-0 px-3"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </div>
                  ) : (
                    <Button
                      variant="outline-dark"
                      className="p-0 px-3"
                      onClick={() =>
                        modalHandler("setup", MULTI_FACTOR_TYPES.AUTHENTICATOR)
                      }
                    >
                      {t("multifactor.setup")}
                    </Button>
                  )}
                </div>
              </AuthCard>
            </Col>
          </Row>
        </div>
      </CustomCard>
      <CustomCard className="p-3">
        <div className="d-flex align-items-center justify-content-between">
          <div> {t("settings.connectFB")}</div>
          <FacebookLogin
            appId={process.env.REACT_APP_FB_APP_ID}
            autoLoad={false}
            fields="email"
            scope={process.env.REACT_APP_FB_PERMISSIONS}
            version="16.0"
            callback={fbLoginCallback}
            render={(renderProps) => {
              return !showLoader ? (
                <Button
                  onClick={
                    isUserPrimary
                      ? () => toggleDialog(renderProps.onClick)
                      : renderProps.onClick
                  }
                >
                  <FontAwesomeIcon
                    className="me-2"
                    icon={faSquareArrowUpRight}
                  />
                  {user.isConnectedToFb ? "Connected" : "Connect"}
                  {isUserPrimary && (
                    <PrimaryTokenBadge>Primary</PrimaryTokenBadge>
                  )}
                </Button>
              ) : (
                <SpinnerButton btnText="Connecting" btnStyle="btn-primary" />
              );
            }}
          />
        </div>
      </CustomCard>
      {modalData.setup.show && (
        <SetupTwoFA
          setupType={modalData.setup.authType}
          toggleModal={() => modalHandler("setup", "")}
        />
      )}
      {modalData.disable.show && (
        <Disable2FA
          disableMultiFactorType={modalData.disable.authType}
          toggleModal={() => modalHandler("disable", "")}
        />
      )}
    </div>
  );
};
export default UserSecurity;
