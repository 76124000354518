import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import ValidationMessage from "../validation-message/validation-message";

const InputGroupText = styled(InputGroup.Text)`
  cursor: pointer;
  width: 46px;
  height: 38px;
`;

const PasswordToggle = ({
  name,
  error,
  type,
  toggleHandler,
  label,
  thinLabel = false
}) => {
  const { register } = useFormContext();
  return (
    <>
      <Form.Label
        className={`${!thinLabel && "fw-bold"} ${error && "text-danger"}`}
      >
        {label}
        {error && "*"}
      </Form.Label>
      <InputGroup>
        <Form.Control
          className={`form-control ${error && "border-danger"}`}
          placeholder={label}
          type={type}
          {...register(name)}
        />
        <InputGroupText onClick={() => toggleHandler(name)}>
          <FontAwesomeIcon icon={type === "password" ? faEyeSlash : faEye} />
        </InputGroupText>
      </InputGroup>
      <ValidationMessage error={error?.message} />
    </>
  );
};

export default PasswordToggle;
