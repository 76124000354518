import styled from "styled-components";
import { useSelector } from "react-redux";
import { Accordion, AccordionButton } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { CustomCard } from "../../common-styling";

const AccordionHeaderButton = styled(AccordionButton)`
  &:focus {
    box-shadow: none;
  }
`;
const CustomAccordionItem = ({
  children,
  evKey,
  title,
  activeIntegrationKey
}) => {
  const activeIntegrations = useSelector(
    (state) => state.integrationsInfo.activeIntegrations
  );
  return (
    <Accordion.Item eventKey={evKey}>
      <AccordionHeaderButton className="ps-3">
        <FontAwesomeIcon
          icon={faCheckCircle}
          className={`me-2 text-success ${
            activeIntegrations[activeIntegrationKey] ? "visible" : "invisible"
          }`}
        />
        {title}
      </AccordionHeaderButton>
      <Accordion.Body>
        <CustomCard>{children}</CustomCard>
      </Accordion.Body>
    </Accordion.Item>
  );
};
export default CustomAccordionItem;
