import styled from "styled-components";
import { countryFlagEmojiHash } from "../common-confgs/common-vars";

const CountryFlagDiv = styled.div`
  font-size: ${({ fs }) => `${fs}px`};
`;

const CountryFlagEmoji = ({ fs = "20", countryName }) => {
  return (
    <CountryFlagDiv fs={fs}>{countryFlagEmojiHash[countryName]}</CountryFlagDiv>
  );
};
export default CountryFlagEmoji;
