import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Card, Modal, Form, FormGroup } from "react-bootstrap";
import { ModalHead } from "../common-styling";
import { showError } from "../../services/toast-service";
import { getAllAssets } from "../../services/api-declaration";
import _debounce from "lodash/debounce";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import CustomDropdown from "./dropdown";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../loader/loader";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImagePreviewModal from "../funnel-form/preview-image-modal";
import ClearButton from "./clear-button";
import AsyncSelectField from "../reusable-form-fields/async-select";
import { useOptionsFetch } from "../../hooks/useOptionsFetch";
import { THUMBNAIL_TYPE } from "../strings";
import { useTranslation } from "react-i18next";

const CardContainer = styled.div`
  height: 250px;
  width: 195px;
  & .card {
    &:hover {
      border: 1px solid var(--primary-color);
    }
  }
  & .img-container {
    pointer-events: auto;
    & img {
      cursor: pointer;
      height: 100%;
      object-fit: contain;
    }
    height: 211px;
  }
`;
const ViewIconContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  align-self: stretch;
`;

const ViewGalleryModal = ({
  handleClose,
  selectImage,
  selectedCategory,
  tagsSelected,
  toggleAddImage
}) => {
  const { t } = useTranslation();
  const [assets, setAssets] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const selectedTags = useRef(tagsSelected.map((tag) => tag._id));
  const categorySelected = useRef(selectedCategory);
  const [showPreview, setShowPreview] = useState(false);
  const [url, setUrl] = useState(null);
  const toggleModal = (item) => {
    if (item?.url) {
      setUrl(`${item.url}/${item.key}`);
    } else {
      setUrl(null);
    }
    setShowPreview(!showPreview);
  };
  const selectedFilters = useRef({
    tags: null,
    title: null,
    category: null,
    pageIndex: 1
  });
  const { control, setValue, getValues, register } = useForm({
    defaultValues: {
      tags: null,
      title: "",
      categoryFilter: selectedCategory
        ? {
            name: selectedCategory.name,
            _id: selectedCategory._id
          }
        : false
    }
  });
  const { handleCategoryOptions, handleTagOptions } = useOptionsFetch();
  const fetchAssets = useMemo(
    () =>
      _debounce(async () => {
        try {
          const response = await getAllAssets(selectedFilters.current);
          const data = response.data.data;
          setIsLoading(false);
          setHasMore(data.hasNextPage);
          setAssets((prev) => [...prev, ...data.rows]);
        } catch (error) {
          showError(
            error?.response?.data?.message ||
              t("toastMessage.errorInGetting", { what: "assets" })
          );
        }
      }, 1000),
    [t]
  );
  const handleTitleOption = useMemo(
    () =>
      _debounce((event) => {
        selectedFilters.current.title = event.target.value || null;
        selectedFilters.current.pageIndex = 1;
        setHasMore(false);
        setIsLoading(true);
        setAssets([]);
        fetchAssets();
      }, 1000),
    [fetchAssets]
  );
  useEffect(() => {
    selectedFilters.current.tags = selectedTags.current;
    if (categorySelected.current?._id) {
      selectedFilters.current.category = categorySelected.current._id;
    }
    fetchAssets();
  }, [fetchAssets]);
  useEffect(() => {
    setValue("tags", tagsSelected);
  }, [setValue, tagsSelected]);
  const clearFilter = (name) => {
    setIsLoading(true);
    setAssets([]);
    let filterName = name;
    if (name === "categoryFilter") filterName = "category";
    selectedFilters.current[filterName] = null;
    fetchAssets();
    if (name === "tags") {
      selectedTags.current = [];
      setValue(name, []);
    } else {
      setValue(name, null);
    }
  };
  return (
    <>
      <Modal centered size="xl" show={true} scrollable>
        <ModalHead className="fw-bold text-light" bg="primary">
          <Modal.Title>{t("viewGalleryModal.title")}</Modal.Title>
          <Button variant="light" onClick={toggleAddImage}>
            {t("viewGalleryModal.addImage")}
          </Button>
        </ModalHead>

        <Modal.Body id="scrollableDiv">
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col-lg-4">
                <FormGroup controlId="title">
                  <Form.Label>
                    {t("title")} {t("or")} {t("common.description")}
                  </Form.Label>
                  <div className="d-flex">
                    <Form.Control
                      {...register("title")}
                      placeholder={`Title or ${t("common.description")}`}
                      onChange={handleTitleOption}
                    />
                    <ClearButton
                      name="title"
                      clearCB={clearFilter}
                      filter={selectedFilters.current.title}
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-lg-4">
                <FormGroup controlId="tags">
                  <Form.Label>Tags</Form.Label>
                  <div className="d-flex">
                    <AsyncSelectField
                      isMulti
                      fetchData={handleTagOptions}
                      name="tags"
                      control={control}
                      onSelect={(_, action) => {
                        if (
                          action?.action === "select-option" &&
                          action?.option?._id
                        ) {
                          selectedTags.current.push(action.option._id);
                        } else if (
                          action?.action === "remove-value" &&
                          action?.removedValue?._id
                        ) {
                          selectedTags.current = selectedTags.current.filter(
                            (tag) => tag !== action.removedValue._id
                          );
                        } else {
                          selectedTags.current = [];
                        }
                        selectedFilters.current.tags = selectedTags.current;
                        selectedFilters.current.pageIndex = 1;
                        setHasMore(false);
                        setIsLoading(true);
                        setAssets([]);
                        fetchAssets();
                      }}
                    />
                    <ClearButton
                      name="tags"
                      clearCB={clearFilter}
                      filter={getValues("tags") || tagsSelected || []}
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-lg-4">
                <FormGroup controlId="Category">
                  <Form.Label>{t("common.category")}</Form.Label>
                  <div className="d-flex">
                    <AsyncSelectField
                      fetchData={handleCategoryOptions}
                      name="categoryFilter"
                      control={control}
                      onSelect={(value, action) => {
                        if (action?.action === "select-option" && value?._id) {
                          selectedFilters.current.category = value._id;
                        } else {
                          selectedFilters.current.category = null;
                        }
                        selectedFilters.current.pageIndex = 1;
                        setHasMore(false);
                        setIsLoading(true);
                        setAssets([]);
                        fetchAssets();
                      }}
                    />
                    <ClearButton
                      name="categoryFilter"
                      clearCB={clearFilter}
                      filter={getValues("categoryFilter")}
                    />
                  </div>
                </FormGroup>
              </div>
            </div>
            <InfiniteScroll
              height={500}
              scrollThreshold={0.9}
              dataLength={assets.length}
              next={() => {
                if (assets.length) {
                  selectedFilters.current.pageIndex =
                    selectedFilters.current.pageIndex + 1;
                }
                fetchAssets();
              }}
              hasMore={hasMore}
              hasChildren={true}
              loader={
                <div className="row gy-2 justify-content-center">
                  <div className="col-lg-11">
                    <div className="container">
                      <div className="row gy-2">
                        <Loader calcHeight={500} />
                      </div>
                    </div>
                  </div>
                </div>
              }
              scrollableTarget="scrollableDiv"
            >
              <div className="row gy-2 justify-content-center">
                <div className="col-lg-11">
                  <div className="container">
                    <div className="row gy-2">
                      {isLoading ? (
                        <Loader calcHeight={500} />
                      ) : assets.length === 0 ? (
                        <div className="border d-flex justify-content-center p-5">
                          <p className="m-0">{t("noAssetMsg")}</p>
                        </div>
                      ) : (
                        assets.map((item) => (
                          <div className="col-lg-3" key={item._id}>
                            <CardContainer title={item.title}>
                              <Card>
                                <Card.Header className="p-0 d-flex justify-content-between align-items-center">
                                  <p className="m-0 text-capitalize ps-2 d-inline-block text-truncate">
                                    {item.title}
                                  </p>
                                  <div className="d-flex align-items-center">
                                    <ViewIconContainer
                                      onClick={() => toggleModal(item)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faEye}
                                        className="text-secondary"
                                      />
                                    </ViewIconContainer>
                                    <CustomDropdown item={item} />
                                  </div>
                                </Card.Header>
                                <div
                                  className="img-container p-2"
                                  onClick={() => {
                                    selectImage({
                                      key: item.key,
                                      url: item.url,
                                      _id: item._id
                                    });
                                  }}
                                >
                                  <Card.Img
                                    src={`${process.env.REACT_APP_ASSET_CDN_URL}/${item.key}/${THUMBNAIL_TYPE.GALLERY_MODAL_ASSET}`}
                                  />
                                </div>
                              </Card>
                            </CardContainer>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </InfiniteScroll>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("common.close")}
          </Button>
        </Modal.Footer>
      </Modal>
      {showPreview && (
        <ImagePreviewModal source={url} toggleModal={toggleModal} />
      )}
    </>
  );
};

export default ViewGalleryModal;
