import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import { Form, Badge, Spinner, Row, Col } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faMinus } from "@fortawesome/free-solid-svg-icons";
import NumberInputGroup from "./number-input-group";
import { showError } from "../../services/toast-service";
import { getLeadPrice } from "../../services/api-declaration";
import { getEuroCurrencyData } from "../../services/common-service";
import { useTranslation } from "react-i18next";
import ValidationMessage from "../validation-message/validation-message";

const PriceRateBadge = styled(Badge)`
  font-size: 12px;
  background: #e8eaed !important;
`;
const LightColoredDiv = styled.div`
  color: var(--field-border-color-regular);
`;

const LeadPriceWrapper = styled.div`
  margin-top: -7px;
  margin-bottom: -7px;
`;

const LeadPriceBenchmark = ({ categoryId, fromModal, errors }) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const [actualLeadPrice, setActualLeadPrice] = useState(0);
  const [actualQualifiedLeadPrice, setActualQualifiedLeadPrice] = useState(0);
  const [isLoadingActualPrice, setIsLoadingActualPrice] = useState(false);
  const diameterSymbol = "⌀";
  const leadPriceBenchmarkValue = parseFloat(watch("leadPriceBenchmark") || 0);
  const leadQualifiedPriceBenchmarkValue = parseFloat(
    watch("leadQualifiedPriceBenchmark") || 0
  );
  const actualRateInPercent =
    Math.round(
      (((actualLeadPrice - leadPriceBenchmarkValue) * 100) /
        leadPriceBenchmarkValue) *
        100
    ) / 100;
  const actualQualifiedRateInPercent =
    Math.round(
      (((actualQualifiedLeadPrice - leadQualifiedPriceBenchmarkValue) * 100) /
        leadQualifiedPriceBenchmarkValue) *
        100
    ) / 100;

  const fetchActualLeadPrice = async (_categoryId) => {
    try {
      setIsLoadingActualPrice(true);
      const response = await getLeadPrice(_categoryId);
      setActualLeadPrice(response.data.data.costPerLead);
      setActualQualifiedLeadPrice(response.data.data.costPerQualifiedLead);
    } catch (err) {
      console.log(err);
      showError(err.response?.data?.message);
    } finally {
      setIsLoadingActualPrice(false);
    }
  };

  const getActualPriceCb = useCallback(fetchActualLeadPrice, []);
  useEffect(() => {
    if (categoryId && !fromModal) {
      getActualPriceCb(categoryId);
    }
  }, [getActualPriceCb, categoryId, fromModal]);

  return (
    <Form.Group controlId="leadPriceBenchmark">
      <Row>
        <Col sm={12} md={6}>
          <Form.Label
            className={errors?.leadPriceBenchmark ? "text-danger" : ""}
          >
            {t("leadPriceBenchmark")}
            {errors?.leadPriceBenchmark && "*"}
          </Form.Label>
          <LeadPriceWrapper className="d-flex align-items-center">
            <div>
              <NumberInputGroup name="leadPriceBenchmark" />
            </div>
            {categoryId && !fromModal && (
              <>
                <LightColoredDiv className="mx-2">
                  <FontAwesomeIcon icon={faMinus} />
                </LightColoredDiv>
                <div>
                  <PriceRateBadge pill className="text-secondary">
                    {isLoadingActualPrice ? (
                      <FontAwesomeIcon icon={faMinus} />
                    ) : (
                      <span>
                        {actualRateInPercent !== Infinity &&
                        !isNaN(actualRateInPercent) ? (
                          actualRateInPercent > 0 ? (
                            `+${actualRateInPercent}%`
                          ) : (
                            `${actualRateInPercent}%`
                          )
                        ) : (
                          <FontAwesomeIcon icon={faMinus} />
                        )}
                      </span>
                    )}
                  </PriceRateBadge>
                </div>
                <LightColoredDiv className="mx-2">
                  <FontAwesomeIcon icon={faArrowRight} />
                </LightColoredDiv>
                <div>
                  <div className="text-center text-primary fw-bold fs-5">
                    {isLoadingActualPrice ? (
                      <Spinner animation="border" />
                    ) : (
                      getEuroCurrencyData(actualLeadPrice)
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="fw-bold me-1">{diameterSymbol}</div>
                    <div>{t("leadPriceCurrentText")}</div>
                  </div>
                </div>
              </>
            )}
          </LeadPriceWrapper>
          {errors?.leadPriceBenchmark && (
            <ValidationMessage error={errors?.leadPriceBenchmark.message} />
          )}
        </Col>
        <Col sm={12} md={6}>
          <Form.Label
            className={errors?.leadQualifiedPriceBenchmark ? "text-danger" : ""}
          >
            {t("leadQualifiedPriceBenchmark")}
            {errors?.leadQualifiedPriceBenchmark && "*"}
          </Form.Label>
          <LeadPriceWrapper className="d-flex align-items-center">
            <div>
              <NumberInputGroup name="leadQualifiedPriceBenchmark" />
            </div>
            {categoryId && !fromModal && (
              <>
                <LightColoredDiv className="mx-2">
                  <FontAwesomeIcon icon={faMinus} />
                </LightColoredDiv>
                <div>
                  <PriceRateBadge pill className="text-secondary">
                    {isLoadingActualPrice ? (
                      <FontAwesomeIcon icon={faMinus} />
                    ) : (
                      <span>
                        {actualQualifiedRateInPercent !== Infinity &&
                        !isNaN(actualQualifiedRateInPercent) ? (
                          actualQualifiedRateInPercent > 0 ? (
                            `+${actualQualifiedRateInPercent}%`
                          ) : (
                            `${actualQualifiedRateInPercent}%`
                          )
                        ) : (
                          <FontAwesomeIcon icon={faMinus} />
                        )}
                      </span>
                    )}
                  </PriceRateBadge>
                </div>
                <LightColoredDiv className="mx-2">
                  <FontAwesomeIcon icon={faArrowRight} />
                </LightColoredDiv>
                <div>
                  <div className="text-center text-primary fw-bold fs-5">
                    {isLoadingActualPrice ? (
                      <Spinner animation="border" />
                    ) : (
                      getEuroCurrencyData(actualQualifiedLeadPrice)
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="fw-bold me-1">{diameterSymbol}</div>
                    <div>{t("leadQualifiedPriceCurrentText")}</div>
                  </div>
                </div>
              </>
            )}
          </LeadPriceWrapper>
          {errors?.leadQualifiedPriceBenchmark && (
            <ValidationMessage
              error={errors?.leadQualifiedPriceBenchmark.message}
            />
          )}
        </Col>
      </Row>
    </Form.Group>
  );
};
export default LeadPriceBenchmark;
