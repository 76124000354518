import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import CategoryForm from "../../components/categories/category-form";
import CategoriesTable from "../../components/categories/categories-table";

const CategoryPage = () => {
  return (
    <>
      <Routes>
        <Route index element={<CategoriesTable />} />
        <Route path="new" element={<CategoryForm />} />
        <Route path=":id/edit" element={<CategoryForm />} />
        <Route path="*" element={<Navigate to="/dashboard/categories" />} />
      </Routes>
      <Outlet />
    </>
  );
};
export default CategoryPage;
