import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import NestedTabsComponent from "../nested-tabs-component";
import { TOOLS_TYPE, NESTED_SIDENAV } from "../strings";

const Tools = () => {
  const { t } = useTranslation();
  return (
    <div className="d-flex h-100">
      <div className="sub-sidebar">
        <div className="inner-sub-sidebar">
          <div className="tab-heading ps-3 mt-4">{t("tools")}</div>
          <NestedTabsComponent
            nestedNavType={NESTED_SIDENAV.EVALUATION}
            tabs={[
              { evKey: TOOLS_TYPE.DUPLICATOR, title: t("adDuplicator.title") }
            ]}
          />
        </div>
      </div>
      <div className="outlet-wrapper">
        <Outlet />
      </div>
    </div>
  );
};
export default Tools;
