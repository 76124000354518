import Form from "react-bootstrap/Form";
import ClearFilterButton from "./clear-filter-button";
import SelectStructure from "./select-structure";

const MinMaxRangeFilter = ({
  label,
  clearFilter,
  active,
  errors,
  register
}) => {
  return (
    <SelectStructure label={label}>
      <Form.Group controlId="min" className="me-4 d-flex align-items-center">
        <Form.Label className={`mb-0 me-2 ${errors?.min && "text-danger"}`}>
          Min:{errors?.min && "*"}
        </Form.Label>
        <Form.Control
          size="sm"
          type="number"
          placeholder="Min Range - 0"
          {...register(`min`)}
          className={errors?.min && "border-danger"}
        />
        {active.min && (
          <ClearFilterButton param={"min"} clearFn={clearFilter} />
        )}
      </Form.Group>
      <Form.Group controlId="max" className="me-4 d-flex align-items-center">
        <Form.Label className={`mb-0 me-2 ${errors?.max && "text-danger"}`}>
          Max:{errors?.max && "*"}
        </Form.Label>
        <Form.Control
          size="sm"
          type="number"
          placeholder="Max Range - 100"
          {...register(`max`)}
          className={errors?.max && "border-danger"}
        />
        {active.max && (
          <ClearFilterButton param={"max"} clearFn={clearFilter} />
        )}
      </Form.Group>
    </SelectStructure>
  );
};
export default MinMaxRangeFilter;
