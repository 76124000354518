import { useFormContext } from "react-hook-form";
import OutcomeOptions from "./outcome-options";
import { BorderlessSelect } from "../../common-styling";
import { useTranslation } from "react-i18next";

const AnswerActionsDropdown = ({ field, isMulti, questionIndex }) => {
  const { t } = useTranslation();
  const { getValues, register } = useFormContext();
  return (
    <BorderlessSelect fs="14" {...register(`${field}.answerAction`)}>
      {!isMulti ? (
        <>
          <option disabled value="">
            {t("funnelQA.selectOption")}
          </option>
          <optgroup label="Questions">
            {/* Jump to the next question */}
            {getValues().funnelQA?.length - 1 !== questionIndex && (
              <option value={"next"}>{t("jumpToNextQuestion")}</option>
            )}
            {/* Jump to the question number */}
            {getValues().funnelQA?.length - 1 !== questionIndex &&
              getValues().funnelQA?.length > 1 &&
              getValues().funnelQA.map(
                (item, itemIndex) =>
                  questionIndex !== itemIndex && (
                    <option key={itemIndex} value={itemIndex}>
                      {t("jumptToQuestion")} # {itemIndex + 1}
                    </option>
                  )
              )}
            {/* End questionnaire */}
            <option value={"last"}>{t("endQuestionnaire")}</option>
          </optgroup>
          {/* Jump to the outcome */}
          <OutcomeOptions />
        </>
      ) : (
        <optgroup label="Multi">
          <option value="multi">{t("funnelQA.multi")}</option>
        </optgroup>
      )}
    </BorderlessSelect>
  );
};
export default AnswerActionsDropdown;
