import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import NestedTabsComponent from "../nested-tabs-component";
import { MISSING_INFO_TYPE, NESTED_SIDENAV } from "../strings";

const MissingInfo = () => {
  const missingInfoCount = useSelector((state) => state.missingInfo);
  const {
    leadCount,
    categoryCount,
    customerCount,
    hubspotCount,
    leadUTMCount,
    adAccountCount
  } = missingInfoCount;

  return (
    <div className="d-flex h-100">
      <div className="sub-sidebar">
        <div className="inner-sub-sidebar">
          <div className="tab-heading ps-3 mt-4">Missing Info</div>
          <NestedTabsComponent
            nestedNavType={NESTED_SIDENAV.MISSING_INFO}
            clearParams
            tabs={[
              {
                evKey: MISSING_INFO_TYPE.LEAD_INFO,
                title: "Lead Info",
                popUp: Boolean(leadCount)
              },
              {
                evKey: MISSING_INFO_TYPE.CATEGORY_INFO,
                title: "Category Info",
                popUp: Boolean(categoryCount)
              },
              {
                evKey: MISSING_INFO_TYPE.CUSTOMER_INFO,
                title: "Customer Info",
                popUp: Boolean(customerCount)
              },
              {
                evKey: MISSING_INFO_TYPE.HUBSPOT_INFO,
                title: "Hubspot Info",
                popUp: Boolean(hubspotCount)
              },
              {
                evKey: MISSING_INFO_TYPE.AD_ACCOUNT,
                title: "AD Account Info",
                popUp: Boolean(adAccountCount)
              },
              {
                evKey: MISSING_INFO_TYPE.UTM_PARAMETER,
                title: "UTM Parameter",
                popUp: Boolean(leadUTMCount)
              }
            ]}
          />
        </div>
      </div>
      <div className="outlet-wrapper">
        <Outlet />
      </div>
    </div>
  );
};
export default MissingInfo;
