import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AbsoluteButtonAnchor = ({
  icon,
  link,
  classes,
  isDisabled,
  buttonText,
  customButtonStyles
}) => {
  return (
    <a href={link} target="_blank" rel="noreferrer">
      <Button
        style={customButtonStyles}
        className={classes}
        disabled={isDisabled}
      >
        <FontAwesomeIcon icon={icon} className="me-2" />
        {buttonText}
      </Button>
    </a>
  );
};
export default AbsoluteButtonAnchor;
