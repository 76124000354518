import {
  Form,
  Tooltip,
  InputGroup,
  OverlayTrigger,
  useAccordionButton
} from "react-bootstrap";
import styled from "styled-components";
import {
  faFile,
  faTrashCan,
  faChevronUp,
  faChevronDown,
  faClipboard
} from "@fortawesome/free-solid-svg-icons";
import CardHeader from "react-bootstrap/CardHeader";
import { useFormContext, useWatch } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ActionPopover from "./actions-popover";
import {
  FixedWidthDiv,
  BorderlessInput,
  FunnelTypeBadge,
  BorderlessSelect,
  CustomBadge
} from "../../common-styling";
import { FUNNEL_QA_ACTIONS, QUESTION_TYPE } from "../../strings";
import ValidationMessage from "../../validation-message/validation-message";
import { useTranslation } from "react-i18next";

const Header = styled(CardHeader)`
  padding: 0;
  background-color: #fff;
`;
const AccordionToggleHandler = styled.div`
  cursor: pointer;
`;
const Icon = styled(FontAwesomeIcon)`
  color: var(--secondary-text);
`;
const Required = styled.div`
  top: -20px;
  left: -8px;
  font-size: 30px;
  font-weight: bold;
`;
const GroupLabel = styled.div`
  width: 80px;
  background: #f5f8f9;
`;

const QuestionList = ({ index, activeKey, onAction, setActiveKey, errors }) => {
  const { t } = useTranslation();
  const { register, getValues, setValue, watch } = useFormContext();
  const isMultiFlagWatcher = useWatch({
    name: `funnelQA.${[index]}.isMultiSelectable`
  });
  const fieldName = `funnelQA.${[index]}`;
  const fieldTypeReg = register(`${fieldName}.type`);

  const options = [
    {
      text: t("copyQuestion"),
      action: FUNNEL_QA_ACTIONS.COPY,
      textColor: "",
      icon: faClipboard,
      onClick: (action) => {
        onAction(index, action);
      }
    },
    {
      text: t("duplicateQuestion"),
      action: FUNNEL_QA_ACTIONS.DUPLICATE,
      textColor: "",
      icon: faFile,
      onClick: (action) => {
        onAction(index, action);
      }
    },
    {
      text: t("deleteQuestion"),
      action: FUNNEL_QA_ACTIONS.DELETE,
      textColor: "--danger-color",
      icon: faTrashCan,
      onClick: (action) => {
        onAction(index, action);
      }
    }
  ];
  const ToggleAccordion = ({ eventKey, content }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      if (eventKey === activeKey) {
        setActiveKey(-1);
      } else {
        setActiveKey(eventKey);
      }
    });
    return (
      <AccordionToggleHandler onClick={decoratedOnClick}>
        {content}
      </AccordionToggleHandler>
    );
  };
  const handleUpdateAnswerActions = (isChecked) => {
    if (
      getValues(fieldName).type === QUESTION_TYPE.FREE_TEXT_FIELD ||
      getValues(fieldName).type === QUESTION_TYPE.SLIDER
    ) {
      setValue(`${fieldName}.type`, QUESTION_TYPE.ICONS);
    }
    if (getValues(fieldName).answers.length) {
      const answers = getValues(fieldName).answers.map((ans) => {
        ans.answerAction = isChecked ? "multi" : "";
        return ans;
      });
      setValue(`${fieldName}.answers`, answers);
    }
  };
  const handleSelectOnChange = (e) => {
    if (
      e.target.value === QUESTION_TYPE.FREE_TEXT_FIELD ||
      e.target.value === QUESTION_TYPE.SLIDER
    ) {
      setValue(`funnelQA[${index}].answers`, []);
    }
    fieldTypeReg.onChange(e);
  };
  return (
    <>
      {errors?.length && errors[index] && (
        <Required className="position-absolute text-danger">*</Required>
      )}
      <Header className={errors?.length && errors[index] && "border-danger"}>
        {watch("funnelQA")[index]?.isDuplicated && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip">{t("duplicatedQuestion")}</Tooltip>}
          >
            <CustomBadge />
          </OverlayTrigger>
        )}

        <div className="d-flex align-items-center">
          <FixedWidthDiv
            width="75"
            className="text-center align-self-stretch mb-2"
          >
            <ToggleAccordion
              eventKey={index}
              content={
                <>
                  <FunnelTypeBadge
                    className="mt-2"
                    btnbg="--lead-badge-bg"
                    btncolor={"--lead-color"}
                  >
                    {watch("funnelQA")[index]?.answers &&
                      `${watch("funnelQA")[index].answers.length} ${t(
                        "common.answer"
                      )}`}
                  </FunnelTypeBadge>
                  <div className="mt-2 d-flex align-items-center justify-content-center">
                    <div className="me-2">{index + 1}</div>
                    {activeKey === index ? (
                      <Icon icon={faChevronUp} />
                    ) : (
                      <Icon icon={faChevronDown} />
                    )}
                  </div>
                </>
              }
            />
          </FixedWidthDiv>
          <div className="flex-fill p-0 border-start align-self-stretch">
            <InputGroup
              className={`border-bottom ${
                errors?.length && errors[index]?.text && "border-danger"
              }`}
            >
              <GroupLabel
                className={`d-flex align-items-center text-secondary px-2 fw-bold ${
                  errors?.length && errors[index]?.text && "text-danger"
                }`}
              >
                {t("question", { count: 1 })}:
                {errors && errors[index]?.text && "*"}
              </GroupLabel>
              <BorderlessInput
                className="border-start border-end"
                type="text"
                name={`${fieldName}.text`}
                placeholder={t("funnelQA.questionPlaceholder")}
                {...register(`${fieldName}.text`)}
              />
            </InputGroup>
            <InputGroup>
              <GroupLabel className="d-flex align-items-center text-secondary px-2 fw-bold">
                Subtext:
              </GroupLabel>
              <BorderlessInput
                className="border-start border-end"
                type="text"
                name={`${fieldName}.subtext`}
                placeholder="Add question sub-text"
                {...register(`${fieldName}.subtext`)}
              />
            </InputGroup>
          </div>
          <div className="border-end align-self-stretch align-items-center d-flex">
            <FixedWidthDiv className="px-3" width="200">
              <div className="d-flex align-items-center justify-content-between">
                <div className="border-bottom fw-bold text-secondary small">
                  Multiple Choice?
                </div>
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      {isMultiFlagWatcher
                        ? t("funnelQA.multi")
                        : t("funnelQA.single")}
                    </Tooltip>
                  }
                >
                  <Form.Check
                    role="button"
                    type="switch"
                    checked={fieldName.isMultiSelectable}
                    disabled={
                      getValues(fieldName).type ===
                        QUESTION_TYPE.FREE_TEXT_FIELD ||
                      getValues(fieldName).type === QUESTION_TYPE.SLIDER
                    }
                    {...register(`${fieldName}.isMultiSelectable`, {
                      onChange: ({ target: { checked } }) => {
                        handleUpdateAnswerActions(checked);
                        return checked;
                      }
                    })}
                  />
                </OverlayTrigger>
              </div>

              <div className="d-flex align-items-center justify-content-between">
                <div className="border-bottom fw-bold text-secondary small">
                  {t("common.type")} :
                </div>
                <BorderlessSelect
                  fs="12"
                  height="30"
                  width="100"
                  align="right"
                  {...register(`${fieldName}.type`)}
                  onChange={handleSelectOnChange}
                >
                  <option value={QUESTION_TYPE.ICONS}>Icons</option>
                  <option value={QUESTION_TYPE.TEXTONLY}>
                    {t("funnelQA.textOnly")}
                  </option>
                  {!isMultiFlagWatcher && [
                    <option
                      key="freeText"
                      value={QUESTION_TYPE.FREE_TEXT_FIELD}
                    >
                      {t("funnelQA.freeText")}
                    </option>,
                    <option key="slider" value={QUESTION_TYPE.SLIDER}>
                      {t("funnelQA.slider")}
                    </option>
                  ]}
                </BorderlessSelect>
              </div>
            </FixedWidthDiv>
          </div>
          <FixedWidthDiv className="text-center" width="40">
            <ActionPopover options={options} />
          </FixedWidthDiv>
        </div>
      </Header>
      {errors?.length && (
        <small className="fw-bold">
          <ValidationMessage error={errors[index]?.answers?.message} />
        </small>
      )}
    </>
  );
};
export default QuestionList;
