import { faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ClearButton = ({ filter, name, clearCB }) => {
  const { t } = useTranslation();
  const checkFilter = (filter) => {
    return Array.isArray(filter) ? filter.length !== 0 && true : filter && true;
  };
  return (
    <>
      {checkFilter(filter) && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>{t("delete")}</Tooltip>}
        >
          <Button
            onClick={() => clearCB(name)}
            className="ms-2"
            variant="outline-danger"
          >
            <FontAwesomeIcon icon={faFilterCircleXmark} />
          </Button>
        </OverlayTrigger>
      )}
    </>
  );
};
export default ClearButton;
