export const generateInstruction = (params) => {
  const {
    speech,
    productName,
    isLeadFunnel,
    funnelURL,
    specialInstruction = "",
    companyName,
    tonality,
    example1,
    example2,
    example3,
    shouldUseEmoji
  } = params;
  const examples = [example1, example2, example3];
  const SPEECH_WORD = speech === "du" ? "Du-Form" : "Sie-Form";
  const FUNNEL_TYPE = isLeadFunnel
    ? "Neukundengewinnung"
    : "Mitarbeitergewinnung";
  let systemPrompt;
  if (example1 || example2 || example3) {
    systemPrompt = {
      role: "system",
      content:
        "Du bist ein KI-unterstützter Copywriting Profi. Du bekommst von mir drei Beispiele für hochkonvertierende Werbeanzeigen, die in der Vergangenheit sehr gut konvertiert haben. Schreibe mir basierend auf diesen Beispielen Werbeanzeigen für eine andere Firma, im Stil meiner Beispiele."
    };
  } else {
    systemPrompt = {
      role: "system",
      content:
        "Verhalte dich wie ein KI-unterstützter Copywriting-Profi. Du bekommst von mir eine Anweisung und sollst damit hochkonvertierende Facebook Werbeanzeigen schreiben."
    };
  }
  const examplesPrompt = [];
  examples.forEach((content) => {
    if (content) {
      examplesPrompt.push({ role: "user", content, type: "example" });
    }
  });
  const prompt = {
    role: "user",
    content: `Schreibe anhand meiner Beispiele 3 unterschiedliche Werbeanzeigen mit den folgenden Angaben:
Firma: ${companyName}
Ziel: ${FUNNEL_TYPE}
Produkt: ${productName}
Emojis verwenden: ${shouldUseEmoji}
Tonalität: ${tonality}${
      specialInstruction &&
      `
Spezielle Anweisungen: ${specialInstruction}`
    }
Ansprache: ${SPEECH_WORD}
URL für CTA: ${funnelURL}
Wichtig: Gib mir die Antworten jeweils nacheinander in dreifachen curly brackets zurück. Do not use "1." or "1)" as enumeration!`
  };
  const payload = [systemPrompt, ...examplesPrompt, prompt];
  return payload;
};
