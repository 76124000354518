import { Container, Row, Col } from "react-bootstrap";

const CardDataContainer = ({ collection }) => {
  return (
    <Container>
      {collection.map((data, idx) => (
        <Row key={idx} className="mb-2">
          <Col className="fw-bold">{data.label}:</Col>
          <Col xs={10}>
            <span>{data.value}</span>
          </Col>
        </Row>
      ))}
    </Container>
  );
};
export default CardDataContainer;
