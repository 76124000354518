import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  TableRow,
  CustomCard,
  FirstHeaderColumn,
  HeaderColumn,
  LastHeaderColumn,
  BootstrapTable,
  TableDetailCell,
  PageHeading,
  NameLink
} from "../common-styling";
import NoResultsRow from "../no-results-table-row";
import { showError, showSuccess } from "../../services/toast-service";
// import AdvancedFilters from "../table-filter/advanced-filter";
// import CustomPagination from "../pagination/custom-pagination";
import { getMe, setPrimaryToken } from "../../services/api-declaration";
// import { userFilterConfig } from "../funnels-table/filter-config";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import {
  faCheck,
  faPenToSquare
  //   faPlus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SpinnerButton from "../spinner-button/spinner-button";
import { useSelector } from "react-redux";
import { useDialog } from "../../hooks/useDialog";
import { setMe, setPrimaryFbToken } from "../../store/slices/auth";
// import DeleteButton from "../delete-button";

const TokenSettingsTable = () => {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const fbTokens = currentUser.workspace.fbTokens;
  const navgiate = useNavigate();
  const { showConfirmationDialogBox } = useDialog();
  // const [isLoading, setIsLoading] = useState(true);
  // const [users, setUsers] = useState();
  const [primaryLoadingIndex, setPrimaryLoadingIndex] = useState(-1);
  // const [activeUser, setActiveUser] = useState("");
  //   const [paginateOpts, setPaginateOpts] = useState(true);
  // const [searchParams] = useSearchParams();
  // const [show, setShow] = useState(false);

  //   const handleQueryParams = (property, value) => {
  //     searchParams.set(property, value);
  //     setSearchParams(searchParams);
  //   };

  // const createToggleModal = () => {
  //   setActiveUser({});
  //   setShow(!show);
  // };

  const toggleDialog = (user, idx) =>
    showConfirmationDialogBox({
      dialogMessage: changePrimaryDialogMessage(),
      dialogType: "warning",
      responseAction: (action) => handlePrimaryTokenChange(action, user, idx),
      title: "Warning"
    });

  const changePrimaryDialogMessage = () => (
    <div
      dangerouslySetInnerHTML={{
        __html: t("activatePrimaryMessage")
      }}
    ></div>
  );

  const handlePrimaryTokenChange = async (action, user, index) => {
    if (!action) return;
    setPrimaryLoadingIndex(index);
    try {
      const { data } = await setPrimaryToken(user._id);
      dispatch(setPrimaryFbToken({ userId: user._id }));
      showSuccess(data.message || "Made user token primary successfully!");
    } catch (error) {
      showError(
        error?.response?.data?.message ||
          t("toastMessage.errorInUpdating", {
            what: t("common.user", { count: 1 })
          })
      );
    } finally {
      setPrimaryLoadingIndex(-1);
    }
  };

  //   const updateToggleModal = (user) => {
  //     setActiveUser({
  //       userId: user._id,
  //       userName: user.firstName ? `${user.firstName} ${user.lastName}` : ""
  //     });
  //     setShow(!show);
  //   };

  // const onCreate = (userData) => {
  //   setUsers((prev) => [userData, ...prev]);
  //   createToggleModal();
  // };

  // const onUpdate = async () => {
  //   await fetchUsers();
  //   createToggleModal();
  // };

  const navigateToSettings = () => {
    navgiate("/dashboard/settings/security");
  };

  const fetchMe = async (dispatch) => {
    const response = await getMe();
    dispatch(setMe(response.data.data));
  };
  // const fetchUsers = async () => {
  //   try {
  //     setIsLoading(true);
  //     const params = {};
  //     for (const [key, value] of searchParams.entries()) {
  //       params[key] = value;
  //     }
  //     const response = await fetchAllUsersWithToken(params);
  //     const data = response.data.data;
  //     setUsers(data.rows);
  //     //   const foundVal = data.rows.find((d) => d.isPrimary)?._id;
  //     //   if (foundVal) {
  //     //     setValue("isPrimaryUser", foundVal);
  //     //   }
  //     //   setPaginateOpts({
  //     //     totalPages: data.totalPages,
  //     //     hasPrevPage: data.hasPrevPage,
  //     //     hasNextPage: data.hasNextPage,
  //     //     totalDocs: data.totalDocs,
  //     //     rowsOnPage: data.rows.length
  //     //   });
  //   } catch (error) {
  //     showError(
  //       error?.response?.data?.message ||
  //         t("toastMessage.errorInGetting", {
  //           what: t("common.user", { count: 2 })
  //         })
  //     );
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const fetchMeCb = useCallback(() => fetchMe(), []);

  useEffect(() => {
    fetchMeCb();
    //   fetchUsers();
    //   // eslint-disable-next-line
  }, [fetchMeCb]);

  return (
    <>
      <div>
        <PageHeading className="">Facebook Accounts</PageHeading>
        {!fbTokens.some(({ userId }) => userId._id === currentUser._id) && (
          <div className="mt-3 d-flex justify-content-end">
            <Button variant="primary" onClick={navigateToSettings}>
              <span className="ms-2">{t("settings.connectFB")}</span>
            </Button>
            {/* <AdvancedFilters
            components={userFilterConfig}
            pageType=""
            pageName="name"
            btnText={t("common.assignNew.title", {
              name: t("common.user").toLowerCase()
            })}
            isResourceTypeAvailable={false}
            placeholder={t("userSearchPlaceholder")}
            handleModal={createToggleModal}
          /> */}
          </div>
        )}
        <CustomCard className="mt-4 mb-3 p-3 rounded-0">
          <BootstrapTable responsive>
            <thead>
              <tr>
                <FirstHeaderColumn className="text-center">
                  Primary
                </FirstHeaderColumn>
                <HeaderColumn>{t("common.email")}</HeaderColumn>
                <HeaderColumn>{t("common.firstName")}</HeaderColumn>
                <HeaderColumn>{t("common.lastName")}</HeaderColumn>
                <HeaderColumn>{t("common.role")}</HeaderColumn>
                <HeaderColumn>Status</HeaderColumn>
                <LastHeaderColumn />
              </tr>
            </thead>
            <tbody>
              {fbTokens.length ? (
                fbTokens.map(({ userId: user, ...item }, idx) => (
                  <TableRow key={item._id}>
                    <TableDetailCell
                      style={{ width: 50 }}
                      className="text-capitalize py-3 text-center"
                    >
                      {primaryLoadingIndex === idx ? (
                        <SpinnerButton btnStyle="btn-warning rounded-circle btn-sm" />
                      ) : (
                        <Button
                          onClick={() => toggleDialog(user, idx)}
                          size="sm"
                          disabled={primaryLoadingIndex !== -1}
                          variant={
                            item.isPrimary ? "success" : "outline-success"
                          }
                          className={`rounded-circle ${
                            item.isPrimary ? "pe-none" : ""
                          }`}
                        >
                          <FontAwesomeIcon icon={faCheck} />
                        </Button>
                      )}
                    </TableDetailCell>
                    <TableDetailCell
                      //   onClick={() => updateToggleModal(user)}
                      className="cursor-pointer py-3"
                    >
                      <NameLink to={`/dashboard/user/${user._id}`}>
                        {user.email}
                      </NameLink>
                    </TableDetailCell>
                    <TableDetailCell className="py-3">
                      {user.firstName}
                    </TableDetailCell>
                    <TableDetailCell className="py-3">
                      {user.lastName}
                    </TableDetailCell>
                    <TableDetailCell className="text-capitalize py-3">
                      {user.role}
                    </TableDetailCell>
                    <TableDetailCell className="text-capitalize py-3">
                      {user.status?.toLowerCase()}
                    </TableDetailCell>
                    <TableDetailCell
                      style={{ width: 10 }}
                      className="text-end py-3"
                    >
                      {/* <div className="d-flex justify-content-end"> */}
                      {currentUser._id === user._id && (
                        <Button
                          size="sm"
                          className="me-2 rounded-circle"
                          variant="outline-primary"
                          onClick={navigateToSettings}
                        >
                          <FontAwesomeIcon size="sm" icon={faPenToSquare} />
                        </Button>
                      )}
                      {/* <DeleteButton
                          variant={"rounded"}
                          deleteId={item._id}
                          onDelete={deleteUserToken}
                          onDeleteSuccess={fetchUsers}
                          message={{
                            success: "deleteUserTokenSuccess",
                            confirmation: "deleteUserTokenMessage"
                          }}
                        />
                      </div> */}
                    </TableDetailCell>
                  </TableRow>
                ))
              ) : (
                <NoResultsRow lengthOfColumns="8" />
              )}
            </tbody>
          </BootstrapTable>
        </CustomCard>
        {/* <CustomPagination
          paginateOpts={paginateOpts}
          activeTab={Number(searchParams.get("pageIndex")) || 1}
          select={(val) => handleQueryParams("pageIndex", val)}
        /> */}
      </div>
      {/* {show && (
        <AssignUserToken
          userId={activeUser.userId}
          onCreate={onCreate}
          onUpdate={onUpdate}
          onClose={createToggleModal}
        />
      )} */}
    </>
  );
};

export default TokenSettingsTable;
