import { useRef } from "react";
import { Controller } from "react-hook-form";
import AsyncCreatableSelect from "react-select/async-creatable";
import { useTranslation } from "react-i18next";

const AsyncCreatableSelectField = ({
  fetchData,
  optionLabel = "name",
  getOptionLabel,
  name,
  control,
  paramName = "name",
  isMulti = false,
  isClearable = false,
  placeholder,
  onCreate,
  isDisabled = false,
  capitalized = true
}) => {
  const { t } = useTranslation();
  const timer = useRef();
  const isFirstRender = useRef(true);
  const promiseOptions = (inputValue) => {
    if (isDisabled) return;
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return fetchData({ [paramName]: inputValue });
    }
    return new Promise((resolve) => {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        resolve(fetchData({ [paramName]: inputValue }));
      }, 1000);
    });
  };
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <AsyncCreatableSelect
          {...field}
          isClearable={isClearable}
          formatOptionLabel={(option) =>
            option?.__isNew__
              ? `${t("common.create")} ${option.value}`
              : getOptionLabel
                ? getOptionLabel(option)
                : option[optionLabel]
          }
          className={`w-100 ${capitalized && "text-capitalize"}`}
          onCreateOption={onCreate}
          isMulti={isMulti}
          defaultOptions
          placeholder={placeholder || t("common.select")}
          cacheOptions
          loadOptions={promiseOptions}
          getOptionValue={(option) => option._id}
          isDisabled={isDisabled}
        />
      )}
    />
  );
};

export default AsyncCreatableSelectField;
