import { Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "../components/auth/login-page";
import Dashboard from "../pages/dashboard/dashboard";
import { useSelector, useDispatch } from "react-redux";
import authConfig from "../config/auth";
import { initApp, setMe } from "../store/slices/auth";
import { useEffect } from "react";
import { getMe, getBasicTags } from "../services/api-declaration";
import { showError } from "../services/toast-service";
import { setTags } from "../store/slices/tags";
import { USER_ROLES } from "../components/strings";
import ForgotPasswordPage from "../components/auth/forgot-password";
import ResetPassword from "../components/auth/reset-password";
import Loader from "../components/loader/loader";
import { useTranslation } from "react-i18next";

const Router = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const loginUrl = auth.redirect
    ? `/login?redirect=${auth.redirect}`
    : "/login";
  const search = window.location.search;
  const dashboardUrl =
    search.split("redirect=").length > 1
      ? search.split("redirect=").pop()
      : "dashboard/funnels";
  useEffect(() => {
    const initAuth = async () => {
      const storedToken = window.localStorage.getItem(
        authConfig.storageTokenKeyName
      );
      if (storedToken) {
        try {
          const response = await getMe();
          dispatch(setMe(response.data.data));
        } catch (error) {
          console.log("error", error);
          showError("Authentication error!");
        }
      } else {
        dispatch(
          initApp({
            value: `${window.location.pathname}${window.location.search}`,
            type: "redirect"
          })
        );
      }
    };
    initAuth();
  }, [dispatch]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const tagsResponse = await getBasicTags();
        const data = tagsResponse.data.data;
        dispatch(setTags(data));
      } catch (error) {
        showError(
          t("toastMessage.errorInGetting", {
            what: "Basic Tags"
          })
        );
        console.log(error);
      }
    };
    if (auth?.user?.role === USER_ROLES.ADMIN) {
      fetchTags();
    }
    // eslint-disable-next-line
  }, [dispatch, auth?.user?.email, auth?.user?.role]);
  return (
    <>
      {auth.loading ? (
        <Loader />
      ) : (
        <Routes>
          <Route
            path="/login"
            element={
              auth.user ? (
                <Navigate to={`${dashboardUrl}`} replace />
              ) : (
                <LoginPage />
              )
            }
          />
          <Route
            path="/forgot"
            element={
              auth.user ? (
                <Navigate to="/dashboard/funnels" replace />
              ) : (
                <ForgotPasswordPage />
              )
            }
          />
          <Route
            path="/reset"
            element={
              auth.user ? (
                <Navigate to="/dashboard/funnels" replace />
              ) : (
                <ResetPassword />
              )
            }
          />
          <Route
            path="dashboard/*"
            element={
              auth.user ? (
                <Dashboard />
              ) : (
                <Navigate to={`${loginUrl}`} replace />
              )
            }
          />
          <Route
            path="*"
            element={
              auth.user ? (
                <Navigate to="/dashboard/funnels" replace />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
        </Routes>
      )}
    </>
  );
};
export default Router;
