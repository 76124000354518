import { useState, useEffect, useRef } from "react";
import QRCode from "qrcode";
import styled from "styled-components";
import { Modal, ModalTitle, Button, Form } from "react-bootstrap";
import {
  generateOTP,
  setupAuthenticator,
  setupEmailVerification
} from "../../services/api-declaration";
import { showError, showSuccess } from "../../services/toast-service";
import Loader from "../loader/loader";
import { setMe } from "../../store/slices/auth";
import SpinnerButton from "../spinner-button/spinner-button";
import { useDispatch, useSelector } from "react-redux";
import { MULTI_FACTOR_TYPES } from "../strings";
import OTPMethod from "../auth/otp-method";
import { useTranslation } from "react-i18next";
import { INITITIAL_LOADING_DATA } from "../common-confgs/common-vars";

const Container = styled.div`
  max-height: 230px;
  min-height: 230px;
`;

const SetupTwoFA = ({ toggleModal, setupType }) => {
  const { t } = useTranslation();
  const [loadingData, setLoadingData] = useState(INITITIAL_LOADING_DATA);
  const inputsRef = useRef([]);
  const dispatch = useDispatch();
  const [secret, setSecret] = useState();
  const [qrCode, setQRCode] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const otpRef = useRef();
  const submitHandler = async () => {
    try {
      setIsLoading(true);
      const { data } = await setupAuthenticator({
        token: otpRef.current?.value
      });
      dispatch(setMe({ userData: { ...user, ...data.data } }));
      toggleModal();
      showSuccess(data.message);
    } catch (error) {
      showError(
        error.response?.data?.message || t("toastMessage.verificationError")
      );
    } finally {
      setIsLoading(false);
    }
  };
  const emailVerificationHandler = async (token) => {
    try {
      setIsLoading(true);
      const { data } = await setupEmailVerification({
        token
      });
      dispatch(
        setMe({
          userData: {
            ...user,
            multiFactorEnabled: { ...user.multiFactorEnabled, email: true }
          }
        })
      );
      toggleModal();
      showSuccess(data.message);
    } catch (error) {
      showError(error.response?.data?.message || "Error in verification");
    } finally {
      inputsRef.current.forEach((input) => {
        input.disabled = false;
        input.value = "";
      });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const genOTP = async () => {
      try {
        const { data } = await generateOTP();
        setSecret(data.data.otpauth_url);
      } catch (error) {
        toggleModal();
        showError(
          error?.response?.data?.message || t("toastMessage.qrCodeError")
        );
        console.log(error);
      }
    };
    if (setupType === MULTI_FACTOR_TYPES.AUTHENTICATOR) {
      genOTP();
    }
    // eslint-disable-next-line
  }, [setupType, toggleModal]);

  useEffect(() => {
    secret && QRCode.toDataURL(secret).then(setQRCode);
  }, [secret]);

  return (
    <Modal
      size="md"
      backdrop="static"
      keyboard={false}
      onHide={() => toggleModal()}
      show={true}
      centered
    >
      <Modal.Header closeButton>
        <ModalTitle>Two-Factor Authentication (2FA)</ModalTitle>
      </Modal.Header>
      {setupType === MULTI_FACTOR_TYPES.AUTHENTICATOR ? (
        <>
          <Modal.Body>
            <h5 className="text-primary border-bottom">
              {t("multifactor.configure.heading")}
            </h5>
            <div className="mb-3">
              <ol>
                <li>{t("multifactor.configure.step1")}</li>
                <li>{t("multifactor.configure.step2")}</li>
                <li>{t("multifactor.configure.step3")}</li>
              </ol>
            </div>
            <div className="mb-3">
              <h5 className="text-primary border-bottom">
                {t("multifactor.scan.heading")} QR Code
              </h5>
              <Container className="d-flex justify-content-center align-items-center">
                {secret ? (
                  <img src={qrCode} alt="" />
                ) : (
                  <Loader calcHeight={700} />
                )}
              </Container>
            </div>
            <div>
              <h5 className="text-primary border-bottom">
                {t("multifactor.verify.heading")}
              </h5>
              <p>{t("multifactor.verify.msg")}</p>
            </div>
            <div className="w-50">
              <Form.Control
                ref={otpRef}
                type="text"
                placeholder="Authentication Code"
                className="form-control"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={isLoading} onClick={toggleModal}>
              {t("common.close")}
            </Button>
            {isLoading ? (
              <SpinnerButton
                btnStyle="btn-success"
                btnText="Verify & Activate"
              />
            ) : (
              <Button
                onClick={submitHandler}
                disabled={!secret || isLoading}
                variant="success"
              >
                {t("multifactor.btnText")}
              </Button>
            )}
          </Modal.Footer>
        </>
      ) : (
        <Modal.Body>
          <OTPMethod
            email={user.email}
            onSubmit={({ otp }) => emailVerificationHandler(otp)}
            inputsRef={inputsRef}
            loadingData={loadingData}
            setLoadingData={setLoadingData}
            defaultAuthType={MULTI_FACTOR_TYPES.EMAIL}
            authenticators={[{ text: "Email", type: MULTI_FACTOR_TYPES.EMAIL }]}
          />
        </Modal.Body>
      )}
    </Modal>
  );
};

export default SetupTwoFA;
