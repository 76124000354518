import { useFormContext } from "react-hook-form";
import { Form, InputGroup } from "react-bootstrap";
import { EURO_CURRENCY_SYMBOL } from "../strings";

const NumberInputGroup = ({
  name,
  minVal = 0,
  placeholder = "",
  inputSize = "md",
  isDisabled = false,
  groupTextSymbol = EURO_CURRENCY_SYMBOL
}) => {
  const { register } = useFormContext();
  return (
    <InputGroup size={inputSize}>
      <Form.Control
        min={minVal}
        type="number"
        {...register(name)}
        className="text-end"
        disabled={isDisabled}
        placeholder={placeholder}
        onWheel={({ target }) => target.blur()}
        onKeyDown={(e) => e.code === "Minus" && e.preventDefault()}
      />
      <InputGroup.Text>{groupTextSymbol}</InputGroup.Text>
    </InputGroup>
  );
};
export default NumberInputGroup;
