import { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faVideo } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Button, Accordion, Container } from "react-bootstrap";
import ImagePreviewModal from "../funnel-form/preview-image-modal";
import { generateAssetFeedSpecs } from "../../services/common-service";
import AbsoluteLoaderWithOverlay from "../loader/absolute-loader-with-overlay";
import CreativeFeedSpecAccordionItem from "../creative-feed-spec-accordion-item";
import { useTranslation } from "react-i18next";

const Thumb = styled.img`
  opacity: 1;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
`;
const ThumbCenter = styled.div`
  opacity: 0;
  top: 50%;
  left: 50%;
  position: absolute;
  text-align: center;
  transition: 0.5s ease;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
`;
const ThumbContainer = styled.div`
  width: 65px;
  position: relative;
  &:hover ${Thumb} {
    opacity: 0.4;
  }
  &:hover ${ThumbCenter} {
    opacity: 1;
  }
`;

const CreativeInfoCard = ({ accordionInfo }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const hasVideo = accordionInfo.videoId && !accordionInfo.previewImgUrl;
  const assetFeedSpecs = generateAssetFeedSpecs({
    t,
    data: accordionInfo.asset_feed_spec,
    insights: accordionInfo.insights
  });
  const toggleModal = () => setShowModal(!showModal);
  const handleThumbClick = () => {
    if (hasVideo) return;
    toggleModal();
  };
  return (
    <Container className="mt-2">
      <Card>
        <Card.Header className="fw-bold">
          {t("common.creative")} Info
        </Card.Header>
        <Card.Body>
          <Row className="mb-2">
            <Col xs={3} className="fw-bold">
              {t("title")}:
            </Col>
            <Col xs={9}>
              <span>{accordionInfo?.title}</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={3} className="fw-bold">
              Thumbnail:
            </Col>
            <Col xs={9}>
              <ThumbContainer>
                <Thumb alt="thumb" src={accordionInfo.thumb} />
                <ThumbCenter>
                  <Button
                    size="sm"
                    onClick={handleThumbClick}
                    variant={hasVideo ? "dark" : "primary"}
                    className={`ms-2 rounded-circle ${
                      hasVideo ? "pe-none" : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={hasVideo ? faVideo : faEye} />
                  </Button>
                </ThumbCenter>
              </ThumbContainer>
            </Col>
          </Row>
          <Accordion className="position-relative" flush>
            {accordionInfo.isLoadingInsights && <AbsoluteLoaderWithOverlay />}
            {assetFeedSpecs.map((feedSpec, index) => (
              <CreativeFeedSpecAccordionItem
                key={index}
                evKey={index}
                feedSpec={feedSpec}
                headerText={feedSpec.headerText}
              />
            ))}
          </Accordion>
        </Card.Body>
      </Card>
      {showModal && (
        <ImagePreviewModal
          source={accordionInfo?.previewImgUrl}
          toggleModal={toggleModal}
        />
      )}
    </Container>
  );
};
export default CreativeInfoCard;
