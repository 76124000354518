import { Routes, Route, Outlet } from "react-router-dom";
import AdsEvaluation from "../../components/ads-evaluation/ads-evaluations";
import AdsOverview from "../../components/ads-evaluation/overview";
import SuggestionGallery from "../../components/ads-evaluation/suggestion-gallery";
// import AdsAnalytics from "../../components/ads-evaluation/analytics";

const AdsEvaluationPage = () => {
  return (
    <>
      <Routes>
        <Route element={<AdsEvaluation />}>
          <Route index path="overview" element={<AdsOverview />} />
          <Route path="suggestion-gallery" element={<SuggestionGallery />} />
          {/* <Route path="insights" element={<AdsAnalytics />} /> */}
        </Route>
      </Routes>
      <Outlet />
    </>
  );
};
export default AdsEvaluationPage;
