import { useState } from "react";
import Form from "react-bootstrap/Form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { faRetweet } from "@fortawesome/free-solid-svg-icons";
import {
  showError,
  showSuccess,
  showWarning
} from "../../services/toast-service";
import AuthTemplate from "./auth-template";
import SubmitButton from "./submit-button";
import { resetPassword } from "../../services/api-declaration";
import { useNavigate, useSearchParams } from "react-router-dom";
import PasswordToggle from "../change-password/field-with-toggle";
import { ResetPasswordFormSchema } from "../../services/validation-schema";
import { useTranslation } from "react-i18next";

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [type, setType] = useState({
    password: "password",
    confirmPassword: "password"
  });
  const [searchParams] = useSearchParams();
  const navgiate = useNavigate();
  const passwordToggler = (field) => {
    const fieldType = type[field] === "password" ? "text" : "password";
    setType({ ...type, [field]: fieldType });
  };
  const handleLogin = async (formValues) => {
    try {
      const token = searchParams.get("token");
      if (token) {
        setIsSubmitting(true);
        const response = await resetPassword({ ...formValues, token });
        showSuccess(response.data.message);
        navgiate("/login");
      } else {
        showWarning(t("missingToken"));
      }
    } catch (error) {
      console.log(error);
      showError(error.response?.data?.message || t("toastMessage.wentWrong"));
    } finally {
      setIsSubmitting(false);
    }
  };
  const methods = useForm({
    defaultValues: { password: "", confirmPassword: "" },
    resolver: yupResolver(ResetPasswordFormSchema)
  });
  const {
    handleSubmit,
    formState: { errors }
  } = methods;
  return (
    <AuthTemplate title={t("resetPassword")}>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(handleLogin)}>
          <div className="mb-3">
            <PasswordToggle
              label={t("newPassword")}
              type={type.password}
              name="password"
              toggleHandler={passwordToggler}
              error={errors?.password}
              thinLabel
            />
          </div>
          <PasswordToggle
            label={t("confirmNewPassword")}
            type={type.confirmPassword}
            name="confirmPassword"
            toggleHandler={passwordToggler}
            error={errors?.confirmPassword}
            thinLabel
          />
          <hr />
          <div className="d-flex justify-content-between align-items-center">
            <SubmitButton
              btnText={t("reset")}
              icon={faRetweet}
              isSubmitting={isSubmitting}
              spinnerBtnText={`${t("reset")}...`}
            />
          </div>
        </Form>
      </FormProvider>
    </AuthTemplate>
  );
};
export default ResetPasswordPage;
