import { Routes, Route, Outlet } from "react-router-dom";
import Analytics from "../../components/funnels-evaluation/analytics";
import FunnelsEvaluation from "../../components/funnels-evaluation/funnels-evaluation";
import AdOptimization from "../../components/funnels-evaluation/optimization";
import EvaluationOverview from "../../components/funnels-evaluation/overview";
// import

const FunnelsEvaluationPage = () => {
  return (
    <>
      <Routes>
        <Route element={<FunnelsEvaluation />}>
          <Route index path="overview" element={<EvaluationOverview />} />
          <Route path="insights" element={<Analytics />} />
          <Route path="optimization" element={<AdOptimization />} />
        </Route>
      </Routes>
      <Outlet />
    </>
  );
};
export default FunnelsEvaluationPage;
