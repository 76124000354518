import { useFormContext } from "react-hook-form";
import usePrompt from "../../hooks/usePrompt";
import { useTranslation } from "react-i18next";

const PreventReload = ({ message = null }) => {
  const { formState } = useFormContext();
  const { t } = useTranslation();

  const msg = message ? message : t("preventReloadWarning");

  usePrompt(msg, formState.isDirty, formState.isSubmitSuccessful);
};

export default PreventReload;
