import { useParams } from "react-router";
import LeadsTable from "../leads/leads-table";
import { CustomCard } from "../common-styling";

const CustomerLeads = () => {
  const params = useParams();
  return (
    <div className="my-4">
      <CustomCard className="px-4">
        <LeadsTable customerId={params.id} limit={"5"} asSubComponent />
      </CustomCard>
    </div>
  );
};
export default CustomerLeads;
