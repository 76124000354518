// import { useState } from "react";
import { CustomCard } from "../common-styling";
import ImageWorker from "../web-worker/image-worker";
// import ImagePreviewModal from "../funnel-form/preview-image-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  getCurrencyData,
  getFormattedNumber
} from "../../services/common-service";
import { useDialog } from "../../hooks/useDialog";

const AdCard = ({ data }) => {
  const {
    thumbnail,
    image,
    leadCount,
    budgetSpent,
    leadCost,
    leadQualified,
    leadQualifiedCost,
    account_currency
    // videoInfo
  } = data;
  // const [showPreview, setShowPreview] = useState(false);
  const { t } = useTranslation();
  const statsDiv = (stats, isAmount) => (
    <div className="fs-6 fw-bolder">
      {stats
        ? isAmount
          ? getCurrencyData(account_currency, stats)
          : getFormattedNumber(stats)
        : "-"}
    </div>
  );
  const statsDivForQualified = () => (
    <div className="fs-6 fw-bolder">
      {leadQualified && budgetSpent
        ? `${getFormattedNumber(leadQualified)} / ${getCurrencyData(
            account_currency,
            leadQualifiedCost
          )}`
        : "-"}
    </div>
  );
  const { showImageDetailsDialogBox } = useDialog();

  const handlePreview = () => {
    showImageDetailsDialogBox({
      data
    });
    // setShowPreview(!showPreview);
  };
  return (
    <>
      <CustomCard className="p-2">
        <div
          className="position-absolute"
          style={{ right: "12px", top: "12px" }}
        >
          <Button
            size="sm"
            variant="outline-primary"
            className="rounded-circle d-flex justify-content-center align-items-center"
            style={{
              width: "25px",
              height: "25px"
            }}
            onClick={handlePreview}
          >
            <FontAwesomeIcon size="sm" icon={faEye} />
          </Button>
        </div>

        <div className="w-100" style={{ height: "237px" }}>
          <ImageWorker
            src={image || thumbnail}
            style={{ height: "237px", objectFit: "contain" }}
            imageClass="w-100"
          />
        </div>
        <div className="d-flex justify-content-between align-items-center mt-1">
          <div>
            <div>
              <small>{t("adEvaluation.leadCount")}</small>
              {statsDiv(leadCount)}
            </div>
            <div>
              <small>{t("adEvaluation.leadCost")}</small>
              {statsDiv(leadCost, true)}
            </div>
          </div>
          <div>
            <div>
              <small>{t("adEvaluation.budgetSpent")}</small>
              {statsDiv(budgetSpent, true)}
            </div>
            <div>
              <small>{t("adEvaluation.qualified")}</small>
              {statsDivForQualified()}
            </div>
          </div>
        </div>
      </CustomCard>
      {/* {showPreview && (
        <ImagePreviewModal source={image} toggleModal={handlePreview} />
      )} */}
    </>
  );
};

export default AdCard;
