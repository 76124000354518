import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components";

const LoaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ calcHeight }) =>
    calcHeight ? `calc(100vh - ${calcHeight}px)` : "calc(100vh - 56px)"};
`;
const SpinnerElement = styled(Spinner)`
  width: 80px;
  height: 80px;
`;

const Loader = ({ calcHeight = null }) => {
  return (
    <LoaderDiv calcHeight={calcHeight}>
      <SpinnerElement animation="border" />
    </LoaderDiv>
  );
};
export default Loader;
