import { Col, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import TextField from "./text-field";
import SelectField from "./select-field";
import { useOptionsFetch } from "../../hooks/useOptionsFetch";
import { countryOptions } from "../common-confgs/common-vars";
import AsyncCreatableSelectField from "./creatable-async-select";
import { useTranslation } from "react-i18next";

const ContactDetailsCommonFields = ({
  labelStyle = "fw-normal",
  handleAllowAddNew,
  handleCreateCityText
}) => {
  const { t } = useTranslation();
  const { control, register } = useFormContext();
  const { handleCityOptions } = useOptionsFetch();

  return (
    <>
      <Row className="mb-3">
        <Col xs={12} md={6}>
          <Form.Group>
            <Form.Label className={labelStyle}>
              {t("common.country")}
            </Form.Label>
            <SelectField
              control={control}
              name="country"
              options={countryOptions}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group controlId="city">
            <Form.Label className={labelStyle}>{t("common.city")}</Form.Label>
            <AsyncCreatableSelectField
              control={control}
              fetchData={handleCityOptions}
              name="city"
              onCreate={(name) => {
                handleCreateCityText(name);
                handleAllowAddNew(true);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <TextField
            label={t("common.address")}
            placeholder={`${t("common.customer")} ${t("common.address")} `}
            type="text"
            name="address"
            register={register}
            boldLabel={labelStyle !== "fw-normal"}
          />
        </Col>
        <Col xs={12} md={6}>
          <TextField
            label={t("common.postalCode")}
            placeholder={`${t("common.customer")} ${t("common.postalCode")}`}
            type="number"
            name="zipCode"
            register={register}
            boldLabel={labelStyle !== "fw-normal"}
            blurOnScroll
          />
        </Col>
      </Row>
    </>
  );
};

export default ContactDetailsCommonFields;
