import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import GalleryTable from "../../components/galllery-table/gallery-table";
import GalleryForm from "../../gallery-form/gallery-form";

const GalleryPage = () => {
  return (
    <>
      <Routes>
        <Route index element={<GalleryTable />} />
        <Route path=":assetId" element={<GalleryForm />} />
        <Route path="*" element={<Navigate to="/dashboard/gallery" />} />
      </Routes>
      <Outlet />
    </>
  );
};
export default GalleryPage;
