import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Card, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { useCallback, useEffect, useRef, useState } from "react";
import Loader from "../loader/loader";
import { PerformanceCard } from "./performance-card";
import {
  resetAllParams,
  setGeneralParams,
  resetFunnelParams
} from "../../store/slices/performanceReportQueryParams";
import { CustomCard, PageHeading } from "../common-styling";
import { getCustomerPerformanceReport } from "../../services/api-declaration";
import { useTranslation } from "react-i18next";

const CustomerPerformance = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [total, setTotal] = useState(0);
  const pageIndex = useRef(1);
  const { generalParams } = useSelector(
    (state) => state.performanceReportQueryParams
  );
  const switchFilters = [
    {
      label: t("common.activeCampaignsOnly"),
      fieldName: "hasFunnelsWithLeads"
    },
    {
      label: t("common.displayFunnelsWithFbLeadsOnly"),
      fieldName: "hasFbLeads"
    }
  ];

  const methods = useForm({
    defaultValues: {
      hasFunnelsWithLeads: generalParams.hasFunnelsWithLeads,
      hasFbLeads: generalParams.hasFunnelsWithFbLeads
    }
  });
  const { watch, setValue } = methods;

  const fetchCustomerPerformance = useCallback(async () => {
    const queryParams = {
      filterMode: "BT_L14D",
      page: pageIndex.current,
      hasFunnelsWithLeads: watch("hasFunnelsWithLeads"),
      hasFunnelsWithFbLeads: watch("hasFbLeads")
    };
    try {
      const response = await getCustomerPerformanceReport(
        params.id,
        queryParams
      );
      const respData = response.data.data;
      setTotal(respData?.metadata?.total);
      setReports((prevReports) => [...prevReports, ...respData.rows]);
    } catch (error) {
      console.log("ERROR: ", error);
    } finally {
      setIsLoading(false);
    }
  }, [params, pageIndex, watch]);

  useEffect(() => {
    fetchCustomerPerformance();
    return () => dispatch(resetAllParams());
  }, [fetchCustomerPerformance, dispatch]);

  const handleCheckboxChange = async (fieldName, fieldValue) => {
    setValue(fieldName, fieldValue);
    setIsLoading(true);
    dispatch(
      setGeneralParams({
        hasFunnelsWithLeads: watch("hasFunnelsWithLeads"),
        hasFunnelsWithFbLeads: watch("hasFbLeads")
      })
    );
    dispatch(resetFunnelParams());
    const queryParams = {
      filterMode: "BT_L14D",
      page: 1,
      hasFunnelsWithLeads: watch("hasFunnelsWithLeads"),
      hasFunnelsWithFbLeads: watch("hasFbLeads")
    };
    try {
      const response = await getCustomerPerformanceReport(
        params.id,
        queryParams
      );
      const respData = response.data.data;
      pageIndex.current = 1;
      setTotal(respData?.metadata?.total);
      setReports(respData.rows);
    } catch (error) {
      console.log("ERROR: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="my-4 h-75">
      <CustomCard className="px-4">
        <div className="d-flex gap-2 justify-content-between align-items-center">
          <div className="d-flex gap-2">
            <PageHeading className="">
              {t("common.performanceReport")}
            </PageHeading>
            <div className="mt-4 mb-0 fw-bold leads-header text-[var(--text-gray-muted)] align-self-center">
              (
              {` ${!isLoading ? total : ""} ${
                total === 1 ? "Funnel" : "Funnels"
              }  `}
              )
            </div>
          </div>
          <Form className="mt-4 mx-3 p-0">
            {switchFilters.map((filterItem, filterIndex) => (
              <Form.Check
                type="switch"
                key={filterIndex}
                id={filterItem.fieldName}
                className="mt-1 d-flex align-items-center gap-1 fw-semibold gap-2"
                label={filterItem.label}
                checked={watch(filterItem.fieldName)}
                disabled={isLoading}
                onChange={({ target: { checked } }) =>
                  handleCheckboxChange(filterItem.fieldName, checked)
                }
              />
            ))}
          </Form>
        </div>
        <Card.Body className="px-0">
          <InfiniteScroll
            height={700}
            scrollThreshold={0.9}
            dataLength={reports.length}
            style={{ padding: "0px 30px 0px 5px" }}
            next={() => {
              if (reports.length) {
                pageIndex.current = pageIndex.current + 1;
              }
              fetchCustomerPerformance();
            }}
            hasMore={reports.length < total}
            hasChildren={true}
            loader={
              <div className="row gy-2 justify-content-center">
                <div className="col-lg-11">
                  <div className="container">
                    <div className="row gy-2">
                      <Loader calcHeight={500} />
                    </div>
                  </div>
                </div>
              </div>
            }
            scrollableTarget="scrollableDiv"
          >
            {isLoading ? (
              <Loader colSpan={8} />
            ) : reports.length === 0 ? (
              <CustomCard className="mb-3 p-3 rounded-0">
                <h6 className="mb-0 fw-bold p-5 align-self-center">
                  {t("noData")}
                </h6>
              </CustomCard>
            ) : (
              reports.map((report, idx) => (
                <PerformanceCard
                  key={`report-${idx}`}
                  report={report}
                  customerId={params.id}
                  funnelId={report._id}
                />
              ))
            )}
          </InfiniteScroll>
        </Card.Body>
      </CustomCard>
    </div>
  );
};
export default CustomerPerformance;
