import { useRef, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Form, Row, Col, Collapse } from "react-bootstrap";
import {
  FUNNEL_DESIGNS,
  THUMBNAIL_TYPE,
  PHONE_NUMBER_RADIO,
  FORM_IMAGE_PLACEMENT
} from "../strings";
import { FieldSet, Legend } from "../common-styling";
import TextArea from "../reusable-form-fields/text-area";
import TextField from "../reusable-form-fields/text-field";
import FileInput from "../reusable-form-fields/input-file";
import RadioInput from "../reusable-form-fields/input-radio";
import { colorCodeRegex } from "../../services/common-service";
import ColorPickerWithTextField from "../reusable-form-fields/color-picker-with-text-field";
import { useTranslation } from "react-i18next";

const TextElementsSection = ({
  resourceId,
  parentFolderName,
  selectedCategory
}) => {
  const { t } = useTranslation();
  const nextBtnColorPickerRef = useRef("");
  const {
    watch,
    register,
    clearErrors,
    formState: { errors }
  } = useFormContext();

  const nextBtnBg = watch("textElements.nextButtonBackground");
  const isModernFunnel = watch("funnelDesign") === FUNNEL_DESIGNS.MODERN;
  const enablePhoneWithTwoSteps = watch("textElements.enablePhoneWithTwoSteps");
  const enableFormImage = watch("textElements.enableFormImage");
  const formImage = watch("textElements.formImage");

  const parseResourceSrc = (resourceObj) =>
    resourceObj?.key
      ? `${process.env.REACT_APP_ASSET_CDN_URL}/${resourceObj.key}/${THUMBNAIL_TYPE.ANSWER_OPTION_ICON}`
      : "";
  const parsePreviewResourceSrc = (resourceObj) =>
    resourceObj?.key ? `${resourceObj.url}/${resourceObj.key}` : "";

  useEffect(() => {
    if (
      colorCodeRegex.test(nextBtnBg) &&
      nextBtnBg.length === 7 &&
      nextBtnColorPickerRef.current
    ) {
      nextBtnColorPickerRef.current.value = nextBtnBg;
    }
  }, [nextBtnBg]);

  useEffect(() => {
    if (formImage && Object.keys(formImage)?.length) {
      clearErrors("textElements.formImage");
    }
  }, [formImage, clearErrors]);

  return (
    <div className="py-3 px-0">
      <FieldSet>
        <Legend>
          <h6 className="mb-0 fw-bold">{t("textElements")}</h6>
        </Legend>
        <div className="px-0">
          <Collapse in={!isModernFunnel}>
            <Row>
              <Col xs={12} md={6}>
                <TextField
                  label={t("nextButtonText")}
                  placeholder="Weiter"
                  type="text"
                  name="textElements.nextButtonText"
                  register={register}
                />
              </Col>
              <Col xs={12} md={6}>
                <ColorPickerWithTextField
                  label={t("nextButtonBG")}
                  placeholder="#0d6efd"
                  fieldName="textElements.nextButtonBackground"
                  fieldError={null}
                  fieldRef={nextBtnColorPickerRef}
                  defaultColorValue="#0d6efd"
                  isInGroup
                  noSpacing
                  hideErrMsg
                />
              </Col>
            </Row>
          </Collapse>
          <Row>
            <Collapse in={!isModernFunnel}>
              <Col xs={12} md={6}>
                <TextArea
                  label={t("waitingScreen")}
                  name="textElements.waitingScreen"
                  register={register}
                />
              </Col>
            </Collapse>
            <Col xs={12} md={6}>
              <TextArea
                label={t("zipCodeScreen")}
                name="textElements.zipCodeScreen"
                register={register}
              />
            </Col>
            <Col xs={12} md={6}>
              <TextField
                label={`${t("zipCodeScreen")} Button`}
                placeholder="Weiter"
                type="text"
                name="textElements.zipCodeScreenButton"
                register={register}
              />
            </Col>
          </Row>
          <Collapse in={!isModernFunnel}>
            <div>
              <Row>
                <Col xs={12} md={6}>
                  <TextArea
                    label={`${t("waitingScreen")} 2`}
                    name="textElements.waitingScreenTwo"
                    register={register}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <TextArea
                    label={`${t("formScreen")} Header`}
                    name="textElements.formScreenHeader"
                    register={register}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <TextArea
                    label={`${t("formScreen")} Subtext`}
                    name="textElements.formScreenSubtext"
                    register={register}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <TextArea
                    label={`${t("common.form")} ${t("common.headline")}`}
                    name="textElements.formHeadline"
                    register={register}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <TextArea
                    label={`${t("common.form")} Subheadline`}
                    name="textElements.subHeadlineForm"
                    register={register}
                  />
                </Col>
              </Row>
              <div>
                <Row className="mb-2">
                  <Col xs={12} md={6} className="fw-bold">
                    <Form.Check
                      role="button"
                      type="switch"
                      className="fw-bold"
                      label={t("textElementsImageCheck.switchLabel")}
                      {...register("textElements.enableFormImage")}
                    />
                  </Col>
                </Row>
                <Collapse in={enableFormImage}>
                  <div>
                    <Row>
                      <Col xs={12} md={6}>
                        <FileInput
                          label={t("textElementsImageCheck.fieldLabel")}
                          name="textElements.formImage"
                          resourceId={resourceId}
                          parentFolderName={parentFolderName}
                          url={parseResourceSrc(formImage)}
                          urlForPreview={parsePreviewResourceSrc(formImage)}
                          asset={formImage?.asset}
                          selectedCategory={selectedCategory}
                          error={errors?.textElements?.formImage}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <RadioInput
                          label={t(
                            "textElementsImageCheck.placementRadioLabel"
                          )}
                          firstOptLabel={t(
                            "textElementsImageCheck.radioBeforeLabel"
                          )}
                          secondOptLabel={t(
                            "textElementsImageCheck.radioAfterLabel"
                          )}
                          name="textElements.formImagePlacement"
                          firstValue={FORM_IMAGE_PLACEMENT.BEFORE}
                          firstValueId={FORM_IMAGE_PLACEMENT.BEFORE}
                          secondValue={FORM_IMAGE_PLACEMENT.AFTER}
                          secondValueId={FORM_IMAGE_PLACEMENT.AFTER}
                          register={register}
                          labelSpacing="mb-4"
                        />
                      </Col>
                    </Row>
                  </div>
                </Collapse>
              </div>
            </div>
          </Collapse>
          <Row>
            <Col xs={12} md={6}>
              <TextField
                label={`${t("fieldTitle")}: ${t("common.name")}`}
                placeholder={t("common.name")}
                type="text"
                name="textElements.fieldTitleName"
                register={register}
              />
            </Col>
            <Col xs={12} md={6}>
              <TextField
                label={`${t("fieldPlaceholder")}: ${t("common.name")}`}
                placeholder={t("firstAndLastName")}
                type="text"
                name="textElements.fieldPlaceholderName"
                register={register}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <TextField
                label={`${t("fieldTitle")}: E-Mail`}
                placeholder="E-Mail"
                type="mail"
                name="textElements.fieldTitleEmail"
                register={register}
              />
            </Col>
            <Col xs={12} md={6}>
              <TextField
                label={`${t("fieldPlaceholder")}: E-Mail`}
                placeholder="E-Mail Adresse"
                type="mail"
                name="textElements.fieldPlaceholderEmail"
                register={register}
              />
            </Col>
          </Row>
          <Collapse in={!isModernFunnel}>
            <div>
              <Row className="mb-2">
                <Col xs={12} md={6} className="fw-bold">
                  <Form.Check
                    role="button"
                    type="switch"
                    className="fw-bold"
                    label={t("textElementsTelephoneCheck.switchLabel")}
                    {...register("textElements.enablePhoneWithTwoSteps")}
                  />
                </Col>
              </Row>
              <Collapse in={enablePhoneWithTwoSteps}>
                <div>
                  <Row>
                    <Col xs={12} md={6}>
                      <TextField
                        type="text"
                        register={register}
                        name="textElements.phoneScreenHeading"
                        label={t("textElementsTelephoneCheck.screenHeadline")}
                        placeholder={`${t("common.enter")} ${t(
                          "textElementsTelephoneCheck.screenHeadline"
                        )}`}
                        errors={errors?.textElements?.phoneScreenHeading}
                      />
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </div>
          </Collapse>
          <Row>
            <Col xs={12} md={6}>
              <TextField
                label={`${t("fieldTitle")}: ${t("common.telephone")}`}
                placeholder={t("common.telephone")}
                type="text"
                name="textElements.fieldTitlePhone"
                register={register}
              />
            </Col>
            <Col xs={12} md={6}>
              <TextField
                label={`${t("fieldPlaceholder")}: ${t("common.telephone")}`}
                placeholder={`${t("common.telephone")} (${t("forQuestions")})`}
                type="text"
                name="textElements.fieldPlaceholderPhone"
                register={register}
              />
            </Col>
          </Row>
          <Collapse in={!isModernFunnel}>
            <Row>
              <Col xs={12} md={6}>
                <TextField
                  label={`${t("submitButton")}:`}
                  placeholder={t("getAnOffer")}
                  type="text"
                  name="textElements.submitButton"
                  register={register}
                />
              </Col>
              <Col xs={12} md={6}>
                <TextField
                  label={`${t("submitButton")} Subtext:`}
                  placeholder={`(${t("freeOfCharge")})`}
                  type="text"
                  name="textElements.submitButtonSubtext"
                  register={register}
                />
              </Col>
            </Row>
          </Collapse>
          <Row>
            <Col xs={12} md={6}>
              <RadioInput
                label={t("numberCheck")}
                firstOptLabel="Standart"
                secondOptLabel="Light"
                thirdOptLabel={t("phoneNumberVerification")}
                errors={errors?.textElements?.numberCheck}
                name="textElements.numberCheck"
                firstValue={PHONE_NUMBER_RADIO.STANDARD}
                firstValueId={PHONE_NUMBER_RADIO.STANDARD}
                secondValue={PHONE_NUMBER_RADIO.LIGHT}
                secondValueId={PHONE_NUMBER_RADIO.LIGHT}
                thirdValue={PHONE_NUMBER_RADIO.VERIFY}
                thirdValueId={PHONE_NUMBER_RADIO.VERIFY}
                register={register}
              />
            </Col>
          </Row>
        </div>
      </FieldSet>
    </div>
  );
};
export default TextElementsSection;
