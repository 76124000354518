import Select from "react-select";
import styled from "styled-components";
import { Card, Col } from "react-bootstrap";
import { FunnelTypeBadge } from "../common-styling";

const AnimationContainer = styled.div`
  position: relative;
  animation: slideUp 0.8s;
  @keyframes slideUp {
    from {
      right: -100%;
      opacity: 0;
    }
    to {
      right: 0;
      opacity: 1;
    }
  }
`;
const SelectContainer = styled(Select)`
  & > div {
    cursor: text;
  }
`;
const ForceCallContainer = styled.div`
  cursor: pointer;
  text-decoration: underline;
`;
const ControlledHeightContainer = styled.div`
  overflow: scroll;
  max-height: 650px;
`;
const ControlledHeightLoaderContainer = styled.div`
  height: 550px;
  max-height: 550px;
`;
const CustomCardHeader = styled(Card.Header)`
  z-index: 1;
`;
const CustomBadge = styled(FunnelTypeBadge)`
  font-size: 14px;
`;
const CustomDiv = styled.div`
  padding: 16px;
  border-radius: 6px;
  background: #f8f9fa;
`;
const DotSeparator = styled.div`
  width: 5px;
  height: 5px;
  margin: 0 12px;
  border-radius: 50%;
  align-self: center;
  background: var(--color-light-gray);
`;
const AbsoluteInfoContainer = styled.div`
  top: 8px;
  right: 8px;
  position: absolute;
`;
const AddressColumn = styled(Col)`
  border-right: 2px solid #d4d5d7;
`;
const CustomFontSizeDiv = styled.div`
  font-size: ${({ fs }) => `${fs}px`};
`;
const CalcDistanceText = styled.div`
  width: 70px;
  font-size: 12px;
`;
const ExtraInfoCustomBadge = styled.div`
  top: -5px;
  left: -5px;
  width: 10px;
  height: 10px;
  font-size: 0;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.3s all ease-in-out;
  &:hover {
    width: 120px;
    height: auto;
    min-height: 25px;
    padding: 2px 4px;
    font-size: 12px;
    border-radius: 6px;
    z-index: 2;
  }
`;

export {
  CustomDiv,
  CustomBadge,
  DotSeparator,
  AddressColumn,
  SelectContainer,
  CalcDistanceText,
  CustomCardHeader,
  CustomFontSizeDiv,
  AnimationContainer,
  ForceCallContainer,
  ExtraInfoCustomBadge,
  AbsoluteInfoContainer,
  ControlledHeightContainer,
  ControlledHeightLoaderContainer
};
