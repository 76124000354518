import moment from "moment";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Modal, Button, Form } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import {
  DATE_FORMAT,
  ACTIVITY_TYPES,
  DATE_REVERSE_FORMAT,
  ACTIVITY_RESULT_TYPES,
  DATE_TIME_COMPLETE_FORMAT
} from "../strings";
import { showError } from "../../services/toast-service";
import { ContactLaterDialogSchema } from "../../services/validation-schema";
import { useTranslation } from "react-i18next";

const PickerDialog = ({ show, onHide, activityHandler }) => {
  const { t } = useTranslation();
  const [isUpdating, setIsUpdating] = useState(false);
  const errorBorderClasses = "border border-2 border-danger";
  const initialValues = { date: "" };
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(ContactLaterDialogSchema)
  });
  const {
    reset,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors }
  } = methods;
  const submitDate = async (values) => {
    try {
      if (isUpdating) return;
      setIsUpdating(true);
      await activityHandler({
        type: ACTIVITY_TYPES.CALL,
        result: ACTIVITY_RESULT_TYPES.CONTACT_LATER,
        comment: `${t("common.on")} ${moment(values.date).format(
          DATE_REVERSE_FORMAT
        )}`,
        contactLaterVal: moment(values.date).format(DATE_TIME_COMPLETE_FORMAT)
      });
      handleCloseDialog();
    } catch (error) {
      console.log("Error in creating activity and updating lead", error);
      showError(error.response?.data?.message);
    } finally {
      setIsUpdating(false);
    }
  };
  const handleCloseDialog = () => {
    reset(initialValues);
    onHide();
  };
  return (
    <Modal
      show={show}
      centered
      size="md"
      backdrop="static"
      className="text-center"
      onEscapeKeyDown={(e) => e.preventDefault()}
    >
      <Modal.Header className="justify-content-center">
        <h5 className="mb-0">{t("leadAgent.contactLaterDialogHeading")}</h5>
      </Modal.Header>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(submitDate)}>
          <div className="text-center my-4">
            <ReactDatePicker
              selected={""}
              minDate={moment().add(1, "day").valueOf()}
              dateFormat={DATE_FORMAT}
              onChange={(selectedDate) => {
                setValue("date", selectedDate);
                clearErrors("date");
              }}
              calendarClassName={errors?.date ? errorBorderClasses : ""}
              inline // to show picker without display input
            />
            {errors?.date && (
              <div className="text-danger fw-bold">
                {errors?.date?.message}*
              </div>
            )}
          </div>
          <Modal.Footer>
            <Button
              disabled={isUpdating}
              onClick={handleCloseDialog}
              variant="outline-secondary"
            >
              {t("common.close")}
            </Button>
            <Button disabled={isUpdating} type="submit" variant="success">
              {t("common.save")}
            </Button>
          </Modal.Footer>
        </Form>
      </FormProvider>
    </Modal>
  );
};
export default PickerDialog;
