import { useTranslation } from "react-i18next";
import CopyToClipboardButton from "../copy-to-clipboard-button";
const LinkPreviewComponent = ({ link, linkText, show, showCopyButton }) => {
  const { t } = useTranslation();
  return (
    <div className="mt-2 d-flex align-items-center justify-content-between">
      <div>
        <span>Link-{t("common.preview")}: </span>
        {show ? (
          <span>
            <a href={link} target="_blank" rel="noreferrer">
              {linkText}
            </a>
          </span>
        ) : (
          <></>
        )}
      </div>
      {showCopyButton && (
        <CopyToClipboardButton copyWhat="Domain" linkToCopy={link} />
      )}
    </div>
  );
};
export default LinkPreviewComponent;
