import { Card, Col, Row } from "react-bootstrap";
import { CustomCard } from "../common-styling";
import { AdsEvaluationFilterConfig } from "../funnels-table/filter-config";
import AdvancedFilters from "../table-filter/advanced-filter";
import { useTranslation } from "react-i18next";
import AdCard from "./ad-card";
import { useEffect } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getAllAdCreativesEvaluation } from "../../services/api-declaration";
import Loader from "../loader/loader";
import CustomPagination from "../pagination/custom-pagination";
import { showError } from "../../services/toast-service";
import { useCallback } from "react";

const AdsOverview = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);
  const [paginateOpts, setPaginateOpt] = useState({});
  const [adCreativeData, setAdCreativeData] = useState();

  const getParamsData = () => {
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    return params;
  };

  const handleQueryParams = (property, value) => {
    searchParams.set(property, value);
    setSearchParams(searchParams);
  };

  const fetchAdCreativesEvaluation = async () => {
    try {
      setIsLoading(true);
      const paramsData = getParamsData();
      const params = { ...paramsData };
      const response = await getAllAdCreativesEvaluation(params);
      const respData = response.data.data;
      if (!respData.data.length) {
        setAdCreativeData(undefined);
        setPaginateOpt({});
        return;
      }

      setAdCreativeData(respData.data);
      setPaginateOpt({
        totalPages: Math.ceil(
          respData.metadata.total / respData.metadata.limit
        ),
        hasPrevPage: respData.metadata.hasPrevPage,
        hasNextPage: respData.metadata.hasNextPage,
        totalDocs: respData.metadata.total,
        rowsOnPage: respData.metadata.length
      });
    } catch (error) {
      setPaginateOpt({});
      setAdCreativeData(undefined);
      console.log("ERROR: ", error);
      showError(
        error.response?.data.message ||
          t("toastMessage.errorInGetting", { what: "Ad Creatives" })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAdCreativesEvaluationCB = useCallback(
    fetchAdCreativesEvaluation,
    // eslint-disable-next-line
    [searchParams]
  );

  useEffect(() => {
    fetchAdCreativesEvaluationCB();
  }, [fetchAdCreativesEvaluationCB]);

  return (
    <div className="mt-3 w-100">
      <AdvancedFilters
        hideAddNew
        isResourceTypeAvailable={false}
        heading=""
        pageType="fbAds"
        pageNameList={[
          { label: "Funnel", value: "funnelName" },
          {
            label: `${t("campaign")} ${t("common.name")}`,
            value: "campaignName"
          }
        ]}
        components={AdsEvaluationFilterConfig}
      />
      <div className="mt-3" />
      <CustomCard className="mb-3 p-3 rounded-0">
        <Card.Header className="bg-white mb-4">
          <h5>{t("adEvaluation.creativeOverview")}</h5>
        </Card.Header>
        {isLoading ? (
          <Loader colSpan={8} />
        ) : !adCreativeData?.length ? (
          <h6 className="mb-0 fw-bold p-5 align-self-center">{t("noData")}</h6>
        ) : (
          <Row>
            {adCreativeData.map((data, index) => (
              <Col md={2} sm={6} xs={12} className="my-2" key={index}>
                <AdCard data={data} />
              </Col>
            ))}
          </Row>
        )}
      </CustomCard>
      {paginateOpts.totalPages && (
        <CustomPagination
          paginateOpts={paginateOpts}
          activeTab={Number(searchParams.get("pageIndex")) || 1}
          select={(val) => handleQueryParams("pageIndex", val)}
        />
      )}
    </div>
  );
};

export default AdsOverview;
