import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BarChart from "../charts/barChart";
import { CustomCard } from "../common-styling";

const LeadServiceAnalytics = ({ data, startedAt }) => {
  const { t } = useTranslation();
  return (
    <CustomCard>
      <Card.Header className="bg-white kpi-tiles-header">
        <h5 className="mb-0 fw-bold">Lead Service 2.0 Insights</h5>
        <div className="kpi-date">( Started From: {startedAt} )</div>
      </Card.Header>
      {data.total ? (
        <BarChart
          columnWidth={"25%"}
          height={"400px"}
          isStrokeVisible
          colors={[
            "#808080",
            "#008ffbd9",
            "#dc3545",
            "#dc3545",
            "#dc3545",
            "#dc3545",
            "#20c997"
          ]}
          series={[
            {
              data: [
                data.total,
                data.open,
                data.nameWrong,
                data.numberWrong,
                data.notReached,
                data.notSuitable,
                data.suitable
              ],
              name: "Count"
            }
          ]}
          distributed
          categories={[
            t("funnelInsights.leadService.total"),
            t("funnelInsights.leadService.open"),
            t("funnelInsights.leadService.nameWrong"),
            t("funnelInsights.leadService.numberWrong"),
            t("funnelInsights.leadService.notReached"),
            t("funnelInsights.leadService.notSuitable"),
            t("funnelInsights.leadService.suitable")
          ]}
        />
      ) : (
        <h6 className="mb-0 fw-bold p-5 align-self-center">No Data</h6>
      )}
    </CustomCard>
  );
};

export default LeadServiceAnalytics;
