import {
  Col,
  Row,
  Form,
  Button,
  Collapse,
  ListGroup,
  FormControl
} from "react-bootstrap";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useOutletContext, useParams } from "react-router";
import { QueryBuilder, formatQuery } from "react-querybuilder";
import { QueryBuilderBootstrap } from "@react-querybuilder/bootstrap";
import { FUNNEL_TYPE_FILTER } from "../strings";
import PreventReload from "../funnel-form/prevent-reload";
import TextField from "../reusable-form-fields/text-field";
import SpinnerButton from "../spinner-button/spinner-button";
import { updateFunnelFilters } from "../../services/api-declaration";
import { showError, showSuccess } from "../../services/toast-service";
import { HorizontalDivider, VerticalDivider } from "../common-styling";
import ValidationMessage from "../validation-message/validation-message";
import { FunnelFiltersFormSchema } from "../../services/validation-schema";
import { useTranslation } from "react-i18next";
import "./funnel-filters.scss";

const validator = (r) => !!r.value;
const fields = [
  {
    name: "postalCode",
    label: "Postal Code",
    valueEditorType: "number",
    comparator: (f) => f.datatype === "number",
    datatype: "number",
    validator,
    operators: [
      { name: "=", label: "Exactly matches" },
      { name: "<", label: "Smaller than" },
      { name: ">", label: "Greater than" },
      { name: ">=", label: "Greater than equal to" },
      { name: "<=", label: "Smaller than equal to" },
      { name: "beginsWith", label: "Begins with" }
    ]
  }
  // {
  //     name: "email",
  //     label: "Email",
  //     validator: ({ value }) => /^[^@]+@[^@]+/.test(value),
  // }
];

const STATUS_ENUM = {
  ACTIVE: "ACTIVE",
  IN_ACTIVE: "IN-ACTIVE"
};

const initialQuery = {
  combinator: "and",
  rules: []
};

const MutliPCContainer = styled.div`
  background-color: white;
  border: 1px solid #959ba1;
  border-radius: 6px;
`;
const ExternalLinksContainer = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 10px;
`;

const MultiPCTextArea = styled(FormControl)`
  border: 0;
  margin-top: 2px;
  height: 100%;
  width: 98%;
  resize: none;
  border-radius: 0;
  &:focus {
    box-shadow: none;
  }
  &:focus-visible {
    outline: none;
  }
`;

const TextBtn = styled.h6`
  cursor: pointer;
`;

const FunnelFilters = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { funnel, setFunnel } = useOutletContext();

  const generateFilterHeading = () =>
    funnel.filters?.heading
      ? funnel.filters?.heading
      : funnel.general.funnelType === FUNNEL_TYPE_FILTER.LEAD
        ? "Anfrage außerhalb des Einzugsgebietes"
        : funnel.general.funnelType === FUNNEL_TYPE_FILTER.EMPLOYEE
          ? "Bewerbung außerhalb des Einzugsgebietes"
          : "";
  const generateFilterMessage = () =>
    funnel.filters?.message
      ? funnel.filters?.message
      : funnel.general.funnelType === FUNNEL_TYPE_FILTER.LEAD
        ? `Wir danken Ihnen herzlich für Ihr Interesse.\n\nLeider befindet sich Ihr Wohnort außerhalb des von uns definierten Einzugsgebiets.\nAus logistischen Gründen können wir Ihre Anfrage daher aktuell nicht berücksichtigen.\n\nMit freundlichen Grüßen`
        : funnel.general.funnelType === FUNNEL_TYPE_FILTER.EMPLOYEE
          ? `Vielen Dank für Dein Interesse an einer Stelle in unserem Unternehmen. Leider liegt Dein Wohnort außerhalb des von uns definierten Einzugsgebiets. Aus logistischen Gründen können wir Deine Bewerbung daher aktuell nicht berücksichtigen.\n\nWir schätzen Dein Engagement und wünschen Dir weiterhin viel Erfolg bei Deiner Jobsuche.\n\nHerzliche Grüße`
          : "";

  const initialValues = {
    heading: generateFilterHeading(),
    message: generateFilterMessage(),
    multiPostalCode: funnel.filters?.multiPostalCode || "",
    multiPostalCodes: funnel.filters?.multiPostalCodes || []
  };

  const methods = useForm({
    resolver: yupResolver(FunnelFiltersFormSchema),
    defaultValues: initialValues
  });
  const {
    getValues,
    watch,
    clearErrors,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = methods;

  const [query, setQuery] = useState(initialQuery);
  const [multiPostalCodes, setMultiPostalCodes] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSave = async () => {
    clearErrors();
    const queryData = query || initialQuery;
    setIsSubmitting(true);
    const version = funnel?.version;
    try {
      const body = {
        version,
        filters: {
          data: queryData,
          query: formatQuery(queryData, "mongodb"),
          logic: formatQuery(queryData, { format: "cel", parseNumbers: true }),
          multiPostalCodes: multiPostalCodes
            .filter((d) => d.status === STATUS_ENUM.ACTIVE)
            .map((d) => d.name),
          message: getValues("message"),
          heading: getValues("heading")
        }
      };
      const response = await updateFunnelFilters(params.id, body);
      setFunnel({ ...funnel, filters: body.filters });
      showSuccess(response.data.message || `Funnel updated successfully!`);
      reset({}); // resetting formState of isDirty for allow to navigate after save
    } catch (error) {
      showError(
        error.response.data.message || t("toastMessage.updateFunnelFilters")
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const addLocation = () => {
    const data = getValues("multiPostalCode");
    let transformedData = data
      .replace(/[^\d\n]/g, "")
      .split("\n")
      .filter((d) => d.length);
    transformedData = transformedData.map((d) => ({
      name: d,
      status: STATUS_ENUM.ACTIVE
    }));
    const collection = [...multiPostalCodes, ...transformedData];
    setMultiPostalCodes(collection);
    updateMultiPostalCodesFormValue(collection);
  };

  const togglePostalCode = (postalCode) => {
    setMultiPostalCodes((postalCodes) => {
      const newData = [
        ...postalCodes.map((p) => {
          if (p.name === postalCode.name) {
            p.status =
              p.status === STATUS_ENUM.ACTIVE
                ? STATUS_ENUM.IN_ACTIVE
                : STATUS_ENUM.ACTIVE;
          }
          return p;
        })
      ];
      return newData;
    });
  };

  const updateMultiPostalCodesFormValue = (data) =>
    setValue("multiPostalCodes", data, { shouldDirty: true });

  const handleClearAll = () => {
    setMultiPostalCodes([]);
    updateMultiPostalCodesFormValue([]);
  };

  useEffect(() => {
    if (funnel) {
      setQuery(funnel.filters?.data);
      const transformedData = funnel.filters?.multiPostalCodes.map((d) => ({
        name: d,
        status: STATUS_ENUM.ACTIVE
      }));
      setMultiPostalCodes(transformedData || []);
    }
  }, [funnel]);

  return (
    <>
      <QueryBuilderBootstrap>
        {/* <h5 className="my-4">Filters</h5> */}
        <div className="mt-4">
          <QueryBuilder
            showCombinatorsBetweenRules
            controlClassnames={{
              queryBuilder: "queryBuilder-branches",
              addRule: "as"
            }}
            fields={fields}
            query={query || initialQuery}
            onQueryChange={(q) => setQuery(q)}
            translations={{
              addGroup: { label: t("addNewGroup"), title: "" },
              addRule: {
                label: t("addNewCondition"),
                title: ""
              }
            }}
          />
        </div>
      </QueryBuilderBootstrap>
      <div className="my-4">
        <ExternalLinksContainer>
          <a
            href="https://www.freemaptools.com/find-german-postcodes-inside-radius.htm"
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="primary" size="sm" className="me-3">
              {t("funnelFilters.germany")}
            </Button>
          </a>
          <a
            href="https://www.freemaptools.com/find-switzerland-postal-codes-inside-radius.htm"
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="primary" size="sm" className="me-3">
              {t("funnelFilters.switzerland")}
            </Button>
          </a>
          <a
            href="https://www.freemaptools.com/find-austrian-postcodes-inside-radius.htm"
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="primary" size="sm" className="me-3">
              {t("funnelFilters.austria")}
            </Button>
          </a>
        </ExternalLinksContainer>
        <MutliPCContainer className="px-0">
          <>
            <Row>
              <Col xs={12} sm={3} className="position-relative">
                <div className="p-3">
                  <h5>{t("funnelFilters.multiPCHeading")}</h5>
                  <p>{t("funnelFilters.multiPCCaption")}</p>
                  <VerticalDivider />
                </div>
              </Col>
              <Col xs={12} sm={9}>
                <Form.Group className="mb-3" controlId={"multi-postal-code"}>
                  <MultiPCTextArea
                    {...register("multiPostalCode")}
                    rows={5}
                    as="textarea"
                    placeholder={t("funnelFilters.multiPCPlaceHolder")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <HorizontalDivider className="m-auto text-dark" customWidth="95" />
            <Row>
              <Col className="d-flex justify-content-end my-3">
                <Button
                  variant="primary"
                  className="me-3"
                  type="button"
                  disabled={!watch("multiPostalCode")}
                  onClick={addLocation}
                >
                  {t("funnelFilters.addLocations")}
                </Button>
              </Col>
            </Row>
          </>
        </MutliPCContainer>
      </div>
      <Collapse in={multiPostalCodes.length > 0}>
        <ListGroup>
          <div className="bg-primary rounded-top px-4 pt-1 d-flex justify-content-between">
            <h6 className="text-white">{`Selected Postal Codes (${
              multiPostalCodes.filter((d) => d.status === STATUS_ENUM.ACTIVE)
                .length
            })`}</h6>
            <TextBtn className="text-white" onClick={handleClearAll}>
              {t("funnelFilters.clearAll")}
            </TextBtn>
          </div>
          {multiPostalCodes.map((postalCode, idx) => (
            <ListGroup.Item key={idx}>
              <Form.Check
                type={"checkbox"}
                checked={postalCode.status === STATUS_ENUM.ACTIVE}
                onChange={() => togglePostalCode(postalCode)}
                label={postalCode.name}
                id={`default-checkbox`}
              />
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Collapse>
      <FormProvider {...methods}>
        <PreventReload />
        <Form className="my-4">
          {/* <Container className="px-0"> */}
          <div className="px-0">
            <Row>
              <Col>
                <TextField
                  label={t("common.heading")}
                  placeholder={t("enterHeading")}
                  type="text"
                  name="heading"
                  errors={errors?.heading}
                  register={register}
                  noSpacing
                />
                <Form.Group className="my-4" controlId={"Message"}>
                  <Form.Label className={errors.message && "text-danger"}>
                    {t("common.message")}
                    {errors.message && "*"}
                  </Form.Label>

                  <Form.Control
                    className={errors?.message ? "border-danger" : ""}
                    rows="4"
                    as="textarea"
                    placeholder={t("msgPlaceholder")}
                    {...register("message")}
                  />
                  <ValidationMessage error={errors?.message?.message} />
                </Form.Group>
              </Col>
            </Row>
          </div>
          {/* </Container> */}
          <hr />
          <div className="px-0">
            {!isSubmitting ? (
              <Button
                variant="primary"
                className="me-3 mb-3"
                type="button"
                onClick={() =>
                  !query?.rules.length
                    ? handleSave()
                    : handleSubmit(handleSave)()
                }
              >
                {t("common.save")} Funnel Filters
              </Button>
            ) : (
              <SpinnerButton
                btnText="Saving Filters"
                btnStyle="me-3 mb-3 btn-primary"
              />
            )}
          </div>
        </Form>
      </FormProvider>
    </>
  );
};

export default FunnelFilters;
