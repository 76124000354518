import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  funnelParams: {},
  generalParams: {
    page: 1,
    hasFunnelsWithLeads: false,
    hasFunnelsWithFbLeads: false
  }
};
export const performanceReportQueryParamsSlice = createSlice({
  name: "performanceReportQueryParams",
  initialState,
  reducers: {
    setGeneralParams(state, action) {
      state.generalParams.hasFunnelsWithLeads =
        action.payload.hasFunnelsWithLeads;
      state.generalParams.hasFunnelsWithFbLeads =
        action.payload.hasFunnelsWithFbLeads;
    },
    setFunnelFilterMode(state, action) {
      state.funnelParams[action.payload.funnelId] = {
        filterMode: action.payload.value
      };
    },
    resetAllParams(state, action) {
      state.funnelParams = initialState.funnelParams;
      state.generalParams = initialState.generalParams;
    },
    resetFunnelParams(state, action) {
      state.funnelParams = initialState.funnelParams;
    }
  }
});

export const {
  resetAllParams,
  setGeneralParams,
  resetFunnelParams,
  setFunnelFilterMode
} = performanceReportQueryParamsSlice.actions;
export default performanceReportQueryParamsSlice.reducer;
