import { useCallback, useEffect, useRef } from "react";
import { Chart } from "chart.js";

const BarGraph = ({ config }) => {
  const svgRef = useRef();
  const canvasRef = useRef();

  const createGraph = () => {
    const ctx = svgRef.current;
    if (canvasRef.current) {
      canvasRef.current.destroy();
    }
    canvasRef.current = new Chart(ctx, config);
  };

  //  ---------- CallBacks
  const createGraphCB = useCallback(createGraph, [config]);

  //  ---------- UseEffects
  useEffect(() => {
    return () => {
      if (canvasRef.current) {
        canvasRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (!config || !svgRef.current) return;

    createGraphCB();
  }, [config, createGraphCB]);

  return <div>{config && <canvas ref={svgRef}></canvas>}</div>;
};
export default BarGraph;
