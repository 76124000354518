import authConfig from "../config/auth";
import { logout } from "../store/slices/auth";
import axiosInstance from "./api";
const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const storedToken = localStorage.getItem(authConfig.storageTokenKeyName);
      const token = storedToken && storedToken;
      if (token) {
        config.headers["authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const { dispatch } = store;
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (error) => {
      if (error.response) {
        const { status } = error.response;
        if (
          (status === 401 || status === 403) &&
          error.config.url !== `/${authConfig.loginEndpoint}`
        ) {
          dispatch(
            logout({
              value: `${window.location.pathname}${window.location.search}`,
              type: "redirect"
            })
          );
          return Promise.reject(error);
        } else {
          return Promise.reject(error);
        }
      } else {
        return Promise.reject(error);
      }
    }
  );
};

export default setup;
