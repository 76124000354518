import { Modal, Button } from "react-bootstrap";
import { ModalHead } from "../common-styling";
import { useTranslation } from "react-i18next";

const AlertDialog = ({
  show,
  title,
  setShow,
  dialogType,
  description,
  acceptLabel,
  showFooter = true
}) => {
  const { t } = useTranslation();
  const handlClose = () => setShow(false);
  return (
    <Modal show={show} backdrop="static">
      <ModalHead
        className={`fw-bold ${dialogType === "danger" ? "text-light" : ""}`}
        bg={dialogType}
      >
        <Modal.Title>{title}</Modal.Title>
      </ModalHead>
      <Modal.Body>{description}</Modal.Body>
      {showFooter && (
        <Modal.Footer className={`border-${dialogType}`}>
          <Button variant="outline-secondary" onClick={handlClose}>
            {acceptLabel || t("common.close")}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};
export default AlertDialog;
