import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloseIconContainer } from "../common-styling";

const Img = styled(Image)`
  width: 100%;
  height: 100%;
  border-radius: 6px;
`;

const VerticallyCenteredModal = (props) => {
  return (
    <Modal {...props} size="lg" centered>
      <Img src={props.source} />
      <CloseIconContainer onClick={props.onHide}>
        <FontAwesomeIcon icon={faClose} className="text-danger" />
      </CloseIconContainer>
    </Modal>
  );
};

const ImagePreviewModal = ({ source, toggleModal }) => {
  return (
    <VerticallyCenteredModal source={source} show={true} onHide={toggleModal} />
  );
};
export default ImagePreviewModal;
