import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import UserFormPage from "../../components/user-form/user-form-page";
import UsersTable from "../../components/users-table/users-table";

const UsersPage = () => {
  return (
    <>
      <Routes>
        <Route index element={<UsersTable />} />
        <Route
          path=":id"
          element={<UserFormPage showProfileHeading={false} />}
        />
        <Route path="*" element={<Navigate to="/dashboard/user" />} />
      </Routes>
      <Outlet />
    </>
  );
};
export default UsersPage;
