import { Modal, Button } from "react-bootstrap";
import { ModalHead } from "../../common-styling";
import { useTranslation } from "react-i18next";

const ConfirmationDialog = ({
  title,
  dialogType,
  show,
  toggleDialog,
  dialogResponseAction,
  dependentStates,
  renderDialogMessage,
  acceptLabel,
  declineLabel,
  disableAcceptBtn = false
}) => {
  const { t } = useTranslation();
  const handleClick = (action) => {
    toggleDialog();
    dialogResponseAction(action, dependentStates);
  };
  return (
    <Modal show={show} onHide={toggleDialog} backdrop="static">
      <ModalHead
        className={`fw-bold ${dialogType === "warning" ? "" : "text-light"}`}
        bg={dialogType}
      >
        <Modal.Title>{title}</Modal.Title>
      </ModalHead>
      <Modal.Body>{renderDialogMessage}</Modal.Body>
      <Modal.Footer className={`border-${dialogType}`}>
        <Button variant="outline-secondary" onClick={() => handleClick(false)}>
          {declineLabel ? declineLabel : t("common.no")}
        </Button>
        <Button
          variant={dialogType}
          onClick={() => handleClick(true)}
          disabled={disableAcceptBtn}
        >
          {acceptLabel ? acceptLabel : t("common.yes")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ConfirmationDialog;
