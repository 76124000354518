import Modal from "react-bootstrap/Modal";

const FormModal = ({
  show,
  onHide,
  modalHeading,
  modalSize = "xl",
  children
}) => {
  return (
    <Modal show={show} onHide={onHide} size={modalSize} centered>
      <Modal.Header closeButton>
        <Modal.Title className="h5">{modalHeading}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mb-3">{children}</Modal.Body>
    </Modal>
  );
};
export default FormModal;
