import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import OTPModal from "./otp-modal";
import { CustomCard } from "../common-styling";
import PasswordToggle from "./field-with-toggle";
import PreventReload from "../funnel-form/prevent-reload";
import { ChangePasswordFormSchema } from "../../services/validation-schema";
import { useTranslation } from "react-i18next";

const ChangePassword = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const defaultValues = {
    oldPassword: "",
    newPassword: "",
    cNewPassword: ""
  };
  const methods = useForm({
    resolver: yupResolver(ChangePasswordFormSchema),
    defaultValues
  });
  const [type, setType] = useState({
    oldPassword: "password",
    newPassword: "password",
    cNewPassword: "password"
  });
  const passwordToggler = (field) => {
    const fieldType = type[field] === "password" ? "text" : "password";
    setType({ ...type, [field]: fieldType });
  };
  const {
    handleSubmit,
    formState: { errors },
    getValues
  } = methods;
  const handleChangePassword = async () => {
    setShowModal(true);
  };
  return (
    <>
      <div className="my-4">
        <h5 className="mb-4 text-start">
          {t("common.changePassword")}
          {/* <FontAwesomeIcon icon={faKey} className="ms-2" /> */}
        </h5>
        <div>
          <Row className="justify-content-center">
            <Col sm={12} md={12}>
              <CustomCard className="p-3">
                <FormProvider {...methods}>
                  <PreventReload />
                  <form onSubmit={handleSubmit(handleChangePassword)}>
                    <div>
                      <Row className="gy-3">
                        <Col sm={12}>
                          <PasswordToggle
                            toggleHandler={passwordToggler}
                            error={errors?.oldPassword}
                            name="oldPassword"
                            type={type.oldPassword}
                            label={`${t("common.old")} ${t("password")}`}
                          />
                        </Col>
                        <Col sm={12}>
                          <PasswordToggle
                            toggleHandler={passwordToggler}
                            error={errors?.newPassword}
                            name="newPassword"
                            type={type.newPassword}
                            label={`${t("common.new")} ${t("password")}`}
                          />
                        </Col>
                        <Col sm={12}>
                          <PasswordToggle
                            toggleHandler={passwordToggler}
                            error={errors?.cNewPassword}
                            name="cNewPassword"
                            type={type.cNewPassword}
                            label={t("confirmNewPassword")}
                          />
                        </Col>
                      </Row>
                      <div className="mt-3 d-flex justify-content-end">
                        <div>
                          <Button type="submit">
                            {t("common.changePassword")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </FormProvider>
              </CustomCard>
            </Col>
          </Row>
        </div>
      </div>
      {showModal && (
        <OTPModal setShowModal={setShowModal} getValues={getValues} />
      )}
    </>
  );
};
export default ChangePassword;
