import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ForwardedStatusOverlay = ({ icon, tooltip }) => {
  return (
    <OverlayTrigger placement="top" overlay={<Tooltip>{tooltip}</Tooltip>}>
      <span>{icon}</span>
    </OverlayTrigger>
  );
};
export default ForwardedStatusOverlay;
