import React, { useState } from "react";
import moment from "moment";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import DatePickerLimitRange from "../../analytics/date-range-picker";
// import { DATE_RANGE_MODE } from "../../analytics/analytics-helper";
import Select from "react-select";
import {
  DATE_FILTERS,
  PICKER_LAYOUT,
  // PICKER_OPT_A,
  // PICKER_OPT_B,
  dateRangeGenerator,
  showDatePicker
} from "../picker-helper";
import styled from "styled-components";
import { useEffect } from "react";

const ToggleBtn = styled(ToggleButton)`
  z-index: 0 !important;
`;

const DateSelectorToolbar = ({
  fetchData,
  options,
  layout,
  defaultSelected,
  minDate
}) => {
  const [selectedDateFilter, setSelectedDateFilter] = useState(defaultSelected);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);

  const handleDateChange = async (event) => {
    let _selectedDateFilter;
    if (event) {
      _selectedDateFilter = event.value || event.target.value;
    } else {
      _selectedDateFilter = selectedDateFilter;
    }
    setSelectedDateFilter(_selectedDateFilter);
    const [startDate, endDate] = _handleDateChange(
      _selectedDateFilter,
      new Date()
    );
    await fetchData({
      selectedDateFilter: _selectedDateFilter,
      startDate,
      endDate
    });
  };

  useEffect(() => {
    handleDateChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRangeChange = async (range) => {
    if (selectedDateFilter !== DATE_FILTERS.RANGE) {
      const [startDate, endDate] = _handleDateChange(selectedDateFilter, range);
      await fetchData({
        selectedDateFilter,
        startDate,
        endDate
      });
      return;
    }
    setDateRange(range);
    if (range[0] && range[1]) {
      const [startDate, endDate] = range;
      setSelectedDate(startDate);
      await fetchData({
        selectedDateFilter,
        startDate,
        endDate
      });
    }
  };

  const _handleDateChange = (selectedValue, date) => {
    const { startDate, endDate } = dateRangeGenerator(selectedValue, date);
    setDateRange([startDate, endDate]);
    setSelectedDate(startDate);
    return [startDate, endDate];
  };

  const handleLeftBtn = async () => {
    let date = moment(dateRange[0]);
    switch (selectedDateFilter) {
      case DATE_FILTERS.DAY:
        date.subtract(1, "day");
        break;
      case DATE_FILTERS.WEEK:
        date.subtract(7, "days");
        break;
      case DATE_FILTERS.MONTH:
        date.subtract(1, "month");
        break;
      case DATE_FILTERS.YEAR:
        date.subtract(1, "year");
        break;
      default:
        break;
    }
    const [startDate, endDate] = _handleDateChange(
      selectedDateFilter,
      date.toDate()
    );
    await fetchData({
      selectedDateFilter,
      startDate,
      endDate
    });
  };

  const handleRightBtn = async () => {
    let date = moment(dateRange[1]);
    date.add(1, "day");

    if (date < new Date()) {
      const [startDate, endDate] = _handleDateChange(
        selectedDateFilter,
        date.toDate()
      );
      await fetchData({
        selectedDateFilter,
        startDate,
        endDate
      });
    }
  };

  return (
    <div
      className="d-flex justify-content-end"
      // style={{ width: "fit-content", minWidth: "400px" }}
    >
      {showDatePicker(selectedDateFilter) && (
        <DatePickerLimitRange
          mode={DATE_FILTERS}
          className="w-50"
          dateRangeMode={selectedDateFilter}
          maxDate={moment.now()}
          dateRange={dateRange}
          handleLeftBtn={handleLeftBtn}
          handleRightBtn={handleRightBtn}
          selectedDate={selectedDate}
          onRangeChange={onRangeChange}
          minDate={minDate ? new Date(minDate) : undefined}
        />
      )}

      {layout === PICKER_LAYOUT.TABS ? (
        <div className="kpi-tiles-header">
          <ButtonGroup>
            {options.map((item, idx) => (
              <ToggleBtn
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                className="kpi-toggle-button"
                name="radio"
                value={item.name}
                checked={selectedDateFilter === item.name}
                onChange={handleDateChange}
              >
                {item.name}
              </ToggleBtn>
            ))}
          </ButtonGroup>
        </div>
      ) : (
        <Select
          className="range_select"
          options={options.map((item) => ({
            label: item.name,
            value: item.name
          }))}
          value={{ label: selectedDateFilter, value: selectedDateFilter }}
          onChange={handleDateChange}
        />
      )}
    </div>
  );
};

export default DateSelectorToolbar;
