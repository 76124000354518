import {
  Col,
  Row,
  Form,
  Button,
  Tooltip,
  Collapse,
  OverlayTrigger
} from "react-bootstrap";
import { useState } from "react";
import styled from "styled-components";
import { useOutletContext } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import {
  TONALITY_TYPES,
  FUNNEL_TYPE_FILTER,
  EMOJI_SELCECT_LABELS
} from "../strings";
import AdCopyResults from "./ad-copy-results";
import EditPromptModal from "./edit-prompt-modal";
import { showError } from "../../services/toast-service";
import PreventReload from "../funnel-form/prevent-reload";
import TextField from "../reusable-form-fields/text-field";
import RadioInput from "../reusable-form-fields/input-radio";
import { useOptionsFetch } from "../../hooks/useOptionsFetch";
import { FontSizeTwelve, CustomCard } from "../common-styling";
import { generateInstruction } from "../../ad-copy-prompt/prompt1";
import { AdCopyScehma } from "../../services/validation-schema";
import AsyncSelectField from "../reusable-form-fields/async-select";
import TextArea from "../../components/reusable-form-fields/text-area";
import { useTranslation } from "react-i18next";

const CustomBadge = styled.div`
  top: -10px;
  right: -8px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  background: #1b8754;
  cursor: pointer;
`;

const AdCopy = () => {
  const { t } = useTranslation();
  const { funnel } = useOutletContext();
  const getFunnelURL = () => {
    let funnelUrl = "";
    if (funnel.subDomain && funnel.domain) {
      funnelUrl = `https://${funnel.subDomain}.${funnel.domain}`;
    }
    return funnelUrl;
  };
  const methods = useForm({
    defaultValues: {
      companyName: funnel.companyInfo.companyName,
      category: { ...funnel.category },
      funnelUrl: getFunnelURL(),
      type: funnel.general.funnelType,
      emojis: EMOJI_SELCECT_LABELS.Ja,
      tonality: TONALITY_TYPES.SERIOUS,
      specialInstruction: "",
      speech: "du"
    },
    resolver: yupResolver(AdCopyScehma)
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch
  } = methods;

  const tonalityValue = watch("tonality");
  const [adCopyResultsData, setAdCopyResultsData] = useState({
    show: false,
    results: []
  });
  const { trainingExample1, trainingExample2, trainingExample3 } =
    funnel.category;
  const initialDataCondition = Boolean(
    trainingExample1 || trainingExample2 || trainingExample3
  );
  const [isDataCompleted, setIsDataCompleted] = useState(initialDataCondition);
  const [modalContent, setModalContent] = useState([]);
  const onSubmit = (formValues) => {
    setAdCopyResultsData({ results: [], show: false });
    const { trainingExample1, trainingExample2, trainingExample3 } =
      formValues.category;
    const {
      category: { name },
      companyName,
      funnelUrl,
      type,
      emojis,
      tonality,
      specialInstruction,
      speech
    } = formValues;
    const isLeadFunnel = type === FUNNEL_TYPE_FILTER.LEAD ? true : false;
    const messages = generateInstruction({
      speech,
      productName: name,
      companyName,
      funnelURL: funnelUrl,
      isLeadFunnel,
      specialInstruction,
      shouldUseEmoji: emojis,
      tonality,
      example1: trainingExample1,
      example2: trainingExample2,
      example3: trainingExample3
    });
    setModalContent(messages);
  };

  const onCreatePrompt = (data) => {
    try {
      if (!data.data?.length) throw Error("No Data");
      const serverResponse = data.data[0].message.content;
      // curly braces regex
      const regexsForDataExtraction = /\{\{\{([\s\S]*?)\}\}\}/g;
      const results = [];
      let match;
      while ((match = regexsForDataExtraction.exec(serverResponse)) !== null) {
        results.push(match[1].trim()); // Push the matched text (without curly braces) into the array
      }
      setAdCopyResultsData({ show: true, results });
      setModalContent([]);
    } catch (error) {
      showError(error.response.data.message);
      console.log(error);
    }
  };
  const { handleCategoryOptions } = useOptionsFetch();
  const handleCategorySelect = (value) => {
    const { trainingExample1, trainingExample2, trainingExample3 } = value;
    const dataCondition = Boolean(
      trainingExample1 || trainingExample2 || trainingExample3
    );
    setIsDataCompleted(dataCondition);
  };

  return (
    <>
      <FormProvider {...methods}>
        <PreventReload />
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomCard className="position-relative mt-4">
            {isDataCompleted && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip">{t("adCopy.dataCompleted")}</Tooltip>
                }
              >
                <CustomBadge />
              </OverlayTrigger>
            )}
            <div className="p-4">
              <Row className="mb-4">
                <Col xs={12} md={6}>
                  <TextField
                    type="text"
                    name="companyName"
                    register={register}
                    label={t("common.company")}
                    errors={errors?.companyName}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label>
                      {t("common.product")} / {t("job")}
                    </Form.Label>
                    <AsyncSelectField
                      control={control}
                      name="category"
                      fetchData={handleCategoryOptions}
                      onSelect={handleCategorySelect}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <TextField
                    type="text"
                    name="funnelUrl"
                    register={register}
                    label="Funnel-URL"
                    errors={errors?.funnelUrl}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="type">
                    <Form.Label>{t("common.type")}</Form.Label>
                    <Form.Select {...register("type")}>
                      <option>{FUNNEL_TYPE_FILTER.LEAD}</option>
                      <option>{FUNNEL_TYPE_FILTER.EMPLOYEE}</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3" controlId="emojis">
                    <Form.Label>{t("adCopy.emojis")}</Form.Label>
                    <Form.Select {...register("emojis")}>
                      {Object.values(EMOJI_SELCECT_LABELS).map(
                        (value, index) => (
                          <option key={index}>{value}</option>
                        )
                      )}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="tonalität">
                    <Form.Label>{t("tonality")}</Form.Label>
                    <Form.Select {...register("tonality")}>
                      {Object.values(TONALITY_TYPES).map((value, index) => (
                        <option value={value} key={index}>
                          {t(`tonalities.${value}.title`)}
                        </option>
                      ))}
                    </Form.Select>
                    <FontSizeTwelve className="text-muted my-2">
                      {t(`tonalities.${tonalityValue}.description`)}
                    </FontSizeTwelve>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <TextArea
                    label={t("specialInstructions")}
                    name="specialInstruction"
                    register={register}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <RadioInput
                    label={t("speech")}
                    firstOptLabel="Du" // formal
                    secondOptLabel="Sie" // Informal
                    name="speech" // Translation of Ansprache, formal or informal
                    firstValue={"du"}
                    firstValueId={"du"}
                    secondValue={"sie"}
                    secondValueId={"sie"}
                    register={register}
                  />
                </Col>
              </Row>
            </div>
          </CustomCard>
          <div className="d-flex justify-content-end my-3">
            <Button type="submit">
              <FontAwesomeIcon icon={faWandMagicSparkles} className="me-1" />
              {adCopyResultsData.show && adCopyResultsData.results.length === 3
                ? "Re-Generate"
                : t("common.generate")}{" "}
              {t("adCopy.title")}
            </Button>
          </div>
        </form>
      </FormProvider>
      <Collapse in={adCopyResultsData.show}>
        <div>
          <AdCopyResults results={adCopyResultsData.results} />
        </div>
      </Collapse>
      {modalContent.length > 0 && (
        <EditPromptModal
          closeModal={() => setModalContent([])}
          onCreatePrompt={onCreatePrompt}
          content={modalContent}
        />
      )}
    </>
  );
};

export default AdCopy;
