import {
  faPlus,
  faTrashCan,
  faWandMagicSparkles
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import styled from "styled-components";
import { createAdCopies } from "../../services/api-declaration";
import { showError } from "../../services/toast-service";
import TextArea from "../reusable-form-fields/text-area";
import EmojiTextarea from "../reusable-form-fields/text-area-wtih-emoji";
import TextField from "../reusable-form-fields/text-field";
import SpinnerButton from "../spinner-button/spinner-button";

const DeleteButton = styled.div`
  right: 0;
  color: red;
  cursor: pointer;
  border: 1px solid;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  top: -10px;
`;

const EditPromptModal = ({ content = [], onCreatePrompt, closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm({
    defaultValues: {
      fields: [...content],
      max_tokens: "4989",
      temperature: "1"
    }
  });
  const { control, register, handleSubmit } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: `fields` // unique name for your Field Array
  });
  return (
    <Modal
      show={true}
      size="xl"
      centered
      onHide={isLoading ? () => {} : closeModal}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title className="h5" id="contained-modal-title-vcenter">
          Edit Prompt
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-2 pt-4">
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(async function (formValues) {
              try {
                if (isLoading) return;
                setIsLoading(true);
                const results = [];
                formValues.fields.forEach((value) => {
                  if (value.type) {
                    results.push({ role: value.role, content: value.content });
                  } else {
                    results.push(value);
                  }
                });
                const { data } = await createAdCopies({
                  messages: results,
                  max_tokens: Number(formValues.max_tokens),
                  temperature: Number(formValues.temperature)
                });
                onCreatePrompt(data);
              } catch (error) {
                showError("Something went wrong..");
                console.log(error);
              } finally {
                setIsLoading(false);
              }
            })}
          >
            <Container>
              <Row className="gy-3">
                {fields.map((e, i) => (
                  <Col key={i} sm={12} md={6}>
                    {e.type === "example" ? (
                      <EmojiTextarea
                        label={`Example ${i}`}
                        name={`fields[${i}].content`}
                        placeholder="Edit training example"
                        rows="4"
                      />
                    ) : (
                      <div className="position-relative">
                        {e.type === "removable" && (
                          <DeleteButton
                            onClick={() => remove(i)}
                            className="position-absolute d-flex justify-content-center align-items-center"
                          >
                            <FontAwesomeIcon icon={faTrashCan} />
                          </DeleteButton>
                        )}
                        <TextArea
                          label={`Field ${i + 1}`}
                          name={`fields[${i}].content`}
                          register={register}
                        />
                      </div>
                    )}
                  </Col>
                ))}
                <TextField
                  type="number"
                  name="temperature"
                  register={register}
                  label="Temperature"
                  noSpacing
                  required
                  step="any"
                />
                <div className="text-muted my-2">Enter value between 0 - 2</div>
                <TextField
                  type="number"
                  name="max_tokens"
                  register={register}
                  label="Maximum Length"
                  noSpacing
                  required
                  step="any"
                />
                <div className="text-muted my-2">
                  Enter value between 1 - 8191
                </div>
              </Row>
            </Container>
            {isLoading ? (
              <SpinnerButton btnText="Generating" />
            ) : (
              <Button type="submit">
                <FontAwesomeIcon icon={faWandMagicSparkles} className="me-1" />
                Generate Ad Copy
              </Button>
            )}
          </form>
        </FormProvider>
        <div className="d-flex justify-content-end">
          <Button
            disabled={isLoading}
            onClick={() =>
              append({ role: "system", content: "", type: "removable" })
            }
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditPromptModal;
