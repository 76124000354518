import styled from "styled-components";
import { Card, Container } from "react-bootstrap";
import CardHeader from "react-bootstrap/esm/CardHeader";

const AuthContainer = styled(Container)`
  width: 100vw;
  height: 100vh;
`;
const CardStyle = styled(Card)`
  min-width: ${({ width }) => `${width}px`};
  border: 1px solid var(--dark-bg-color);
`;
const CardHeaderStyle = styled(CardHeader)`
  padding-bottom: 10px;
`;

const AuthTemplate = ({ children, title, width = 350 }) => {
  return (
    <AuthContainer className="d-flex align-items-center justify-content-center">
      <CardStyle width={width}>
        <CardHeaderStyle className="text-white bg-dark">
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="mb-0">{title}</h4>
            <div className="text-muted">A&M FunnelHub</div>
          </div>
        </CardHeaderStyle>
        <Card.Body>{children}</Card.Body>
      </CardStyle>
    </AuthContainer>
  );
};

export default AuthTemplate;
