import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import NestedTabsComponent from "../nested-tabs-component";
import { CREATIVE_DASHBOARD_TABS, NESTED_SIDENAV } from "../strings";

const AdsEvaluation = () => {
  const { t } = useTranslation();
  return (
    <div className="d-flex h-100">
      <div className="sub-sidebar">
        <div className="inner-sub-sidebar">
          <div className="tab-heading ps-3 mt-4">{t("creativeDashboard")}</div>
          <NestedTabsComponent
            nestedNavType={NESTED_SIDENAV.ADS_EVALUATION}
            clearParams
            tabs={[
              { evKey: CREATIVE_DASHBOARD_TABS.OVERVIEW, title: t("overview") },
              {
                evKey: CREATIVE_DASHBOARD_TABS.SUGGESTION_GALLERY,
                title: t("adEvaluation.suggestionGallery")
              }
            ]}
          />
        </div>
      </div>
      <div className="outlet-wrapper">
        <Outlet />
      </div>
    </div>
  );
};
export default AdsEvaluation;
