import { Button, Modal } from "react-bootstrap";
import UserForm from "../user-form/profile";
import { ModalHead } from "../common-styling";
import { createUser } from "../../services/api-declaration";
import { showError, showSuccess } from "../../services/toast-service";
import { useTranslation } from "react-i18next";

const CreateUser = ({ onClose, onCreate }) => {
  const { t } = useTranslation();
  const handleSubmit = async (formValues) => {
    try {
      const { data } = await createUser(formValues);
      onCreate(data.data);
      showSuccess(data.message || "Benutzer erfolgreich erstellt!");
    } catch (error) {
      showError(
        error?.response?.data?.message || t("toastMessage.createUserError")
      );
    }
  };
  return (
    <>
      <Modal centered size="lg" show={true}>
        <ModalHead className="fw-bold text-light" bg="primary">
          <Modal.Title>{t("createUser")}</Modal.Title>
        </ModalHead>
        <Modal.Body>
          <UserForm handleFormSubmit={handleSubmit} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            {t("common.close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateUser;
