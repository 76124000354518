import axios from "axios";
import api from "../apiService/api";

export const axiosFetch = (url, options = {}) => {
  return api.get(`/${url}`, options);
};

export const axiosPost = (url, body, options = {}) => {
  return api.post(`/${url}`, body, options);
};

export const axiosPatch = (url, body) => {
  return api.patch(`/${url}`, body);
};

export const axiosDelete = (url, body) => {
  return api.delete(`/${url}`, { data: body });
};

export const axioxFileUpload = (
  url,
  postData,
  uploadProgressCb,
  isExternal = true
) => {
  const formData = new FormData();
  for (let prop in postData) {
    formData.append(prop, postData[prop]);
  }
  if (isExternal) {
    return axios.post(url, formData, {
      onUploadProgress: _uploadProgressHandler(uploadProgressCb)
    });
  } else {
    return api.post(`/${url}`, postData, {
      onUploadProgress: _uploadProgressHandler(uploadProgressCb)
    });
  }
};
export const axiosGetBuildFile = () => {
  return axios.get("/index.html", { responseType: "text" });
};
export const axiosDownload = ({
  url,
  hasAbsoluteUrl = false,
  queryParams = {}
}) => {
  const respTypeBlob = { responseType: "blob" };
  return hasAbsoluteUrl
    ? axios.get(url, respTypeBlob)
    : api.get(`/${url}`, { ...respTypeBlob, params: queryParams });
};

function _uploadProgressHandler(progressCb) {
  return (progressEvent) => {
    const percentCompleted = (progressEvent.loaded / progressEvent.total) * 100;
    progressCb(percentCompleted);
  };
}
