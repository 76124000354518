import { useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { changepassword } from "../../services/api-declaration";
import OTPMethod from "../auth/otp-method";
import { useNavigate } from "react-router";
import { INITITIAL_LOADING_DATA } from "../common-confgs/common-vars";
import { MULTI_FACTOR_TYPES } from "../strings";
import { showError, showSuccess } from "../../services/toast-service";
import { useTranslation } from "react-i18next";

const OTPModal = ({ setShowModal, getValues }) => {
  const { t } = useTranslation();
  const [loadingData, setLoadingData] = useState(INITITIAL_LOADING_DATA);
  const inputsRef = useRef([]);
  const user = useSelector((state) => state.auth.user);
  const nvaigate = useNavigate();
  const handleChangePassword = async (otp) => {
    try {
      const formValues = getValues();
      await changepassword({ ...formValues, otp });
      showSuccess(t("passwordChangeMsg"));
      nvaigate("/");
    } catch (error) {
      console.log(error);
      showError(
        error?.response?.data?.message || t("toastMessage.changePassword")
      );
    } finally {
      inputsRef.current.forEach((input) => {
        input.value = "";
        input.disabled = false;
      });
    }
  };
  return (
    <Modal
      size="md"
      backdrop="static"
      keyboard={false}
      onHide={() => setShowModal(false)}
      show={true}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="h5" id="contained-modal-title-vcenter">
          Enter Code
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <OTPMethod
          email={user.email}
          onSubmit={({ otp }) => handleChangePassword(otp)}
          inputsRef={inputsRef}
          loadingData={loadingData}
          setLoadingData={setLoadingData}
          defaultAuthType={MULTI_FACTOR_TYPES.EMAIL}
          authenticators={[{ text: "Email", type: MULTI_FACTOR_TYPES.EMAIL }]}
        />
      </Modal.Body>
    </Modal>
  );
};

export default OTPModal;
